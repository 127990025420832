﻿const toastr = window.toastr;

window.app.service('pointeditorService', [
  'modelService',
  'userAccountService',
  'mapService',
  pointeditorService,
]);

function pointeditorService(modelService, userAccountService, mapService) {
  var pointeditorService = {
    getItems: getItems,
    getOnderzoekInstumentaria: getOnderzoekInstumentaria,
    selectItem: selectItem,
    saveItem: saveItem,
  };

  async function getItems(ids) {
    ids = ids || [];
    if (ids.length) {
      return userAccountService.getOnderzoekenById(ids);
    } else {
      return [];
    }
  }

  async function getOnderzoekInstumentaria(id) {
    if (id) {
      const instrumentaria =
        await userAccountService.getOnderzoekInstumentaria(id);
      return instrumentaria.map((instrumentarium) =>
        Object.defineProperty(instrumentarium, 'primairid', { value: id }),
      );
    } else {
      return [];
    }
  }

  function selectItem(item) {
    modelService.update('model-selectie', [item.id], true);
  }

  async function saveItem(item) {
    await userAccountService.putOnderzoeken([item]);
    toastr.success('Wijzigingen zijn bewaard');
    mapService.refresh(['Meetpunten']);
  }

  return pointeditorService;
}
