﻿import icVisibilitySvg from '../../images/svgs/ic_visibility.svg';

const Slick = window.Slick;

window.app.factory('gridMetadata', ['$rootScope', 'gridFactory', gridMetadata]);

function gridMetadata($rootScope, gridFactory) {
  var groupings;
  var columns;
  var views;
  var gridfactory;
  const colDefs = {};

  initArrays();

  $rootScope.$on('model-grid', (_, model) => {
    if (model.grid.METADATA) {
      const options = {
        editable: false,
        autoEdit: false,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 25,
        enableColumnReorder: true,
        showHeaderRow: true,
        defaultColumnWidth: 75,
      };

      initColumns();
      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(model.grid.METADATA, columns, options, 'METADATA')
        .then(() => {
          setView(0);
          gridfactory.getMetaData(model);
        });
    }
  });

  $rootScope.$on('model-selectie', (_, model) => {
    if (model.grid.METADATA && model.onderzoekSelectedFromMap) {
      gridFactory.highlightRow(model.selectie[0]);
    }
  });

  $rootScope.$on('model-getFeatures', (_, model) => {
    if (
      model.grid.METADATA &&
      model.modus().startsWith('K') &&
      !model.filter.KAART
    ) {
      gridFactory.getMetaData(model);
    }
  });

  $rootScope.$on('model-modus', (_, model) => {
    if (model.grid.METADATA) {
      gridFactory.getMetaData(model);
    }
  });

  // No model-modus on P to P
  $rootScope.$on('model-project', (_, model) => {
    if (model.grid.METADATA && model.modus() === 'P') {
      gridFactory.getMetaData(model);
    }
  });

  // No model-modus on PF to PF
  $rootScope.$on('model-filter-kaart', (_, model) => {
    if ((model.grid.METADATA && model.modus() === 'PF') || model.filter.KAART) {
      gridFactory.getMetaData(model);
    }
  });

  var gridMetadata = {
    destroyGrid: destroyGrid,
    setGrouping: setGrouping,
    getGroupings: getGroupings,
    getViews: getViews,
    setView: setView,
  };
  return gridMetadata;

  function initArrays() {
    groupings = [
      { id: 'geen', label: 'Niets' },
      //{ id: "onderzoekstype", label: "Type onderzoek" },
      //{ id: "onderzoeksstatus", label: "Status onderzoek" },
      //{ id: "xystatus", label: "Status XY" }
    ];

    colDefs.generic = [
      {
        id: '_checkbox_selector',
        nameExact: `<image src="${icVisibilitySvg}" id="grid-extraFilter">`,
        toolTip: '',
        field: 'id',
        width: 25,
        formatter: Slick.Formatters.Selector,
        sortable: false,
      },
      {
        id: 'projectnummer',
        name: 'projectnr',
        toolTip: 'projectnummer',
        formatter: Slick.Formatters.Integer,
        width: 80,
      },
      'naam',
      { id: 'id', formatter: Slick.Formatters.Integer, width: 80 },
    ];

    colDefs.bhrgt = [
      { id: 'objectidbronhouder', name: 'objectid' },
      'bhrgt_kwaliteitsregime',
      {
        id: 'einddiepteboring',
        name: 'einddiepte boring m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'einddieptebemonstering',
        name: 'einddiepte bemonstering m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'boordatum',
        cssClass: 'right',
      },
      'bhrgt_stopcriterium',
      'bodemgebruik',
      { id: 'liggingopgrondlichaam', name: 'ligging op grondlichaam' },
      {
        id: 'bhrgt_grondwaterstand',
        name: 'GWS tijdens boren m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'ghg',
        name: 'GHG m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'glg',
        name: 'GLG m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'bhrgt_waterdiepte',
        name: 'waterdiepte m',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'ondergrondverontreinigd',
        name: 'ondergrond verontreinigd',
        formatter: Slick.Formatters.Checkmark,
      },
      'boorprocedure',
      'boortechniek',
      'bemonsteringprocedure',
      {
        id: 'sliblaagdikte',
        formatter: Slick.Formatters.Float3,
      },
      'sliblaagkleur',
      { id: 'uitvoerderboring', name: 'KVK uitvoerder boring' },
      { id: 'dataleverancier', name: 'KVK dataleverancier' },
      { id: 'bhrgt_bronhouder', name: 'KVK bronhouder' },
      {
        id: 'gatafgewerkt',
        name: 'gat afgewerkt',
        formatter: Slick.Formatters.Checkmark,
      },
      { id: 'bhrgt_methodelocatiebepaling', name: 'methode XY' },
      {
        id: 'datumlocatiebepaling',
        name: 'datum XY',
        formatter: Slick.Formatters.DatePoint,
      },
      {
        id: 'verschuiving',
        name: 'Z m NAP',
        formatter: Slick.Formatters.Plus2,
      },
      { id: 'methodeverticalepositiebepaling', name: 'methode Z' },
      {
        id: 'datumverticalepositiebepaling',
        name: 'datum Z',
        formatter: Slick.Formatters.DatePoint,
      },
      { id: 'bhrgt_lokaalverticaalreferentiepunt', name: 'refpunt Z' },
      { id: 'bhrgt_verticaalreferentievlak', name: 'refvlak Z' },
      {
        id: 'trajectweggegraven',
        name: 'traject weggegraven',
        formatter: Slick.Formatters.Checkmark,
      },
      {
        id: 'einddieptegraven',
        name: 'einddiepte graven m-mv',
        formatter: Slick.Formatters.Float3,
      },
      'voorbereiding',
      {
        id: 'einddieptevoorbereiding',
        name: 'einddiepte voorbereiding m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'gesteenteaangeboord',
        name: 'gesteente aangeboord',
        formatter: Slick.Formatters.Checkmark,
      },
      { id: 'tijdelijkeverandering', name: 'tijdelijke verandering' },
    ];

    colDefs.cpt = [
      { id: 'object_id', name: 'objectid' },
      'cpt_kwaliteitsregime',
      {
        id: 'einddiepte',
        name: 'einddiepte m-mv',
        formatter: Slick.Formatters.Float3,
      },
      'cpt_stopcriterium',
      {
        id: 'rapportagedatum',
        formatter: Slick.Formatters.DatePoint,
      },
      'sondeernorm',
      'kwaliteitsklasse',
      'testcategorie',
      'sondeermethode',
      'omschrijving',
      'omstandigheden',
      {
        id: 'voorgeboord_tot',
        name: 'voorgeboord tot m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'cpt_grondwaterstand',
        name: 'grondwaterstand m-mv',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'cpt_waterdiepte',
        name: 'waterdiepte m',
        formatter: Slick.Formatters.Float3,
      },
      'conustype',
      { id: 'conusklasse', formatter: Slick.Formatters.Integer },
      {
        id: 'conusdiameter',
        name: 'conusdiameter mm',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'oppervlak_conuspunt',
        name: 'oppervlakte conuspunt mm2',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'oppervlaktequotient_conuspunt',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'oppervlak_kleefmantel',
        name: 'oppervlakte kleefmantel mm2',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'oppervlaktequotient_kleefmantel',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'afstand_conus_tot_midden_kleefmantel',
        name: 'afstand conus tot midden kleefmantel mm',
        formatter: Slick.Formatters.Float3,
      },
      { id: 'uitvoerderonderzoek', name: 'KVK uitvoerder sondering' },
      { id: 'cpt_bronhouder', name: 'KVK bronhouder' },
      'kader_aanlevering',
      'kader_inwinning',
      { id: 'methode_locatiebepaling', name: 'methode XY' },
      { id: 'methode_verticale_positiebepaling', name: 'methode Z' },
      { id: 'cpt_lokaalverticaalreferentiepunt', name: 'refpunt Z' },
      { id: 'cpt_lokaalverticaalreferentievlak', name: 'refvlak Z' },
      {
        id: 'dissipatietest_uitgevoerd',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'bewerking_onderbrekingen_uitgevoerd',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'expertcorrectie_uitgevoerd',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'signaalbewerking_uitgevoerd',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'aanvullend_onderzoek_uitgevoerd',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'starttijd_meten',
        formatter: Slick.Formatters.DatePoint,
      },
      'opmerkingen1',
      'opmerkingen2',
      {
        id: 'conusweerstand',
        name: 'qc start - eind',
        cssClass: 'right',
      },
      {
        id: 'plaatselijke_wrijving',
        name: 'fs start - eind',
        cssClass: 'right',
      },
      {
        id: 'waterspanning',
        name: 'u start - eind',
        cssClass: 'right',
      },
      {
        id: 'helling',
        name: 'helling start - eind',
        cssClass: 'right',
      },
      {
        id: 'hellingresultante',
        name: 'hellingresultante start - eind',
        cssClass: 'right',
      },
      {
        id: 'elektrische_geleidbaarheid',
        name: 'G start - eind',
        cssClass: 'right',
      },
    ];

    colDefs.gmw = [
      { id: 'gmw_kwaliteitsregime', name: 'kwaliteitsregime' },
      { id: 'buisnummer', formatter: Slick.Formatters.Integer },
      {
        id: 'inrichtingsdatum_put',
        name: 'inrichtingsdatum put',
        formatter: Slick.Formatters.DatePoint,
      },
      {
        id: 'diameter_bovenkant_buis',
        name: 'diameter bovenkant buis mm',
        formatter: Slick.Formatters.Integer,
      },
      {
        id: 'maaiveldpositie_m_nap',
        name: 'Z mv m NAP',
        formatter: Slick.Formatters.Plus2,
      },
      {
        id: 'positie_bovenkant_buis_m_nap',
        name: 'Z bkpb m NAP',
        formatter: Slick.Formatters.Plus2,
      },
      {
        id: 'bk_en_ok_filter_m_NAP',
        cssClass: 'right',
      },
      {
        id: 'bk_en_ok_filter_m_mv',
        cssClass: 'right',
      },
      'beschermconstructie',
      {
        id: 'bk_en_ok_beschermconstr_m_mv',
        cssClass: 'right',
      },
      { id: 'constructionstandard', name: 'constructiestandaard' },
      {
        id: 'gws_in_peilbuis_m_mv',
        formatter: Slick.Formatters.Plus2,
      },
      {
        id: 'ec_bij_plaatsing_in_peilbuis',
        name: 'Ec bij plaatsing in peilbuis uS/cm',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'ec_werkwater',
        name: 'Ec werkwater uS/cm',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'lengte_stijgbuisdeel',
        name: 'lengte stijgbuisdeel m',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'filterlengte',
        name: 'filterlengte m',
        formatter: Slick.Formatters.Float3,
      },
      {
        id: 'lengte_zandvang',
        name: 'lengte zandvang m',
        formatter: Slick.Formatters.Float3,
      },
      'buismateriaal',
      'buistype',
      'lijm',
      'kousmateriaal',
      { id: 'voorzien_van_drukdop', formatter: Slick.Formatters.Checkmark },
      { id: 'voorzien_van_zandvang', formatter: Slick.Formatters.Checkmark },
      { id: 'eigenaar', name: 'KVK eigenaar' },
      { id: 'gmw_bronhouder', name: 'KVK bronhouder' },
      { id: 'onderhoudende_instantie', name: 'KVK onderhoudende instantie' },
      'nitgcode',
      'putcode',
      'kaderaanlevering',
      { id: 'init_functie', name: 'initiele functie' },
      { id: 'gmw_methodelocatiebepaling', name: 'methode XY' },
      { id: 'methodelocatiebepaling_maaiveld', name: 'methode Z mv' },
      { id: 'methode_positiebepaling_bovenkant_buis', name: 'methode Z bkpb' },
      { id: 'gmw_lokaalverticaalreferentiepunt', name: 'refpunt Z' },
      { id: 'gmw_verticaalreferentievlak', name: 'refvlak Z' },
    ];

    colDefs.lababcde = [];

    colDefs.labmdta = [
      { id: 'intern', name: 'uitgevoerde laboratoriumproeven (totaal)' },
      { id: 'extern', name: 'uitgevoerde laboratoriumproeven (klant)' },
    ];

    colDefs.extra = ['bhrgt', 'cpt', 'gmw', 'lababcde', 'labmdta'];

    function view(id, category) {
      function colIds(category) {
        const items = colDefs[category];
        return items.map((item) => item.id || item);
      }
      const genericIds = colIds('generic');
      return {
        id,
        label: id,
        columns: genericIds.concat(colIds(category)),
      };
    }

    views = [
      view('Sonderingen', 'cpt'),
      view('Boringen', 'bhrgt'),
      view('Labparameters', 'lababcde'),
      view('Labtests', 'labmdta'),
      view('Peilbuizen', 'gmw'),
    ];
  }

  function initColumns() {
    columns = [];
    Object.keys(colDefs).forEach((category) =>
      colDefs[category].forEach((colDef) => {
        function read(key, defaultValue) {
          return colDef[key] === undefined ? defaultValue : colDef[key];
        }
        const id = read('id', colDef);
        const prefix = category + '_';
        let name = read('name', id);
        if (name.startsWith(prefix)) {
          name = name.slice(prefix.length);
        }
        name = name.replaceAll('_', ' ');
        name = colDef.nameExact || name;
        const column = { id, name };
        function add(key, defaultValue) {
          column[key] = read(key, defaultValue);
        }
        add('field', id);
        add('toolTip', name);
        add('width', undefined);
        add('minWidth', colDef.width === undefined ? 150 : undefined);
        add('formatter', Slick.Formatters.Text);
        add('resizable', true);
        add('sortable', true);
        add('filterable', true);
        add('editable', false);
        add('multiEdit', false);
        add('filter', 'Text');
        const numeric = ['Integer', 'Float', 'Plus', 'Date', 'Checkmark'].find(
          (prefix) =>
            column.formatter && column.formatter.name.startsWith(prefix),
        );
        add('cssClass', numeric ? 'right' : undefined);
        columns.push(column);
      }),
    );
  }

  function destroyGrid() {
    gridfactory.destroyGrid();
  }

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping) {
    gridfactory.setGrouping(grouping);
  }

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView('#gridMetadataCtrl', columns, views, viewIndex);
  }
}

export function gridMetadataTransform(onderzoek) {
  function datePoint(value) {
    return Slick.Formatters.DatePoint(null, null, value);
  }
  function float(value, decimals) {
    value = Slick.Formatters['Float' + decimals](null, null, value);
    return value;
  }
  function float4(value) {
    return float(value, 4);
  }
  function plus(value, decimals) {
    value = Slick.Formatters['Plus' + decimals](null, null, value);
    return value;
  }
  function plus2(value) {
    return plus(value, 2);
  }

  const { bhrgt, cpt, gmw, lababcde, labmdta } = onderzoek;

  if (bhrgt) {
    const { startdatumboring, einddatumboring } = onderzoek;
    onderzoek['boordatum'] =
      startdatumboring === einddatumboring
        ? datePoint(einddatumboring)
        : `${datePoint(startdatumboring)} / ${datePoint(einddatumboring)}`;
    delete onderzoek.startdatumboring;
    delete onderzoek.einddatumboring;
  }

  if (cpt) {
    const { conusweerstand_vooraf, conusweerstand_achteraf } = onderzoek;
    onderzoek['conusweerstand'] = conusweerstand_vooraf
      ? `${float4(conusweerstand_vooraf)} / ${float4(conusweerstand_achteraf)}`
      : '';
    delete onderzoek.conusweerstand_achteraf;
    delete onderzoek.conusweerstand_vooraf;

    const { plaatselijke_wrijving_vooraf, plaatselijke_wrijving_achteraf } =
      onderzoek;
    onderzoek['plaatselijke_wrijving'] = plaatselijke_wrijving_vooraf
      ? `${float4(plaatselijke_wrijving_vooraf)} / ${float4(
          plaatselijke_wrijving_achteraf,
        )}`
      : '';
    delete onderzoek.plaatselijke_wrijving_achteraf;
    delete onderzoek.plaatselijke_wrijving_vooraf;

    const {
      waterspanning_u1_achteraf,
      waterspanning_u1_vooraf,
      waterspanning_u2_achteraf,
      waterspanning_u2_vooraf,
      waterspanning_u3_achteraf,
      waterspanning_u3_vooraf,
    } = onderzoek;
    const waterspanning_vooraf =
      waterspanning_u1_vooraf ||
      waterspanning_u2_vooraf ||
      waterspanning_u3_vooraf;
    const waterspanning_achteraf =
      waterspanning_u1_achteraf ||
      waterspanning_u2_achteraf ||
      waterspanning_u3_achteraf;
    onderzoek['waterspanning'] = waterspanning_vooraf
      ? `${float4(waterspanning_vooraf)} / ${float4(waterspanning_achteraf)}`
      : '';
    delete onderzoek.waterspanning_u1_achteraf;
    delete onderzoek.waterspanning_u1_vooraf;
    delete onderzoek.waterspanning_u2_achteraf;
    delete onderzoek.waterspanning_u2_vooraf;
    delete onderzoek.waterspanning_u3_achteraf;
    delete onderzoek.waterspanning_u3_vooraf;

    const {
      helling_noord_zuid_achteraf,
      helling_noord_zuid_vooraf,
      helling_oost_wet_achteraf,
      helling_oost_wet_vooraf,
    } = onderzoek;
    const helling_vooraf = helling_noord_zuid_vooraf || helling_oost_wet_vooraf;
    const helling_achteraf =
      helling_noord_zuid_achteraf || helling_oost_wet_achteraf;
    onderzoek['helling'] = helling_vooraf
      ? `${float4(helling_vooraf)} / ${float4(helling_achteraf)}`
      : '';
    delete onderzoek.helling_noord_zuid_achteraf;
    delete onderzoek.helling_noord_zuid_vooraf;
    delete onderzoek.helling_oost_wet_achteraf;
    delete onderzoek.helling_oost_wet_vooraf;

    const { helling_resultante_vooraf, helling_resultante_achteraf } =
      onderzoek;
    onderzoek['hellingresultante'] = helling_resultante_vooraf
      ? `${float4(helling_resultante_vooraf)} / ${float4(
          helling_resultante_achteraf,
        )}`
      : '';
    delete onderzoek.helling_resultante_achteraf;
    delete onderzoek.helling_resultante_vooraf;

    const {
      elektrische_geleidbaarheid_vooraf,
      elektrische_geleidbaarheid_achteraf,
    } = onderzoek;
    onderzoek['elektrische_geleidbaarheid'] = elektrische_geleidbaarheid_vooraf
      ? `${float4(elektrische_geleidbaarheid_vooraf)} / ${float4(
          elektrische_geleidbaarheid_achteraf,
        )}`
      : '';
    delete onderzoek.elektrische_geleidbaarheid_achteraf;
    delete onderzoek.elektrische_geleidbaarheid_vooraf;
  }

  if (gmw) {
    const { positie_bovenkant_filter_m_nap, positie_onderkant_filter_m_nap } =
      onderzoek;
    onderzoek['bk_en_ok_filter_m_NAP'] = positie_bovenkant_filter_m_nap
      ? `${plus2(positie_bovenkant_filter_m_nap)} / ${plus2(
          positie_onderkant_filter_m_nap,
        )}`
      : '';
    delete onderzoek.positie_bovenkant_filter_m_nap;
    delete onderzoek.positie_onderkant_filter_m_nap;

    const { positie_bovenkant_filter_m_mv, positie_onderkant_filter_m_mv } =
      onderzoek;
    onderzoek['bk_en_ok_filter_m_mv'] = positie_bovenkant_filter_m_mv
      ? `${plus2(positie_bovenkant_filter_m_mv)} / ${plus2(
          positie_onderkant_filter_m_mv,
        )}`
      : '';
    delete onderzoek.positie_bovenkant_filter_m_mv;
    delete onderzoek.positie_onderkant_filter_m_mv;

    const { beschermconstructie_van, beschermconstructie_tot } = onderzoek;
    // Note the minus:
    onderzoek['bk_en_ok_beschermconstr_m_mv'] = beschermconstructie_van
      ? `${plus2(-beschermconstructie_van)} / ${plus2(
          -beschermconstructie_tot,
        )}`
      : '';
    delete onderzoek.beschermconstructie_van;
    delete onderzoek.beschermconstructie_tot;
  }

  if (lababcde) {
    // Nothing, at the moment.
  }

  if (labmdta) {
    // Nothing, at the moment.
  }

  return onderzoek;
}
