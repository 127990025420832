﻿window.app.controller('gridWerkzaamhedenCtrl', [
  '$scope',
  'gridWerkzaamheden',
  'gridFactory',
  gridWerkzaamhedenControl,
]);

function gridWerkzaamhedenControl($scope, gridWerkzaamheden, gridFactory) {
  $scope.views = gridWerkzaamheden.getViews();
  $scope.showPresentationSelection = true;
  $scope.showGroupingSelection = true;
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridWerkzaamheden.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  //$scope.togglePresentationSelection = function (e) {
  //    $scope.showPresentationSelection = !$scope.showPresentationSelection;
  //}
  //$scope.toggleGroupingSelection = function (e) {
  //    $scope.showGroupingSelection = !$scope.showGroupingSelection;
  //}
  //$scope.toggleFilterRow = function () {
  //    gridFactory.toggleFilterRow();
  //};
  //$scope.toggleSelectionMethod = function (e) {
  //    if ($scope.selectionMethod == "Cell") {
  //        $scope.selectionMethod = "Row";
  //    } else {
  //        $scope.selectionMethod = "Cell";
  //    }
  //    gridFactory.setSelectionMethod($scope.selectionMethod);
  //}

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridWerkzaamheden.setView(index);
    }
  };
  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };
  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridWerkzaamheden.setGrouping($scope.selectedGrouping);
  };
  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };
  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };
  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };
  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel();
  };
  $scope.Export2Txt = function () {
    gridFactory.Export2Csv('uitzet.txt', ['naam', 'x', 'y']);
  };
  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };
}
