﻿const angular = window.angular;

window.app.controller('areaCtrl', [
  '$scope',
  '$rootScope',
  '$controller',
  'measureService',
  'mapToolsService',
  'modelService',
  areaCtrl,
]);

function areaCtrl(
  $scope,
  $rootScope,
  $controller,
  measureService,
  mapToolsService,
  modelService,
) {
  angular.extend(this, $controller('measureCtrl', { $scope: $scope }));

  $rootScope.$on('mapDoubleClicked', function () {
    console.log('doubleClick ontvangen in areaCtrl');
  });

  $scope.MeasureArea = function (el) {
    el = el || window.event;
    el.srcElement.style = 'opacity: 1; z-index: 25;';
    modelService.update('model-tools', 'AREA', 'ADD');
    measureService.addMeasure('AREA');
    mapToolsService.MeasureArea();
  };
}
