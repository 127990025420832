﻿const toastr = window.toastr;

window.app.factory('maplayerService', ['$http', '$q', maplayerService]);

function maplayerService($http, $q) {
  // Define the functions and properties to reveal.
  var service = {
    serviceTypes: {
      WMS: 1,
      WMTS: 2,
      WFS: 3,
      SHAPE: 4,
    },
    getServicetypeNames,
    getCapabilities,
  };

  return service;

  function ErrorHandler(response) {
    if (response.status !== 200) {
      toastr.error('Er is een probleem opgetreden!');
      console.error(JSON.stringify(response.statusText));
    }
  }

  function getServicetypeNames() {
    return Object.getOwnPropertyNames(this.serviceTypes);
  }

  function getCapabilities(url) {
    var deferred = $q.defer();
    $http({
      method: 'GET',
      url: url,
    }).then(
      function (response) {
        deferred.resolve(response.data);
        return response.data;
      },
      function (response) {
        ErrorHandler(response);
        deferred.reject(response.status);
      },
    );
    return deferred.promise;
  }
}
