﻿import src from './onderzoekstypes.html?url';

import closeSvg from '../../images/svgs/close.svg';
import addSvg from '../../images/svgs/add.svg';

window.app.controller('onderzoekstypesCtrl', [
  '$scope',
  '$rootScope',
  '$mdToast',
  '$sce',
  '$element',
  'userAccountService',
  'mapService',
  'gridFactory',
  'tooltipService',
  'xyzService',
  'modelService',
  onderzoekstypesCtrl,
]);

function onderzoekstypesCtrl(
  $scope,
  $rootScope,
  $mdToast,
  $sce,
  $element,
  userAccountService,
  mapService,
  gridFactory,
  tooltipService,
  xyzService,
  modelService,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.closeSvg = closeSvg;
  $scope.addSvg = addSvg;

  $scope.speedDialOpen = false;

  $scope.project = null;
  $scope.onderzoekstypes = [];
  let selectedOnderzoekstype = null;

  function init(model) {
    $scope.project = model.project;
    selectedOnderzoekstype = null;
    $scope.onderzoekFactory.stop();
    loadOnderzoekstypes();
  }

  $rootScope.$on('model-project', (_, model) => {
    init(model);
  });

  var tools;
  $rootScope.$on('model-tools', (_, model) => {
    tools = model.tools;
    $scope.$apply();
  });
  $scope.hasActiveTools = () => tools && tools.getSize();
  $scope.hasActiveTool = (tool) => tools && tools.has(tool);

  $scope.xyzFile = function (file) {
    function callback(err) {
      if (err) {
        alert(err);
      } else {
        update();
        loadOnderzoekstypes();
      }
    }
    xyzService.run(file, callback, $scope.project.id);
  };

  function distance(c1, c2, decimals) {
    const a = c1[0] - c2[0];
    const b = c1[1] - c2[1];
    const c = Math.sqrt(a * a + b * b);
    return c.toFixed(decimals || 2);
  }

  $scope.MovePointActivated = false;

  function setMovePointAvailable({ modus }) {
    $scope.MovePointAvailable = modus().startsWith('P');
  }
  $rootScope.$on('model-modus', (_, model) => {
    setMovePointAvailable(model);
  });
  setMovePointAvailable(modelService.getModel());

  $scope.moveMeetpunt = function () {
    // window.onkeyup = function (event) {
    //   if (event.keyCode == 27) {
    //     alert('ESC clicked');
    //   }
    // }
    tooltipService('Kies meetpunt om te verplaatsen');
    $scope.MovePointActivated = true;
    mapService.pickMeetpunt.start(function (err, id, feature) {
      if (err) {
        $scope.MovePointActivated = false;
        tooltipService('');
        // window.onkeyup = function (event) {}
        alert(err);
      } else {
        const start = feature.getGeometry().getFirstCoordinate();
        const tooltip = 'Kies nieuwe plaats voor ' + feature.get('naam');
        tooltipService(tooltip);
        mapService.pickCoordinaat.start(async (err, finished, coordinate) => {
          if (err) {
            $scope.MovePointActivated = false;
            tooltipService('');
            // window.onkeyup = function (event) {}
            alert(err);
          } else if (!finished) {
            tooltipService(
              tooltip + '; afstand = ' + distance(start, coordinate),
            );
          } else {
            if (distance(start, coordinate) > 0) {
              tooltipService('');
              await userAccountService.putOnderzoeken([
                {
                  id: id,
                  x: coordinate[0],
                  y: coordinate[1],
                  z: null,
                  xymethode: 'GEOREF',
                },
              ]);
              $scope.MovePointActivated = false;
              tooltipService('');
              // window.onkeyup = function (event) {}
              mapService.refresh([
                'Projecten',
                'Meetpunten',
                'Meetpunt labels',
              ]);
              setTimeout(gridFactory.getData, 1000);
            } else {
              tooltipService('');
              // window.onkeyup = function (event) {}
              $scope.MovePointActivated = false;
              $mdToast.showSimple(
                'Eindpunt is gelijk aan startpunt: punt is niet verplaatst.',
              );
            }
          }
        });
      }
    });
  };

  var onderzoekstypesLimit = 30;
  $scope.onderzoekstypesVisible = false;
  $scope.setOnderzoekstypesVisible = function (visible) {
    // aan- en uitzetten van schermdeel met lijsten van onderzoekstypes voor aanmaken van nieuwe onderzoeken op de kaart
    if (visible === $scope.onderzoekstypesVisible) {
      return;
    }
    var oldClass = visible ? 'flex-0' : 'flex-' + onderzoekstypesLimit;
    var newClass = visible ? 'flex-' + onderzoekstypesLimit : 'flex-0';
    $element[0].nextElementSibling.classList.replace(oldClass, newClass);
    $scope.onderzoekstypesVisible = visible;
  };

  function loadOnderzoekstypes() {
    if ($scope.project) {
      userAccountService
        .getOnderzoekstypes($scope.project.id)
        .then(function (onderzoekstypes) {
          $scope.onderzoekstypes = onderzoekstypes;
        });
    } else {
      $scope.onderzoekstypes = [];
    }
  }

  function update(layers) {
    layers = layers || ['Projecten', 'Meetpunten', 'Meetpunt labels'];
    mapService.refresh(layers);
    gridFactory.getData();
  }

  function postPrimairOnderzoek(coordinate) {
    userAccountService
      .postPrimairOnderzoek(
        $scope.project.id,
        selectedOnderzoekstype.naam,
        coordinate,
      )
      .then(function () {
        update();
        selectedOnderzoekstype.bestaand++;
      });
  }

  function postInstrumentaria(primair_onderzoek) {
    userAccountService
      .postInstrumentaria(primair_onderzoek, selectedOnderzoekstype.naam)
      .then(function () {
        update(['Meetpunten', 'Meetpunt labels']);
        selectedOnderzoekstype.bestaand++;
      });
  }

  function OnderzoekFactory() {
    this.active = false;

    this.stop = function () {
      $scope.setOnderzoekstypesVisible(false);
      if (this.active) {
        this.active = false;
        tooltipService('');
        mapService.pickCoordinaat.cancel();
        mapService.pickMeetpunt.cancel();
        modelService.update('model-tools', 'ADDONDERZOEK', 'DELETE');
      }
    };

    this.start = function (onderzoekstype) {
      selectedOnderzoekstype = onderzoekstype;
      modelService.update('model-tools', 'ADDONDERZOEK', 'ADD');
      tooltipService('');
      this.active = true;
      if (selectedOnderzoekstype.primair) {
        mapService.pickMeetpunt.cancel();
        var previous;
        mapService.pickCoordinaat.start(function (err, finished, coordinate) {
          if (err) {
            tooltipService('');
            alert(err);
          } else if (!finished) {
            if (previous) {
              tooltipService(
                'Afstand tot vorige = ' + distance(previous, coordinate),
              );
            }
          } else {
            tooltipService('');
            previous = coordinate;
            postPrimairOnderzoek(coordinate);
          }
        }, true);
      } else {
        mapService.pickCoordinaat.cancel();
        mapService.pickMeetpunt.start(function (err, id) {
          if (err) {
            alert(err);
          } else {
            postInstrumentaria(id);
          }
        }, true);
      }
    };

    this.button = function () {
      if ($scope.MovePointActivated) {
        $scope.MovePointActivated = false;
        tooltipService('');
        mapService.pickMeetpunt.cancel();
      } else {
        if ($scope.onderzoekstypesVisible) {
          this.stop();
        } else {
          $scope.setOnderzoekstypesVisible(true);
        }
      }
    };
  }

  document.addEventListener('keydown', function (event) {
    if (!$scope.onderzoekFactory.active && !$scope.MovePointActivated) {
      return;
    }
    switch (event.key) {
      case 'Escape':
        if ($scope.onderzoekFactory.active) {
          $scope.onderzoekFactory.stop();
          $scope.$apply();
        }
        if ($scope.MovePointActivated) {
          $scope.MovePointActivated = false;
          tooltipService('');
          mapService.pickMeetpunt.cancel();
        }
        break;
    }
  });

  $scope.onderzoekFactory = new OnderzoekFactory();

  $rootScope.$on('model-grid', (_, model) => {
    if (!model.grid.ONDERZOEKEN) {
      $scope.onderzoekFactory.stop();
    }
  });

  init(modelService.getModel());
}
