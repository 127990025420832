﻿import KML from 'ol/format/KML';
import { fromExtent } from 'ol/geom/Polygon';
import * as geom from 'ol/geom';

const Slick = window.Slick;

window.app.factory('gridNotities', [
  '$rootScope',
  'modelService',
  'userAccountService',
  'gridFactory',
  gridNotities,
]);

function gridNotities(
  $rootScope,
  modelService,
  userAccountService,
  gridFactory,
) {
  const API = {
    setGrouping,
    getGroupings,
    getViews,
    setView,
    getNotities,
    refresh,
    deleteNotities,
    postNotitie,
    putNotitie,
  };

  const columnObjects = {
    tonen: {
      formatter: Slick.Formatters.Tonen,
      asyncPostRender: gridFactory.tonenPostRender,
      name: '',
      toolTip: 'Tonen/verbergen op de kaart',
      width: 30,
      minWidth: 30,
      maxWidth: 30,
    },
    text: {
      name: 'Omschrijving',
      toolTip: 'Omschrijving',
      width: 300,
      minWidth: 100,
      maxWidth: 400,
    },
    type: {
      name: 'Type',
      toolTip: 'Type notitie',
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    gebruiker: {
      name: 'Gebruiker',
      toolTip: 'Laatst gewijzigd door:',
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    tijdstip: {
      name: 'Datum',
      toolTip: 'Laatst gewijzigd op:',
      formatter: Slick.Formatters.DateTime,
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    x: {
      name: 'X',
      toolTip: 'Situering',
      formatter: Slick.Formatters.Integer,
      width: 100,
      minWidth: 100,
      maxWidth: 300,
    },
    y: {
      name: 'Y',
      toolTip: 'Situering',
      formatter: Slick.Formatters.Integer,
      width: 100,
      minWidth: 100,
      maxWidth: 300,
    },
  };

  const columns = [];

  Object.keys(columnObjects).forEach((key) => {
    const column = columnObjects[key];
    column.id = column.id || key;
    columns.push(newColumn(column));
  });

  function newColumn(column) {
    function defaultValue(key, value) {
      if (column[key] === undefined) {
        column[key] = value;
      }
    }
    defaultValue('name', column.id);
    defaultValue('field', column.id);
    defaultValue('formatter', Slick.Formatters.Text);
    defaultValue('resizable', true);
    defaultValue('sortable', true);
    defaultValue('filterable', true);
    defaultValue('filter', 'Text');
    return column;
  }

  let grid = {},
    gridfactory;
  initGrid(modelService.getModel());

  function init(model) {
    grid = model.grid;
    return !!grid.NOTITIES;
  }

  function initGrid(model) {
    if (init(model)) {
      const options = {
        editable: true,
        autoEdit: true,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 25,
        enableColumnReorder: true,
        showHeaderRow: true,
        enableAsyncPostRender: true,
      };

      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(grid.value, columns, options, grid.key)
        .then(() => {
          setView(0);
          requestNotitieData(model);
        });
    }
  }

  $rootScope.$on('model-grid', (_, model) => {
    initGrid(model);
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      requestNotitieData(model);
    }
  });

  let notities;

  function getNotities(ids) {
    return (notities || []).filter((item) => !ids || ids.includes(0 + item.id)); // "1"
  }

  function refresh(model) {
    return requestNotitieData(model);
  }

  function requestNotitieData(model) {
    if (!gridfactory.getGrid()) {
      return;
    }
    if (model.project) {
      fetchNotitieData(model.project.id, model.exclude.NOTITIE || []).then(
        (data) => {
          gridfactory.updateData(data);
          notities = data;
        },
      );
    } else {
      gridfactory.updateData([]);
    }
  }

  const groupings = [{ id: 'geen', label: 'Niets' }];

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping, options) {
    gridfactory.setGrouping(grouping, options);
  }

  const views = [
    {
      id: 'Standaard',
      label: 'Standaard',
      columns: Object.keys(columnObjects),
    },
  ];

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView(grid.value, columns, views, viewIndex);
  }

  let _KMLformat;
  function KMLformat() {
    _KMLformat = _KMLformat || new KML();
    return _KMLformat;
  }

  function fetchNotitieData(subproject, exclude) {
    return userAccountService.getNotities(subproject).then((notities) =>
      notities.map(({ features, id, gebruiker, tijdstip }) => {
        features = KMLformat().readFeatures(features, {
          featureProjection: window.map.getView().getProjection(),
        });
        const feature = features.filter((f) => !isPoint(f))[0],
          point = features.filter((f) => isPoint(f))[0],
          geom = feature.getGeometry();
        return {
          id,
          text: point
            ? point.get('name')
            : feature.get(
                'name',
              ) /* older "straatnamen" had no point, but a LabelStyle on the Linestring*/,
          type: isPolygon(feature)
            ? 'Opmerking'
            : numCoordinates(feature) === 2
            ? 'Straatnaam'
            : 'Rijroute',
          geom,
          gebruiker,
          tijdstip,
          tonen: !exclude.includes(id),
          bbox: fromExtent(geom.getExtent()),
          x: geom.getFirstCoordinate()[0],
          y: geom.getFirstCoordinate()[1],
          hull: (() => {
            const clone = geom.clone();
            clone.scale(1.5);
            return clone;
          })(),
        };
      }),
    );
    function isPoint(feature) {
      return isGeometryType(feature, 'Point');
    }
    function isPolygon(feature) {
      return isGeometryType(feature, 'Polygon');
    }
    function isGeometryType(feature, type) {
      return feature.getGeometry() instanceof geom[type];
    }
    function numCoordinates(feature) {
      return feature.getGeometry().getCoordinates().length;
    }
  }

  function deleteNotities(ids) {
    return userAccountService.deleteNotities(ids);
  }

  function postNotitie(subproject, kml) {
    return userAccountService.postNotitie(subproject, kml);
  }

  function putNotitie(id, kml) {
    return userAccountService.putNotitie(id, kml);
  }

  return API;
}
