﻿import src from './projecttabbladen.html?url';

window.app.controller('projectTabbladenCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  projectTabbladenCtrl,
]);

function projectTabbladenCtrl($scope, $rootScope, $sce) {
  const Tabs = Object.freeze({
    ALGEMEEN: 0,
    DOCUMENTEN: 1,
    PLANTEKENINGEN: 2,
    PROJECTKAARTEN: 3,
    KLICNETWERKEN: 4,
  });

  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.selectedProjectTab = Tabs.ALGEMEEN;

  $rootScope.$on('model-projectTabs', () => {
    // ensure reload of open tab content from md-on-select
    // by first moving back to the ALGEMEEN tab
    $scope.selectedProjectTab = Tabs.ALGEMEEN;
  });

  $scope.onload = (data) => {
    $scope.$broadcast('onload', data);
  };
}
