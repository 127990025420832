﻿import src from './mapexporttools.html?url';
import printHtml from './print.html?url';
import selectHtml from './select.html?url';

import icBoxesBSvg from '../../images/svgs/ic_boxes_b.svg';
import icBoxesRSvg from '../../images/svgs/ic_boxes_r.svg';
import icBoxesSvg from '../../images/svgs/ic_boxes.svg';
import icCheckboxBlankSvg from '../../images/svgs/ic_checkbox-blank.svg';
import icOpenWithSvg from '../../images/svgs/ic_open_with.svg';
import iconCenterSvg from '../../images/svgs/icon_center.svg';
import iconLeftSvg from '../../images/svgs/icon-left.svg';
import iconRightSvg from '../../images/svgs/icon-right.svg';
import iconUpSvg from '../../images/svgs/icon-up.svg';
import printerSvg from '../../images/svgs/printer.svg';

import closeSvg from '../../images/svgs/close.svg';
import icFolderBlackSvg from '../../images/svgs/ic-folder-black.svg';
import iconEyeSvg from '../../images/svgs/icon-eye.svg';

const angular = window.angular;
const toastr = window.toastr;
const $ = window.$;

window.app.controller('mapExportCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  '$mdDialog',
  'mapService',
  'mapexportService',
  'modelService',
  'userAccountService',
  mapExportCtrl,
]);

function mapExportCtrl(
  $scope,
  $rootScope,
  $sce,
  $mdDialog,
  mapService,
  mapexportService,
  modelService,
  userAccountService,
) {
  $scope.src = $sce.trustAsResourceUrl(src);
  $scope.icBoxesBSvg = icBoxesBSvg;
  $scope.icBoxesRSvg = icBoxesRSvg;
  $scope.icBoxesSvg = icBoxesSvg;
  $scope.icCheckboxBlankSvg = icCheckboxBlankSvg;
  $scope.icOpenWithSvg = icOpenWithSvg;
  $scope.iconCenterSvg = iconCenterSvg;
  $scope.iconLeftSvg = iconLeftSvg;
  $scope.iconRightSvg = iconRightSvg;
  $scope.iconUpSvg = iconUpSvg;
  $scope.printerSvg = printerSvg;

  $scope.items = mapexportService.getItems();
  $scope.tooltips = initTooltips();
  $scope.showMove = false;
  var showBoxesArray = ['NO', 'veldtekening', 'situatietekening'];
  $scope.showBoxes = showBoxesArray[0];

  $scope.isActive = function (size, orientation) {
    return mapexportService.checkTemplate(
      $scope.items.selectedPageSize + size,
      $scope.items.selectedPageOrientation + orientation,
      $scope.items,
    );
  };

  $scope.toggelShowMove = function () {
    this.showMove = !this.showMove;
  };

  $scope.toggelShowBoxes = function () {
    var idx = showBoxesArray.indexOf($scope.showBoxes);
    if (idx == showBoxesArray.length - 1) {
      $scope.showBoxes = showBoxesArray[0];
    } else if (idx >= 0) {
      $scope.showBoxes = showBoxesArray[idx + 1];
    }
    const project = modelService.getModel().project;
    var subprojectNr = project
      ? project.status + '-' + project.subprojectnr
      : '';
    if (subprojectNr != '') {
      mapexportService.getBoxes(subprojectNr, $scope.showBoxes);
    }
  };

  $scope.moveBox = function (x, y) {
    var shift = getShift();
    //var shift = 50; // 100; (omdat EPSG:28992 is 50 ook 50 m op kaart)
    mapexportService.moveBox(x * shift, y * shift);
    mapexportService.calculateAndDrawBox($scope.items);
  };

  function getShift() {
    var unitFactors = { km: 1000, m: 1, mm: 0.001 };
    try {
      var line = $('.ol-scale-line-inner')[0];
      var text = line.innerText;
      var value = text.split(' ')[0];
      var units = text.split(' ')[1];
      var shift = parseInt(value) * unitFactors[units]; // (units === "km") ? parseInt(value) * 1000 : parseInt(value);
    } catch (error) {
      return 50;
    }
    return shift;
  }

  $scope.centerBox = function () {
    mapexportService.initBox($scope.items);
    mapexportService.calculateAndDrawBox($scope.items);
  };

  $scope.changePageSize = function (value) {
    if (value > 0) {
      if (
        $scope.items.selectedPageSize <
        $scope.items.pageSizeList.length - 1
      ) {
        //if (!mapexportService.checkTemplate($scope.items.selectedPageSize + 1, $scope.items.selectedPageOrientation, $scope.items)) {
        //    toastr.error("Gekozen template is niet beschikbaar", "", { timeout: 1000 });
        //    return
        //}
        $scope.items.selectedPageSize++;
      }
    }
    if (value < 0) {
      if ($scope.items.selectedPageSize > 0) {
        //if (!mapexportService.checkTemplate($scope.items.selectedPageSize - 1, $scope.items.selectedPageOrientation, $scope.items)) {
        //    toastr.error("Gekozen template is niet beschikbaar", "", { timeout: 1000 });
        //    return
        //}
        $scope.items.selectedPageSize--;
      }
    }
    mapexportService.calculateAndDrawBox($scope.items);
  };
  $scope.changePageOrientation = function (value) {
    if (value > 0) {
      if (
        $scope.items.selectedPageOrientation <
        $scope.items.pageOrientationList.length - 1
      ) {
        //if (!mapexportService.checkTemplate($scope.items.selectedPageSize, $scope.items.selectedPageOrientation + 1, $scope.items)) {
        //    toastr.error("Gekozen template is niet beschikbaar", "", { timeout: 1000 });
        //    return
        //}
        $scope.items.selectedPageOrientation++;
      }
    }
    if (value < 0) {
      if ($scope.items.selectedPageOrientation > 0) {
        //if (!mapexportService.checkTemplate($scope.items.selectedPageSize, $scope.items.selectedPageOrientation - 1, $scope.items)) {
        //    toastr.error("Gekozen template is niet beschikbaar", "", { timeout: 1000 });
        //    return
        //}
        $scope.items.selectedPageOrientation--;
      }
    }
    mapexportService.calculateAndDrawBox($scope.items);
  };
  $scope.changeScale = function (value) {
    if (value > 0) {
      if ($scope.items.selectedScale < $scope.items.scaleList.length - 1) {
        $scope.items.selectedScale++;
      }
    }
    if (value < 0) {
      if ($scope.items.selectedScale > 0) {
        $scope.items.selectedScale--;
      }
    }
    mapexportService.calculateAndDrawBox($scope.items);
  };
  $scope.changeRotation = function (value) {
    if (value > 0) {
      $scope.items.pageRotation++;
      if ($scope.items.pageRotation >= 360) {
        $scope.items.pageRotation = 0;
      }
    }
    if (value < 0) {
      $scope.items.pageRotation--;
      if ($scope.items.pageRotation < 0) {
        $scope.items.pageRotation = 359;
      }
    }
    mapexportService.calculateAndDrawBox($scope.items);
  };
  $scope.showBox = function () {
    mapexportService.calculateAndDrawBox($scope.items);
  };

  var punteninArea;
  $scope.print = function () {
    var gegevens = mapexportService.getGegevens();
    gegevens.getekend = $scope.vm.userData.userInitials;
    gegevens.selectedResolutie = Math.max(
      $scope.items.scaleList[$scope.items.selectedScale].resolutieListId,
      $scope.items.pageSizeList[$scope.items.selectedPageSize].resolutieListId,
    );
    $scope.loadingData = true;
    mapexportService
      .getPuntenInPrintArea()
      .then((data) => {
        punteninArea = data;
        $mdDialog.show({
          multiple: true,
          controller: pageDialogController,
          templateUrl: $sce.trustAsResourceUrl(printHtml),
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: false,
          locals: {
            items: $scope.items,
            gegevens: gegevens,
            userInitials: $scope.vm.userData.userInitials,
          },
        });
      })
      .finally(() => ($scope.loadingData = false));
  };

  function pageDialogController(
    $scope,
    $mdDialog,
    items,
    gegevens,
    userInitials,
  ) {
    $scope.closeSvg = closeSvg;
    $scope.icFolderBlackSvg = icFolderBlackSvg;
    $scope.iconEyeSvg = iconEyeSvg;
    $scope.printerSvg = printerSvg;
    $scope.iconRightSvg = iconRightSvg;

    gegevens.mapInfo = mapexportService.getMapInfo();
    $scope.gegevens = gegevens;
    $scope.xyzGegevens = mapexportService.getXyzGegevens();
    $scope.baseTemplate = '';
    $scope.canPrint = true;
    $scope.cancel = function () {
      if (!$scope.canPrint) {
        toastr.warning('Printopdracht wordt op de achtergrond uitgevoerd');
      }
      $mdDialog.hide($scope.items);
    };
    setTagklantInLabels();

    function setTagklantInLabels() {
      const xyz =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name === 'xyz';
      $scope.gegevens.tagklantInLabels = !!punteninArea
        .filter((p) => xyz || p.isInPrintableZone)
        .filter((p) => p.tagklant).length;
    }
    $scope.switchTekeningSoort = function () {
      setTagklantInLabels();
      $scope.gegevens.showExtraText =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name ===
        'veldtekening';
      $scope.gegevens.fileName =
        ($scope.gegevens.subprojectNr || 'KAART') +
        '_' +
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name +
        '_' +
        mapexportService.getLocaleToday();
      $scope.gegevens.status = mapexportService.setStatusText(
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name,
      );
      $scope.gegevens.printZ =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name ===
        'situatietekening';
      $scope.gegevens.printNotities =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name ===
        'veldtekening';
      $scope.gegevens.printRijroutes =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name ===
        'veldtekening';
      $scope.gegevens.gew1 = '';
      $scope.gegevens.gew2 = '';
      $scope.gegevens.gew3 = '';
      $scope.gegevens.gew4 = '';
      $scope.gegevens.datum = mapexportService.getShortLocaleToday();
      $scope.gegevens.getekend = userInitials;
    };
    $scope.switchBladnr = function () {
      // TODO: hier moeten we nog de regels voor bepalen !
      //$scope.gegevens.gew1 = "";
      //$scope.gegevens.gew2 = "";
      //$scope.gegevens.gew3 = "";
      //$scope.gegevens.gew4 = "";
      //$scope.gegevens.datum = mapexportService.getShortLocaleToday();
      //$scope.gegevens.getekend = userInitials;
    };
    $scope.switchLabelLocation = function () {
      var id = $scope.gegevens.selectedLabelLocatie.id;
      $scope.gegevens.selectedLabelLocatie =
        id < $scope.gegevens.labelLocaties.length - 1
          ? $scope.gegevens.labelLocaties[id + 1]
          : $scope.gegevens.labelLocaties[0];
    };
    $rootScope.$on('createDocReady', function () {
      $scope.canPrint = true;
    });
    $scope.toggleOpacity = function () {
      var opacity = $('#mapexportPrint').css('opacity');
      if (opacity < 1) {
        opacity = 1;
      } else {
        opacity = 0.2;
      }
      $('#mapexportPrint').css('opacity', opacity);
    };
    $scope.selectSavedtemplate = function () {
      var projectnr = $scope.gegevens.subprojectNr || 'KAART';
      var tekeningtype = null; //$scope.gegevens.typeList[$scope.gegevens.selectedType].name;
      userAccountService
        .getPrint(projectnr, tekeningtype)
        .then(function (data) {
          var items = data;
          $mdDialog
            .show({
              multiple: true,
              controller: selectDialogController,
              templateUrl: $sce.trustAsResourceUrl(selectHtml),
              parent: angular.element(document.body),
              clickOutsideToClose: false,
              escapeToClose: false,
              locals: { items: items },
            })
            .then(function (itemInfo) {
              var item = itemInfo.item;
              //$scope.sameItems = [];
              //for (let it of itemInfo.sameItems) {
              //    $scope.sameItems.push(it.tekeningtype);
              //};
              $scope.disableTypes = itemInfo.sameItems.map((it) => {
                return it.tekeningtype;
              });
              if (item != null) {
                $scope.baseTemplate =
                  item.tekeningtype +
                  ': project ' +
                  item.projectnr +
                  ', blad ' +
                  item.bladnr;
                items = item.content.items;
                $scope.gegevens = item.content.gegevens;
                if ($scope.gegevens.gew1 == '') {
                  $scope.gegevens.gew1 =
                    mapexportService.getShortLocaleToday() + ' ' + userInitials;
                } else if ($scope.gegevens.gew2 == '') {
                  $scope.gegevens.gew2 =
                    mapexportService.getShortLocaleToday() + ' ' + userInitials;
                } else if ($scope.gegevens.gew3 == '') {
                  $scope.gegevens.gew3 =
                    mapexportService.getShortLocaleToday() + ' ' + userInitials;
                } else if ($scope.gegevens.gew4 == '') {
                  $scope.gegevens.gew4 =
                    mapexportService.getShortLocaleToday() + ' ' + userInitials;
                }
                $scope.gegevens.fileName =
                  ($scope.gegevens.subprojectNr || 'KAART') +
                  '_' +
                  $scope.gegevens.typeList[$scope.gegevens.selectedType].name +
                  '_' +
                  mapexportService.getLocaleToday();
                mapexportService.setMapTools(
                  items.selectedPageSize,
                  items.selectedPageOrientation,
                  items.selectedScale,
                );
                mapService.setView(item.content.mapinfo);
                mapexportService.setMapInfo(item.content.mapinfo);
                mapexportService.calculateAndDrawBox(items);
                $scope.canPrint = false;
                mapexportService
                  .getPuntenInPrintArea()
                  .then((data) => {
                    punteninArea = data;
                  })
                  .finally(() => ($scope.canPrint = true));
              }
            });
        });
    };
    $scope.print = function () {
      var mapInfo = mapexportService.getMapInfo();
      var projectnr = $scope.gegevens.subprojectNr || 'KAART';
      var tekeningtype =
        $scope.gegevens.typeList[$scope.gegevens.selectedType].name;
      var content = {
        gegevens: $scope.gegevens,
        items: items,
        mapinfo: mapInfo,
      };
      var opt_bladnr = $scope.gegevens.blad;
      userAccountService.putPrint(projectnr, tekeningtype, content, opt_bladnr);
      var isBepaalLocatieInVeld = $scope.gegevens.showExtraText; //($scope.gegevens.showExtraText && ($scope.gegevens.typeList[$scope.gegevens.selectedType].name === "veldtekening"));
      var meetpunten;
      $scope.canPrint = false;
      mapexportService
        .getMeetpunten(
          punteninArea,
          isBepaalLocatieInVeld,
          $scope.gegevens.extraText,
          $scope.gegevens.printZ,
          $scope.gegevens.projectnrInLabels,
          $scope.gegevens.tagklantInLabels,
        )
        .then(function (data1) {
          meetpunten = data1;
          var iconCounts = meetpunten.legenda.split(',').length;
          var pointCounts = $scope.gegevens.printCoordinates
            ? meetpunten.coordinaten.split(',').length
            : 0;
          var bottomY = 95 + iconCounts * 13 + pointCounts * 10;
          var fitsOnPage = bottomY < 604 ? true : false;
          if (!fitsOnPage) {
            $scope.canPrint = true;
            const content = `
              <p>De coordinatenlijst past niet op de tekening.</p>
              <p>Kies minder punten of zet printen van coordinatenlijst uit.</p>
              <p>De tekening wordt niet geprint.</p>
            `;
            const template = `
              <md-dialog-content class="md-dialog-content">
                <h2 class="md-title">{{::title}}</h2>
                <div style="overflow: auto; max-height: 324px;">
                  ${content}
                </div>
              </md-dialog-content>
              <md-dialog-actions>
                <button
                  class="md-primary md-confirm-button md-button md-ink-ripple md-blue-theme"
                  type="button"
                  ng-click="hide()"
                >
                  {{::ok}}
                </button>
              </md-dialog-actions>
            `;
            const controller = function ($scope, $mdDialog) {
              $scope.title = `Waarschuwing`;
              $scope.ok = 'Sluiten';
              $scope.hide = $mdDialog.hide;
            };
            controller.$inject = ['$scope', '$mdDialog'];
            $mdDialog.show({
              controller,
              template,
              multiple: true,
              parent: angular.element('#mapexportPrint'),
              clickOutsideToClose: true,
              escapeToClose: true,
            });
          } else {
            mapService.refresh(['Meetpunten', 'Meetpunt labels']);
            var data = mapexportService.createPrintData(
              items,
              $scope.gegevens,
              meetpunten,
            );
            userAccountService.createDoc(data);
          }
        });
    };
    $scope.printXYZ = function () {
      $scope.canPrint = false;
      mapexportService
        .getMeetpuntenXYZ(punteninArea, $scope.xyzGegevens)
        .then(function (data1) {
          const meetpunten = data1.coordinaten.meetpunten;
          const tagklantType = data1.coordinaten.tagklantType;
          var data = mapexportService.createXyzPrintData(
            tagklantType,
            $scope.gegevens.typeList[$scope.gegevens.selectedType].name,
            meetpunten,
            data1.projection,
          );
          userAccountService.createDoc(data);
        });
    };
  }
  pageDialogController.$inject = [
    '$scope',
    '$mdDialog',
    'items',
    'gegevens',
    'userInitials',
  ];

  function selectDialogController($scope, items) {
    $scope.closeSvg = closeSvg;

    $scope.items = items;
    $scope.cancel = function () {
      $mdDialog.hide(null);
    };
    $scope.select = function (item) {
      var sameItems = items.filter((it) => {
        return (
          it.projectnr == item.projectnr &&
          it.tekeningtype != item.tekeningtype &&
          it.bladnr == item.bladnr
        );
      });
      $mdDialog.hide({ item: item, sameItems: sameItems });
    };
  }
  selectDialogController.$inject = ['$scope', 'items'];

  function initTooltips() {
    return {
      minPageSize: 'Kleiner formaat pagina',
      pageSize: 'Huidig pagina formaat',
      maxPageSize: 'Groter formaat pagina',
      minPageOrientation: 'Portrait',
      pageOrientation: 'Huidige pagina oriëntatie',
      maxPageOrientation: 'Landscape',
      minScale: 'Grotere schaal',
      scale: 'Huidige schaal',
      maxScale: 'Kleinere schaal',
      box: 'Vernieuw kader',
      print: 'Print kader',
      move: 'Toon/Verberg pijlen om kader te veplaatsen',
      nietBeschikbaar: 'Niet beschikbaar',
      moveTopLeft: 'Verschuif kader naar linksboven',
      moveTop: 'Verschuif kader naar boven',
      moveTopRight: 'Verschuif kader naar rechtsboven',
      moveLeft: 'Verschuif kader naar links',
      moveRight: 'Verschuif kader naar rechts',
      moveBottomLeft: 'Verschuif kader naar linksonder',
      moveBottom: 'Verschuif kader naar onder',
      moveBottomRight: 'Verschuif kader naar rechtsonder',
      center: 'Verschuif kader naar centrum kaart',
      boxes: 'Toon printkaders (geen - veldtekeningen - situatietekeningen)',
    };
  }
}
