import angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-sanitize';
import 'angular-material';
import 'ng-file-upload';
import './liveSearch';

const app = angular.module('app', [
  'ng',
  'ngAnimate',
  'ngAria',
  'ngMessages',
  'ngSanitize',
  'ngMaterial',
  'ngFileUpload',
  'LiveSearch',
]);
window.app = app;

app.config([
  '$mdThemingProvider',
  ($mdThemingProvider) => {
    $mdThemingProvider
      .theme('blue')
      .primaryPalette('blue')
      .accentPalette('blue')
      .warnPalette('blue')
      .backgroundPalette('grey');

    $mdThemingProvider.definePalette('WenPPalette', {
      //lightblue, bruin accent    //grey
      50: '#FCFDFF', // '#FCFDFF'   // '#FAFAFA'
      100: '#F7FBFE', // '#F7FBFE'   // '#F5F5F5'
      200: '#F1F9FE', // '#F1F9FE'   // '#EEEEEE'
      300: '#EBF6FE', // '#EBF6FE'   // '#E0E0E0'
      400: '#E7F4FD', // '#E7F4FD'   // '#BDBDBD'
      500: '#E3F2FD', // '#E3F2FD'   // '#9E9E9E'
      600: '#E0F0FD', // '#E0F0FD'   // '#757575'
      700: '#DCEEFC', // '#DCEEFC'   // '#616161'
      800: '#D8ECFC', // '#D8ECFC'   // '#424242'
      900: '#D0E8FC', // '#D0E8FC'   // '#212121'
      A100: '#D3C2BE', // '#D9E7FF    // '#c87965'
      A200: '#B69A93', // '#A6C8FF    // '#cf8978'
      A400: '#6D3527', // '#73A8FF    // '#d59a8b'
      A700: '#5A281D', // '#5998FF    // '#0f0705'
      contrastDefaultColor: 'light', // whether, by default, text (contrast)
      // on this palette should be dark or light
      contrastDarkColors: [
        '50',
        '100', //hues which contrast should be 'dark' by default can be specified if default is 'light'
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
        'A100',
        'A200',
      ],
      contrastLightColors: undefined, //['700', '800', '900', 'A400', 'A700']//undefined    //hues which contrast should be 'light' by default can be specified if default is 'dark'
    });

    //BROWN
    //        '50': '#c16852',
    //        '100': '#b85a42',
    //        '200': '#a5503b',
    //        '300': '#934734',
    //        '400': '#803e2e',
    //        '500': '#6D3527',
    //        '600': '#5a2c20',
    //        '700': '#47231a',
    //        '800': '#351a13',
    //        '900': '#22100c',
    //        'A100': '#c87965',
    //        'A200': '#cf8978',
    //        'A400': '#d59a8b',
    //        'A700': '#0f0705',

    $mdThemingProvider.definePalette('WenPPaletteAccent', {
      50: '#2e74b3',
      100: '#3381c7',
      200: '#458ecf',
      300: '#599ad4',
      400: '#6da6da',
      500: '#82b3df',
      600: '#aacbe9',
      700: '#bfd8ee',
      800: '#d3e4f4',
      900: '#e7f0f9',
      A100: '#aacbe9',
      A200: '#96BFE4',
      A400: '#82b3df',
      A700: '#fbfdfe',
      contrastDefaultColor: 'dark', //'light' whether, by default, text (contrast)
      // on this palette should be dark or light
      contrastDarkColors: [
        '50',
        '100', //hues which contrast should be 'dark' by default
        '200',
        '300',
        '400',
        'A100',
        'A200',
      ],
      contrastLightColors: undefined, // could also specify this if default was 'dark'
    });
    $mdThemingProvider.definePalette('WenPPaletteWarn', {
      50: '#fff280',
      100: '#ffef67',
      200: '#ffec4d',
      300: '#ffe934',
      400: '#ffe71a',
      500: '#FFE401',
      600: '#e6ce00',
      700: '#cdb700',
      800: '#b3a000',
      900: '#9a8a00',
      A100: '#fff49a',
      A200: '#fff7b3',
      A400: '#fffacd',
      A700: '#807300',
      contrastDefaultColor: 'dark', // whether, by default, text (contrast)
      // on this palette should be dark or light
      contrastDarkColors: undefined,
      contrastLightColors: [
        '50',
        '100', //hues which contrast should be 'dark' by default
        '200',
        '300',
        '400',
        'A100',
        'A200',
      ], // could also specify this if default was 'dark'
    });
    $mdThemingProvider
      .theme('WenPTheme')
      .primaryPalette('WenPPalette')
      .accentPalette('WenPPaletteAccent')
      .warnPalette('WenPPaletteWarn');
    //var merkatorMap = $mdThemingProvider.extendPalette('green', { 'A200': '2c7e76' });
    //$mdThemingProvider.definePalette('merkator', merkatorMap);
    //$mdThemingProvider.theme('default').accentPalette('merkator')
    $mdThemingProvider.setDefaultTheme('WenPTheme');
  },
]);
