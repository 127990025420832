import config from '../config';

import includeSrc from './documenten.html?url';

window.app.controller('documentenCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  'utilService',
  'modelService',
  documentenCtrl,
]);

function documentenCtrl($scope, $rootScope, $sce, utilService, modelService) {
  $scope.includeSrc = $sce.trustAsResourceUrl(includeSrc);

  function src(model) {
    model = model || modelService.getModel();
    let src = 'about:blank';
    if (window.INTERNAL_USER && model.project) {
      const projectnr = '' + model.project.projectnr;
      // eslint-disable-next-line no-inner-declarations
      function left(len) {
        return projectnr.substring(0, len);
      }
      // switch on the VPN to make this work on localhost:
      src = `${config.prefix}/lezen/${left(2)}xxx/${left(3)}xx/${left(
        4,
      )}x/${projectnr}/_Project/`;
    }
    // https://docs.angularjs.org/api/ng/service/$sce#trustAsResourceUrl
    $scope.src = $sce.trustAsResourceUrl(src);
  }

  src();

  $rootScope.$on('model-project', (_, model) => {
    src(model);
    $scope.$apply();
  });

  let doc;
  utilService.elementPromise('#documentsFrame').then((iframe) => {
    const win = iframe.contentWindow;
    if (config.prefix) {
      // on reading win.document:
      // Blocked a frame with origin "http://localhost:3000" from accessing a cross - origin frame.
      return;
    }
    // too late for an onload or DOMContentLoaded
    setInterval(() => {
      if (doc === win.document) {
        return;
      }
      doc = win.document;
      hideElement(iframe);
      setTimeout(() => {
        tags('A').forEach((a) => {
          copyBodyStyle(a, 'fontFamily');
          // skip directories
          if (!a.href.endsWith('/')) {
            // make the file open in a new tab, instead of trying to
            // render it within the iframe
            a.target = a.href;
          }
          if (a.innerHTML === '[To Parent Directory]') {
            a.innerHTML = '[Terug]';
            if (win.location.href.endsWith('/_Project/')) {
              hideElement(a);
              let sibling = a;
              while ((sibling = sibling.nextSibling || {}).tagName === 'BR') {
                hideElement(sibling);
              }
            }
          }
        });
        hideElements(tags('H1'));
        hideElements(tags('HR'));
        copyBodyStyle(doc.body, 'fontSize');
        showElement(iframe);
      }, 100);
    }, 100);
  });

  function tags(name) {
    return Array.from(doc.getElementsByTagName(name));
  }

  function copyBodyStyle(to, key) {
    copyStyle(document.body, to, key);
  }

  function copyStyle(from, to, key) {
    copyStyles(from, to, [key]);
  }

  function copyStyles(from, to, keys) {
    const fromStyle = getComputedStyle(from);
    keys.forEach((key) => (to.style[key] = fromStyle[key]));
  }

  function hideElement(element) {
    hideElements([element]);
  }

  function showElement(element) {
    showElements([element]);
  }

  function hideElements(elements) {
    displayElements(elements, 'none');
  }

  function showElements(elements) {
    displayElements(elements, 'unset');
  }

  function displayElements(elements, display) {
    elements.forEach((element) => (element.style.display = display));
  }
}
