﻿import * as olProj from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import icNavigation from '../../images/svgs/ic-navigation.svg';

window.app.service('streetviewService', [
  '$rootScope',
  'utilService',
  streetviewService,
]);

function streetviewService($rootScope, utilService) {
  const service = {
    initStreetview,
    destroyStreetview,
  };

  $rootScope.$on('map-init', () => {
    if ($rootScope.streetview) {
      // The map view's projection has changed; reinitialise StreetView.
      destroyStreetview();
      initStreetview();
    }
  });

  let panorama;
  let centerLayer;
  let viewpoint;

  return service;

  function initStreetview() {
    utilService.elementPromise('#streetviewContainer').then(() => {
      const ID = 'googlemapsApi';
      if (document.getElementById(ID)) {
        init();
      } else {
        // this will dynamically download a complete google maps client
        var script = document.createElement('script');
        script.id = ID;
        script.src = $rootScope.geowepSettings.googlemapsApikey;
        script.onload = init;
        document.body.appendChild(script);
      }
    });
  }

  function init() {
    $rootScope.streetview = true;
    var center = window.map.getView().getCenter();
    var latlonCoord = olProj.toLonLat(
      center,
      window.map.getView().getProjection(),
    );
    var location = { lat: latlonCoord[1], lng: latlonCoord[0] };
    document.getElementById('streetview').style.position = 'static';
    // var infoWindow = new google.maps.InfoWindow();
    // var maxZoomService = new google.maps.MaxZoomService();
    var pov = { heading: 34, pitch: 0 };
    panorama = createPanorama(location, pov);
    centerLayer = createLayer(location);
    window.map.addLayer(centerLayer);
    $rootScope.$on('mapClicked', function (evt, e) {
      e.stopPropagation();
      changePosition(e);
    });
    // function showMaxZoom(e) {
    //     var sourceProj = window.map.getView().getProjection();
    //     var coord = ol.proj.transform(e.coordinate, sourceProj, 'EPSG:4326');
    //     var latLng = new google.maps.LatLng(coord[0], coord[1]);
    //     maxZoomService.getMaxZoomAtLatLng(latLng, function (response) {
    //         if (response.status !== 'OK') {
    //             infoWindow.setContent('Error in MaxZoomService');
    //         } else {
    //             infoWindow.setContent(
    //                 'The maximum zoom at this location is: ' + response.zoom);
    //         }
    //         infoWindow.setPosition(latLng);
    //         infoWindow.open(map);
    //     });
    // }
  }

  function destroyStreetview() {
    window.map.removeLayer(centerLayer);
    $rootScope.streetview = false;
  }

  function changePosition(e) {
    var rdCoord = e.coordinate;
    var latlonCoord = olProj.toLonLat(
      rdCoord,
      window.map.getView().getProjection(),
    );
    var location = { lat: latlonCoord[1], lng: latlonCoord[0] };
    var pov = { heading: 34, pitch: 0 };
    panorama = createPanorama(location, pov);
    var panoramaPosition = panorama.getPosition();
    var sourceProj = window.map.getView().getProjection();
    var panoramaRdCoord = olProj.transform(
      [panoramaPosition.lng(), panoramaPosition.lat()],
      sourceProj,
      'EPSG:4326',
    );
    moveViewpoint(panoramaRdCoord);
  }

  function moveViewpoint(rdCoord) {
    viewpoint.getGeometry().setCoordinates([rdCoord[0], rdCoord[1]]);
  }
  function rotateViewpoint() {
    var style = centerLayer.getStyle();
    var image = style.getImage();
    var rotation = (panorama.getPov().heading * Math.PI) / 180;
    image.setRotation(rotation);
    centerLayer.setStyle(style);
  }

  function createPanorama(location, pov) {
    // eslint-disable-next-line no-undef
    panorama = new google.maps.StreetViewPanorama(
      document.getElementById('streetview'),
      {
        position: location,
        pov: {
          heading: pov.heading,
          pitch: pov.pitch,
        },
      },
    );
    panorama.addListener('position_changed', function () {
      var position = panorama.getPosition();
      var sourceProj = window.map.getView().getProjection();
      var lng = position.lng();
      var lat = position.lat();
      var latlngCoord = [lng, lat];
      var rdCoord = olProj.transform(latlngCoord, 'EPSG:4326', sourceProj);
      moveViewpoint(rdCoord);
    });
    //panorama.addListener('pano_changed', function () {
    //    alert('Pano changed');
    //    var pano = panorama.getPano();
    //});
    //panorama.addListener('links_changed', function () {
    //    alert('Links changed');
    //    var links = panorama.getLinks();
    //});
    panorama.addListener('pov_changed', function () {
      rotateViewpoint();
    });
    return panorama;
  }

  function createLayer(pos) {
    var iconFeatures = [];

    viewpoint = new Feature({
      geometry: new Point([pos.lat, pos.lng]),
    });
    iconFeatures.push(viewpoint);

    var vectorSource = new VectorSource({
      features: iconFeatures,
    });

    function iconStyle() {
      return new Style({
        image: new Icon({
          src: icNavigation,
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          rotation: (panorama.getPov().heading * Math.PI) / 180,
          scale: 2,
        }),
      });
    }
    var vectorLayer = new VectorLayer({
      source: vectorSource,
      style: iconStyle(),
      zIndex: 1000,
      title: 'streetview',
      id: 'streetview',
    });
    return vectorLayer;
  }
}
