﻿window.app.service('selectionService', ['modelService', selectionService]);

function selectionService(modelService) {
  var _selections = [];
  var selectionService = {
    hasSelections,
    addSelection,
    removeSelection,
    clearSelections,
    selectSomething,
  };

  function hasSelections() {
    return _selections.length > 0;
  }

  function clearTools() {
    for (var i in _selections) {
      modelService.update('model-tools', _selections[i], 'DELETE');
    }
  }

  function clearSelections() {
    clearTools();
    _selections = [];
  }

  function addSelection(tool) {
    _selections.push(tool);
  }

  function removeSelection(tool) {
    var index = _selections.indexOf(tool);
    if (index >= 0) {
      _selections.splice(index, 1);
    }
  }

  function selectSomething(geom) {
    modelService.update('model-filter-kaart', geom, true);
    //clearTools();
  }

  return selectionService;
}
