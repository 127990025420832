window.app.controller('tooltipCtrl', [
  '$scope',
  '$rootScope',
  '$timeout',
  tooltipCtrl,
]);

function tooltipCtrl($scope, $rootScope, $timeout) {
  $rootScope.$on('tooltip', (_, tooltip) => {
    // $timeout as a way of "$apply() when needed"
    $timeout(() => {
      $scope.tooltip = tooltip;
    });
  });
}
