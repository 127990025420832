﻿const angular = window.angular;

window.app.controller('lengthCtrl', [
  '$scope',
  '$rootScope',
  '$controller',
  'measureService',
  'mapToolsService',
  'modelService',
  lengthCtrl,
]);

function lengthCtrl(
  $scope,
  $rootScope,
  $controller,
  measureService,
  mapToolsService,
  modelService,
) {
  angular.extend(this, $controller('measureCtrl', { $scope: $scope }));

  $rootScope.$on('mapDoubleClicked', function () {
    console.log('doubleClick ontvangen in lengthCtrl');
  });

  $scope.MeasureLength = function (el) {
    el = el || window.event;
    el.srcElement.parentElement.style = 'opacity: 1; z-index: 25;';
    modelService.update('model-tools', 'LENGTH', 'ADD');
    measureService.addMeasure('LENGTH');
    mapToolsService.MeasureLength();
  };
}
