﻿window.app.controller('measureCtrl', [
  '$scope',
  '$rootScope',
  'measureService',
  measureCtrl,
]);

function measureCtrl($scope, $rootScope, measureService) {
  $scope.hasMeasures = function () {
    return measureService.hasMeasures();
  };

  $scope.clearMeasures = function () {
    measureService.clearMeasures();
  };
}
