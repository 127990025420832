﻿import Feature from 'ol/Feature';
import GeometryCollection from 'ol/geom/GeometryCollection';

import src from './plantekeningen.html?url';

const toastr = window.toastr;

window.app.controller('gridPlantekeningenCtrl', [
  '$rootScope',
  '$scope',
  '$sce',
  'modelService',
  'userAccountService',
  'mapService',
  'gridPlantekeningen',
  'gridFactory',
  gridPlantekeningenCtrl,
]);

function gridPlantekeningenCtrl(
  $rootScope,
  $scope,
  $sce,
  modelService,
  userAccountService,
  mapService,
  gridPlantekeningen,
  gridFactory,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.views = gridPlantekeningen.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridPlantekeningen.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  $scope.disabled = true;
  $scope.projectId;

  $scope.$on('onload', (_, id) => {
    $scope.id = $scope.id || id;
  });

  function setDisabled(value) {
    $scope.disabled = value;
  }

  function setProject(model) {
    setDisabled(true);
    $scope.projectId = model.project ? model.project.id : undefined;
  }

  let layer, featureType;

  function init(model) {
    if (model.grid.PLANTEKENINGEN) {
      layer = 'Plantekeningen';
      featureType = 'PLAN';
    } else if (model.grid.PROJECTKAARTEN) {
      layer = 'Projectkaarten';
      featureType = 'PROJECT';
    }
    return model.grid.PLANTEKENINGEN || model.grid.PROJECTKAARTEN;
  }

  $rootScope.$on('model-selectie', (_, model) => {
    if (init(model)) {
      setDisabled(model.selectie.length === 0);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      setProject(model);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-grid', (_, model) => {
    if (init(model)) {
      setProject(model);
      mapService.setHighlight(layer, (ids) =>
        gridPlantekeningen
          .getPlantekeningen(ids)
          .map((tekening) => new Feature(tekening.hull)),
      );
      $scope.$apply();
    }
  });

  function getSelectedPlantekeningen() {
    return gridFactory.getRowsFromRange().map((row) => row.item);
  }

  $scope.zoomToPlantekeningen = () => {
    const plantekeningen = getSelectedPlantekeningen(),
      bboxes = plantekeningen.map((item) => item.bbox),
      geometryCollection = new GeometryCollection(bboxes),
      extent = geometryCollection.getExtent();
    mapService.fitToExtent(extent, {
      layer: layer,
      maxZoom: 13,
      center: [plantekeningen[0].x, plantekeningen[0].y],
    });
  };

  $scope.deletePlantekeningen = () => {
    const ids = getSelectedPlantekeningen().map((item) => item.id);
    if (confirm('Geselecteerde bestanden verwijderen?')) {
      userAccountService.deletePlantekeningen(ids).then(function () {
        toastr.success('Bestanden verwijderd');
        refresh();
      });
    }
  };

  $scope.upload = {
    tooltip: 'Bestand uploaden',
    busy: false,
    post: (file) => {
      if (file) {
        $scope.upload.busy = true;
        userAccountService
          .postPlantekening($scope.projectId, featureType, file)
          .then(() => {
            toastr.success('Upload geslaagd');
            refresh();
          })
          .finally(() => {
            $scope.upload.busy = false;
          });
      }
    },
  };

  function refresh() {
    const model = modelService.getModel();
    gridPlantekeningen.refresh(model);
    mapService.refresh(layer);
  }

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridPlantekeningen.setView(index);
    }
  };

  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridPlantekeningen.setGrouping($scope.selectedGrouping);
  };

  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };

  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };

  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel();
  };

  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };
}
