﻿import Overlay from 'ol/Overlay';
import Draw from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import Modify from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Style from 'ol/style/Style';
import * as olSphere from 'ol/sphere';
import * as olEventsCondition from 'ol/events/condition';
import * as olProj from 'ol/proj';
import WKT from 'ol/format/WKT';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import Collection from 'ol/Collection';

import curDrawSvg from '../../images/mapcursor/cur_draw.svg';

const angular = window.angular;

window.app.service('mapToolsService', [
  '$rootScope',
  'modelService',
  'mapService',
  'mapSelectionService',
  'selectionService',
  mapToolsService,
]);

function mapToolsService(
  $rootScope,
  modelService,
  mapService,
  mapSelectionService,
  selectionService,
) {
  /** VARIABLES */
  var helpTooltip = null;
  var helpTooltipElement = null;
  var measureTooltipElement = null;
  var measureTooltipPart = null;
  var measureTooltipPartElement = null;
  var measureTooltip = null;
  var tooltiptext = '';
  var tooltipStartDrawMeasure = 'Klik om de meting te starten';
  var tooltipContinueDrawMeasure = 'Klik nogmaals';
  var tooltipContinueDrawMeasureBack = 'Esc om stap terug te gaan';
  var tooltipContinueDrawMeasureFinish = 'Dubbelklik om de meting te stoppen';
  var sketch = null;
  var draw = null;
  var drawnFeatures = new Collection();
  drawnFeatures.wktWriter = new WKT();
  drawnFeatures.getWKT = function () {
    var wkt = this.wktWriter.writeFeatures(this.getArray(), { decimals: 2 });
    if (this.getLength() === 1) {
      wkt = 'GEOMETRYCOLLECTION(' + wkt + ')';
    }
    return wkt;
  };
  var mousePointerStyle = 'default';
  var sourceVectorMeasureLines = new VectorSource({ wrapX: false });
  var sourceVectorMeasureAreas = new VectorSource({ wrapX: false });
  var vectorMeasureLines = new VectorLayer({
    source: sourceVectorMeasureLines,
    style: new Style({
      fill: new Fill({
        color: 'rgba(104, 209, 0, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(104, 209, 0, 0.9)',
        width: 3,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: 'rgba(104, 209, 0, 1)',
        }),
      }),
    }),
    visible: true,
    zIndex: 999999,
    title: 'geowep_measure_lines',
  });
  var vectorMeasureAreas = new VectorLayer({
    source: sourceVectorMeasureAreas,
    style: new Style({
      fill: new Fill({
        color: 'rgba(104, 209, 0, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(104, 209, 0, 0.9)',
        width: 3,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: 'rgba(104, 209, 0, 1)',
        }),
      }),
    }),
    visible: true,
    zIndex: 999999,
    title: 'geowep_measure_areas',
  });
  var overlay = new Overlay({
    element: document.getElementById('popup'),
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });
  var selectStyle = new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({ color: 'rgba(68, 201, 123, 0.2)' }),
      stroke: new Stroke({ color: 'rgba(68, 201, 123, 0.9)', width: 3 }),
    }),
  });
  var selectedLocationfeatures = new Collection();
  selectedLocationfeatures.on('add', addSelectionListener);
  selectedLocationfeatures.on('remove', removeSelectionListener);
  var polygonfeature;
  var drawpolygon;
  var modifypolygon;
  var polygonFeatures = new Collection();
  var polygonSource = new VectorSource({ features: polygonFeatures });
  var polygonOKStyle = polygonStyle(124, 178, 170);
  var polygonModifyStyle = polygonStyle(30, 190, 255);
  var polygonWarningStyle = polygonStyle(255, 228, 0);
  var featureOverlay = new VectorLayer({
    source: polygonSource,
    style: polygonOKStyle,
  });
  $rootScope.$on('map-layers-loaded', () => {
    featureOverlay.setMap(window.map);
  });

  function polygonStyle(r, g, b) {
    const width = 6;
    const highlightWidth = 4;
    const opacity = {
      stroke: 0.8,
      fill: 0.2,
    };
    const highlightColor = `rgba(255, 255, 255, ${opacity.stroke})`;
    const strokeColor = `rgba(${r}, ${g}, ${b}, ${opacity.stroke})`;
    const fillColor = `rgba(${r}, ${g}, ${b}, ${opacity.fill})`;
    return [
      new Style({
        stroke: new Stroke({
          color: highlightColor,
          width: width + highlightWidth,
        }),
      }),
      new Style({
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width }),
        image: new CircleStyle({
          radius: width * 1.5,
          stroke: new Stroke({
            color: highlightColor,
            width: highlightWidth * 0.75,
          }),
          fill: new Fill({ color: strokeColor }),
        }),
      }),
    ];
  }

  var selectInteraction = new Select({
    //only the pointdata
    layers: function (layer) {
      //console.log(layer.getProperties().title);
      if (
        layer.getProperties().title &&
        layer.getProperties().title.substring(0, 10) === 'Meetpunten'
      ) {
        return true;
      } else {
        return false;
      }
    },
    style: selectStyle,
    multi: true,
    features: selectedLocationfeatures,
    condition: function (mapBrowserEvent) {
      return (
        olEventsCondition.click(mapBrowserEvent) &&
        olEventsCondition.shiftKeyOnly(mapBrowserEvent)
      );
    },
  });

  addEventListeners();

  function setMousePointerSwitcher(newMouse) {
    //default, crosshair, grab, help, ..., draw
    //console.log("changing mousepointerstyle to " + mousePointerStyle);
    if (newMouse === 'draw') {
      mousePointerStyle = `url('${curDrawSvg}'), 'pointer'`;
    } else {
      mousePointerStyle = newMouse;
    }
    window.map.getViewport().style.cursor = mousePointerStyle;
    // Change mouse pointer style when it is over the map area.
    window.map.getViewport().onmouseenter = function () {
      //console.log("enter change mouse to " + mousePointerStyle);
      window.map.getViewport().style.cursor = mousePointerStyle;
    };

    // When not over the map area, mouse pointer style
    // is the default one.
    window.map.getViewport().onmouseleave = function () {
      //console.log("leaving change mouse to default");
      window.map.getViewport().style.cursor = 'default';
    };
  }

  function MeasureLength() {
    RemoveEvents();
    setMousePointerSwitcher('crosshair');
    addInteraction('LineString');
  }

  function MeasureArea() {
    RemoveEvents();
    setMousePointerSwitcher('crosshair');
    addInteraction('Polygon');
  }

  function EraseMeasures() {
    RemoveEvents();
    RemoveOverlays();
    setMousePointerSwitcher('default');
  }

  function RemoveEvents() {
    //console.debug("remove events");
    window.map.un('pointermove', pointerMoveHandler);

    if (draw) {
      window.map.removeInteraction(draw);
    }
    if (modifypolygon) {
      window.map.removeInteraction(modifypolygon);
    }
    if (drawpolygon) {
      window.map.removeInteraction(drawpolygon);
    }
    if (selectInteraction) {
      window.map.removeInteraction(selectInteraction);
    }
  }

  function addInteraction(typeMeasure, opt_tooltiptext, opt_ondrawend) {
    drawnFeatures.clear();

    //window.map.removeLayer(vectorMeasure);//removes old listeners
    var maplayers = window.map.getLayers();
    var foundLayer;
    var i;
    if (typeMeasure === 'LineString' || typeMeasure === 'Point') {
      for (i = 0; i < maplayers.getLength(); i++) {
        if (maplayers.item(i).get('title') === 'geowep_measure_lines') {
          foundLayer = maplayers.item(i);
        }
      }
      if (!foundLayer) {
        window.map.addLayer(vectorMeasureLines);
      }
      vectorMeasureLines.setVisible(true);
    } else {
      for (i = 0; i < maplayers.getLength(); i++) {
        if (maplayers.item(i).get('title') === 'geowep_measure_areas') {
          foundLayer = maplayers.item(i);
        }
      }
      if (!foundLayer) {
        window.map.addLayer(vectorMeasureAreas);
      }
      vectorMeasureAreas.setVisible(true);
    }

    tooltiptext = opt_tooltiptext || tooltipStartDrawMeasure;
    window.map.on('pointermove', pointerMoveHandler);
    var type = typeMeasure;
    var geomfunc = function (coordinates, geometry) {
      if (!geometry) {
        if (type === 'LineString') {
          geometry = new LineString([]);
        } else if (type === 'Point') {
          geometry = new Point([]);
        } else {
          geometry = new Polygon([]);
        }
      } else {
        var coords = geometry.getCoordinates();
        var diff = coordinates.length - coords.length;
        if (diff > 1) {
          coordinates.splice(coordinates.length - diff, diff - 1);
        }
      }
      geometry.setCoordinates(coordinates);
      return geometry;
    };
    draw = new Draw({
      source:
        typeMeasure === 'Polygon'
          ? sourceVectorMeasureAreas
          : sourceVectorMeasureLines,
      type: /** @type {ol.geom.GeometryType} **/ (type),
      style: new Style({
        fill: new Fill({
          color: 'rgba(104, 209, 0, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(104, 209, 0, 0.5)',
          lineDash: [5, 5],
          width: 3,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.9)',
          }),
          fill: new Fill({
            color: 'rgba(104, 209, 0, 0.2)',
          }),
        }),
      }),
      geometryFunction: geomfunc,
      features: drawnFeatures,
    });

    createHelpTooltip();

    draw.on(
      'drawstart',
      function (evt) {
        // set sketch
        //console.log ("drawstart: measure");
        sketch = evt.feature;
        angular
          .element(document.getElementById('dMainContent'))
          .scope()
          .$broadcast('busybee-start', { message: 'maat tekenen' });
      },
      this,
    );

    draw.on(
      'drawend',
      function (evt) {
        if (evt.feature) {
          //console.log ("drawend: measure");
          if (measureTooltipElement) {
            measureTooltipElement.className = 'tooltip tooltip-measure-static';
            measureTooltip.setOffset([0, -15]);
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            if (measureTooltipPartElement) {
              measureTooltipPartElement.parentNode.removeChild(
                measureTooltipPartElement,
              );
              measureTooltipPartElement = null;
            }
          }
        }
        // unset sketch
        sketch = null;
        angular
          .element(document.getElementById('dMainContent'))
          .scope()
          .$broadcast('busybee-finish', { message: 'klaar met maat tekenen' });
        if (opt_ondrawend) {
          opt_ondrawend(evt);
        }
      },
      this,
    );

    window.map.addInteraction(draw);
  }

  function startInfo(layer) {
    if (typeof layer.name !== 'undefined') {
      mapSelectionService.addIdentifyLayer(layer);
    }
    setMousePointerSwitcher('help');
  }

  function stopInfo(layer) {
    if (typeof layer.name !== 'undefined') {
      mapSelectionService.removeIdentifyLayer(layer);
    }
    if (!mapSelectionService.hasIdentifyLayers()) {
      DefaultState();
    }
  }

  function addMeetpuntToSelectie(id) {
    var pointsLayer = mapService.getPointsLayer();
    if (pointsLayer) {
      for (var f = 0; f < pointsLayer.getSource().getFeatures().length; f++) {
        if (pointsLayer.getSource().getFeatures()[f].get('id') == id) {
          selectInteraction
            .getFeatures()
            .push(pointsLayer.getSource().getFeatures()[f]);
          break;
        }
      }
    }
  }

  function removeMeetpuntFromSelectie(id) {
    var features = selectInteraction.getFeatures();
    for (var f = 0; f < features.getLength(); f++) {
      if (id == features.getArray()[f].get('id')) {
        selectInteraction.getFeatures().remove(features.getArray()[f]);
        break;
      }
    }
  }

  function AddDrawPolygon() {
    polygonfeature = null;
    setMousePointerSwitcher('draw');
    if (!drawpolygon) {
      drawpolygon = new Draw({
        features: polygonFeatures,
        style: polygonOKStyle,
        type: 'Polygon',
      });
      drawpolygon.on('drawend', function (evt) {
        polygonfeature = evt.feature;
        var geom = polygonfeature.getGeometry();
        window.map.removeInteraction(drawpolygon);
        modelService.update('model-tools', 'SELECTION', 'DELETE');
        AddInteractionPolygon();
        selectionService.selectSomething(geom);
      });
    }
    window.map.addInteraction(drawpolygon);
  }

  function EraseSelectionPolygons() {
    polygonFeatures.clear();
    selectInteraction.getFeatures().clear();
    if (modifypolygon) {
      window.map.removeInteraction(modifypolygon);
    }
    if (drawpolygon) {
      window.map.removeInteraction(drawpolygon);
    }
    if (selectInteraction) {
      window.map.removeInteraction(selectInteraction);
    }
  }

  function RemovePolygon() {
    RemoveAllInteractionPolygon();
    if (featureOverlay) {
      featureOverlay.getSource().clear();
    }
    polygonfeature = null;
  }

  /** PRIVATE */

  function addEventListeners() {
    document.addEventListener('keydown', function () {
      if (sketch) {
        if (event.keyCode === 27) {
          //esc undo last coordinate
          var geom = sketch.getGeometry();
          geom.setCoordinates(geom.getCoordinates().slice(0, -1));
          //console.log("remove point");
          var tooltipCoordPrevious = geom.getCoordinates()[0];
          var outputprev = '';
          if (geom.getCoordinates().length > 1) {
            //add last partial
            var clast = geom.getCoordinates()[geom.getCoordinates().length - 1];
            var cbeforelast =
              geom.getCoordinates()[geom.getCoordinates().length - 2];
            //console.debug("clast", clast);
            //console.debug("cbeforelast", cbeforelast);
            if (clast !== cbeforelast) {
              tooltipCoordPrevious = [
                (cbeforelast[0] + clast[0]) / 2,
                (cbeforelast[1] + clast[1]) / 2,
              ];
              outputprev = getDistance(cbeforelast, clast);
            }
          }
          if (measureTooltipPartElement) {
            measureTooltipPartElement.innerHTML = outputprev;
            measureTooltipPart.setPosition(tooltipCoordPrevious);
          }
        }
      }
    });
  }

  function getDistance(coordinate1, coordinate2) {
    var sourceProj = window.map.getView().getProjection();
    var c1 = olProj.transform(coordinate1, sourceProj, 'EPSG:4326');
    var c2 = olProj.transform(coordinate2, sourceProj, 'EPSG:4326');

    var length = olSphere.getDistance(c1, c2);

    var output;
    if (length > 100) {
      output = '≈ ' + Math.round((length / 1000) * 100) / 100 + ' km';
    } else if (length > 0.005) {
      output = '≈ ' + Math.round((length / 1000) * 100) / 100 + ' m';
    } else {
      output = '';
    }
    //console.debug("output", output);
    return output;
  }

  function DefaultState() {
    setMousePointerSwitcher('default');
  }

  function formatLength(line) {
    var sourceProj = window.map.getView().getProjection();
    var length = olSphere.getLength(line, { projection: sourceProj });

    var output;
    if (length > 100) {
      output = '≈ ' + Math.round((length / 1000) * 100) / 100 + ' km';
    } else if (length > 0.005) {
      output = '≈ ' + Math.round(length * 100) / 100 + ' m';
    } else {
      output = '';
    }
    return output;
  }

  function formatArea(polygon) {
    var area = polygon.getArea();
    var output;
    if (area > 10000) {
      output =
        Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else if (area > 0.005) {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    } else {
      output = '';
    }
    return output;
  }

  function RemoveOverlays() {
    //getsource, measures are drawn on a temporary source
    vectorMeasureAreas.getSource().clear();
    vectorMeasureLines.getSource().clear();
    vectorMeasureAreas.setVisible(false);
    vectorMeasureLines.setVisible(false);
    //also remove the measurement tooltips
    if (measureTooltipPartElement) {
      measureTooltipPartElement.parentNode.removeChild(
        measureTooltipPartElement,
      );
      measureTooltipPartElement = null;
    }
    if (measureTooltipPart) {
      window.map.removeOverlay(measureTooltipPart);
    }
    if (measureTooltipElement) {
      var divMeasureParent = measureTooltipElement.parentNode;
      divMeasureParent.removeChild(measureTooltipElement);
      measureTooltipElement = null;
    }
    if (measureTooltip) {
      window.map.removeOverlay(measureTooltip);
    }
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      helpTooltipElement = null;
    }
    if (helpTooltip) {
      window.map.removeOverlay(helpTooltip);
    }
    if (overlay) window.map.removeOverlay(overlay);

    // also all previous measures
    var lstChilds = window.map.getOverlays().getArray();
    for (var i = lstChilds.length - 1; i >= 0; i--) {
      var oldoverlay = lstChilds[i];
      var element = oldoverlay.get('element');
      if (element && element.tagName.toLowerCase() === 'div') {
        if (
          oldoverlay.getElement().classList.contains('tooltip-measure-static')
        ) {
          window.map.removeOverlay(oldoverlay);
        }
      }
    }
  }

  function pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }
    var helpMsg = tooltiptext;
    var tooltipCoord = evt.coordinate;
    var tooltipCoordPrevious = evt.coordinate;

    if (sketch) {
      //measure
      var output = '';
      var output2 = '';
      var geom = sketch.getGeometry();
      helpMsg = tooltipContinueDrawMeasure;

      if (geom instanceof LineString) {
        var coordinates = geom.getCoordinates();
        if (coordinates.length > 2) {
          helpMsg =
            tooltipContinueDrawMeasureBack +
            '<br />' +
            tooltipContinueDrawMeasureFinish;
        }
        output = formatLength(geom.clone());
        tooltipCoord = geom.getLastCoordinate();
        if (!measureTooltipElement && coordinates.length === 2) {
          createMeasureTooltip();
        }
        if (!measureTooltipPartElement && coordinates.length === 3) {
          createMeasurePartTooltip();
        }
        if (coordinates.length > 2) {
          //add last partial
          var clast = coordinates[coordinates.length - 1];
          var cbeforelast = coordinates[coordinates.length - 2];
          //console.debug("clast", clast);
          //console.debug("cbeforelast", cbeforelast);
          if (clast !== cbeforelast) {
            tooltipCoordPrevious = [
              (cbeforelast[0] + clast[0]) / 2,
              (cbeforelast[1] + clast[1]) / 2,
            ];
            output2 = getDistance(cbeforelast, clast);
          }
        }
      } else if (geom instanceof Polygon) {
        var coordinatesp = geom.getCoordinates()[0]; //first array is outerpolygon, more array's are holes
        if (coordinatesp.length > 2) {
          helpMsg = tooltipContinueDrawMeasureFinish;
        }
        if (!measureTooltipElement && coordinatesp.length === 3) {
          createMeasureTooltip();
        }
        output = formatArea(geom.clone()); //.transform('EPSG:4326', srid))
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      }
      if (measureTooltipElement) {
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      }
      if (measureTooltipPartElement && output2 !== '') {
        measureTooltipPartElement.innerHTML = output2;
        measureTooltipPart.setPosition(tooltipCoordPrevious);
      }
    }
    if (helpTooltipElement) {
      helpTooltipElement.innerHTML = helpMsg;
      helpTooltip.setPosition(evt.coordinate);
    }
  }

  function createHelpTooltip() {
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement('div');
    helpTooltipElement.className = 'tooltip';
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });
    window.map.addOverlay(helpTooltip);
  }

  function createMeasureTooltip() {
    //if (measureTooltipElement) {
    //    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    //}
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'tooltip tooltip-measure';
    measureTooltipElement.addEventListener('click', function () {
      return false;
    }); //dont get into edit or click when
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -30],
      positioning: 'bottom-center',
    });
    window.map.addOverlay(measureTooltip);
  }

  function createMeasurePartTooltip() {
    //if (measureTooltipPartElement) {
    //    measureTooltipPartElement.parentNode.removeChild(measureTooltipPartElement);
    //    measureTooltipPartElement = null;
    //}
    measureTooltipPartElement = document.createElement('div');
    measureTooltipPartElement.className = 'tooltip tooltip-measure';
    measureTooltipPartElement.addEventListener('click', function () {
      return false;
    }); //dont get into edit or click when
    measureTooltipPart = new Overlay({
      element: measureTooltipPartElement,
      offset: [0, 0],
      positioning: 'bottom-center',
    });
    window.map.addOverlay(measureTooltipPart);
  }

  function addSelectionListener(event) {
    var feature = event.element;
    //var collection = select.getFeatures();
    //collection.forEach(function (feature) {
    feature.set('backupstyle', feature.getStyle());
    feature.setStyle(selectStyle);
    //});
  }

  function removeSelectionListener(event) {
    var feature = event.element;
    //var collection = mapService.getPointsLayer.getSource().getFeatures();
    //collection.forEach(function (feature) {
    feature.setStyle(feature.get('backupstyle')); //getStyleForLayer('meetpunten'));
    //});
  }

  function AddInteractionPolygon() {
    //console.log("start editing");
    if (!modifypolygon) {
      modifypolygon = new Modify({
        features: polygonFeatures,
        style: polygonModifyStyle,
        type: /** @type {ol.geom.GeometryType} */ 'Polygon',
        // the SHIFT key must be pressed to delete vertices, so
        // that new vertices can be drawn at the same position
        // of existing vertices
        deleteCondition: function (event) {
          return (
            olEventsCondition.shiftKeyOnly(event) &&
            olEventsCondition.singleClick(event)
          );
        },
      });
      modifypolygon.on('modifyend', function (evt) {
        //console.log("end editing");
        polygonfeature = evt.features.getArray()[0]; //only one in object
        var geom = polygonfeature.getGeometry();
        ValidateFeature(geom);
        updateSelection(geom);
      });
    }
    window.map.addInteraction(modifypolygon);
  }

  function AddInteractionPolygonFromFeature(feature) {
    polygonfeature = feature;
    setMousePointerSwitcher('draw');
    polygonFeatures = new Collection([polygonfeature]);
    polygonSource = new VectorSource({ features: polygonFeatures });
    var geom = polygonfeature.getGeometry();
    selectionService.selectSomething(geom);
    updateSelection(geom);
  }

  function ValidateFeature(geom) {
    //console.log("valid area?"+geom);
    if (geom instanceof Polygon) {
      var area = geom.getArea();
      //console.log("area:" + area);
      if (area > 4000000 || area < 500) {
        //console.log("set error style");
        featureOverlay.setStyle(polygonWarningStyle);
      } else {
        //console.log("set ok style");
        featureOverlay.setStyle(polygonOKStyle);
      }
      window.map.render();
    }
  }

  function RemoveAllInteractionPolygon() {
    window.map.removeInteraction(selectInteraction);
    window.map.removeInteraction(drawpolygon);
    window.map.removeInteraction(modifypolygon);
  }

  function updateSelection(geom) {
    var pointsLayer = mapService.getPointsLayer();
    if (pointsLayer) {
      selectInteraction.getFeatures().clear(); //remove everything
      var featuresInGeomExtent = pointsLayer
        .getSource()
        .getFeaturesInExtent(geom.getExtent());
      featuresInGeomExtent.forEach((feature) => {
        var coords = feature.getGeometry().getCoordinates();
        if (geom.intersectsCoordinate(coords)) {
          selectInteraction.getFeatures().push(feature);
        }
      });
      // FIXME: dit doet niks toch?
      if (selectedLocationfeatures.getLength() > 0) {
        var locations = [];
        selectedLocationfeatures.forEach(function (feature) {
          if (feature.get('id') === undefined) {
            locations.push(feature.get('meetpuntid'));
          }
        });
      }
    }
  }

  return {
    setMousePointerSwitcher,
    MeasureLength,
    MeasureArea,
    EraseMeasures,
    RemoveEvents,
    addInteraction,
    startInfo,
    stopInfo,
    addMeetpuntToSelectie,
    removeMeetpuntFromSelectie,
    AddDrawPolygon,
    EraseSelectionPolygons,
    RemovePolygon,
    AddInteractionPolygonFromFeature,
  };
}
