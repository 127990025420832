﻿import WFSClient from './wfsclient';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import * as olRender from 'ol/render';
import Polygon from 'ol/geom/Polygon';
import Icon from 'ol/style/Icon';

import config from '../config';

const StreetSmartApi = window.StreetSmartApi;

window.app.service('streetsmartService', [
  '$rootScope',
  'utilService',
  streetsmartService,
]);

function streetsmartService($rootScope, utilService) {
  const service = {
    initStreetsmart,
    destroyStreetsmart,
  };

  const CYCLOMEDIA = config.prefix + '/geowep/cyclomedia';
  const origFetch = window.fetch;
  window.fetch = async (resource, init = {}) => {
    let url = resource.url || resource;
    if (url.startsWith('/')) {
      url = config.prefix + url;
    }
    if (resource instanceof Request) {
      // The properties of a request object are read-only, so we have to
      // construct a new request object to be able to alter its url, using `new
      // Request(url, init)`.
      const init = {};
      // Object.assign() and Object.entries() only read "own" properties, which
      // a request object doesn't have. For...in also reads properties up the
      // chain.
      for (const key in resource) {
        init[key] = resource[key];
      }
      if (resource.method === 'PUT' || resource.method === 'POST') {
        // Read the request object's body to include it in the init object.
        init.body = await resource.blob();
      }
      resource = new Request(url, init);
    } else {
      resource = url;
    }
    if (config.prefix && url.startsWith(config.prefix)) {
      init.credentials = 'include';
    }
    if (url.endsWith('/configuration/api_pro')) {
      // rewrite cyclomedia endpoint URLs to use our reversed proxy for authorisation
      const response = await origFetch(resource, init);
      let body = await response.text();
      const REPLACE = CYCLOMEDIA;
      ['cyclomedia.com', 'cyclomedia.nl'].forEach((domain) => {
        body = body.replaceAll(`https://atlas.${domain}`, REPLACE);
        body = body.replaceAll(
          `https://atlas{1-4}.${domain}`,
          `${REPLACE}{1-4}`,
        );
        body = body.replaceAll(`https://atlasapi.${domain}`, `${REPLACE}api`);
        body = body.replaceAll(
          `https://atlasapi{1-4}.${domain}`,
          `${REPLACE}api{1-4}`,
        );
      });
      return new Response(body, response);
    }
    return origFetch(resource, init);
  };

  $rootScope.$on('map-init', () => {
    if ($rootScope.streetsmart) {
      // The map view's projection has changed; reinitialise StreetSmart.
      destroyStreetsmart();
      initStreetsmart();
    }
  });

  let streetsmartvector = [];
  let streetsmartvectorcone = [];

  return service;

  function initStreetsmart() {
    utilService.elementPromise('#streetsmartApi').then((element) => {
      init(element);
    });
  }

  function destroyStreetsmart() {
    $rootScope.streetsmart = false;
    const target = document.getElementById('streetsmartApi');
    try {
      for (const s in streetsmartvector) {
        window.map.removeLayer(streetsmartvector[s]);
        console.log(
          'Streetsmart vectorlayer ' + streetsmartvector[s].id + ' removed',
        );
      }
      streetsmartvector = [];
      for (const s in streetsmartvectorcone) {
        window.map.removeLayer(streetsmartvectorcone[s]);
      }
      streetsmartvectorcone = [];
      //window.map.refresh();
    } catch (err) {
      console.log('StreetsmartApi delete vectorlayer failed');
    }
    try {
      StreetSmartApi.destroy({
        targetElement: target,
      });
    } catch (err) {
      console.log('streetsmartApi: destroy: failed.');
    }
  }

  function init(target) {
    $rootScope.streetsmart = true;
    // proper Authorization header is set by our reversed proxy
    var password = '$rootScope.geowepSettings.streetsmartPassword';
    var username = '$rootScope.geowepSettings.streetsmartUsername';
    var apiKey = $rootScope.geowepSettings.streetsmartApikey;
    var message = document.getElementById('streetsmartMessage');
    if (apiKey == '' || username == '' || password == '') {
      alert('Inloggegevens ontbreken');
      message.innerHTML =
        '<p>U heeft geen rechten voor het gebruik van streetSmart</p>';
      return;
    }
    var srs = window.map.getView().getProjection().getCode();
    var wfsClient = new WFSClient(
      CYCLOMEDIA + '/Recordings/wfs',
      'atlas:Recording',
      srs,
      '',
    );

    StreetSmartApi.init({
      targetElement: target,
      username,
      password,
      apiKey,
      srs,
      locale: 'nl',
      configurationUrl: CYCLOMEDIA + 'api/api/configuration',
      loginOauth: false,
      addressSettings: {
        locale: 'nl',
        database: 'CMDatabase',
      },
    }).then(
      function () {
        $rootScope.$on('mapClicked', function (evt, e) {
          //window.map.on('singleclick', function (evt) {
          //openImage("145566.106913, 423007.027425");
          //openImage("5D4KX5SM");
          var features = [];
          window.map.forEachFeatureAtPixel(
            e.pixel,
            function (feature) {
              features.push(feature);
            },
            function (layer) {
              return layer.get('id') == 'streetsmart';
            },
          );
          if (features.length > 0) {
            openImage(features[0].get('imageId')); // To prevent opening multiple panoramaviews (at ..€ each)
          }
        });
        onMoveEnd(null, window.map);
        $rootScope.$on('map-moveend', onMoveEnd);
      },
      function (error) {
        console.error(error);
        message.innerHTML =
          '<p>Het initialiseren van streetSmart is mislukt.</p>';
      },
    );

    function onMoveEnd() {
      var view = window.map.getView();
      var zoomlevel = view.getZoom();
      if (zoomlevel > 14) {
        message.innerHTML = '';
        if ($rootScope.streetsmart) {
          showStreetsmartLayers(true);
          var extent = window.map
            .getView()
            .calculateExtent(window.map.getSize());
          wfsClient.loadBbox(
            extent[0],
            extent[1],
            extent[2],
            extent[3],
            bboxReady,
            username,
            password,
          );
        }
      } else {
        message.innerHTML = '<p>Verder inzoomen a.u.b.</p>';
        showStreetsmartLayers(false);
      }
    }

    function showStreetsmartLayers(visibility) {
      for (const i in streetsmartvector) {
        streetsmartvector[i].set('visible', visibility);
      }
      for (const i in streetsmartvectorcone) {
        streetsmartvectorcone[i].set('visible', visibility);
      }
    }

    var vector = new VectorLayer({
      source: new VectorSource(),
      projection: window.map.getView().getProjection(),
      style: new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({ color: '#4060FF', opacity: 0.7 }),
          stroke: new Stroke({ color: '#2030DD', width: 1, opacity: 0.7 }),
        }),
      }),
      visible: true,
      zIndex: 1000,
      title: 'streetsmart',
      id: 'streetsmart',
    });
    streetsmartvector.push(vector);

    function RecordingFeature(coordinate, imageId) {
      var geom = new Point(coordinate);
      var elem = { geometry: geom, imageId: imageId };
      return new Feature(elem);
    }

    function bboxReady() {
      if (vector != null) {
        window.map.removeLayer(vector);
      }
      var layerSource = vector.getSource();
      var recordings = wfsClient.recordingList;
      if (recordings.length > 0) {
        for (let i = 0; i < recordings.length; i++) {
          var rec = recordings[i];
          var coord = [rec.lon, rec.lat];
          var imageId = rec.imageId;
          var feature = RecordingFeature(coord, imageId);
          layerSource.addFeature(feature);
        }
      }
      window.map.addLayer(vector);
    }

    let colorStyle;
    function openImage(location) {
      StreetSmartApi.open(location, {
        viewerType: [StreetSmartApi.ViewerType.PANORAMA],
        srs: window.map.getView().getProjection().getCode(),
        replace: true,
        closable: false,
      })
        .then(
          function (result) {
            if (result) {
              for (let i = 0; i < result.length; i++) {
                if (result[i].getType() === StreetSmartApi.ViewerType.PANORAMA)
                  window.panoramaViewer = result[i];
              }
            }
            const viewerColor = window.panoramaViewer.getViewerColor();
            colorStyle = new Style({
              fill: new Fill({ color: viewerColor }),
              stroke: new Stroke({ color: viewerColor, width: 0.2 }),
            });
            window.panoramaViewer.toggleRecordingsVisible(true);
            window.panoramaViewer.on(
              StreetSmartApi.Events.panoramaViewer.VIEW_CHANGE,
              changeview,
            );
            window.panoramaViewer.on(
              StreetSmartApi.Events.panoramaViewer.IMAGE_CHANGE,
              changeview,
            );
            changeview();
          }.bind(this),
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function calculateConeCoords(options) {
      var hFov = options.hFov;
      var scale = options.scale;
      // The viewing cone mirrors around the central viewing direction axis.
      // We'll split the cone into 2 virtual triangles, like this:
      //
      //                       a = sin(angle)
      //      _________           B ____ C                      ____
      //      \   |   /             \   |                      |   /
      //       \  |  /   =           \  |                   +  |  /
      //        \ | /           c = 1 \ | b =                  | /
      //         \|/                   \|  sqrt(c^2 - a^2)     |/
      //                                A
      //
      // Since both triangles now contain one 90 degree angle, we can use simple trigonometry to compute the points.
      // Note that below, angle and width are based on one of those sides, not the total of the two.
      // Get half the field of view
      var angle = hFov / 2.0;

      // Using law of sines:
      //      sin A = a / c
      //   =>     a = c * sin A
      // where a is the side opposite to A, and c is the hypotenuse (edge without right angle), which we set to 1.0.
      var width = Math.sin(angle);

      // Use famous Pythagoras formula to compute length, using a constant unit length for the hypotenuse
      var length = Math.sqrt(1.0 - width * width);

      // Total area of 2 triangles
      var area = width * length;

      // Scale such that it has a desired screen size, and make scaling dependent on actual area.
      // As a result of this, smaller view angles will result in an elongated cone, whereas greater angles will
      // produce a shorter cone.
      // NB: this will also happen to a smaller extent without this area-based scaling, since we are using a constant
      //     length for the hypotenuse.
      var size = scale / Math.sqrt(area);

      // Coordinates for the cone polygon. Must be closed by repeating first coordinate.
      // Make sure all coordinates fit within a canvas: range [0,0] to [2*width,height]
      return [
        [0, 0],
        [size * width * 2, 0],
        [size * width, size * length],
        [0, 0],
      ];
    }

    var createCanvasContext2D = function (opt_width, opt_height) {
      var canvas = document.createElement('CANVAS');
      if (opt_width) {
        canvas.width = opt_width;
      }
      if (opt_height) {
        canvas.height = opt_height;
      }
      return canvas.getContext('2d');
    };

    var id = 1;
    var mapLayer;

    function changeview() {
      var rl = window.panoramaViewer.getRecording();

      if (rl != null) {
        var viewerData = {};
        var orientation = window.panoramaViewer.getOrientation();
        viewerData.yaw = (orientation.yaw * Math.PI) / 180;
        viewerData.hFov = (orientation.hFov * Math.PI) / 180.0;
        viewerData.xyz = rl.xyz;
        viewerData.srs = rl.srs;
        viewerData.scale = 50;

        window.map.removeLayer(mapLayer);

        mapLayer = new VectorLayer({
          source: new VectorSource(),
          // visible: this.visible,
          updateWhileInteracting: true,
          zIndex: 1000,
          title: 'streetsmartArrow',
          id: 'streetsmartArrow',
        });
        streetsmartvectorcone.push(mapLayer);

        var coordinates = calculateConeCoords({
          hFov: viewerData.hFov,
          scale: viewerData.scale,
        });

        // return length and width of cone to fit in. (+1 for margin on edges)
        var dimensions = [coordinates[1][0] + 0.5, coordinates[2][1] + 0.5];

        // Create canvas to draw the cone on.
        var context = createCanvasContext2D(dimensions);
        var vectorContext = olRender.toContext(context, {
          size: dimensions,
          pixelRatio: 1,
        });

        // Set the coloring style for the cone.
        vectorContext.setStyle(colorStyle);

        // Draw the cone on the canvas.
        vectorContext.drawGeometry(new Polygon([coordinates]));

        mapLayer.setStyle(
          new Style({
            image: new Icon({
              img: context.canvas,
              rotation: viewerData.yaw,
              anchor: [0.5, 1],
              imgSize: dimensions,
              rotateWithView: true,
            }),
          }),
        );
        window.map.addLayer(mapLayer);

        var mapSource = mapLayer.getSource();
        var coneFeature = mapSource.getFeatureById('cone_' + id);

        if (!coneFeature) {
          var point_geom = new Point([viewerData.xyz[0], viewerData.xyz[1]]);

          // Build feature from polygon
          coneFeature = new Feature(point_geom);

          // Set id, type, style and viewerData for the feature
          coneFeature.setId('cone_' + id);
          coneFeature.set('viewerData', viewerData);
          coneFeature.set('color', colorStyle);
          coneFeature.set('type', 'cone');

          // Add circleFeature and coneFeature to maplayer.
          mapLayer.getSource().addFeature(coneFeature);
        } else {
          // update viewerdata of the feature.
          coneFeature.set('viewerData', viewerData);
          // Needed to draw the cone on the correct location.
          var coneGeometry = coneFeature.getGeometry();
          coneGeometry.setCoordinates([viewerData.xyz[0], viewerData.xyz[1]]);
        }
        window.map.render();
      }
    }
  }
}
