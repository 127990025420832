﻿import WMSCapabilities from 'ol/format/WMSCapabilities.js';

import config from '../config';

window.app.controller('maplayersCtrl', [
  '$scope',
  '$rootScope',
  'mapService',
  'userAccountService',
  'modelService',
  maplayersCtrl,
]);

function maplayersCtrl(
  $scope,
  $rootScope,
  mapService,
  userAccountService,
  modelService,
) {
  return (async () => {
    await window.GEOLOKET_LOGIN;

    var myLayerData = null;

    $scope.layers = myLayerData;

    const wmsCapabilities = new WMSCapabilities();

    $rootScope.$on('model-project', (_, model) => {
      // If srid changes, qgisLayers will be called on map-init below (after it
      // repopulates $scope.layers).
      if (
        !model.project ||
        `EPSG:${model.project.srid}` ===
          window.map.getView().getProjection().getCode()
      ) {
        qgisLayers(model);
      }
    });

    function qgisLayers(model = modelService.getModel()) {
      // Remove any existing qgis layers.
      window.map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get('qgis'))
        .forEach((layer) => window.map.removeLayer(layer));
      const projectInformatie = $scope.layers.find(
        ({ name }) => name === 'Projectinformatie',
      );
      projectInformatie.maplayers = projectInformatie.maplayers.filter(
        ({ qgis }) => !qgis,
      );
      $scope.$apply();

      // Load any new qgis layers.
      if (model.project) {
        const main_url =
          config.prefix + `/qgis/project/project-${model.project.subprojectnr}`;
        fetch(main_url + '?service=WMS&request=GetCapabilities', {
          credentials: 'include',
        })
          .then((response) =>
            response.text().then((text) => {
              if (response.ok) {
                loadQgisLayersFromCapabilities(text, main_url);
              } else if (
                response.status === 500 &&
                text.includes('Project file error')
              ) {
                console.log(
                  `No QGIS layers for project ${model.project.subprojectnr}.`,
                );
              } else {
                throw new Error(
                  `QGIS GetCapabilities - HTTP ${response.status}: ${text}`,
                );
              }
            }),
          )
          .catch((error) => {
            console.error(error);
            // TODO: toast.
          });
      }

      function loadQgisLayersFromCapabilities(xml, main_url) {
        const { Capability } = wmsCapabilities.read(xml);
        const projectKaarten = projectInformatie.maplayers.find(
          ({ name }) => name === 'Projectkaarten',
        );
        let i = 0;
        for (const layer of Capability.Layer.Layer) {
          const { Title, CRS, Name } = layer;
          const { Format } = Capability.Request.GetMap;
          const step = ++i * 0.0001;
          const maplayer = Object.assign(window.angular.copy(projectKaarten), {
            qgis: true,
            id: projectKaarten.id + step,
            name: Title,
            projection_code:
              CRS.find(
                (crs) => crs === window.map.getView().getProjection().getCode(),
              ) ||
              CRS.find((crs) => crs === 'EPSG:4326') ||
              CRS.find((crs) => crs === 'EPSG:3857') ||
              CRS.shift(),
            main_url,
            main_layers: Name,
            main_querylayers: Name,
            main_format:
              Format.find((format) => format === 'image/png; mode=8bit') ||
              Format.find((format) => format === 'image/png8') ||
              Format.find((format) => format === 'image/png') ||
              Format.find((format) => format === 'image/jpeg') ||
              Format.find((format) => format === 'image/jpg') ||
              Format.shift(),
            layer_type: 'WMS',
            z_index: projectKaarten.z_index - step,
          });
          addMapLayer(maplayer).then((layer) => {
            // Mark as QGIS layer.
            layer.set('qgis', true);
            // QGIS layers take the Meetpunten maxResolution.
            layer.setMaxResolution(
              mapService.getLayer('Meetpunten').getMaxResolution(),
            );
            // Add the new layer to the $scope.
            projectInformatie.maplayers.push(maplayer);
          });
        }
        $scope.$apply();
      }
    }

    function addMapLayer(m) {
      m.isSelectable = !!m.main_querylayers;

      const mapLayer = mapService.addMapLayer(
        m.id,
        m.name,
        m.projection_code,
        m.main_url,
        m.main_layers,
        m.main_querylayers,
        m.main_format,
        m.main_unique_id,
        m.main_cql_filter,
        m.main_min_level,
        m.main_max_level,
        m.main_max_zoom,
        m.secondary_url,
        m.secondary_max_level,
        m.secondary_max_zoom,
        m.visible_on_start,
        m.layer_type,
        m.layer_attributions,
        m.layer_opacity,
        m.property_page_url,
        m.layergroup,
        m.z_index,
        m.parent_layer_id,
        m.main_layerparam,
        m.secondary_layerparam,
        m.sld_url,
        m.useproxy,
        m.field_date,
        m.field_geometry,
        m.stylefield,
      );

      if (m.parent_layer_id && m.parent_layer_id == m.id) {
        m.hasChildren = true;
        m.showChildren = false;
      }

      if (m.parent_layer_id && m.parent_layer_id !== m.id) {
        //    //layer that is part of the group that is
        //    m.parent_layer_id //do not use to build the
        //    maplayermenu //console.log("removing from layerlist:"
        //    + m.name + " belongs to " + m.parent_layer_id); delete
        //    cat.maplayers[l];
        m.isChild = true;
        m.showInList = false;
      } else {
        m.showInList = true;
      }

      if (!m.visible_on_start) {
        m.layer_opacity = 0.0;
      }

      return mapLayer;
    }

    $rootScope.$on('map-init', () => {
      userAccountService.getMapLayersforUser().then(function (data) {
        // In each maplayer of each category, map the array with role names in
        // visible_on_start to a boolean, stating whether the current user has
        // one of these roles.
        for (const category of data) {
          category.maplayers = category.maplayers.map((maplayer) => {
            const intersection = maplayer.visible_on_start.filter((role) =>
              window.ROLES.includes(role),
            );
            maplayer.visible_on_start = !!intersection.length;
            return maplayer;
          });
        }

        myLayerData = data; //.$values
        for (var i in myLayerData) {
          if (myLayerData[i] !== undefined) {
            //categories
            var cat = myLayerData[i];
            cat.showMaplayers = true;
            cat.index = i;
            //console.log("processing category:" + cat.name);
            if (cat.maplayers) {
              //loop over array of maplayers and add to 2D
              for (var l in cat.maplayers) {
                if (cat.maplayers[l] !== undefined) {
                  addMapLayer(cat.maplayers[l]);
                }
              }
              ////delete only turned it into undefined, now really remove from
              ///array, but go over it backwards to keep correct index
              //for (l = cat.maplayers.length - 1; l >= 0; l--) { if
              //    (cat.maplayers[l] === undefined) { cat.maplayers.splice(l,
              //    1);
              //    }
              //}
            }
          }
        }
        $scope.layers = myLayerData;
        qgisLayers();
        $scope.showLayers = undefined;
        if (mapService.checkMap()) {
          //map.renderSync(); map.render();
          window.map.updateSize();
        }
        initKLIC();
        $rootScope.$emit('map-layers-loaded');
      });
    });

    $scope.TurnLayerSwitchesOff = function (name2check, layergroup2check) {
      // turn off by setting the binded value to false used for working with
      // layergroups, only one may be on at a time, others need to be switched
      // off console.log("checken group:" + layergroup2check + " name that is
      // switched on: "+ name2check);
      for (var i in myLayerData) {
        //categories
        if (myLayerData[i] !== undefined) {
          var cat = myLayerData[i];
          //console.log("hierzo:" + cat.name);
          if (cat.maplayers) {
            //loop over array of maplayers and find the one with name
            for (var l in cat.maplayers) {
              if (cat.maplayers[l].layergroup === layergroup2check) {
                //console.log("same group: " + cat.maplayers[l].name);
                if (cat.maplayers[l].name !== name2check) {
                  //console.log("turn off " + cat.maplayers[l].name);
                  cat.maplayers[l].visible_on_start = false;
                  cat.maplayers[l].layer_opacity = 0.0;
                }
              }
            }
          }
        }
      }
    };

    $scope.openMenu = function ($mdMenu, ev) {
      $mdMenu.open(ev);
    };

    function setMaplayerOpacity(maplayer, opacity) {
      opacity = opacity > 1 ? 1 : opacity;
      opacity = opacity < 0 ? 0 : opacity;
      if (mapService.changeLayerTransparency(maplayer.name, opacity)) {
        maplayer.layer_opacity = opacity.toFixed(2) * 1;
      }
    }

    function stepMaplayerOpacity(maplayer, direction) {
      const step = 0.05;
      const add = direction > 0 ? step : -step;
      const opacity = maplayer.layer_opacity + add;
      setMaplayerOpacity(maplayer, opacity);
    }

    $scope.lessMaplayerOpacity = (maplayer) => {
      if (maplayer.layer_opacity > 0) stepMaplayerOpacity(maplayer, -1);
    };

    $scope.moreMaplayerOpacity = (maplayer) => {
      if (maplayer.layer_opacity < 1) stepMaplayerOpacity(maplayer, 1);
    };

    $scope.toggleLayerOpacity = function (maplayer) {
      const opacity = maplayer.layer_opacity > 0 ? 0 : 1;
      setMaplayerOpacity(maplayer, opacity);
    };

    $scope.detail = (maplayer, deltaPct) => {
      const layer = mapService.getLayer(maplayer.name);
      if (!layer) {
        return;
      }
      const source = layer.getSource();
      const detail = source.detail;
      if (typeof detail === 'function') {
        return detail(deltaPct);
      }
    };

    $scope.showSubLayerSelectie = function (m) {
      m.showChildren = !m.showChildren;
      for (var i in myLayerData) {
        //categories
        if (
          myLayerData[i] !== undefined &&
          myLayerData[i].id == m.map_category_id
        ) {
          var cat = myLayerData[i];
          //console.log("hierzo:" + cat.name);
          if (cat.maplayers) {
            //loop over array of maplayers and find the one with name
            for (var l in cat.maplayers) {
              if (cat.maplayers[l].parent_layer_id === m.id) {
                if (cat.maplayers[l].id !== m.id) {
                  //isChild;
                  cat.maplayers[l].showInList = m.showChildren;
                }
              }
            }
          }
        }
      }
    };

    const model = modelService.getModel();

    // Must be "assignable" to be used in md-checkbox ng-model
    $scope.labels = {};
    for (const key of model.labels.keys()) {
      $scope.labels[key] = true;
    }

    $scope.toggleLabel = (key) => modelService.update('model-labels', key);

    $rootScope.$on('model-modus', setProjectLabel);
    function setProjectLabel(_, model) {
      if (model.modus().startsWith('P')) {
        if (model.labels.has('PROJECTNR')) {
          modelService.update('model-labels', 'PROJECTNR');
          $scope.labels['PROJECTNR'] = false;
        }
      } else if (!model.labels.has('PROJECTNR')) {
        modelService.update('model-labels', 'PROJECTNR');
        $scope.labels['PROJECTNR'] = true;
      }
    }

    function setBronnen(_, model) {
      // Must be "assignable" to be used in md-checkbox ng-model
      $scope.bronnen = {};
      const bronnen = model.project ? model.project.bronnen : model.bronnen;
      for (const bron of bronnen.keys()) {
        $scope.bronnen[bron] = true;
      }
    }
    $rootScope.$on('model-project', setBronnen);
    setBronnen(null, model);
    $scope.toggleBron = (key) => {
      function anyBronnen() {
        return !!Object.values($scope.bronnen).filter((value) => value).length;
      }
      if (modelService.getModel().project || anyBronnen()) {
        modelService.update('model-bronnen', key);
      } else {
        // Ongedaan maken laatste bron uitzetten in K-modus
        $scope.bronnen[key] = true;
      }
    };

    $scope.KLIC = {
      Details: false,
    };

    function initKLIC() {
      const layer = mapService.getLayer('KLIC');

      function setParam(key, value) {
        if (key === 'vigerend') {
          value = !value;
          value = value || 'maybe';
        }
        mapService.setLayerParam(layer, key, value);
      }
      $scope.setKLIC = (key) => {
        setParam(key, $scope.KLIC[key]);
      };

      [
        'EigenTopografie',
        'AanduidingEisVoorzorgsmaatregel',
        'Annotatie',
        'Maatvoering',
        'LeidingElementen',
        'vigerend',
        'orientatieverzoeken',
        'grijs',
      ].forEach((key) => {
        const val =
          mapService.getLayerParam(layer, key) ||
          (key === 'vigerend' ? false : true);
        $scope.KLIC[key] = key === 'vigerend' ? !val : val;
        $scope.setKLIC(key);
      });

      const access_token = $rootScope.geowepSettings['klicav.access_token'];
      setParam('access_token', access_token);

      const matches = layer.getSource().urls[0].match(/(.*)\/klicav\//);
      $rootScope.geowepSettings['klicav.host'] = matches[1];
    }

    function categoryHeight(numLayers) {
      // FIXME: 2.02 should really be 2, but then it doesn't always fit -> ?
      return numLayers * 2.02 + 0.5 + 'em';
    }

    function setCategoryHeights(initial) {
      setTimeout(() =>
        document.querySelectorAll('#maplayers').forEach((category, index) => {
          const maplayers = category.querySelectorAll(
            '.primary-maplayer, [aria-hidden="false"]>.secondary-maplayer',
          );
          category.style.flexBasis = categoryHeight(maplayers.length);

          if (initial) {
            if (index === 0) {
              category.parentElement.style.width =
                category.parentElement.offsetWidth + 'px';
            } else {
              category.style.minHeight = categoryHeight(1);
              if (index === 1) {
                category.style.flexShrink = 200;
              } else {
                category.style.flexShrink = 100;
              }
            }
          }
        }),
      );
    }

    $scope.toggleShowLayers = () => {
      if ($scope.showLayers === undefined) {
        // Once, on first show
        setCategoryHeights(true);
      }
      $scope.showLayers = !$scope.showLayers;
    };

    $scope.details = {};
    $scope.toggleDetails = (name) => {
      $scope.details[name] = !$scope.details[name];
      setCategoryHeights();
    };
  })();
}
