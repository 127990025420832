﻿const Slick = window.Slick;
const $ = window.$;

window.app.factory('gridWerkzaamheden', [
  '$rootScope',
  'gridFactory',
  gridWerkzaamheden,
]);

function gridWerkzaamheden($rootScope, gridFactory) {
  var groupings;
  var columns;
  var views;
  var gridfactory;

  initArrays();

  var gridWerkzaamheden = {
    createGrid: createGrid,
    destroyGrid: destroyGrid,
    setGrouping: setGrouping,
    getGroupings: getGroupings,
    getViews: getViews,
    setView: setView,
  };
  return gridWerkzaamheden;

  function initArrays() {
    groupings = [
      { id: 'geen', label: 'Niets' },
      { id: 'onderzoekstype', label: 'Type onderzoek' },
      { id: 'onderzoeksstatus', label: 'Status onderzoek' },
      { id: 'xystatus', label: 'Status XY' },
    ];

    views = [
      {
        id: 'Werkzaamheden',
        label: 'Werkzaamheden',
        columns: ['projectnr', 'sub', 'naam', 'onderzoekstype'],
      },
      {
        id: 'Insrumentaria',
        label: 'Instrumentaria',
        columns: ['projectnr', 'sub', 'naam', 'onderzoekstype'],
      },
    ];
  }

  function initColumns() {
    // mogelijkewerkzaamheden = $rootScope.refdata.mogelijkewerkzaamheden;
    const onderzoekstypeList = $rootScope.refdata.onderzoekstype;
    // const partities = $rootScope.refdata.partities;

    var baseColumns = [
      {
        id: 'projectnr',
        name: 'Projectnr',
        toolTip: '',
        field: 'projectnr',
        minWidth: 100,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'sub',
        name: 'Sub',
        toolTip: '',
        field: 'sub',
        minWidth: 50,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'naam',
        name: 'Naam onderzoek',
        toolTip: 'Naam onderzoek',
        field: 'naam',
        minWidth: 150,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'onderzoekstype',
        name: 'Type onderzoek',
        toolTip: '',
        field: 'onderzoekstype',
        minWidth: 150,
        formatter: Slick.Formatters.Onderzoekstype,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Onderzoekstype',
        dataSource: onderzoekstypeList,
        multiEdit: true,
      },
    ];

    var refColumns = [
      { id: 'primair', name: 'primair', toolTip: '', field: 'primair' },
      {
        id: 'primaironderzoek',
        name: 'primair onderzoek',
        toolTip: '',
        field: 'primair_onderzoek',
      },
      {
        id: 'primaironderzoekstype',
        name: 'primair onderzoekstype',
        toolTip: '',
        field: 'primair_onderzoekstype',
      },
    ];

    //var werkzaamheidstypes = [];
    //for (var i in mogelijkewerkzaamheden) {
    //    werkzaamheidstypes.push({
    //        id: mogelijkewerkzaamheden[i].Id,
    //        name: mogelijkewerkzaamheden[i].Name,
    //        field: mogelijkewerkzaamheden[i].Field,
    //        minWidth: mogelijkewerkzaamheden[i].MinWidth,
    //        headerCssClass: mogelijkewerkzaamheden[i].CssClass,
    //        editor: getEditor(mogelijkewerkzaamheden[i].Editor),
    //        formatter: getFormatter(mogelijkewerkzaamheden[i].Formatter),
    //        validator: requiredFieldValidator,
    //        sortable: mogelijkewerkzaamheden[i].Sortable,
    //        filterable: false,
    //        assignable: mogelijkewerkzaamheden[i].Assignable,
    //        assigned: assigned,
    //        maxAssign: maxAssign,
    //        defaultValue: mogelijkewerkzaamheden[i].DefaultValue
    //    });
    //}

    columns = baseColumns.concat(refColumns);
  }

  function createGrid(element) {
    this.element = element;

    const options = {
      editable: false,
      autoEdit: false,
      enableAddRow: false,
      enableCellNavigation: true,
      asyncEditorLoading: true,
      forceFitColumns: false,
      topPanelHeight: 25,
      autoHeight: true,
      enableColumnReorder: true,
      showHeaderRow: true,
      defaultColumnWidth: 75,
    };

    initColumns();
    gridfactory = Object.create(gridFactory);
    gridfactory.createGrid(element, columns, options).then(() => {
      $('#gridWerkzaamheden').resizable();
      //gridFactory.setSelectionMethod("Row");
      setView(0);
      gridfactory.getData();
    });

    //$q.all([
    //    getWerkzaamheidstypes(),
    //    getMogelijkeWerkzaamheden(),
    //    getProjectVoorbereiding()
    //]).then(function (data) {
    //    var data1 = data[0];
    //    var data2 = data[1];
    //    var data3 = data[2];
    //    //for (var i in data1) {
    //    //    var assigned = 0;
    //    //    for (var j in data3) {
    //    //        if (data3[j].Id == data1[i].Id) {
    //    //            var maxAssign = data3[j].Aantal;
    //    //        }
    //    //    }
    //    //    werkzaamheidstypes.push({
    //    //        id: data1[i].Id,
    //    //        name: data1[i].Name,
    //    //        field: data1[i].Field,
    //    //        minWidth: data1[i].MinWidth,
    //    //        headerCssClass: data1[i].CssClass,
    //    //        editor: getEditor(data1[i].Editor),
    //    //        formatter: getFormatter(data1[i].Formatter),
    //    //        validator: requiredFieldValidator,
    //    //        sortable: data1[i].Sortable,
    //    //        filterable: false,
    //    //        assignable: data1[i].Assignable,
    //    //        assigned: assigned,
    //    //        maxAssign: maxAssign,
    //    //        defaultValue: data1[i].DefaultValue
    //    //    });
    //    //}
    //    for (var i in data3) {
    //        var assigned = 0;
    //        //for (var j in data3) {
    //        //    if (data3[j].Id == data1[i].Id) {
    //        //        var maxAssign = data3[j].Aantal;
    //        //    }
    //        //}
    //        werkzaamheidstypes.push({
    //            id: data3[i].Id,
    //            name: data3[i].Id,
    //            toolTip: data3[i].Name,
    //            field: data3[i].Field,
    //            //minWidth: data3[i].MinWidth,
    //            headerCssClass: data3[i].CssClass,
    //            editor: getEditor(data3[i].Editor),
    //            formatter: getFormatter(data3[i].Formatter),
    //            validator: requiredFieldValidator,
    //            sortable: data3[i].Sortable,
    //            filterable: false,
    //            assignable: data3[i].Assignable,
    //            assigned: assigned,
    //            maxAssign: data3[i].Aantal,
    //            defaultValue: data3[i].DefaultValue
    //        });
    //    }
    //    columns = baseColumns.concat(werkzaamheidstypes);
    //    for (var i in data2) {
    //        mogelijkeWerkzaamheden.push({
    //            onderzoekstypeId: data2[i].OnderzoekstypeId,
    //            werkzaamheidstypeId: data2[i].WerkzaamheidstypeId,
    //            constraint: data2[i].Constraint
    //        });
    //    }
    //    for (var i in data3) {
    //        projectVoorbereiding.push({
    //            id: data3[i].Id,
    //            name: data3[i].Id,
    //            toolTip: data3[i].Name,
    //            field: data3[i].Field,
    //            //minWidth: data3[i].MinWidth,
    //            headerCssClass: data3[i].CssClass,
    //            editor: getEditor(data3[i].Editor),
    //            formatter: getFormatter(data3[i].Formatter),
    //            validator: requiredFieldValidator,
    //            sortable: data3[i].Sortable,
    //            filterable: data3[i].Filterable,
    //            aantal: data3[i].Aantal,
    //            defaultValue: data3[i].DefaultValue
    //        });
    //    }
    //    gridfactory = Object.create(gridFactory);
    //    gridfactory.createGrid(element, columns, options);
    //    $("#gridWerkzaamheden").resizable();
    //    gridFactory.setSelectionMethod("Row");
    //    getData();
    //}, function (error) {
    //    console.log(error);
    //});
  }

  function destroyGrid() {
    gridfactory.destroyGrid();
    // werkzaamheidstypes = [];
    // mogelijkeWerkzaamheden = [];
    // projectVoorbereiding = [];
  }

  function requiredFieldValidator(value) {
    if (value == null || value == undefined || !value.length) {
      return { valid: false, msg: 'This is a required field' };
    } else {
      return { valid: true, msg: null };
    }
  }

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping) {
    gridfactory.setGrouping(grouping);
  }

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView('#gridWerkzaamhedenCtrl', columns, views, viewIndex);
  }

  //function SelectEditor(args) {
  //    var $input;
  //    var defaultValue;
  //    var scope = this;
  //    var calendarOpen = false;
  //    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];
  //    this.init = function () {
  //        $input = $('<select></select>');
  //        PopulateSelect($input[0], args.column.dataSource, args.item, args.column.field, false);
  //        $input.appendTo(args.container);
  //        $input.focus().select();
  //        $input.select2({
  //            placeholder: '-',
  //            allowClear: true,
  //            width: '100%'
  //        });
  //    };
  //    this.destroy = function () {
  //        $input.select2('destroy');
  //        $input.remove();
  //    };
  //    this.show = function () {
  //    };
  //    this.hide = function () {
  //        $input.select2('results_hide');
  //    };
  //    this.position = function (position) {
  //    };
  //    this.focus = function () {
  //        $input.select2('input_focus');
  //    };
  //    this.loadValue = function (item) {
  //        defaultValue = item[args.column.field];
  //        $input.val(defaultValue);
  //        $input[0].defaultValue = defaultValue;
  //        $input.trigger("change.select2");
  //    };
  //    this.serializeValue = function () {
  //        var test = $input.val();
  //        return test; $input.val();
  //    };
  //    this.applyValue = function (item, state) {
  //        item[args.column.field] = state;
  //    };
  //    this.isValueChanged = function () {
  //        return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
  //    };
  //    this.validate = function () {
  //        return {
  //            valid: true,
  //            msg: null
  //        };
  //    };
  //    this.init();
  //}

  /*
        
            function getWerkzaamheidstypes() {
                var deferred = $q.defer();
                userAccountService.getWerkzaamheidstypes()
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (error, status) {
                        deferred.reject(response.status);
                    });
                return deferred.promise;
            }
        
            function getMogelijkeWerkzaamheden() {
                var deferred = $q.defer();
                userAccountService.getMogelijkeWerkzaamheden()
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (error, status) {
                        deferred.reject(response.status);
                    });
                return deferred.promise;
            }
        
            function getProjectVoorbereiding() {
                var deferred = $q.defer();
                userAccountService.getProjectVoorbereiding()
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (error, status) {
                        deferred.reject(response.status);
                    });
                return deferred.promise;
            }
        
        
            function getData() {
            }
        
            function setEditEvent() {
                grid = gridfactory.getGrid();
                grid.onBeforeEditCell.subscribe(function (e, args) {
                    var constraint = getConstraint(dataView.getItem(args.row).onderzoekstype, args.column.id);
                    if (constraint == null) {
                        alert("Deze optie kan niet gekozen worden !");
                        return false;
                    } else if (constraint == "V") {
                        alert("Deze optie is verplicht !");
                        return false;
                    } else {
                        return true;
                    }
                });
            }
        
            function getEditor(type) {
                switch (type) {
                    case "text":
                        return Slick.Editors.Text;
                        break;
                    case "integer":
                        return Slick.Editors.Integer;
                        break;
                    case "float":
                        return Slick.Editors.Float;
                        break;
                    case "date":
                        return Slick.Editors.Date;
                        break;
                    case "bool":
                        return Slick.Editors.YesNoSelect;
                        break;
                    case "checkbox":
                        //return Slick.Editors.Checkbox;
                        return WerkzaamhedenEditor;
                        break;
                    case "percentage":
                        return Slick.Editors.PercentComplete;
                        break;
                    case "longtext":
                        return Slick.Editors.LongText;
                        break;
                    default:
                        return Slick.Editors.Text;
                        break;
                }
            }
        
            function getFormatter(type) {
                switch (type) {
                    case "text":
                        return null;
                        break;
                    case "integer":
                        return null;
                        break;
                    case "float":
                        return null;
                        break;
                    case "date":
                        return null;
                        break;
                    case "bool":
                        return Slick.Formatters.YesNo;
                        break;
                    case "checkbox":
                        return Slick.Formatters.Checkbox;
                        break;
                    case "checkmark":
                        return WerkzaamhedenFormatter;
        //                return Slick.Formatters.Checkmark;
                        break;
                    case "percentage":
                        return Slick.Formatters.PercentComplete;
                        break;
                    case "longtext":
                        return null;
                        break;
                    default:
                        return null;
                        break;
                }
            }
        
            function getOnderzoekstype(row) {
                return dataView.getItem(args.row).onderzoekstype;
            }
        
            function getWerkzaamheidstype(column) {
                for (var i in mogelijkeWerkzaamheden) {
                    if (mogelijkeWerkzaamheden[i].id == column.id) {
                        return werkzaamheidstypes[args.column.id].id;
                    }
                }
                return null;
            }
        
            function isWerkzaamheid(col) {
                var result = false;
                for (var i in werkzaamheidstypes) {
                    if (werkzaamheidstypes[i].id == col.id) {
                        result = true;
                    }
                }
                return result;
            }
        
            function getConstraint(onderzoekstypeId, werkzaamheidstypeId) {
                for (var i in mogelijkeWerkzaamheden) {
                    if (mogelijkeWerkzaamheden[i].onderzoekstypeId == onderzoekstypeId) {
                        if (mogelijkeWerkzaamheden[i].werkzaamheidstypeId == werkzaamheidstypeId) {
                            return mogelijkeWerkzaamheden[i].constraint;
                        }
                    }
                }
                return null;
            }
        
            function isMeetpuntLayer(layer) {
                return layer.get("title") == "Meetpunten";
            }
        
            function WerkzaamhedenFormatter(row, cell, value, columnDef, dataContext) {
                var constraint = getConstraint(dataContext.onderzoekstype, columnDef.id);
                switch (constraint) {
                    case 'V':
                        //return "<img src='ic_check.svg'>";
                        return "<img src='tt_checkbox.svg'>";
                        break;
                    case 'S':
                    case 'O':
                        //return value ? "<img src='ic_check.svg'>" : "<img src='ic_close.svg'>";
                        return value ? "<img src='ic_checkbox.svg'>" : "<img src='ic_checkbox-blank.svg'>";
                        break;
                    case 'null':
                    default:
                        //return "<img src='ic_remove.svg'>";
                        return "";
                        break;
                }
                return "";
            }
        
            function WerkzaamhedenEditor(args) {
                var $select;
                var oldValue;
                var constraint = getConstraint(args.item.onderzoekstype, args.column.id);
                var scope = this;
        
                this.init = function () {
                    switch (constraint) {
                        case 'V':
                            $select = $("<span>Verplicht</span>");
                            break;
                        case 'S':
                            $select = $("<span></span>");
                            break;
                        case 'O':
                            $select = $("<span></span>");
                            break;
                        case 'null':
                        default:
                            $select = $("<span>Verboden</span>");
                            break;
                    }
                    $select.appendTo(args.container);
                    $select.focus();
                };
        
                this.destroy = function () {
                    $select.remove();
                };
        
                this.focus = function () {
                    $select.focus();
                };
        
                this.loadValue = function (item) {
                    oldValue = item[args.column.field];
                    if (constraint == 'S' || constraint == 'O') {
                        oldValue = item[args.column.field];
                        $select = $("<span>" + !oldValue + "</span>");
                    }
                    $select.appendTo(args.container);
                    $select.focus();
                    //if (defaultValue) {
                    //    $select.prop('checked', true);
                    //} else {
                    //    $select.prop('checked', false);
                    //}
                };
        
                this.preClick = function () {
                    //$select.prop('checked', !$select.prop('checked'));
                };
        
                this.serializeValue = function () {
                    return $select.prop('checked');
                };
        
                this.applyValue = function (item, state) {
                    item[args.column.field] = !oldValue;
                };
        
                this.isValueChanged = function () {
                    var test = (this.serializeValue() !== oldValue);
                    return (this.serializeValue() !== oldValue);
                };
        
                this.validate = function () {
                    return {
                        valid: true,
                        msg: null
                    };
                };
        
                this.init();
            }
        
            */
}
