﻿import config from '../config';

window.app.controller('searchSubprojectCtrl', [
  '$scope',
  '$rootScope',
  'modelService',
  'userAccountService',
  SearchSubprojectCtrl,
]);

function SearchSubprojectCtrl(
  $scope,
  $rootScope,
  modelService,
  userAccountService,
) {
  var selection = null;

  // ng-model="Search"
  $scope.Search = '';
  $scope.clearDisabled = true;
  $scope.loadDisabled = true;
  $scope.selectDisabled = true;

  $rootScope.$on('model-project', (_, model) => {
    if (model.project) {
      selection = {
        project: model.project,
        caption: model.project.caption,
      };
      $scope.Search = selection.caption;
      $scope.clearDisabled = false;
      $scope.loadDisabled = false;
    } else {
      selection = null;
      $scope.Search = '';
      $scope.clearDisabled = true;
      $scope.loadDisabled = true;
    }
    $scope.$apply();
  });

  $scope.clear = () => {
    // live-search-select="select"
    const clear = true;
    $scope.select(clear);
    modelService.update('model-project');
  };

  $scope.load = (opt_newItem) => {
    var project = null;
    if (opt_newItem) {
      project = opt_newItem.id;
    } else {
      project = selection.project ? selection.project.id : null;
    }
    modelService.update('model-project', project);
  };

  $scope.SearchCallback = ({ selected, query, feed }) => {
    $scope.selectDisabled = true;
    if (selected) {
      $scope.load(selected);
    } else if (query && feed) {
      if (/^\d+[a-z]+/i.test(query)) {
        // starting with 1 or more digits
        // followed by 1 or more letters (case insensitive)
        // possibly followed by anything
        // e.g. 22G045885
        feedPromise(searchKlicmeldnummer);
      } else {
        feedPromise(searchSubprojectnr);
      }
    }

    async function feedPromise(promiseFunction) {
      const data = await promiseFunction(query);
      feed(query, data);
      if (data.length) {
        $scope.selectDisabled = false;
        $scope.$apply();
      }
    }
  };

  function searchSubprojectnr(query) {
    return fetch(config.serviceURL + '/zoek?query=' + query, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) =>
        (data || []).map((item) => ({
          id: item.id,
          caption: item.caption,
          toonString: item.caption,
        })),
      );
  }

  function searchKlicmeldnummer(query) {
    const url = `rpc/tags`,
      body = { klicmeldnummer_ilike: `${query}%` };
    return new Promise((resolve) => {
      userAccountService.klicav.api.post(url, { body }, async (err, json) => {
        if (err) {
          resolve([]);
          return console.error(err);
        } else if (json.length === 0) {
          return resolve([]);
        }
        const tags = Array.from(new Set(json.map((o) => o.tag)));
        let items = [];
        const subprojectnrs = tags.splice(0, 100);
        const response = await fetch(config.serviceURL + '/zoek', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ subprojectnrs }),
        });
        const subprojects = await response.json();
        items = items.concat(
          subprojects.map((subproject) => {
            const caption = format(subproject.caption);
            return {
              id: subproject.id,
              caption,
              toonString: caption,
            };
          }),
        );
        resolve(items);

        function format(tag) {
          const klicmeldnummers = Array.from(
            new Set(
              json.filter((o) => o.tag === tag).map((o) => o.klicmeldnummer),
            ),
          );
          return `${tag} ${(() => {
            let list = '';
            klicmeldnummers.forEach((klicmeldnummer, i) => {
              list += klicmeldnummer;
              if (i < klicmeldnummers.length - 1) {
                list += ', ';
              }
            });
            return `(${list})`;
          })()}`;
        }
      });
    });
  }
}
