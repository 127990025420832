﻿import Feature from 'ol/Feature';
import GeometryCollection from 'ol/geom/GeometryCollection';

import src from './klicnetwerken.html?url';

window.app.controller('gridKlicNetwerkenCtrl', [
  '$rootScope',
  '$scope',
  '$sce',
  'mapService',
  'gridKlicNetwerken',
  'gridFactory',
  gridKlicNetwerkenCtrl,
]);

function gridKlicNetwerkenCtrl(
  $rootScope,
  $scope,
  $sce,
  mapService,
  gridKlicNetwerken,
  gridFactory,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.views = gridKlicNetwerken.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridKlicNetwerken.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  $scope.disabled = true;

  function setDisabled(value) {
    $scope.disabled = value;
  }

  function init(model) {
    return !!model.grid.KLICNETWERKEN;
  }

  $rootScope.$on('model-selectie', (_, model) => {
    if (init(model)) {
      setDisabled(model.selectie.length === 0);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-grid', (_, model) => {
    if (init(model)) {
      setDisabled(true);
      mapService.setHighlight('KLIC', (ids) =>
        gridKlicNetwerken
          .getKlicNetwerken(ids)
          .map((klicNetwerk) => new Feature(klicNetwerk.hull)),
      );
      $scope.$apply();
    }
  });

  function getSelectedKlicNetwerken() {
    return gridFactory.getRowsFromRange().map((row) => row.item);
  }

  $scope.zoomToKlicNetwerken = () => {
    const klicNetwerken = getSelectedKlicNetwerken(),
      bboxes = klicNetwerken.map((item) => item.bbox),
      geometryCollection = new GeometryCollection(bboxes),
      extent = geometryCollection.getExtent();
    mapService.fitToExtent(extent, {
      layer: 'KLIC',
      maxZoom: 15,
      center: [klicNetwerken[0].x, klicNetwerken[0].y],
    });
  };

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridKlicNetwerken.setView(index);
    }
  };

  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridKlicNetwerken.setGrouping($scope.selectedGrouping);
  };

  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };

  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };

  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel();
  };

  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };
}
