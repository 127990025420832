/***
 * Contains basic SlickGrid formatters.
 *
 * NOTE:  These mere examples are a bit more
 *        robust/extensible/localizable/etc. for our use!
 *
 * @module Formatters
 * @namespace Slick
 */

import CheckboxNPng from 'slickgrid/images/CheckboxN.png';
import CheckboxYPng from 'slickgrid/images/CheckboxY.png';
import tickPng from 'slickgrid/images/tick.png';

(function ($) {
  function PercentCompleteFormatter(row, cell, value, columnDef, dataContext) {
    if (value == null || value === '') {
      return '-';
    } else if (value < 50) {
      return "<span style='color:red;font-weight:bold;'>" + value + '%</span>';
    } else {
      return "<span style='color:green'>" + value + '%</span>';
    }
  }

  function PercentCompleteBarFormatter(
    row,
    cell,
    value,
    columnDef,
    dataContext,
  ) {
    if (value == null || value === '') {
      return '';
    }

    var color;

    if (value < 30) {
      color = 'red';
    } else if (value < 70) {
      color = 'silver';
    } else {
      color = 'green';
    }

    return (
      "<span class='percent-complete-bar' style='background:" +
      color +
      ';width:' +
      value +
      "%' title='" +
      value +
      "%'></span>"
    );
  }

  function YesNoFormatter(row, cell, value, columnDef, dataContext) {
    return value ? 'Yes' : 'No';
  }

  function CheckboxFormatter(row, cell, value, columnDef, dataContext) {
    return (
      '<img class="slick-edit-preclick" src="' +
      (value ? CheckboxYPng : CheckboxNPng) +
      '">'
    );
  }

  function CheckmarkFormatter(row, cell, value, columnDef, dataContext) {
    return value ? "<img src='" + tickPng + "'>" : '';
  }

  // exports
  $.extend(true, window, {
    Slick: {
      Formatters: {
        PercentComplete: PercentCompleteFormatter,
        PercentCompleteBar: PercentCompleteBarFormatter,
        YesNo: YesNoFormatter,
        Checkmark: CheckmarkFormatter,
        Checkbox: CheckboxFormatter,
      },
    },
  });
})(jQuery);
