﻿import config from './config';
import './postgrestService';

const angular = window.angular;
const toastr = window.toastr;

window.app.factory('userAccountService', [
  '$http',
  '$q',
  '$rootScope',
  'Upload',
  '$timeout',
  'postgrestService',
  userAccountService,
]);

function userAccountService(
  $http,
  $q,
  $rootScope,
  Upload,
  $timeout,
  postgrestService,
) {
  const api = new Request(config.serviceURL);
  // const apiFormUrlEncoded = new Request(
  //   config.serviceURL,
  //   'application/x-www-form-urlencoded;charset=utf-8'
  // );
  const proxy = new Request(config.prefix);

  var service = {
    klicav: postgrestService.init(
      () => $rootScope.geowepSettings['klicav.host'],
      () => $rootScope.geowepSettings['klicav.access_token'],
    ),
    api,
    proxy,
    logoutUser,
    getMapLayersforUser,
    getgeowepsettings,

    dataViewAsKml,
    createDoc,

    getRefdata,
    getProjectById,
    getOnderzoekstypes,

    postPrimairOnderzoek,
    postInstrumentaria,

    getOnderzoekenById,
    getOnderzoekenBySubproject,
    getOnderzoekenByGeometry,
    getOnderzoekenMetadataById,
    getOnderzoekenMetadataBySubproject,
    getOnderzoekenMetadataByGeometry,
    getOnderzoekenMeetdataById,

    putOnderzoeken,
    postOnderzoek,
    delOnderzoek,
    purchaseOnderzoek,
    getOnderzoekInstumentaria,

    postPlantekening,
    getPlantekeningen,
    getProjectkaarten,
    deletePlantekeningen,

    postXYZ,
    matchOnderzoeken,

    postNotitie,
    putNotitie,
    deleteNotitie,
    deleteNotities,
    getNotities,

    getHistory,

    getPrint,
    putPrint,
  };

  service.klicav.api = postgrestService.init(
    () => $rootScope.geowepSettings['klicav.host'] + '/klicav/api/',
    () => $rootScope.geowepSettings['klicav.access_token'],
  );

  var serverBaseUrl = config.serviceURL;

  const printPrefix = '/geowep/mapfish';

  function Request(prefix, contentType) {
    const that = this;
    prefix = prefix || '';
    contentType = contentType || 'application/json';
    this.get = function (url, options) {
      return that.send(url, options);
    };
    this.put = function (url, data, options) {
      return that.send(url, options, 'PUT', data);
    };
    this.post = function (url, data, options) {
      return that.send(url, options, 'POST', data);
    };
    this.delete = function (url, options) {
      return that.send(url, options, 'DELETE');
    };
    this.send = async function (url, options, method, data) {
      url = prefix + url;
      options = options || {};
      options.credentials = 'include';
      method = method || 'GET';
      if (method) {
        options.method = method;
      }
      if (method === 'PUT' || method === 'POST') {
        options.headers = options.headers || {};
        options.headers['Content-Type'] =
          options.headers['Content-Type'] || contentType;
        if (data) {
          if (options.headers['Content-Type'].startsWith('application/json')) {
            options.body = JSON.stringify(data);
          } else if (
            options.headers['Content-Type'].startsWith(
              'application/x-www-form-urlencoded',
            )
          ) {
            options.body = formUrlEncode(data);
          }
        }
      }
      const response = await fetch(url, options);
      if (!response.ok) {
        if (!options.ignoreErrors) {
          ErrorHandler(response);
        }
        throw new Error(`${response.status} ${response.statusText} ${url}`);
      } else if (
        response.headers.get('Content-Type') &&
        response.headers.get('Content-Type').startsWith('application/json')
      ) {
        return response.json();
      } else {
        return response;
      }
    };
  }

  async function ErrorHandler(response) {
    if (response.status !== 200) {
      if (response.status === 400) {
        //Bad Request
        let message = 'Deze waarde is hier niet geldig.';
        if (response.error_description) {
          message = response.error_description;
        } else if (response.data) {
          message = response.data;
        } else if (response.text) {
          message = await response.text();
        }
        toastr.warning(message);
      } else if (response.status === 401) {
        //Unauthorized
        // GEOLOKET_LOGIN();
      } else if (response.status === 403) {
        //Forbidden
        // user does not have sufficient rights
        toastr.error(
          'U hebt onvoldoende rechten om deze actie uit te voeren (403)',
        );
      } else {
        // its an exception of some sort.notify the client that something went wrong
        //console.error(response);
        //if (response.statusText)
        //{ console.error(JSON.stringify(response.statusText)); }
        if (response.error_description) {
          //console.error(JSON.stringify(response.error_description));
          toastr.error(response.error_description);
        } else {
          toastr.error('Er is een probleem opgetreden!');
        }
      }
    }
  }

  function formUrlEncode(data) {
    function param(obj) {
      var query = '';
      var name, value, fullSubName, subName, subValue, innerObj, i;
      for (name in obj) {
        if (obj[name] instanceof Array) {
          value = obj[name];
          for (i = 0; i < value.length; ++i) {
            subValue = value[i];
            fullSubName = name + '[' + i + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += param(innerObj) + '&';
          }
        } else if (obj[name] instanceof Object) {
          value = obj[name];
          for (subName in value) {
            if (subName.length > 0) {
              subValue = value[subName];
              fullSubName = name + '[' + subName + ']';
              innerObj = {};
              innerObj[fullSubName] = subValue;
              query += param(innerObj) + '&';
            }
          }
        } else if (obj[name] !== undefined && obj[name] !== null) {
          query +=
            encodeURIComponent(name) +
            '=' +
            encodeURIComponent(obj[name]) +
            '&';
        }
      }
      return query.length ? query.substr(0, query.length - 1) : query;
    }
    return angular.isObject(data) && String(data) !== '[object File]'
      ? param(data)
      : data;
  }

  function logoutUser() {
    // Make this request without the /geowep-prefix, to get the Set-Cookie path
    // right.
    location.replace(config.prefix + '/api/logout');
  }

  function getgeowepsettings(orderoption) {
    let url = '/settings';
    if (orderoption) {
      url += '?FieldName=' + orderoption;
    }
    return api.get(url);
  }

  function getMapLayersforUser() {
    const userId = window.localStorage.getItem('geowep.userId') || '';
    return api.get('/GetMapLayersforUser?userId=' + userId);
  }

  function createDoc(items) {
    const url = `${printPrefix}/print/geowep/report.pdf`;
    return proxy
      .post(url, items)
      .then((data) => {
        getDocWhenReady(printPrefix + data.statusURL);
      })
      .catch((error) => {
        $rootScope.$emit('createDocReady', false);
        throw error;
      });
  }

  function getDocWhenReady(statusUrl) {
    const TIMEOUT = 250000;
    const startTime = new Date().getTime();
    (function status() {
      if (new Date().getTime() - startTime > TIMEOUT) {
        toastr.warning('Print gestaakt na ' + TIMEOUT / 1000 + ' seconden');
        $rootScope.$emit('createDocReady', false);
      } else {
        proxy
          .get(statusUrl)
          .then((data) => {
            if (data.done) {
              downloadFromUrl(
                'print.pdf',
                config.prefix + printPrefix + data.downloadURL,
              );
              $rootScope.$emit('createDocReady', true);
            } else {
              setTimeout(status, 500);
            }
          })
          .catch(() => {
            $rootScope.$emit('createDocReady', false);
          });
      }
    })();
  }

  function downloadFromUrl(filename, downloadUrl) {
    var a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_self';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  function getProjectById(id) {
    return api.get('/project?id=' + id);
  }

  function dataViewAsKml(meetpunten, kmz) {
    return api.post('/DataViewAsKml', {
      meetpunten,
      kmz,
    });
  }

  function getOnderzoekstypes(subproject) {
    return api.get(`/project/onderzoekstypes?subproject=${subproject}`);
  }

  function postPrimairOnderzoek(subproject, onderzoekstype, coordinate) {
    var url = '/project/onderzoek?1=1';
    url += '&gebruiker=' + window.localStorage.getItem('geowep.userName');
    url += '&subproject=' + subproject;
    url += '&onderzoekstype=' + onderzoekstype;
    url += '&x=' + coordinate[0];
    url += '&y=' + coordinate[1];
    return api.post(url);
  }

  function postInstrumentaria(primair_onderzoek, onderzoekstype) {
    var url = '/project/onderzoek/onderzoek?1=1';
    url += '&gebruiker=' + window.localStorage.getItem('geowep.userName');
    url += '&primair_onderzoek=' + primair_onderzoek;
    url += '&onderzoekstype=' + onderzoekstype;
    return api.post(url);
  }

  function getOnderzoekenById(ids) {
    return _getOnderzoekenById(ids, false);
  }

  function getOnderzoekenBySubproject(subproject) {
    return _getOnderzoekenBySubproject(subproject, false);
  }

  function getOnderzoekenByGeometry(wkt) {
    return _getOnderzoekenByGeometry(false, wkt);
  }

  function getOnderzoekenMetadataById(ids) {
    return _getOnderzoekenById(ids, true);
  }

  function getOnderzoekenMetadataBySubproject(subproject) {
    return _getOnderzoekenBySubproject(subproject, true);
  }

  function getOnderzoekenMetadataByGeometry(wkt) {
    return _getOnderzoekenByGeometry(true, wkt);
  }

  function getOnderzoekenMeetdataById(ids) {
    return _getOnderzoekenById(ids, false, true);
  }

  function _getOnderzoekenById(ids, metadata, meetdata) {
    const onderzoeken = [],
      BATCH_SIZE = 20,
      TIMES = ids.length / BATCH_SIZE,
      MAX_CONCURRENT = 5;
    let requests = 0,
      responses = 0;
    return new Promise((resolve, reject) => {
      for (let i = 0; i < TIMES; i++) {
        const batch = ids.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
        const interval = setInterval(() => {
          if (requests - responses >= MAX_CONCURRENT) {
            return;
          }
          clearInterval(interval);
          requests++;
          _getOnderzoeken({ ids: batch }, metadata, meetdata)
            .then((response) => {
              response.forEach(function (onderzoek) {
                onderzoeken.push(onderzoek);
              });
              if (++responses === Math.ceil(TIMES)) {
                resolve(onderzoeken);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    });
  }

  function _getOnderzoekenBySubproject(subproject, metadata) {
    return _getOnderzoeken({ subproject }, metadata);
  }

  function _getOnderzoekenByGeometry(metadata, wkt) {
    const code = window.map.getView().getProjection().getCode();
    const srid = parseInt(code.split(':').pop());
    return _getOnderzoeken({ wkt, srid }, metadata);
  }

  const _getOnderzoekenStatus = {
    metadata: {},
    meetdata: {},
    onderzoeken: {},
    busy: (key, metadata, meetdata) => {
      return _getOnderzoekenStatus.get(key, metadata, meetdata);
    },
    start: (key, metadata, meetdata) => {
      _getOnderzoekenStatus.set(key, metadata, meetdata, true);
    },
    finish: (key, metadata, meetdata) => {
      _getOnderzoekenStatus.set(key, metadata, meetdata, false);
    },
    get: (key, metadata, meetdata) => {
      return _getOnderzoekenStatus.set(key, metadata, meetdata, undefined);
    },
    set: (givenKey, metadata, meetdata, active) => {
      let item = 'onderzoeken';
      if (metadata) {
        item = 'metadata';
      } else if (meetdata) {
        item = 'meetdata';
      }
      const key = JSON.stringify(givenKey);
      if (active === undefined) {
        return _getOnderzoekenStatus[item][key];
      } else if (active) {
        _getOnderzoekenStatus[item][key] = true;
      } else {
        delete _getOnderzoekenStatus[item][key];
      }
    },
  };

  function _getOnderzoeken(key, metadata, meetdata) {
    if (_getOnderzoekenStatus.busy(key, metadata)) {
      // Sometimes, this is called more than once for the same data. It's easier
      // to handle that here, than to prevent the extra calls.
      return Promise.reject().catch(() => {});
    }
    let resource = '/onderzoek';
    if (metadata) {
      resource += '/metadata';
    } else if (meetdata) {
      resource += '/meetdata';
    }
    let url = resource + '?1=1';
    if (key.wkt) {
      // POST instead of QUERY (a GET with a body).
      return api.post(url, key);
    }
    if (key.subproject) {
      url += '&subproject=' + key.subproject;
    } else {
      key.ids.forEach(function (id) {
        url += '&id=' + id;
      });
    }
    _getOnderzoekenStatus.start(key, metadata, meetdata);
    return api.get(url).finally(() => {
      _getOnderzoekenStatus.finish(key, metadata, meetdata);
    });
  }

  function putOnderzoeken(onderzoeken, opt_tijdstip) {
    var tijdstip = opt_tijdstip || new Date();
    var data = {
      gebruiker: window.localStorage.getItem('geowep.userName'),
      tijdstip: tijdstip.toISOString(),
      onderzoeken: onderzoeken,
    };
    return api.put('/onderzoek', data);
  }

  function postOnderzoek(onderzoek, ignoreErrors) {
    const gebruiker = window.localStorage.getItem('geowep.userName');
    return api.post('/onderzoek', { gebruiker, onderzoek }, { ignoreErrors });
  }

  function delOnderzoek(id) {
    const gebruiker = window.localStorage.getItem('geowep.userName');
    return api.delete(`/onderzoek?&id=${id}&gebruiker=${gebruiker}`);
  }

  function purchaseOnderzoek(id, opt_referentie) {
    let url = `/onderzoek/purchase?id=${id}`;
    url += opt_referentie ? '&referentie=' + opt_referentie : '';
    url = url.replace(/\+/g, '%2B'); // prevent decoding of "+" into " "
    return api.post(url);
  }

  function getOnderzoekInstumentaria(primair_onderzoek) {
    return api.get(
      `/onderzoek/instrumentaria?primair_onderzoek=${primair_onderzoek}`,
    );
  }

  function _postFile(url, file) {
    var deferred = $q.defer();
    Upload.upload({
      url: url,
      data: { file: file },
      withCredentials: true,
    }).then(
      function (response) {
        deferred.resolve(response.data);
      },
      function (response) {
        ErrorHandler(response);
        deferred.reject(response);
      },
    );
    return deferred.promise;
  }

  function postPlantekening(subproject, featureType, file) {
    var url = serverBaseUrl + '/features/file';
    url += '?featuretype=' + featureType;
    url += '&fk=' + subproject;
    url += '&bestandsnaam=' + file.name;
    url += '&gebruiker=' + window.localStorage.getItem('geowep.userName');
    return _postFile(url, file);
  }

  function getPlantekeningen(subproject) {
    return api.get(`/plantekening?subproject=${subproject}`);
  }

  function getProjectkaarten(subproject) {
    return api.get(`/projectkaart?subproject=${subproject}`);
  }

  function postXYZ(file, sheet) {
    var url = serverBaseUrl + '/xyz?type=' + file.type;
    if (sheet) {
      url += '&sheet=' + sheet;
    }
    return _postFile(url, file);
  }

  function matchOnderzoeken(subproject, namen) {
    const onderzoeken = [],
      BATCH_SIZE = 20,
      TIMES = namen.length / BATCH_SIZE,
      MAX_CONCURRENT = 5;
    let requests = 0,
      responses = 0;
    return new Promise((resolve, reject) => {
      for (var i = 0; i < TIMES; i++) {
        const batch = namen.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
        const interval = setInterval(() => {
          if (requests - responses >= MAX_CONCURRENT) {
            return;
          }
          clearInterval(interval);
          requests++;
          _matchOnderzoeken(subproject, batch)
            .then((response) => {
              response.forEach(function (onderzoek) {
                onderzoeken.push(onderzoek);
              });
              if (++responses === Math.ceil(TIMES)) {
                resolve(onderzoeken);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    });
  }

  function _matchOnderzoeken(subproject, namen) {
    let url = `/project/onderzoek/match?&sp=${subproject}`;
    namen.forEach(function (naam) {
      url += '&n=' + naam;
    });
    return api.get(url);
  }

  function getRefdata() {
    return api.get('/refdata');
  }

  function postNotitie(subproject, kml) {
    const gebruiker = window.localStorage.getItem('geowep.userName');
    return api.post(
      `/features?featuretype=NOTITIE&fk=${subproject}&gebruiker=${gebruiker}`,
      { kml },
    );
  }

  function getNotities(subproject) {
    return api.get(`/features?featuretype=NOTITIE&fk=${subproject}`);
  }

  function putNotitie(id, kml) {
    const gebruiker = window.localStorage.getItem('geowep.userName');
    return api.put(`/features?id=${id}&gebruiker=${gebruiker}`, { kml });
  }

  function deleteFeatures(ids) {
    let url = '/features?1=1';
    ids.forEach((id) => (url += `&id=${id}`));
    return api.delete(url);
  }

  function deleteNotitie(id) {
    return deleteFeatures([id]);
  }

  function deleteNotities(ids) {
    return deleteFeatures(ids);
  }

  function deletePlantekeningen(ids) {
    return deleteFeatures(ids);
  }

  function getHistory(ids) {
    const srid = window.map.getView().getProjection().getCode().split(':')[1];
    return api.post(`/getHistory?srid=${srid}`, { ids });
  }

  function getPrint(projectnr, opt_tekeningtype) {
    const tekeningtype = opt_tekeningtype || '';
    return api.get(
      `/print?projectnr=${projectnr}&tekeningtype=${tekeningtype}`,
    );
  }

  function putPrint(projectnr, tekeningtype, content, opt_bladnr) {
    const bladnr = opt_bladnr || '';
    return api.put(
      `/print?projectnr=${projectnr}&tekeningtype=${tekeningtype}&bladnr=${bladnr}`,
      content,
    );
  }

  return service;
}
