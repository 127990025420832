﻿import config from './config';

import Collection from 'ol/Collection';
import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Style from 'ol/style/Style';
import WKT from 'ol/format/WKT';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import MultiPoint from 'ol/geom/MultiPoint';

window.app.service('doorsneeService', ['mapService', doorsneeService]);

function doorsneeService(mapService) {
  const MAX_DOORSNEE_LENGTH = 5000;
  var doorsneeFeatures = new Collection();
  var doorsneeSource = new VectorSource({ wrapX: false });
  var doorsneePointSource = new VectorSource({ wrapX: false });
  var doorsneePolyline;
  var doorsneeHighlitePoint;
  var doosneefeature;
  var lastPoint;
  var drawdoorsnee;
  var snapdoorsnee;
  var snaplistening;

  const edges = new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: [255, 0, 0, 0.7],
      }),
    }),
    stroke: new Stroke({
      color: [255, 0, 0, 0.7],
      width: 4,
    }),
  });
  const vertices = edges.clone();
  vertices.setGeometry((feature) => {
    const geometry = feature.getGeometry();
    const coordinates = geometry.getCoordinates();
    const points = new MultiPoint(coordinates);
    return points;
  });
  const style = [edges, vertices];

  window.addEventListener('message', ({ data }) => {
    const { key, event } = data;
    if (key !== 'PROFILER') {
      return;
    }
    switch (event.type) {
      case 'polygons':
        mapService.refresh('Units');
        break;
      case 'viewer.mousemove':
        highlightPoint(event.element);
        break;
      case 'viewer.mouseout':
        highlightPoint(null);
        break;
    }
  });

  // Jelmer: Deze wordt aangeroepen door de Viewer class hieronder
  // met een point. Dat heeft meestal of een geom attribuut in WKT
  // vorm of x en y coordinaten.
  function highlightPoint(point) {
    if (point == lastPoint) return;
    lastPoint = point;

    if (!doorsneeHighlitePoint) {
      return;
    }
    const source = doorsneeHighlitePoint.getSource();
    source.clear();

    if (!point) {
      // Just do nothing
    } else if (point.geom) {
      const format = new WKT();
      const feature = format.readFeature(point.geom);
      source.addFeature(feature);
    } else if (point.x && point.y) {
      source.addFeature(
        new Feature({
          geometry: new Point([point.x, point.y]),
        }),
      );
    }
  }

  function createdoorsneeFeaturesOverlays() {
    // Layer on which you draw a line
    if (!doorsneePolyline) {
      const maplayers = window.map.getLayers();
      let foundLayer;
      let i;
      //console.log("createoverlay");
      doorsneePolyline = new VectorLayer({
        title: 'geowep_doorsnee_lines',
        source: doorsneeSource,
        style,
        visible: true,
        zIndex: 999999,
      });
      for (i = 0; i < maplayers.getLength(); i++) {
        if (maplayers.item(i).get('title') === 'geowep_doorsnee_lines') {
          foundLayer = maplayers.item(i);
        }
      }
      if (!foundLayer) {
        window.map.addLayer(doorsneePolyline);
      }
    }
    doorsneePolyline.setVisible(true);
    // Layer that shows the current location of the point you're hovering
    // over in the graph
    if (!doorsneeHighlitePoint) {
      const maplayers = window.map.getLayers();
      let foundLayer;
      let i;
      doorsneeHighlitePoint = new VectorLayer({
        title: 'geowep_doorsnee_points',
        source: doorsneePointSource,
        style,
        visible: true,
        zIndex: 999999,
      });
      for (i = 0; i < maplayers.getLength(); i++) {
        if (maplayers.item(i).get('title') === 'geowep_doorsnee_points') {
          foundLayer = maplayers.item(i);
        }
      }
      if (!foundLayer) {
        window.map.addLayer(doorsneeHighlitePoint);
      }
    }
    doorsneeHighlitePoint.setVisible(true);
  }

  function stop() {
    if (snapdoorsnee) {
      window.map.removeInteraction(snapdoorsnee);
    }
    if (drawdoorsnee) {
      window.map.removeInteraction(drawdoorsnee);
    }
    doorsneePolyline.setVisible(false);
    doorsneeHighlitePoint.setVisible(false);
    setTimeout(function () {
      window.drawingDoorsnee = false;
    }, 500); //double click weer actief, maar deze nog even afvangen
  }

  function EraseDoorsneeLine() {
    doorsneePolyline.getSource().clear();
    doorsneeHighlitePoint.getSource().clear();
    doosneefeature = null;
  }

  function start({ snapLayer } = {}) {
    window.drawingDoorsnee = true;
    createdoorsneeFeaturesOverlays();

    drawdoorsnee = new Draw({
      source: doorsneeSource,
      features: doorsneeFeatures,
      style,
      type: /** @type {ol.geom.GeometryType} */ 'LineString',
      geometryFunction: function (coordinates, geometry) {
        const next = new LineString(coordinates);
        if (!geometry) {
          return next;
        }
        if (next.getLength() <= MAX_DOORSNEE_LENGTH) {
          geometry.setCoordinates(coordinates);
        }
        return geometry;
      },
    });
    drawdoorsnee.on('drawstart', function (e) {
      //oude lijn en punten verwijderen
      EraseDoorsneeLine();
      doosneefeature = e.feature;
    });
    drawdoorsnee.on('drawend', function (e) {
      doosneefeature = e.feature;
      drawCharts(doosneefeature);
    });
    window.map.addInteraction(drawdoorsnee);
    if (snapLayer) {
      const source = snapLayer.getSource();
      snapdoorsnee = new Snap({
        source,
        edge: false,
      });
      const setActive = () => {
        const opacity = snapLayer.getOpacity();
        snapdoorsnee.setActive(!!opacity);
      };
      if (!snaplistening) {
        snaplistening = true;
        snapLayer.on('change:opacity', setActive);
      }
      setActive();
      window.map.addInteraction(snapdoorsnee);
    }
  }

  // Jelmer: deze functie wordt aangeroepen zodra je een lijntje getekend hebt.
  function drawCharts(lineFeature) {
    const format = new WKT();
    const lineString = format.writeFeature(lineFeature);
    const srid = window.map.getView().getProjection().getCode().split(':')[1];
    const hash = encodeURIComponent(`SRID=${srid};${lineString}`);
    const url = `${config.profilerURL}#${hash}`;
    window.open(url, 'Profiler');
  }

  return {
    stop,
    start,
  };
}
