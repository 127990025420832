﻿window.app.factory('postgrestService', [postgrestService]);

function postgrestService() {
  async function request(resource, init, callback) {
    callback = callback || (() => {});
    init = init || {};
    init.method = init.method || 'GET';
    init.headers = init.headers || {};
    if (init.body) {
      init.headers['Content-Type'] =
        init.headers['Content-Type'] || 'application/json';
      if (init.headers['Content-Type'].includes('json')) {
        init.body = JSON.stringify(init.body);
      }
    }
    init.headers['Accept'] = init.headers['Accept'] || 'application/json';
    const response = await fetch(resource, init);
    if (response.ok) {
      const contentType = response.headers.get(['Content-Type']) || '';
      if (contentType.includes('json')) {
        callback(null, await response.json());
      } else {
        callback(null, response);
      }
    } else {
      window.toastr.error(
        'Mislukt: ' + response.status + ' ' + response.statusText,
      );
      callback(response);
    }
  }

  function postgrest(url, init, callback) {
    init = init || {};
    init.headers = init.headers || {};
    init.getAccessToken = init.getAccessToken || getAccessToken;
    init.getAuthorization = init.getAuthorization || getAuthorization;
    if (init.anonymous) {
      const value = init.getAccessToken();
      if (value) {
        init.headers['access_token'] = value;
      }
    } else {
      const value = init.getAuthorization();
      if (value) {
        init.headers['Authorization'] = value;
      }
    }
    if (init.singular) {
      init.headers['Accept'] = 'application/vnd.pgrst.object+json';
    }
    if (init.representation) {
      init.headers['Prefer'] = 'return=representation';
    }
    request(url, init, callback);
  }

  postgrest._method = (method, url, init, callback) => {
    init.method = method;
    postgrest(url, init, callback);
  };

  postgrest.get = (url, init, callback) => {
    postgrest._method('GET', url, init, callback);
  };

  postgrest.put = (url, init, callback) => {
    postgrest._method('PUT', url, init, callback);
  };

  postgrest.post = (url, init, callback) => {
    postgrest._method('POST', url, init, callback);
  };

  postgrest.delete = (url, init, callback) => {
    postgrest._method('DELETE', url, init, callback);
  };

  postgrest.patch = (url, init, callback) => {
    postgrest._method('PATCH', url, init, callback);
  };

  // function jwtClaims() {
  //     return JSON.parse(atob(getAuthorization().split('.')[1]));
  // }

  const AUTHORIZATION =
    location.pathname.split('/', 2).pop() + '.Authorization';

  function getAuthorization() {
    return localStorage.getItem(AUTHORIZATION);
  }

  // function setAuthorization(value) {
  //     if (value) {
  //         localStorage.setItem(AUTHORIZATION, 'Bearer ' + value);
  //     }
  // }

  // function unsetAuthorization() {
  //     localStorage.removeItem(AUTHORIZATION);
  // }

  // const ACCESS_TOKEN = location.pathname.split('/', 2).pop() + '.access_token';

  function getAccessToken() {
    // return localStorage.getItem(ACCESS_TOKEN);
  }

  // function setAccessToken(value) {
  //     if (value) {
  //         localStorage.setItem(ACCESS_TOKEN, value);
  //     }
  // }

  // function unsetAccessToken() {
  //     localStorage.removeItem(ACCESS_TOKEN);
  // }

  // const url = new URL(location);
  // const searchParams = url.searchParams;
  // if (searchParams.has('access_token')) {
  //     const access_token = searchParams.get('access_token');
  //     if (access_token) {
  //         setAccessToken(access_token);
  //     } else {
  //         unsetAccessToken(access_token);
  //     }
  //     searchParams.delete('access_token');
  //     location.replace(url.href);
  // }

  // const KLANT_ROLE = location.pathname.split('/', 2).pop() + '.klant';

  // function getKlant() {
  //     return localStorage.getItem(KLANT_ROLE);
  // }

  // function setKlant(value) {
  //     if (value) {
  //         localStorage.setItem(KLANT_ROLE, value);
  //     }
  // }

  // function unsetKlant() {
  //     localStorage.removeItem(KLANT_ROLE);
  // }

  return {
    init: function initPostgrestService(
      getUrl,
      getAccessToken,
      getAuthorization,
    ) {
      function makeUrl(url) {
        return getUrl() + url;
      }

      function makeInit(init) {
        if (!getAuthorization) {
          init.anonymous = true;
        } else {
          init.getAuthorization = getAuthorization;
        }
        init.getAccessToken = getAccessToken;
        return init;
      }

      function makeAction(action) {
        return (url, init, callback) => {
          if (callback) {
            init = init || {};
          } else {
            callback = init;
            init = {};
          }
          return action(makeUrl(url), makeInit(init), callback);
        };
      }
      return {
        get: makeAction(postgrest.get),
        put: makeAction(postgrest.put),
        post: makeAction(postgrest.post),
        delete: makeAction(postgrest.delete),
        patch: makeAction(postgrest.patch),
      };
    },
  };
}
