﻿const Slick = window.Slick;
const $ = window.$;

window.app.factory('gridKlicNetwerken', [
  '$rootScope',
  'gridFactory',
  'userAccountService',
  'modelService',
  gridKlicNetwerken,
]);

function gridKlicNetwerken(
  $rootScope,
  gridFactory,
  userAccountService,
  modelService,
) {
  var groupings;
  var columns;
  var views;
  var gridfactory;
  var columnObjects;

  initArrays();

  initGrid(modelService.getModel());

  function initGrid(model) {
    if (model.grid.KLICNETWERKEN) {
      initColumns();

      const options = {
        editable: true,
        autoEdit: true,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 25,
        enableColumnReorder: true,
        showHeaderRow: true,
        enableAsyncPostRender: true,
      };

      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(model.grid.KLICNETWERKEN, columns, options, 'KLICNETWERKEN')
        .then((grid) => {
          setView(0);
          requestKlicNetwerkenData(model);

          grid.onBeforeEditCell.subscribe((e, args) => {
            // Cancel the editing if not eisvoorzorgsmaatregel
            return args.item.eisvoorzorgsmaatregel;
          });

          grid.onCellChange.subscribe((e, args) => {
            const item = args.item;
            const params = {};
            params.klicmeldnummer = item.klicmeldnummer;
            params.eisvoorzorgsmaatregelbijlage =
              item.eisvoorzorgsmaatregelbijlage;
            params.vrijgegeven = !!item.vrijgegeven;
            params.opmerking = item.opmerking_vrijgave;
            params.gebruiker = localStorage.getItem('geowep.userName');

            userAccountService.klicav.api.post(
              'rpc/new_eisvoorzorgsmaatregelvrijgave',
              {
                body: params,
              },
              () => requestKlicNetwerkenData(model, true),
            );
          });
        });
    }
  }

  $rootScope.$on('model-grid', (_, model) => {
    initGrid(model);
  });

  $rootScope.$on('model-project', (_, model) => {
    if (model.grid.KLICNETWERKEN) {
      requestKlicNetwerkenData(model);
    }
  });

  var API = {
    setGrouping,
    getGroupings,
    getViews,
    setView,
    getKlicNetwerken,
  };

  function initArrays() {
    groupings = [
      { id: 'geen', label: 'Niets' },
      { id: 'klicmeldnummer', label: 'KLIC-melding' },
    ];

    $.extend(Slick.Formatters, {
      KlicavBestand: function (row, cell, value) {
        if (value) {
          return gridFactory.icon({
            title: 'Download',
            icon: 'cloud_download',
          });
        } else {
          return '';
        }
      },
    });

    function bestandPostRender(cellNode, row, { bestand }) {
      if (!bestand) {
        return;
      }
      const url = bestand;
      cellNode[0].firstElementChild.onclick = () => {
        userAccountService.klicav.get(url, (err, response) => {
          if (err) {
            return;
          }
          response.blob().then((blob) => {
            const anchor = document.createElement('a');
            anchor.download = url.split('/').pop();
            anchor.type = response.headers.get('Content-Type');
            anchor.href = URL.createObjectURL(blob);
            anchor.click();
          });
        });
      };
    }

    columnObjects = {
      thema: {
        name: 'Thema',
        width: 200,
        minWidth: 100,
        maxWidth: 300,
      },
      organisatie: {
        name: 'Netbeheerder',
        width: 350,
        minWidth: 200,
        maxWidth: 500,
      },
      bestand: {
        formatter: Slick.Formatters.KlicavBestand,
        asyncPostRender: bestandPostRender,
        name: 'EV',
        toolTip: 'Eis voorzorgsmaatregel',
        width: 30,
        minWidth: 30,
        maxWidth: 30,
      },
      vrijgegeven: {
        formatter: Slick.Formatters.Checkmark,
        editor: Slick.Editors.Checkbox,
        name: 'OK',
        toolTip: 'Eis voorzorgsmaatregel vrijgegeven',
        width: 27,
        minWidth: 27,
        maxWidth: 27,
      },
      opmerking_vrijgave: {
        editor: Slick.Editors.Text,
        name: 'Opmerking',
        toolTip: 'Opmerking vrijgave eis voorzorgsmaatregel',
        width: 350,
        minWidth: 200,
        maxWidth: 500,
      },
      vrijgegeven_door: {
        name: 'Gebruiker',
        toolTip: 'Eis voorzorgsmaatregel vrijgegeven door:',
        width: 200,
        minWidth: 100,
        maxWidth: 300,
      },
      datum_vrijgave: {
        name: 'Datum',
        toolTip: 'Eis voorzorgsmaatregel vrijgegeven op:',
        width: 200,
        minWidth: 100,
        maxWidth: 300,
      },
    };

    views = [
      {
        id: 'Standaard',
        label: 'Standaard',
        columns: Object.keys(columnObjects),
      },
    ];
  }

  function initColumns() {
    columns = [];
    Object.keys(columnObjects).forEach((key) => {
      const column = columnObjects[key];
      column.id = column.id || key;
      columns.push(newColumn(column));
    });
  }

  function newColumn(column) {
    function defaultValue(key, value) {
      if (column[key] === undefined) {
        column[key] = value;
      }
    }
    defaultValue('name', column.id);
    defaultValue('field', column.id);
    defaultValue('formatter', Slick.Formatters.Text);
    defaultValue('resizable', true);
    defaultValue('sortable', true);
    defaultValue('filterable', true);
    defaultValue('filter', 'Text');
    return column;
  }

  let klicNetwerken;

  function getKlicNetwerken(ids) {
    return (klicNetwerken || []).filter(
      (item) => !ids || ids.includes(0 + item.id),
    );
  }

  function requestKlicNetwerkenData(model, refresh) {
    model = refresh ? model.__fresh() : model;
    gridfactory.getKlicNetwerken(model, (err, json) => {
      if (err) {
        return console.log(err);
      }
      json.forEach((e, i) => (e.id = i));
      klicNetwerken = json;
      function getNumAanvragen() {
        const set = new Set();
        json.forEach((e) => set.add(e.klicmeldnummer));
        return set.size;
      }
      setGrouping('klicmeldnummer', { expand: getNumAanvragen() < 2 });
    });
  }

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping, options) {
    gridfactory.setGrouping(grouping, options);
  }

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView('#gridKlicNetwerkenCtrl', columns, views, viewIndex);
  }

  return API;
}
