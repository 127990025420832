﻿import src from './project.html?url';

window.app.controller('projectCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  'modelService',
  projectCtrl,
]);

function projectCtrl($scope, $rootScope, $sce, modelService) {
  $scope.src = $sce.trustAsResourceUrl(src);

  setProject();

  $rootScope.$on('model-project', (_, model) => setProject(model));

  function setProject(model) {
    model = model || modelService.getModel();
    $scope.project = model.project;
  }
}
