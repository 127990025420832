﻿import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LineString from 'ol/geom/LineString';
import Style from 'ol/style/Style';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import OSM from 'ol/source/OSM';
import Stroke from 'ol/style/Stroke';
import WMTS from 'ol/source/WMTS';
import * as olProj from 'ol/proj';

import config from '../config';

const angular = window.angular;

window.app.service('mapexportService', [
  '$rootScope',
  '$q',
  'userAccountService',
  'mapService',
  'utilService',
  'modelService',
  mapexportService,
]);

function mapexportService(
  $rootScope,
  $q,
  userAccountService,
  mapService,
  utilService,
  modelService,
) {
  var pixels2mm = 0.353;
  const items = initItems();
  var boxStyle = new Style({
    stroke: new Stroke({ color: '#ff0000', width: 3 }),
  });
  var veldtekeningBoxStyle = new Style({
    stroke: new Stroke({ color: [255, 0, 0, 0.5], width: 3 }),
  });
  var situatietekeningBoxStyle = new Style({
    stroke: new Stroke({ color: [0, 0, 255, 0.5], width: 3 }),
  });
  var mapInfo = {
    center: [220011, 575943],
    zoom: 16,
    extent: [0, 0, 0, 0],
  };
  var mapExtents = {
    zone1: [],
    zone2: [],
    zone3: [],
  };

  var mapexportService = {
    init: init,
    clear: clear,
    initBox: initBox,
    moveBox: moveBox,
    calculateAndDrawBox: calculateAndCreateBox,
    createPrintData: createPrintData,
    createXyzPrintData: createXyzPrintData,
    getItems,
    getGegevens: getGegevens,
    getXyzGegevens: getXyzGegevens,
    checkTemplate: checkTemplate,
    getMeetpunten: getMeetpunten,
    getMeetpuntenXYZ: getMeetpuntenXYZ,
    getMapInfo: getMapInfo,
    setMapInfo: setMapInfo,
    setStatusText: setStatusText,
    setMapTools: setMapTools,
    getLocaleToday: getLocaleToday,
    getShortLocaleToday: getShortLocaleToday,
    getBoxes: getBoxes,
    getPuntenInPrintArea: getPuntenInPrintArea,
    projection,
  };

  function init() {
    initBox(items);
    calculateAndCreateBox(items);
    var projectnr = getGegevens().subprojectNr || 'KAART';
    if (projectnr != 'KAART') {
      getBoxes(projectnr, 'NO');
    }
  }

  function clear() {
    removeBox();
  }

  function getMapInfo() {
    return mapInfo;
  }

  function setMapInfo(mapinfo) {
    mapInfo.zoom = mapinfo.zoom;
    mapInfo.center = mapinfo.center;
    mapInfo.extent = mapinfo.extent;
  }

  function setMapTools(pageSize, pageOrientation, scale) {
    items.selectedPageSize = pageSize;
    items.selectedPageOrientation = pageOrientation;
    items.selectedScale = scale;
  }

  function moveBox(x, y) {
    mapInfo.center = [mapInfo.center[0] + x, mapInfo.center[1] + y];
  }

  //function rotateBox() {
  //    var boxCenterX = (mapInfo.extent[] + mapInfo.extent[]) / 2
  //    var boxCenterY = (mapInfo.extent[] + mapInfo.extent[]) / 2
  //    var boxCenter = new ol.Coordinate(boxCenterX, boxCenterY);
  //}

  function initBox(items) {
    mapInfo.zoom = window.map.getView().getZoom();
    mapInfo.center = window.map.getView().getCenter();
    mapInfo.extent = calculateBox(items);
  }

  function calculateBox(items) {
    var orientation =
      items.pageOrientationList[items.selectedPageOrientation].name;
    var longSide = items.pageSizeList[items.selectedPageSize].printRange.width;
    var shortSide =
      items.pageSizeList[items.selectedPageSize].printRange.height;
    var center = mapInfo.center;

    var printableW =
      orientation == 'Landscape' ? longSide * pixels2mm : shortSide * pixels2mm;
    var printableH =
      orientation == 'Landscape' ? shortSide * pixels2mm : longSide * pixels2mm;
    var boxW = printableW * items.scaleList[items.selectedScale].factorMM2M;
    var boxH = printableH * items.scaleList[items.selectedScale].factorMM2M;
    var boxTop = center[1] + boxH / 2;
    var boxBottom = center[1] - boxH / 2;
    var boxLeft = center[0] - boxW / 2;
    var boxRight = center[0] + boxW / 2;

    var extent = [boxLeft, boxBottom, boxRight, boxTop];
    return extent;
  }

  function calculateNonPrintableArea(extent, scalefactor) {
    const factor = scalefactor * pixels2mm;
    const Area1 = [
      extent[2] - 528 * factor,
      extent[1],
      extent[2],
      extent[1] + 157 * factor,
    ];
    const Area2 = [
      extent[2] - 256 * factor,
      extent[1] + 157 * factor,
      extent[2],
      extent[1] + 758 * factor,
    ];
    mapExtents.zone1 = [extent[0], extent[1], Area1[0], extent[3]];
    mapExtents.zone2 = [Area1[0], Area1[3], Area2[0], extent[3]];
    mapExtents.zone3 = [Area2[0], Area2[3], extent[2], extent[3]];
    var points = [];
    points.push([extent[2], extent[1]]);
    points.push([Area1[0], extent[1]]);
    points.push([Area1[0], Area1[3]]);
    points.push([Area2[0], Area1[3]]);
    points.push([Area2[0], Area2[3]]);
    points.push([extent[2], Area2[3]]);
    points.push([extent[2], extent[1]]);
    return points;
  }

  function createBoxFeature(extent) {
    var boxCoordinates = [
      [extent[0], extent[1]],
      [extent[0], extent[3]],
      [extent[2], extent[3]],
      [extent[2], extent[1]],
      [extent[0], extent[1]],
    ];
    var boxGeometry = new LineString(boxCoordinates);

    //var centerX = (extent[0] + extent[2]) / 2;
    //var centerY = (extent[1] + extent[3]) / 2;
    //var centerPoint = [centerX, centerY]; // new ol.geom.Point(centerX, centerY); // [centerX, centerY];
    //var rotation = items.pageRotation * (Math.PI / 180);
    //boxGeometry.rotate(rotation, centerPoint);

    var boxFeature = new Feature({
      geometry: boxGeometry, // new ol.geom.LineString(boxCoordinates)
    });
    //boxFeature.geometry.rotate(90, centerPoint);

    return boxFeature;
  }

  function createLineFeature(extent, items) {
    var lineCoordinates = calculateNonPrintableArea(
      extent,
      items.scaleList[items.selectedScale].factorMM2M,
    );
    var linesGeometry = new LineString(lineCoordinates);

    //var centerX = (extent[0] + extent[2]) / 2;
    //var centerY = (extent[1] + extent[3]) / 2;
    //var centerPoint = [centerX, centerY]; // new ol.geom.Point(centerX, centerY); // [centerX, centerY];
    //var rotation = items.pageRotation * (Math.PI / 180);
    //linesGeometry.rotate(rotation, centerPoint);

    const lineFeature = new Feature({
      geometry: linesGeometry, // new ol.geom.LineString(lineCoordinates)
    });
    lineFeature.setStyle(boxStyle);

    return lineFeature;
  }

  function calculateAndCreateBox(items) {
    removeBox();

    var extent = calculateBox(items);

    var boxFeature = createBoxFeature(extent);
    boxFeature.setStyle(boxStyle);
    var lineFeature = createLineFeature(extent, items);

    var boxVectorSource = new VectorSource({});
    boxVectorSource.addFeature(boxFeature);
    boxVectorSource.addFeature(lineFeature);

    var boxVectorLayer = new VectorLayer({
      title: 'mapBox',
      source: boxVectorSource,
      zIndex: 900,
    });
    window.map.addLayer(boxVectorLayer);

    mapInfo.zoom = window.map.getView().getZoom();
    mapInfo.extent = extent;
    return extent;
  }

  function removeBox() {
    if (mapService.getLayer('mapBox') != null) {
      mapService.removeLayer('mapBox');
    }
  }

  function calculateAndCreateBoxes(boxes) {
    removeBoxes();
    var boxesVectorSource = new VectorSource({});

    for (var box of boxes) {
      var extent = box.content.mapinfo.extent;
      var boxFeature = createBoxFeature(extent);
      if (box.tekeningtype == 'veldtekening') {
        boxFeature.setStyle(veldtekeningBoxStyle);
      } else {
        boxFeature.setStyle(situatietekeningBoxStyle);
      }
      boxesVectorSource.addFeature(boxFeature);
    }

    var boxesVectorLayer = new VectorLayer({
      title: 'mapBoxes',
      source: boxesVectorSource,
      zIndex: 900,
    });
    window.map.addLayer(boxesVectorLayer);
  }

  function removeBoxes() {
    if (mapService.getLayer('mapBoxes') != null) {
      mapService.removeLayer('mapBoxes');
    }
  }

  function createPrintData(items, gegevens, meetpunten) {
    const model = modelService.getModel();
    var type = gegevens.typeList[gegevens.selectedType].name;
    var format = items.pageSizeList[items.selectedPageSize].name;
    var name =
      gegevens.typeList[gegevens.selectedType].name +
      items.pageSizeList[items.selectedPageSize].name +
      '.jrxml';
    var width = items.pageSizeList[items.selectedPageSize].printRange.width;
    var height = items.pageSizeList[items.selectedPageSize].printRange.height;
    var dpi = gegevens.resolutieList[gegevens.selectedResolutie].name;
    var scale = items.scaleList[items.selectedScale].scale;
    var template = {
      type: type,
      format: format,
      orientation:
        items.pageOrientationList[items.selectedPageOrientation].code,
      name: name,
      width: width,
      height: height,
    };
    var labelInfo = {
      x: 13, //15,
      y: 15, //20
    };
    var resolutiefactor =
      gegevens.resolutieList[gegevens.selectedResolutie].factor;
    var labels = {
      x: Math.ceil(
        gegevens.selectedLabelLocatie.x * labelInfo.x * resolutiefactor,
      ),
      y: Math.ceil(
        gegevens.selectedLabelLocatie.y * labelInfo.y * resolutiefactor,
      ),
    };
    var data = {
      layout: template.type + template.format + template.orientation,
      outputFormat: 'pdf',
      outputFilename: gegevens.fileName,
      attributes: {},
    };
    data.attributes.xysysteem = meetpunten.projection.xy;
    data.attributes.zsysteem = meetpunten.projection.z;
    data.attributes.listOfItems = gegevens.printCoordinates
      ? meetpunten.coordinaten
      : null; //";;";
    data.attributes.listOfSVGs = meetpunten.legenda;
    data.attributes.datum = gegevens.datum;
    data.attributes.getekend = gegevens.getekend;
    data.attributes.schaal = '1:' + scale;
    data.attributes.formaat = template.format;
    data.attributes.projectnr = gegevens.subprojectNr;
    data.attributes.gew1 = gegevens.gew1;
    data.attributes.gew2 = gegevens.gew2;
    data.attributes.gew3 = gegevens.gew3;
    data.attributes.gew4 = gegevens.gew4;
    data.attributes.status = gegevens.status;
    data.attributes.blad = gegevens.blad + ' van ' + gegevens.bladvan;
    data.attributes.soorttekening = template.type;
    data.attributes.projectomschrijving = gegevens.projectomschrijving;
    data.attributes.map = {};
    // cf. 'enu' === window.map.getView().getProjection().getAxisOrientation()
    // https://openlayers.org/en/v6.15.1/apidoc/module-ol_proj_Projection-Projection.html#getAxisOrientation
    data.attributes.map.longitudeFirst = true;
    //data.attributes.map.center = mapInfo.center;
    //data.attributes.map.scale = items.scaleList[items.selectedScale].scale;
    data.attributes.map.projection = window.map
      .getView()
      .getProjection()
      .getCode();
    data.attributes.map.dpi = dpi;
    //data.attributes.map.rotation = items.pageRotation; // * (Math.PI / 180);
    data.attributes.map.rotation = 0;
    data.attributes.map.bbox = mapInfo.extent;
    data.attributes.map.layers = [];
    var geoserver = 'http://geowep-geoserver:8080/geoserver';
    if (model.project) {
      if (gegevens.showLabels) {
        const viewParams = mapService
          .getLayerParams('Meetpunt labels')
          .setExtent(mapInfo.extent)
          .setItem('projectnr', gegevens.projectnrInLabels)
          .setItem('tagklant', gegevens.tagklantInLabels)
          .setItem('x', labels.x)
          .setItem('y', labels.y);
        data.attributes.map.layers.push({
          baseURL: geoserver + '/GeoWEP/wms',
          type: 'WMS',
          version: '1.3.0',
          layers: ['GeoWEP:meetpunten_labels'],
          imageFormat: 'image/png8',
          styles: ['labels_css_' + dpi],
          customParams: {
            TRANSPARENT: 'true',
            CQL_FILTER: CQL_FILTER_MEETPUNTEN_AND_LABELS.unEncoded(),
            viewparams: viewParams.unEncoded(),
          },
        });
      }
      const viewParams = mapService
        .getLayerParams('Meetpunten')
        .setExtent(mapInfo.extent)
        .setItem('resolutie', dpi);
      data.attributes.map.layers.push({
        baseURL: geoserver + '/GeoWEP/wms',
        type: 'tiledwms',
        tileSize: [512, 512],
        tileBufferSize: [128, 128],
        version: '1.3.0',
        layers: ['GeoWEP:meetpunten'],
        imageFormat: 'image/png8',
        styles: [''],
        customParams: {
          TRANSPARENT: 'true',
          CQL_FILTER: CQL_FILTER_MEETPUNTEN_AND_LABELS.unEncoded(),
          viewparams: viewParams.unEncoded(),
        },
      });
    } else {
      if (gegevens.showLabels) {
        const viewParams = mapService
          .getLayerParams('Meetpunt labels')
          .setExtent(mapInfo.extent)
          .setItem('projectnr', gegevens.projectnrInLabels)
          .setItem('tagklant', gegevens.tagklantInLabels);
        data.attributes.map.layers.push({
          baseURL: geoserver + '/GeoWEP/wms',
          type: 'WMS',
          version: '1.3.0',
          layers: ['GeoWEP:meetpunten_labels'],
          imageFormat: 'image/png8',
          styles: ['labels_css_' + dpi],
          customParams: {
            TRANSPARENT: 'true',
            CQL_FILTER: CQL_FILTER_MEETPUNTEN_AND_LABELS.unEncoded(),
            viewparams: viewParams.unEncoded(),
          },
        });
      }
      const viewParams = mapService
        .getLayerParams('Meetpunten')
        .setExtent(mapInfo.extent)
        .setItem('resolutie', dpi);
      data.attributes.map.layers.push({
        baseURL: geoserver + '/GeoWEP/wms',
        type: 'tiledwms',
        tileSize: [512, 512],
        tileBufferSize: [128, 128],
        version: '1.3.0',
        layers: ['GeoWEP:meetpunten'],
        imageFormat: 'image/png8',
        styles: [''],
        customParams: {
          TRANSPARENT: 'true',
          CQL_FILTER: CQL_FILTER_MEETPUNTEN_AND_LABELS.unEncoded(),
          viewparams: viewParams.unEncoded(),
        },
      });
    }
    if (template.type === 'veldtekening' && gegevens.subprojectNr != '') {
      const layer = mapService.getLayer('KLIC'),
        baseURL = layer.getSource().getUrls()[0],
        viewParams = mapService.getLayerParams(layer);
      viewParams.setExtent(mapInfo.extent).setItem('resolutie', dpi);
      data.attributes.map.layers.push({
        baseURL: baseURL.startsWith('/') ? location.origin + baseURL : baseURL,
        type: 'WMS',
        version: '1.3.0',
        layers: ['klicav:levering'],
        imageFormat: 'image/png8',
        customParams: {
          TRANSPARENT: 'true',
          viewparams: viewParams.unEncoded(),
        },
      });
    }
    let opacity_plantekening = 1,
      opacity_notities = 1,
      opacity_projectkaart = 1;
    if (!gegevens.enkelBGT) {
      try {
        const l = mapService.getLayer('Plantekeningen');
        opacity_plantekening = l.get('opacity');
        // eslint-disable-next-line no-empty
      } catch {}
      try {
        const l = mapService.getLayer('Projectkaarten');
        opacity_projectkaart = l.get('opacity');
        // eslint-disable-next-line no-empty
      } catch {}
      try {
        const l = mapService.getLayer('Notities');
        opacity_notities = l.get('opacity');
        // eslint-disable-next-line no-empty
      } catch {}
    }
    let layer, cqlFilter, viewParams;
    layer = mapService.getLayer('Plantekeningen');
    cqlFilter = mapService.getLayerFilter(layer);
    viewParams = mapService.getLayerParams(layer);
    viewParams.setExtent(mapInfo.extent);
    data.attributes.map.layers.push({
      baseURL: geoserver + '/GeoWEP/wms',
      opacity: opacity_plantekening,
      type: 'tiledwms',
      tileSize: [512, 512],
      tileBufferSize: [128, 128],
      layers: ['GeoWEP:plantekening'],
      styles: [''],
      imageFormat: 'image/png8',
      customParams: {
        TRANSPARENT: 'true',
        CQL_FILTER: cqlFilter.unEncoded(),
        viewparams: viewParams.unEncoded(),
      },
    });
    layer = mapService.getLayer('Projectkaarten');
    cqlFilter = mapService.getLayerFilter(layer);
    viewParams = mapService.getLayerParams(layer);
    viewParams.setExtent(mapInfo.extent);
    data.attributes.map.layers.push({
      baseURL: geoserver + '/GeoWEP/wms',
      opacity: opacity_projectkaart,
      type: 'tiledwms',
      tileSize: [512, 512],
      tileBufferSize: [128, 128],
      layers: ['GeoWEP:projectkaart'],
      styles: [''],
      imageFormat: 'image/png8',
      customParams: {
        TRANSPARENT: 'true',
        CQL_FILTER: cqlFilter.unEncoded(),
        viewparams: viewParams.unEncoded(),
      },
    });
    layer = mapService.getLayer('Notities');
    cqlFilter = mapService.getLayerFilter(layer);
    viewParams = mapService.getLayerParams(layer);
    viewParams
      .setExtent(mapInfo.extent)
      .setItem('resolution', dpi)
      .setItem('opmerking', gegevens.printNotities)
      .setItem('straatnaam', gegevens.printStraatnamen)
      .setItem('rijroute', gegevens.printRijroutes);
    data.attributes.map.layers.push({
      baseURL: geoserver + '/GeoWEP/wms',
      opacity: opacity_notities,
      type: 'WMS',
      layers: ['GeoWEP:notities'],
      styles: [''],
      imageFormat: 'image/png8',
      customParams: {
        TRANSPARENT: 'true',
        CQL_FILTER: cqlFilter.unEncoded(),
        viewparams: viewParams.unEncoded(),
      },
    });
    if (gegevens.enkelBGT) {
      addLayer(mapService.getLayer('Kadastrale percelen'), 0.7);
      addLayer(mapService.getLayer('BGT'), 1);
    } else {
      mapService
        .getLayers()
        .filter(
          (layer) =>
            ![
              'Plantekeningen',
              'Projectkaarten',
              'Notities',
              'KLIC',
              'Meetpunten',
              'Meetpunt labels',
            ].includes(layer.get('title')),
        )
        .filter((layer) => layer.getVisible() && layer.getOpacity())
        .sort((a, b) => b.getZIndex() - a.getZIndex())
        .forEach((layer) => addLayer(layer));
    }

    return data;

    function addLayer(mapLayer, opt_opacity) {
      const source = mapLayer.getSource(),
        opacity = opt_opacity || mapLayer.getOpacity();
      let layer;
      if (source instanceof WMTS) {
        layer = {
          type: 'WMTS',
          baseURL: baseUrl(),
          opacity,
          layer: source.getLayer(),
          version: source.getVersion(),
          requestEncoding: source.getRequestEncoding(),
          dimensions: source.getDimensions(),
          dimensionParams: {},
          imageFormat: source.getFormat(),
          matrixSet: source.getMatrixSet(),
          matrices: createMatrices(),
        };
      } else if (source instanceof TileWMS || source instanceof ImageWMS) {
        const params = angular.copy(source.getParams());
        layer = {
          type: 'tiledwms',
          tileSize: [512, 512],
          tileBufferSize: [128, 128],
          baseURL: baseUrl(),
          opacity,
          layers: params.LAYERS.split(','),
          imageFormat: params.FORMAT,
          styles: params.STYLES ? params.STYLES.split(',') : [''],
        };
        delete params.LAYERS;
        delete params.FORMAT;
        delete params.STYLES;
        params.TRANSPARENT = true;
        layer.customParams = params;
        // Kadastrale percelen: lijnen in deze laag worden op hogere
        // resoluties te dun afgedrukt. Dus voor deze laag specificeren
        // we een speciale print-stijl.
        if (mapLayer.get('title') === 'Kadastrale percelen') {
          layer.styles = ['print:kadastralegrens'];
        }
      } else if (source instanceof OSM) {
        layer = {
          type: 'osm',
          baseURL: baseUrl(),
          resolutions: source.RESOLUTIONS,
        };
      } else {
        return;
      }
      data.attributes.map.layers.push(layer);

      function baseUrl() {
        const url = source.getUrls ? source.getUrls()[0] : source.getUrl();
        if (url.startsWith('/geowep/cyclomedia/')) {
          // The MapFish configuration will authorise the requests
          // directly, which turned out to be easier that letting it
          // forward the ASP.NET cookie to the proxy.
          return url.replace(
            '/geowep/cyclomedia/',
            'https://atlas.cyclomedia.com/',
          );
        } else if (
          url.startsWith(`${window.location.origin}/geowep/mapproxy`)
        ) {
          return url.replace(
            `${window.location.origin}/geowep/mapproxy`,
            'http://geowep-mapproxy',
          );
        } else if (url.startsWith(`${config.prefix}/geowep/mapproxy`)) {
          return url.replace(
            `${config.prefix}/geowep/mapproxy`,
            'http://geowep-mapproxy',
          );
        } else {
          return url;
        }
      }

      function createMatrices() {
        // Note that you cannot provide MapFish with the projection of the
        // matrices; it assumes it's the same as the map projection. So if the
        // WMTS layer doesn't provide a TileMatrixSet with the same projection
        // as the map, MapFish won't be able to render that layer.
        const matrices = source.TILE_MATRIX_ARRAY.map((matrix) => {
          return {
            identifier: matrix.Identifier,
            matrixSize: [matrix.MatrixWidth, matrix.MatrixHeight],
            scaleDenominator: matrix.ScaleDenominator,
            tileSize: [matrix.TileWidth, matrix.TileHeight],
            topLeftCorner: matrix.TopLeftCorner,
          };
        });
        // BGT: straatnamen in deze laag worden met de normale WMTS
        // instellingen te klein afgedrukt. Dus voor deze laag laten we
        // de te grootschalige matrices weg uit de matrixset.
        if (mapLayer.get('title') === 'BGT') {
          let stop;
          return matrices.filter((matrix) => {
            const include = !stop;
            stop = matrix.scaleDenominator <= scale;
            return include;
          });
        } else {
          return matrices;
        }
      }
    }
  }

  function createXyzPrintData(
    tagklantType,
    soorttekening,
    meetpunten,
    projection,
  ) {
    const project = modelService.getModel().project;
    var datum = getShortLocaleToday();
    var subprojectNr = project
      ? project.status + '-' + project.subprojectnr
      : '';
    var tag = '';
    var layout = 'xyzTag';
    if (tagklantType == 'PT') {
      tag = '_tag';
      layout = 'xyzTag';
    } else if (tagklantType == 'T') {
      tag = '_tag';
      layout = 'xyzNoTag';
    } else {
      tag = '';
      layout = 'xyzNoTag';
    }
    var fileName =
      (project ? subprojectNr : 'KAART') +
      '_' +
      soorttekening.toUpperCase() +
      tag +
      '_' +
      getLocaleToday();
    var data = {
      layout: layout,
      outputFormat: 'pdf',
      outputFilename: fileName,
      attributes: {},
    };
    data.attributes.xysysteem = projection.xy;
    data.attributes.zsysteem = projection.z;
    data.attributes.listOfItems = meetpunten;
    data.attributes.datum = datum;
    data.attributes.projectnr = '' + subprojectNr;
    return data;
  }

  function projection(onderzoeken = []) {
    const data = {
      xy: 'onbekend',
      z: 'onbekend',
    };
    if (onderzoeken.length) {
      // Business specification: all onderzoeken in a print use the same xy and
      // projections.
      for (const onderzoek of onderzoeken) {
        const { srid, srname } = onderzoek;
        const projection = olProj.get('EPSG:' + srid);
        if (projection) {
          const units = projection.getUnits();
          data.xy = `${srname} (${units})`;
          break;
        }
      }
      // Not all onderzoeken have a z-value.
      const { zsysteem } = onderzoeken.find(({ zsysteem }) => zsysteem) || {};
      data.z = zsysteem;
    }
    return data;
  }

  function getMeetpunten(
    puntenInArea,
    isBepaalLocatieInVeld,
    extraText,
    printZ,
    projectnrInLabels,
    tagklantInLabels,
  ) {
    var deferred = $q.defer();
    var meetpunten = {
      coordinaten: null,
      legenda: null,
      projection: projection(),
    };
    puntenInArea = puntenInArea.filter((p) => p.isInPrintableZone);
    if (puntenInArea.length > 0) {
      const ids = puntenInArea.map((p) => p.id);
      userAccountService.getOnderzoekenById(ids).then(
        function (response) {
          meetpunten.coordinaten = GetMeetpuntenString(
            puntenInArea,
            response,
            isBepaalLocatieInVeld,
            extraText,
            printZ,
            projectnrInLabels,
            tagklantInLabels,
          );
          meetpunten.legenda = getLegendaString(puntenInArea);
          meetpunten.ids = ids;
          meetpunten.projection = projection(response);
          deferred.resolve(meetpunten);
        },
        function (response) {
          deferred.reject(response.status);
        },
      );
    } else {
      meetpunten.coordinaten = '';
      meetpunten.legenda = '';
      meetpunten.ids = [];
      deferred.resolve(meetpunten);
    }
    return deferred.promise;
  }

  function getMeetpuntenXYZ(puntenInArea, xyzGegevens) {
    var deferred = $q.defer();
    var meetpunten = {
      coordinaten: null,
      legenda: null,
      projection: projection(),
    };
    if (puntenInArea.length > 0) {
      userAccountService.getOnderzoekenById(puntenInArea.map((p) => p.id)).then(
        function (response) {
          //var inclTagKlant = hasTagklant(xyzGegevens, puntenInArea, response);
          meetpunten.coordinaten = GetMeetpuntenXyzString(
            xyzGegevens,
            puntenInArea,
            response,
          );
          meetpunten.projection = projection(response);
          deferred.resolve(meetpunten);
        },
        function (response) {
          deferred.reject(response.status);
        },
      );
    } else {
      deferred.resolve(meetpunten);
    }
    return deferred.promise;
  }

  // Need to use the labels layer's filter for the meetpunten as well, since
  // the GRID and KAART filters aren't included there (see mapService.js)
  var CQL_FILTER_MEETPUNTEN_AND_LABELS;

  function getPuntenInPrintArea() {
    CQL_FILTER_MEETPUNTEN_AND_LABELS = mapService
      .getLayerFilter('Meetpunt labels')
      .unsetItem('BBOX');
    const viewParams = mapService.getLayerParams('Meetpunten'),
      url =
        '/geowep/geoserver/wfs?1=1' +
        '&service=WFS&request=GetFeature' +
        '&version=1.0.0&typename=GeoWEP:meetpunten' +
        '&outputFormat=json' +
        '&srsname=' +
        window.map.getView().getProjection().getCode() +
        '&cql_filter=' +
        CQL_FILTER_MEETPUNTEN_AND_LABELS +
        '&viewparams=' +
        viewParams.setExtent(mapInfo.extent);
    return userAccountService.proxy.get(url).then((json) => {
      const meetpunten = getPuntenInPrintAreaVervolg(json.features);
      return meetpunten;
    });
  }

  function getPuntenInPrintAreaVervolg(punten) {
    const points = [];
    punten.forEach((f) => {
      const coords = f.geometry.coordinates;
      const properties = f.properties;
      const x = coords[0];
      const y = coords[1];
      const ot = properties.onderzoekstype;
      points.push({
        id: properties.id,
        projectnr: properties.projectnr,
        naam: properties.naam,
        tagklant: properties.tagklant,
        type: ot,
        onderzoekstype: ot,
        uitleg: $rootScope.refdata.onderzoekstype[ot].omschrijving,
        color: properties.categorie_default,
        status: properties.categorie_onderzoekstatus,
        x: x,
        y: y,
        isInPrintableZone: isInPrintableZone(x, y),
      });
    });
    return points.sort(utilService.OnderzoeknaamComparer);
  }

  function round(number, digits) {
    if (number == null) {
      return 'NULL';
    } else {
      var roundedNumber =
        Math.round(Math.abs(number) * Math.pow(10, digits)) /
        Math.pow(10, digits);
      return number < 0
        ? (roundedNumber * -1).toFixed(digits)
        : roundedNumber.toFixed(digits);
    }
  }

  function GetMeetpuntenString(
    puntenInArea,
    onderzoekenInArea,
    isBepaalLocatieInVeld,
    extraText,
    printZ,
    projectnrInLabels,
    tagklantInLabels,
  ) {
    var points = [];
    for (var p in puntenInArea) {
      const roundedX = round(puntenInArea[p].x, 1);
      const roundedY = round(puntenInArea[p].y, 1);
      let roundedZ = ' ';
      if (printZ) {
        const z = round(getZ(onderzoekenInArea, puntenInArea[p].id), 2);
        roundedZ = z == 'NULL' ? ' ' : z == 0 ? '0.00' : z;
      }
      var elkeNrijen = 1;
      var lijn = p % elkeNrijen == elkeNrijen - 1 ? 'lijn' : 'geenlijn';
      if (p == puntenInArea.length - 1) {
        lijn = 'lijn';
      }
      var label = '';
      var puntnaam =
        puntenInArea[p].projectnr == 999998
          ? puntenInArea[p].naam
          : puntenInArea[p].projectnr + '_' + puntenInArea[p].naam;
      if (projectnrInLabels) {
        if (tagklantInLabels) {
          var tagklant = getTagklant(onderzoekenInArea, puntenInArea[p].id);
          if (tagklant == null) {
            tagklant = '';
          }
          label = puntnaam + '_(' + tagklant + ')';
        } else {
          label = puntnaam;
        }
      } else {
        const tagklant = tagklantInLabels
          ? getTagklant(onderzoekenInArea, puntenInArea[p].id)
          : undefined;
        label = tagklant || puntenInArea[p].naam;
      }
      if (
        (isBepaalLocatieInVeld && puntenInArea[p].type == 'B') ||
        (isBepaalLocatieInVeld && puntenInArea[p].type === 'B  ') ||
        (isBepaalLocatieInVeld && puntenInArea[p].type == 'HB') ||
        (isBepaalLocatieInVeld && puntenInArea[p].type == 'HBS')
      ) {
        points.push(label + ';' + extraText + ';;;rood;' + lijn);
      } else {
        if (puntenInArea[p].status == 9) {
          points.push(label + ';' + 'niet uitgevoerd' + ';;;rood;' + lijn);
        } else {
          points.push(
            label +
              ';' +
              roundedX +
              ';' +
              roundedY +
              ';' +
              roundedZ +
              ';zwart;' +
              lijn,
          );
        }
      }
    }
    return points.join(',');
  }

  function GetMeetpuntenXyzString(
    xyzGegevens,
    puntenInArea,
    onderzoekenInArea,
  ) {
    let tagklantType = xyzGegevens.inclTagklant ? 'T' : 'P';
    if (tagklantType == 'P') {
      for (const p in puntenInArea) {
        if (!xyzGegevens.uitgevoerd || puntenInArea[p].color == 5) {
          let tagklant = getTagklant(onderzoekenInArea, puntenInArea[p].id);
          if (tagklant != null && tagklant != '') {
            tagklantType = 'PT';
          }
        }
      }
    }
    const points = [];
    for (const p in puntenInArea) {
      if (!xyzGegevens.uitgevoerd || puntenInArea[p].color == 5) {
        const roundedX = round(puntenInArea[p].x, 1);
        const roundedY = round(puntenInArea[p].y, 1);
        const roundedZ = round(getZ(onderzoekenInArea, puntenInArea[p].id), 2); // (Math.round(getZ(onderzoekenInArea, puntenInArea[p].id) * 100) / 100).toFixed(2); // parseFloat(Math.round(getZ(onderzoekenInArea, puntenInArea[p].id) * 100) / 100).toFixed(2);
        const pointedX = roundedX;
        const pointedY = roundedY;
        const signedZ =
          roundedZ == 'NULL' ? ' ' : roundedZ == 0 ? '0.00' : roundedZ;
        let tagklant = getTagklant(onderzoekenInArea, puntenInArea[p].id);
        if (tagklant == null) {
          tagklant = '';
        }
        const label = puntenInArea[p].naam;
        if (tagklantType == 'PT') {
          points.push(
            label +
              ';' +
              tagklant +
              ';' +
              pointedX +
              ';' +
              pointedY +
              ';' +
              signedZ,
          );
        } else if (tagklantType == 'T') {
          points.push(
            tagklant + ';' + pointedX + ';' + pointedY + ';' + signedZ,
          );
        } else {
          points.push(label + ';' + pointedX + ';' + pointedY + ';' + signedZ);
        }
      }
    }
    return {
      tagklantType: tagklantType,
      meetpunten: points.join(','),
    };
  }

  function getZ(onderzoeken, id) {
    for (var o in onderzoeken) {
      if (onderzoeken[o].id == id) {
        return onderzoeken[o].z;
      }
    }
    return 0;
  }

  function getTagklant(onderzoeken, id) {
    for (var o in onderzoeken) {
      if (onderzoeken[o].id == id) {
        return onderzoeken[o].tagklant;
      }
    }
    return 0;
  }

  function getLegendaString(puntenInArea) {
    var uitvoering = {
      0: 'Onbekend',
      1: 'Bron BRO',
      2: 'Bron DINOloket',
      3: 'Onbekend',
      4: 'Uitgevoerd door derden',
      5: 'Uitgevoerd door W&P',
      6: 'Uitgev. W&P loc. globaal',
      7: 'Onbekend',
      8: 'Niet uitgevoerd',
      9: 'Onbekend',
    };
    var uniquePoints = new Set();
    puntenInArea.forEach((p) =>
      uniquePoints.add(
        JSON.stringify({
          onderzoekstype: p.onderzoekstype,
          color: p.color,
          uitleg: p.uitleg,
        }),
      ),
    );
    // De punten zijn nu, met de nieuwe sorteerfunctie, al gesorteerd op:
    // 1. projectnr
    // 2. primair_onderzoekstype.volgorde
    // 3. onderzoek.naam(vanaf het eerste cijfer)
    var points = [];
    uniquePoints.forEach((json) => {
      const p = JSON.parse(json);
      const baseSvg = mapService.getBaseSvg(p.onderzoekstype, p.color);
      const svgString = mapService.getSvg(
        baseSvg.size,
        baseSvg.path,
        baseSvg.color,
      );
      const svgB64 = btoa(svgString);
      points.push(svgB64 + ';' + p.uitleg + ';' + uitvoering[p.color]);
    });
    return points.join(',');
  }

  function isInPrintableZone(x, y) {
    function between(val, min, max) {
      return val >= min && val <= max;
    }
    const extents = [mapExtents.zone1, mapExtents.zone2, mapExtents.zone3];
    for (const extent of extents) {
      if (
        between(x, extent[0], extent[2]) &&
        between(y, extent[1], extent[3])
      ) {
        return true;
      }
    }
  }

  function checkTemplate(pageSize, pageOrientation, items) {
    if (items.pageSizeList[pageSize] == null) {
      return;
    }
    if (items.pageOrientationList[pageOrientation] == null) {
      return;
    }
    var test =
      items.pageSizeList[pageSize].name +
      items.pageOrientationList[pageOrientation].code;
    return items.templateList.includes(test) ? true : false;
  }

  function getItems() {
    return items;
  }

  function initItems() {
    var templateList = [
      'A4P',
      'A3P',
      'A3L',
      'A2P',
      'A2L',
      'A1P',
      'A1L',
      'A0P',
      'A0L',
    ];
    var pageSizeList = [
      {
        id: 0,
        name: 'A4',
        printRange: { width: 755, height: 509, x: 0.277, y: 0.267 },
        resolutieListId: 0,
        disabled: true,
      }, //0, "disabled": false },
      {
        id: 1,
        name: 'A3',
        printRange: { width: 1105, height: 756, x: 0.277, y: 0.267 },
        resolutieListId: 0,
        disabled: true,
      }, //0, "disabled": false },
      {
        id: 2,
        name: 'A2',
        printRange: { width: 1598, height: 1105, x: 0.277, y: 0.267 },
        resolutieListId: 0,
        disabled: true,
      }, //2, "disabled": false },
      {
        id: 3,
        name: 'A1',
        printRange: { width: 2298, height: 1598, x: 0.277, y: 0.267 },
        resolutieListId: 0,
        disabled: true,
      },
      {
        id: 4,
        name: 'A0',
        printRange: { width: 3284, height: 2298, x: 0.277, y: 0.267 },
        resolutieListId: 0,
        disabled: true,
      },
    ];
    var maxPageSizeId = pageSizeList.length - 1;
    var selectedPageSize = 1;
    var pageOrientationList = [
      { id: 0, name: 'Portrait', code: 'P', disabled: false },
      { id: 1, name: 'Landscape', code: 'L', disabled: false },
    ];
    var maxPageOrientationId = pageOrientationList.length - 1;
    var selectedPageOrientation = 1;
    var scaleList = [
      {
        id: 0,
        name: '1:100',
        scale: 100,
        factorMM2M: 0.1,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 1,
        name: '1:250',
        scale: 250,
        factorMM2M: 0.25,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 2,
        name: '1:500',
        scale: 500,
        factorMM2M: 0.5,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 3,
        name: '1:750',
        scale: 750,
        factorMM2M: 0.75,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 4,
        name: '1:1.000',
        scale: 1000,
        factorMM2M: 1,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 5,
        name: '1:2.000',
        scale: 2000,
        factorMM2M: 2,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 6,
        name: '1:2.500',
        scale: 2500,
        factorMM2M: 2.5,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 7,
        name: '1:5.000',
        scale: 5000,
        factorMM2M: 5,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 8,
        name: '1:10.000',
        scale: 10000,
        factorMM2M: 10,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 9,
        name: '1:25.000',
        scale: 25000,
        factorMM2M: 25,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 10,
        name: '1:50.000',
        scale: 50000,
        factorMM2M: 50,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 11,
        name: '1:100.000',
        scale: 100000,
        factorMM2M: 100,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 12,
        name: '1:250.000',
        scale: 250000,
        factorMM2M: 250,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 13,
        name: '1:500.000',
        scale: 500000,
        factorMM2M: 500,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 14,
        name: '1:1.000.000',
        scale: 1000000,
        factorMM2M: 1000,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 15,
        name: '1:2.500.000',
        scale: 2500000,
        factorMM2M: 2500,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 16,
        name: '1:187,5',
        scale: 187.5,
        factorMM2M: 0.1875,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 17,
        name: '1:375',
        scale: 375,
        factorMM2M: 0.375,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 18,
        name: '1:750',
        scale: 750,
        factorMM2M: 0.75,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 19,
        name: '1:1.500',
        scale: 1500,
        factorMM2M: 1.5,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 20,
        name: '1:3.000',
        scale: 3000,
        factorMM2M: 3,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 21,
        name: '1:6.000',
        scale: 6000,
        factorMM2M: 6,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 22,
        name: '1:12.000',
        scale: 12000,
        factorMM2M: 12,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 23,
        name: '1:24.000',
        scale: 24000,
        factorMM2M: 24,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 24,
        name: '1:48.000',
        scale: 48000,
        factorMM2M: 48,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 25,
        name: '1:96.000',
        scale: 96000,
        factorMM2M: 96,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 26,
        name: '1:192.000',
        scale: 192000,
        factorMM2M: 192,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 27,
        name: '1:384.000',
        scale: 384000,
        factorMM2M: 384,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 28,
        name: '1:768.000',
        scale: 768000,
        factorMM2M: 768,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 29,
        name: '1:1.536.000',
        scale: 1536000,
        factorMM2M: 1536,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 30,
        name: '1:3.072.000',
        scale: 3072000,
        factorMM2M: 3072,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 31,
        name: '1:6.144.000',
        scale: 6144000,
        factorMM2M: 6144,
        resolutieListId: 0,
        disabled: false,
      },
      {
        id: 32,
        name: '1:12.288.000',
        scale: 12288000,
        factorMM2M: 12288,
        resolutieListId: 0,
        disabled: false,
      },
    ];
    var selectedScale = 2;
    var maxScaleId = scaleList.length - 1;
    var pageRotation = 0;
    return {
      templateList: templateList,
      pageSizeList: pageSizeList,
      selectedPageSize: selectedPageSize,
      maxPageSizeId,
      pageOrientationList: pageOrientationList,
      selectedPageOrientation: selectedPageOrientation,
      maxPageOrientationId,
      scaleList,
      selectedScale,
      maxScaleId,
      pageRotation,
    };
  }

  function getLocaleToday() {
    var today = new Date();
    return utilService.dateStringForFilenames(today);
  }

  function getShortLocaleToday() {
    var today = new Date();
    return utilService.dateStringForFilenames(today, true);
  }

  function getGegevens() {
    const model = modelService.getModel();
    const project = model.project;
    var showLabels = true;
    var printCoordinates = true;
    var projectnrInLabels = model.modus().startsWith('K');
    var tagklantInLabels = !projectnrInLabels;
    var typeList = [
      { id: 0, name: 'veldtekening', disabled: false },
      { id: 1, name: 'situatietekening', disabled: false },
      { id: 2, name: 'xyz', disabled: false },
    ];
    var selectedType = 0;
    var extraText = 'locatie HB in het veld te bepalen';
    var showExtraText = typeList[selectedType].name === 'veldtekening';
    var resolutieList = [
      { id: 0, name: '300', factor: 3, disabled: false },
      { id: 1, name: '250', factor: 2.5, disabled: false },
      { id: 2, name: '200', factor: 2, disabled: false },
      { id: 3, name: '150', factor: 1.5, disabled: false },
      { id: 4, name: '100', factor: 1, disabled: false },
      { id: 5, name: '72', factor: 0.7, disabled: false },
    ];
    var selectedResolutie = 0;
    var labelLocaties = [
      { id: 0, name: 'NO', disabled: false, class: 'rotate315', x: 1, y: 1 },
      { id: 1, name: 'ZO', disabled: false, class: 'rotate45', x: 1, y: -1 },
      { id: 2, name: 'ZW', disabled: false, class: 'rotate135', x: -1, y: -1 },
      { id: 3, name: 'NW', disabled: false, class: 'rotate225', x: -1, y: 1 },
    ];
    var selectedLabelLocatie = labelLocaties[0];
    var projectId = project ? project.id : '';
    var datum = getShortLocaleToday();
    var subprojectNr = project
      ? project.status + '-' + project.subprojectnr
      : '';
    var soorttekening = typeList[selectedType];
    var fileName =
      (subprojectNr || 'KAART') +
      '_' +
      soorttekening.name +
      '_' +
      getLocaleToday();
    var getekend = '';
    var schaal = '';
    var formaat = '';
    var projectnr = project ? project.projectnr : '';
    var gew1 = '';
    var gew2 = '';
    var gew3 = '';
    var gew4 = '';
    var status = setStatusText(soorttekening.name);
    var blad = 1;
    var bladvan = 1;
    var projectomschrijving = project
      ? project.omschrijving + ' te ' + project.plaatsnaam
      : '';
    var mapInfo = mapInfo;
    var printZ = soorttekening.name === 'situatietekening';
    var printStraatnamen = true;
    var printRijroutes = soorttekening.name === 'veldtekening';
    var printNotities = soorttekening.name === 'veldtekening';
    var enkelBGT = true;
    return {
      showLabels,
      printStraatnamen,
      printRijroutes,
      printNotities,
      enkelBGT,
      printCoordinates,
      projectnrInLabels,
      tagklantInLabels,
      extraText,
      showExtraText,
      typeList,
      selectedType,
      resolutieList,
      selectedResolutie,
      fileName,
      labelLocaties,
      selectedLabelLocatie,
      projectId,
      subprojectNr,
      datum,
      getekend,
      schaal,
      formaat,
      projectnr,
      gew1,
      gew2,
      gew3,
      gew4,
      status,
      blad,
      bladvan,
      soorttekening,
      projectomschrijving,
      mapInfo,
      printZ,
    };
  }

  function getXyzGegevens() {
    var inclTagklant = false;
    var uitgevoerd = true;
    var printScreen = 'box';
    return {
      inclTagklant,
      uitgevoerd,
      printScreen,
    };
  }

  function setStatusText(soorttekening) {
    var status = '';
    if (soorttekening === 'veldtekening') {
      status = 'uitvoering/KLIC';
    } else {
      status = '';
    }
    return status;
  }

  function getBoxes(projectnr, tekeningtype) {
    userAccountService.getPrint(projectnr, tekeningtype).then(function (data) {
      data.filter((box) => {
        return box.content.tekeningtype == tekeningtype;
      });
      var boxes = data;
      //if (boxes.length > 0) {
      calculateAndCreateBoxes(boxes);
      //}
    });
  }

  return mapexportService;
}
