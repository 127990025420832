﻿import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import * as olProj from 'ol/proj';

import iconTimerSvg from '../../images/svgs/icon-timer.svg';
import moreVertSvg from '../../images/svgs/more_vert.svg';
import addSvg from '../../images/svgs/add.svg';
import klicLegendaPng from '../../images/legends/klic_legenda.png';

const toastr = window.toastr;

window.app.controller('mapCtrl', [
  '$scope',
  '$rootScope',
  'mapService',
  'mapToolsService',
  'userAccountService',
  'modelService',
  'doorsneeService',
  mapCtrl,
]);

function mapCtrl(
  $scope,
  $rootScope,
  mapService,
  mapToolsService,
  userAccountService,
  modelService,
  doorsneeService,
) {
  return (async () => {
    await window.GEOLOKET_LOGIN;

    userAccountService.getgeowepsettings().then((settings) => {
      $rootScope.geowepSettings = [];
      for (const { geowep_key, geowep_value } of settings) {
        $rootScope.geowepSettings[geowep_key] = geowep_value;
      }
      initMap();
    });

    function initMap(project) {
      if (project) {
        // A project has been selected; see if the map view needs to be set to a
        // different projection.
        const { srid } = project;
        const SRID = `EPSG:${srid}`;
        if (SRID === $rootScope.geowepSettings['SRID']) {
          // Same projection; cancel everything.
          return;
        }
        // Different projection; ensure it's registered, then use it.
        projection(srid)
          .then(() => {
            // Store the new projection.
            $rootScope.geowepSettings['SRID'] = SRID;
            // Change the map view.
            mapService.initSettings(project);
          })
          .catch(() => {
            // Undo the project selection.
            modelService.update('model-project');
          });
      } else {
        // Create the initial map based on geowepSettings.
        mapService.initSettings({
          initial: true,
          projection: $rootScope.geowepSettings['SRID'],
          center: $rootScope.geowepSettings['initial_location']
            .split(' ')
            .map(Number),
          resolution: Number($rootScope.geowepSettings['initial_resolution']),
          resolutions: $rootScope.geowepSettings['zoom_resolutions']
            .split(', ')
            .map(Number),
        });
      }
    }

    // Manage srid based on selected project.
    $rootScope.$on('model-project', (_, { project }) => {
      if (project) {
        initMap(project);
      }
    });

    // TODO: move into utilService.
    function projection(srid) {
      const EPSG = `EPSG:${srid}`;
      if (olProj.get(EPSG)) {
        return Promise.resolve();
      } else {
        return userAccountService.api
          .get(`/spatial_ref_sys?srid=${srid}`)
          .then(({ proj4text }) => {
            proj4.defs(EPSG, proj4text);
            register(proj4);
          })
          .catch((error) => {
            toastr.error(
              `${error.name}: ${error.message}`,
              'Definiëren kaartprojectie mislukt',
            );
            throw error;
          });
      }
    }

    $scope.iconTimerSvg = iconTimerSvg;
    $scope.moreVertSvg = moreVertSvg;

    $scope.myDate = new Date();
    $scope.data = {
      selectedIndex: 0,
      secondLocked: false,
      firstLabel: 'Kaart',
      bottom: false,
      layerslist: true,
      layerlistfilter: 'dimension_2d:true',
      showgrid: false,
      gridurl: '',
      settingslist: false,
      settingslisturl: '',
      searchurl: '',
      infourl: '',
      legendurl: '',
      max: true,
      luchtfoto: true,
      brtachtergrondkaartpastel: false,
      currentModule: 'WION',
      checkedClass: 'mapContainerMax',
      mapToolToggle: 'Tools aan/uit',
      searchPlaceholder: 'adres',
      currentview: '2D',
      legenda: false,
      legendaMP: false,
      legendaMPtitle: 'Meetpunten',
      legendaMPlaag: 'Meetpunten',
      information: false,
      gridDiv_title: '',
      information_Title: 'Informatie',
      legend_Title: 'Legenda',
      timeFilter: false,
      filterdays: 28,
      filterStartDate: new Date(
        $scope.myDate.getFullYear(),
        $scope.myDate.getMonth(),
        $scope.myDate.getDate() - 28,
      ), // today - 28 dagen = 4 weken
      filterEndDate: new Date(
        $scope.myDate.getFullYear(),
        $scope.myDate.getMonth(),
        $scope.myDate.getDate() + 28,
      ), //today + 28 dagen = 4 weken
    };
    $scope.information = {
      projectId: '',
      locationId: '',
      locationIds: [],
      locatieUrl: '',
      projectUrl: '',
      locatiesUrl: '',
      documentenUrl: '',
      gridUrl: '',
      gridDiv_title: '',
    };
    //menu visible or not
    $scope.menu = {
      settingslist: false,
      usersettingslist: false,
      modulelist: false,
      search: false,
      projectenlist: false,
    };
    //some more startup-settings
    $scope.mapToolsIsVisible = true; //default open
    $scope.mapToolsDrawing = false; //default false
    $scope.mapToolsBarOpen = false; // used to keep maptools open

    $scope.$watch('mapToolsDrawing', function (current, old) {
      if (current !== old && !current) {
        mapToolsService.RemovePolygon();
      }
    });

    $scope.genericfloatdock = 'generic_grid_center';
    $scope.genericfloatdockdetail = 'generic_grid_center';
    $scope.genericfloatdockgrid = 'generic_grid_center_big';

    $scope.data_max = 'mapContainer';
    $scope.mapwidth = {
      width: 90 + '%',
    };
    $scope.AddPointsIcon = addSvg;
    //maptoollabels
    $scope.mapTools = {
      Information: 'Informatie',
      MeasureLine: 'Meet lijn',
      MeasureArea: 'Meet vlak',
      Home: 'Home',
      ToonMeetpunten: 'Meetpuntenbestand tonen op kaart',
      Draw: 'Teken',
      DrawLine: 'Teken doorsnee',
      DrawSelectPolygon: 'Teken/bewerk polygoon',
      LoadPolygon: 'Laad polygoon',
      AddPoints: 'Toevoegen Meetpunten',
      Print: 'Print kaart',
      StreetSmart: 'Open StreetSmart',
      InksketchPrint: 'Maak print van Scherm',
      StatusChange: 'Wijzig status meetpunt(en)',
    };

    $scope.toggletimefilter = function () {
      if ($scope.menu.timefilter) {
        $scope.menu.timefilter = false;
      } else {
        $scope.menu.timefilter = true;
      }
    };
    // einde togggle menu's

    $scope.onLayerSwitch = function (name, oldstatus) {
      if (name === 'luchtfoto' || oldstatus) {
        $scope.data.brtachtergrondkaartpastel = oldstatus;
      } else {
        $scope.data.luchtfoto = oldstatus;
      }
      // toggleBaselayer(name, oldstatus);
    };
    $scope.toggleLayer = function (name, oldstatus) {
      mapService.toggleLayer(name, oldstatus);
    };

    $scope.setCurrentModule = function (name) {
      $scope.data.currentModule = name;
    };

    $scope.timeFiltering = function () {
      mapService.timeFilterMapLayers(
        $scope.data.filterStartDate,
        $scope.data.filterEndDate,
        !$scope.data.timeFilter,
      ); //! its beeing turned on same time this is fired
    };
    $scope.ResetTimeFilter = function () {
      $scope.data.filterStartDate = new Date(
        $scope.myDate.getFullYear(),
        $scope.myDate.getMonth(),
        $scope.myDate.getDate() - $scope.data.filterdays,
      );
      $scope.data.filterEndDate = new Date(
        $scope.myDate.getFullYear(),
        $scope.myDate.getMonth(),
        $scope.myDate.getDate() + $scope.data.filterdays,
      );
    };

    $scope.toolbarClick = function () {
      //changes the icon
      //console.log("switch maptools :"+$scope.mapToolsIsOpen);

      var el = document.getElementById('toolsmenu');
      if ($scope.mapToolsBarOpen) {
        $scope.mapToolsBarOpen = false;
        el.classList.remove('open');
        el.classList.add('close');
      } else {
        $scope.mapToolsBarOpen = true;
        el.classList.remove('close');
        el.classList.add('open');
      }
      //console.log("switch maptools after:"+$scope.mapToolsIsOpen);
    };

    $scope.DrawLine = function () {
      //if (document.getElementById("btnDrawLine").classList.contains("maptool-active")) {
      if (modelService.getModel().tools.has('PROFILER')) {
        modelService.update('model-tools', 'PROFILER', 'DELETE');
        doorsneeService.stop();
        mapToolsService.setMousePointerSwitcher('default');
        //document.getElementById("btnDrawLine").classList.remove("maptool-active");
      } else {
        mapToolsService.setMousePointerSwitcher('draw');
        modelService.update('model-tools', 'PROFILER', 'ADD');
        const snapLayer = mapService.getLayer('Units');
        doorsneeService.start({ snapLayer });
        //document.getElementById("btnDrawLine").classList.add("maptool-active");//TODO all others should be set inactive
      }
    };

    $scope.NotImplemented = function () {
      alert('deze functie is nog niet geimplementeerd...');
    };

    $scope.toggleSelectable = function (e, maplayer) {
      var classList = e.currentTarget.parentElement.parentElement.classList;
      if (classList.contains('layeritem-info-active')) {
        mapToolsService.stopInfo(maplayer);
        classList.remove('layeritem-info-active');
      } else {
        mapToolsService.startInfo(maplayer);
        classList.add('layeritem-info-active');
      }
    };

    $scope.toggleLegend = function (legend_url, laagnaam) {
      legend_url = legend_url.replace(/^\/app\//, '');
      if (legend_url.endsWith('klic_legenda.png')) {
        legend_url = klicLegendaPng;
      }
      var show =
        laagnaam === $scope.data.legend_Title ? !$scope.data.legenda : true;
      $scope.data.legend_Title = laagnaam;
      $scope.data.legendurl = legend_url;
      $scope.data.legenda = show;
    };

    // Tijdelijk de meetpuntenlegenda standaard aan zetten
    // Later in het project gaat dit er weer uit
    $scope.data.legendaMPtitle = 'meetpunten';
    $scope.data.legendaMPlaag = 'meetpunten';
    $scope.data.legendaMP = true;

    $scope.toggleMeetpuntLegend = function (laagnaam) {
      var show =
        laagnaam === $scope.data.legendaMPlaag ? !$scope.data.legendaMP : true;
      $scope.data.legendaMPtitle = laagnaam;
      $scope.data.legendaMPlaag = laagnaam;
      $scope.data.legendaMP = show;
    };

    $scope.hideInfobox = function () {
      var divinfoContainer = document.getElementById('InfoContainer');
      divinfoContainer.classList.add('ng-hide');
    };

    $scope.GetGraph = function (module, graph) {
      //userAccountService.getKlipObjects().then(function (data) {
      //    vm.values = angular.toJson(data);
      //    console.log('succeeded fetching KlipObjects');
      //});
      userAccountService.getGraph(module, graph);
    };

    $scope.selectText = function ($event) {
      //$event was containerid
      var ranget;
      var elem = $event.currentTarget || $event.srcElement; //added
      while (elem && elem.parentElement) {
        elem = elem.parentElement;
        if (elem.tagName && elem.tagName.toLowerCase() === 'div') {
          if (elem.hasAttribute('selecttext')) {
            if (document.selection) {
              ranget = document.body.createTextRange();
              ranget.moveToElementText(elem); //document.getElementById(containerid)
              ranget.select();
            } else if (window.getSelection) {
              window.getSelection().removeAllRanges();
              ranget = document.createRange();
              ranget.selectNode(elem); //document.getElementById(containerid)
              window.getSelection().addRange(ranget);
            }
            break;
          }
        }
      }
    };

    $scope.hidePositionPopup = function () {
      var divPositionPopup = document.getElementById('DivPopupPosition');
      divPositionPopup.classList.add('ng-hide');
    };
  })();
}
