﻿import src from './metadata.html?url';

window.app.controller('gridMetadataCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  'gridMetadata',
  'gridFactory',
  gridMetadataCtrl,
]);

function gridMetadataCtrl($scope, $rootScope, $sce, gridMetadata, gridFactory) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.views = gridMetadata.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.selectionMethod = 'Cell';

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridMetadata.setView(index);
    }
  };

  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };

  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel('metadata.xlsx', {
      excludeColumnIds: ['_checkbox_selector'],
    });
  };

  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };
}
