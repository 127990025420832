﻿import src from './pointeditor.html?url';

const angular = window.angular;

window.app.controller('pointeditorCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  'pointeditorService',
  'modelService',
  'utilService',
  pointeditorCtrl,
]);

function pointeditorCtrl(
  $scope,
  $rootScope,
  $sce,
  pointeditorService,
  modelService,
  utilService,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.isSmall = false;
  $scope.inEditMode = false;
  $scope.selection = [];
  $scope.item = null;
  $scope.instrumentaria = {
    parent: null,
    suggestedParent: null,
    list: null,
    selected: null,
    lastSelected: null,
    message: false,
  };
  $scope.parent = null;
  $scope.nietUitvoerbaar = false;

  $scope.toggleWidth = function () {
    $scope.isSmall = !$scope.isSmall;
    setWidth();
  };

  function setWidth() {
    if ($scope.isSmall) {
      document.getElementById('pointeditor').style.width = '250px';
    } else {
      document.getElementById('pointeditor').style.width = '750px';
    }
  }

  $rootScope.$on('model-tools', function (event, model) {
    if (!model.tools.has('POINTEDITOR')) {
      if ($scope.inEditMode) {
        $scope.toggleEditing();
      }
    }
  });
  $scope.close = function () {
    if ($scope.inEditMode) {
      $scope.toggleEditing();
    }
    modelService.update('model-tools', 'POINTEDITOR', 'DELETE');
  };

  $rootScope.$on('model-project', (_, model) => {
    if (model.tools.has('POINTEDITOR') && !model.project) {
      $scope.close();
      $scope.$apply();
    }
  });

  $scope.save = function (invalid) {
    if (!invalid) {
      try {
        $scope.item.behaalde_diepte_mmv = Number(
          $scope.item.behaalde_diepte_mmv.replace(',', '.'),
        );
        $scope.item.behaalde_diepte_mnap = Number(
          $scope.item.behaalde_diepte_mnap.replace(',', '.'),
        );
        // eslint-disable-next-line no-empty
      } catch {}
      const changes = utilService.changesById(
        angular.copy($scope.item),
        oldItems,
      );
      pointeditorService.saveItem(changes);
      toggleEditMode();
    }
  };

  $scope.toggleEditing = function (inactive) {
    if (!inactive) {
      toggleEditMode();
    }
  };

  function toggleEditMode() {
    $scope.inEditMode = !$scope.inEditMode;
    if ($scope.inEditMode) {
      document.getElementById('pointeditor-table').style.color = 'lightgrey';
      setTimeout(function () {
        var inputContainers = document.getElementsByClassName('setFocus');
        inputContainers[0].focus();
      }, 100);
    } else {
      try {
        $scope.item.behaalde_diepte_mmv = Number(
          $scope.item.behaalde_diepte_mmv.replace(',', '.'),
        );
        $scope.item.behaalde_diepte_mnap = Number(
          $scope.item.behaalde_diepte_mnap.replace(',', '.'),
        );
        // eslint-disable-next-line no-empty
      } catch {}
      document.getElementById('pointeditor-table').style.color = 'black';
    }
  }

  $scope.selectItemFromList = function (item) {
    if (item.id) {
      $scope.instrumentaria.suggestedParent = $scope.selection.find(
        (it) => it.naam == item.primair_onderzoek,
      );
      selectItem(item);
    }
  };

  $scope.onSelectInstrumentarium = function (item) {
    if (item.id) {
      if (
        $scope.instrumentaria.lastSelected &&
        item.id != $scope.instrumentaria.lastSelected.id
      ) {
        selectItem(item);
      } else {
        var model = modelService.getModel();
        showItems(model);
      }
    }
  };

  function selectItem(item) {
    $scope.instrumentaria.lastSelected = item;
    pointeditorService.selectItem(item);
  }

  $rootScope.$on('model-tools', function (event, model) {
    if (model.tools.has('POINTEDITOR')) {
      showItems(model);
    }
  });

  $rootScope.$on('model-selectie', function (event, model) {
    if (model.tools.has('POINTEDITOR')) {
      showItems(model);
    }
  });

  let oldItems;

  async function showItems(model) {
    const onderzoeken = await pointeditorService.getItems(model.selectie);
    oldItems = angular.copy(onderzoeken);
    if (onderzoeken.length == 0) {
      $scope.item = null;
      $scope.selection = onderzoeken;
      $scope.isSmall = true;
      setWidth();
    } else if (onderzoeken.length > 1) {
      $scope.item = null;
      $scope.selection = onderzoeken;
      $scope.isSmall = true;
      setWidth();
    } else {
      $scope.item = onderzoeken[0];
      $scope.selection = onderzoeken;
      $scope.isSmall = false;
      setWidth();
      var alleOnderzoeken = Object.values(
        $rootScope.refdata.onderzoekstype[$scope.item.onderzoekstype]
          .onderzoekstatus,
      );
      $scope.onderzoekstatusOptions = alleOnderzoeken.filter((o) => {
        return (
          o.naam == '99-N_UITV' ||
          o.naam == '3. UITV_OK' ||
          o.naam == $scope.item.onderzoekstatus
        );
      });
      $scope.nietUitvoerbaar = false;
      // $scope.nietUitvoerbaar = ($scope.item.onderzoekstatus == '99-N_UITV'); DEZE code moet aan als je wilt afdwingen dat afwijkingen ook ingevuld moet worden als status al nietuitvoerbaar was.
      if ($scope.item.primair) {
        if ($scope.instrumentaria.parent == null) {
          $scope.instrumentaria.parent = $scope.item;
          vernieuwInstrumentaria();
        } else if ($scope.instrumentaria.parent.id != $scope.item.id) {
          $scope.instrumentaria.parent = $scope.item;
          vernieuwInstrumentaria();
        } else {
          $scope.instrumentaria.selected = $scope.instrumentaria.list[0];
        }
      } else {
        if (
          $scope.instrumentaria.parent == null ||
          ($scope.instrumentaria.parent != null &&
            $scope.instrumentaria.parent.naam != $scope.item.primair_onderzoek)
        ) {
          if (
            $scope.instrumentaria.suggestedParent != null &&
            $scope.instrumentaria.suggestedParent.naam ==
              $scope.item.primair_onderzoek
          ) {
            $scope.instrumentaria.parent =
              $scope.instrumentaria.suggestedParent;
            vernieuwInstrumentaria();
          } else {
            $scope.instrumentaria.message = true;
            $scope.instrumentaria.list = [
              { naam: 'Te weinig gegevens om primair onderzoek te vinden' },
            ];
            $scope.instrumentaria.selected = $scope.instrumentaria.list[0];
          }
        } else {
          $scope.instrumentaria.selected = $scope.instrumentaria.list[0];
        }
      }
    }
    $scope.$apply();
  }

  async function vernieuwInstrumentaria() {
    const instrumentaria = await pointeditorService.getOnderzoekInstumentaria(
      $scope.instrumentaria.parent.id,
    );
    $scope.instrumentaria.children = instrumentaria;
    if (instrumentaria.length > 0) {
      $scope.instrumentaria.message = false;
      $scope.instrumentaria.list = [
        { naam: 'Kies een instrumentarium uit de lijst' },
      ];
      $scope.instrumentaria.list.push($scope.instrumentaria.parent);
      instrumentaria.forEach((instrumentarium) =>
        $scope.instrumentaria.list.push(instrumentarium),
      );
      $scope.instrumentaria.selected = $scope.instrumentaria.list[0];
    } else {
      $scope.instrumentaria.message = true;
      $scope.instrumentaria.list = [{ naam: 'Geen instrumentaria gevonden' }];
      $scope.instrumentaria.selected = $scope.instrumentaria.list[0];
    }
    $scope.$apply();
  }

  $scope.onderzoekstatusChanged = function () {
    $scope.nietUitvoerbaar = $scope.item.onderzoekstatus == '99-N_UITV';
    //if (($scope.nietUitvoerbaar) && ($scope.item.afwijkingen == '')) {
    //    TODO: activeer foutmelding op veld afwijkingen
    //}
  };

  $scope.float = function (value, digits) {
    return value != null ? Number(value).toFixed(digits) : 'null';
  };

  $scope.date = function (value) {
    var maand = [
      { m: '1', mm: '01', mmm: 'jan', mmmm: 'jabuari' },
      { m: '2', mm: '02', mmm: 'feb', mmmm: 'februari' },
      { m: '3', mm: '03', mmm: 'mrt', mmmm: 'maart' },
      { m: '4', mm: '04', mmm: 'apr', mmmm: 'april' },
      { m: '5', mm: '05', mmm: 'mei', mmmm: 'mei' },
      { m: '6', mm: '06', mmm: 'jun', mmmm: 'juni' },
      { m: '7', mm: '07', mmm: 'jul', mmmm: 'juli' },
      { m: '8', mm: '08', mmm: 'aug', mmmm: 'augustus' },
      { m: '9', mm: '09', mmm: 'sep', mmmm: 'september' },
      { m: '10', mm: '10', mmm: 'okt', mmmm: 'oktober' },
      { m: '11', mm: '11', mmm: 'nov', mmmm: 'november' },
      { m: '12', mm: '12', mmm: 'dec', mmmm: 'december' },
    ];
    var text = 'ONB';
    if (value) {
      try {
        var date = new Date(value);
        var day =
          date.getDate().length < 2 ? '0' + date.getDate() : date.getDate();
        var month = Number(date.getMonth());
        text = day + ' ' + maand[month].mmm + ' ' + date.getFullYear();
      } catch (error) {
        text = 'ONB';
      }
    }
    return text;
  };
}
