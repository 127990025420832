﻿import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import WKT from 'ol/format/WKT';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';

import iconPlaceSvg from '../../images/svgs/icon_place.svg';

window.app.controller('searchGenericCtrl', [
  '$scope',
  'modelService',
  'mapService',
  'xyzService',
  SearchGenericCtrl,
]);

function SearchGenericCtrl($scope, modelService, mapService, xyzService) {
  $scope.toggleSearch = () => {
    $scope.show = !$scope.show;
  };

  // ng-model="Search"
  $scope.Search = '';
  $scope.clearDisabled = true;
  $scope.loadDisabled = true;
  $scope.selectDisabled = true;

  $scope.clear = () => {
    // live-search-select="select"
    const clear = true;
    $scope.select(clear);
    $scope.clearDisabled = true;
    $scope.loadDisabled = true;
    $scope.selectDisabled = true;
    markerLayer.setMap(null);
    xyzService.removeLayer();
  };

  const wktFormat = new WKT(),
    markerFeature = new Feature(),
    markerLayer = new VectorLayer({
      title: 'marker',
      source: new VectorSource({
        features: [markerFeature],
      }),
    });

  markerFeature.setStyle(
    new Style({
      image: new Icon({
        src: iconPlaceSvg,
      }),
      text: new Text({
        font: '500 14px system-ui',
        offsetY: 22,
        stroke: new Stroke({
          width: 1,
          color: 'white',
        }),
      }),
    }),
  );

  function zoom(geometryOrExtent, options) {
    options = options || {};
    options.maxZoom = options.maxZoom || 15;
    mapService.fitToExtent(geometryOrExtent, options);
  }

  function mark(geometry, { caption }) {
    markerFeature.setGeometry(geometry);
    markerFeature.getStyle().getText().setText(caption);
    markerLayer.setMap(window.map);
    zoom(geometry);
  }

  let selection;
  $scope.load = (param) => {
    selection = param || selection;
    $scope.clearDisabled = false;
    $scope.loadDisabled = false;
    const currentProjection = window.map.getView().getProjection();
    switch (selection.type) {
      case 'ONDERZOEK':
        (function () {
          const { id, x, y } = selection;
          const layer = 'Meetpunten';
          mapService.setHighlight(layer);
          const geometry = new Point([x, y]);
          geometry.transform('EPSG:4326', currentProjection);
          zoom(geometry, { layer });
          const onderzoekSelectedFromMap = true;
          modelService.update('model-selectie', [id], onderzoekSelectedFromMap);
          setTimeout(() => {
            // FIXME. When the ONDERZOEKEN grid wasn't active, it will
            // update model-selectie itself during grid setup, and the
            // highlights of the grid row and the map feature fail.
            modelService.update(
              'model-selectie',
              [id],
              onderzoekSelectedFromMap,
            );
          }, 2000);
        })();
        break;

      case 'LOCATION':
        (function () {
          const geometry = wktFormat.readGeometry(selection.wkt, {
            dataProjection: 'EPSG:28992',
            featureProjection: currentProjection,
          });
          mark(geometry, selection);
        })();
        break;

      case 'COORDINATE':
        (function () {
          const geometry = selection.geometry.clone();
          geometry.transform('EPSG:4326', currentProjection);
          mark(geometry, selection);
        })();
        break;

      case 'MATRIX':
        xyzService.loadLayer(selection);
        break;
    }
  };

  $scope.SearchCallback = ({ selected, query, feed }) => {
    if (selected) {
      $scope.load(selected);
      return;
    }

    const lines = query.split(/\s*\r?\n\s*/);
    let matrix = lines.map((row) => row.split(/\s*,\s*/));
    function isXYFormat() {
      const coordinate = /^\d+([.,]\d+)?$/;
      return !matrix.filter(
        (row) => !coordinate.test(row[1]) || !coordinate.test(row[2]),
      ).length;
    }
    if (!isXYFormat()) {
      matrix = lines.map((row) => row.split(/\s+/));
    }
    if (isXYFormat()) {
      $scope.load(Object.defineProperty(matrix, 'type', { value: 'MATRIX' }));
      document.activeElement.blur();
      $scope.Search = 'XY-data';
      return;
    }

    if (/^\d+(\.\d+)?(\s*,\s*|\s+)\d+(\.\d+)?$/.test(query)) {
      feedPromise(searchCoordinate);
    } else {
      feedPromise(searchOnderzoek);
      feedPromise(searchLocation);
    }

    async function feedPromise(promiseFunction) {
      $scope.selectDisabled = true;
      if (!feed) {
        return;
      }
      const data = await promiseFunction(query);
      feed(query, data);
      if (data.length) {
        $scope.selectDisabled = false;
        $scope.$apply();
      }
    }
  };

  function searchOnderzoek(query) {
    return fetch('/api/zoek/onderzoek?query=' + query)
      .then((response) => response.json())
      .then((data) =>
        (data || []).map((item) => ({
          id: item.id,
          type: 'ONDERZOEK',
          caption: item.caption,
          toonString: `${item.bron} ${item.caption}`,
          x: item.x,
          y: item.y,
        })),
      );
  }

  function searchLocation(query) {
    return fetch(
      'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?q=' + query,
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .then((data) =>
        data.response.docs.map((item) => ({
          type: 'LOCATION',
          locationType: item.type,
          caption: item.weergavenaam,
          toonString: item.weergavenaam,
          wkt: item.centroide_rd,
        })),
      );
  }

  function searchCoordinate(query) {
    const [RD, WGS84] = ['EPSG:28992', 'EPSG:4326'];
    let [source, destination] = [RD, WGS84],
      [x, y] = query
        .replace(',', ' ')
        .split(/\s+/)
        .map((ordinate) => parseFloat(ordinate));
    if (x > 300000) {
      [x, y] = [y, x];
      if (x < 0 || x > 300000 || y < 300000 || y > 700000) {
        return Promise.resolve([]);
      }
    }
    if (y < 300000) {
      [source, destination] = [destination, source];
      if (y > 90 || y < -90) {
        [x, y] = [y, x];
      }
      if (x < -180 || x > 180 || y < -90 || y > 90) {
        return Promise.resolve([]);
      }
    }
    function round(coordinate, projection) {
      return coordinate.map((ordinate) =>
        ordinate.toFixed(projection === RD ? 2 : 5),
      );
    }
    const sourcePoint = new Point([x, y]),
      destinationPoint = sourcePoint.clone().transform(source, destination),
      [x2, y2] = round(destinationPoint.getFirstCoordinate(), destination);
    [x, y] = round([x, y], source);
    return Promise.resolve([
      {
        type: 'COORDINATE',
        caption: `${x} ${y}`,
        toonString: `${x} ${y} (${x2} ${y2})`,
        geometry: source === WGS84 ? sourcePoint : destinationPoint,
      },
    ]);
  }
}
