﻿import src from './tabbladen.html?url';
import srcStreetsmart from '../streetsmart/streetsmart.html?url';
import srcStreetview from '../streetview/streetview.html?url';

window.app.controller('tabbladenCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  'modelService',
  'utilService',
  'streetsmartService',
  'streetviewService',
  tabbladenCtrl,
]);

function tabbladenCtrl(
  $scope,
  $rootScope,
  $sce,
  modelService,
  utilService,
  streetsmartService,
  streetviewService,
) {
  const Tabs = Object.freeze({
    PROJECT: 0,
    ONDERZOEKEN: 1,
    METADATA: 2,
    STREETSMART: 3,
    STREETVIEW: 4,
    PROJECT_ALGEMEEN: 0,
    PROJECT_DOCUMENTEN: 1,
    PROJECT_PLANTEKENINGEN: 2,
    PROJECT_KLIC: 3,
  });

  $scope.src = $sce.trustAsResourceUrl(src);
  $scope.srcStreetsmart = $sce.trustAsResourceUrl(srcStreetsmart);
  $scope.srcStreetview = $sce.trustAsResourceUrl(srcStreetview);

  $scope.projectlabel = 'project';
  $scope.selectedTab = Tabs.PROJECT;

  $rootScope.$on('model-tools', (_, model) => {
    if (model.tools.has('POINTEDITOR')) {
      $scope.selectedTab = Tabs.PROJECT; // ["PROJECT"];
    }
  });

  $rootScope.$on('model-selectie', (_, model) => {
    if (!model.tools.has('POINTEDITOR')) {
      if (
        model.onderzoekSelectedFromMap &&
        !model.grid.METADATA &&
        !model.grid.ONDERZOEKEN
      ) {
        $scope.selectedTab = Tabs['ONDERZOEKEN'];
      }
    }
  });

  $rootScope.$on('model-project', (_, model) => {
    const project = model.project;
    if (project) {
      $scope.projectlabel = project.dspprojectnr;
      if (project.showTab) {
        $scope.selectedTab = Tabs.PROJECT;
      }
    } else {
      $scope.projectlabel = 'project';
    }
  });

  $scope.initProjectTabbladen = function () {
    workAroundInvisibleTabs();
    modelService.update('model-projectTabs');
  };

  $scope.streetsmartInitiated = false;
  $scope.InitStreetSmart = function () {
    $scope.streetsmartInitiated = true;
    streetsmartService.initStreetsmart();
  };
  $scope.DestroyStreetSmart = function () {
    streetsmartService.destroyStreetsmart();
  };

  $scope.InitStreetView = function () {
    streetviewService.initStreetview();
  };
  $scope.DestroyStreetView = function () {
    streetviewService.destroyStreetview();
  };

  $scope.InitGrid = function (element, type) {
    workAroundInvisibleTabs();
    utilService.elementPromise(element).then(() => {
      modelService.update('model-grid', type, element);
    });
  };
  $scope.DestroyGrid = function () {
    modelService.update('model-grid', 'NONE');
  };

  // tabs with controller-controlled content sometimes don't show up
  function workAroundInvisibleTabs() {
    let tainted;
    function taint() {
      tainted = !tainted;
      document
        .querySelectorAll('md-tabs-content-wrapper')
        .forEach((element) => {
          const style = element.style;
          if (tainted) {
            style.visibility = 'hidden';
            style.position = 'unset';
          } else {
            style.position = '';
            style.visibility = '';
          }
        });
    }
    setTimeout(() => {
      taint();
      setTimeout(taint, 50);
    }, 50);
  }
}
