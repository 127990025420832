﻿window.app.service('measureService', [
  'mapToolsService',
  'modelService',
  measureService,
]);

function measureService(mapToolsService, modelService) {
  var _measures = [];
  var measureService = {
    hasMeasures,
    addMeasure,
    removeMeasure,
    clearMeasures,
  };

  function hasMeasures() {
    return _measures.length > 0;
  }

  function clearMeasures() {
    for (var i in _measures) {
      modelService.update('model-tools', _measures[i], 'DELETE');
    }
    _measures = [];
    mapToolsService.EraseMeasures();
  }

  function addMeasure(tool) {
    _measures.push(tool);
  }

  function removeMeasure(tool) {
    var index = _measures.indexOf(tool);
    if (index >= 0) {
      _measures.splice(index, 1);
    }
  }

  return measureService;
}
