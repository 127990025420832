﻿const Slick = window.Slick;

window.app.factory('gridPlantekeningen', [
  '$rootScope',
  'modelService',
  'gridFactory',
  gridPlantekeningen,
]);

function gridPlantekeningen($rootScope, modelService, gridFactory) {
  const API = {
    setGrouping,
    getGroupings,
    getViews,
    setView,
    getPlantekeningen,
    refresh,
  };

  const columnObjects = {
    tonen: {
      formatter: Slick.Formatters.Tonen,
      asyncPostRender: gridFactory.tonenPostRender,
      name: '',
      toolTip: 'Tonen/verbergen op de kaart',
      width: 30,
      minWidth: 30,
      maxWidth: 30,
    },
    naam: {
      name: 'Aanduiding',
      toolTip: 'Beschrijvende tekst uit het bestand',
      width: 300,
      minWidth: 100,
      maxWidth: 400,
    },
    bestandsnaam: {
      name: 'Bestandsnaam',
      width: 300,
      minWidth: 100,
      maxWidth: 400,
    },
    gebruiker: {
      name: 'Gebruiker',
      toolTip: 'Bestand geüpload door:',
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    tijdstip: {
      name: 'Datum',
      toolTip: 'Bestand geüpload op:',
      formatter: Slick.Formatters.DateTime,
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    x: {
      name: 'X',
      toolTip: 'Situering',
      formatter: Slick.Formatters.Integer,
      width: 100,
      minWidth: 100,
      maxWidth: 300,
    },
    y: {
      name: 'Y',
      toolTip: 'Situering',
      formatter: Slick.Formatters.Integer,
      width: 100,
      minWidth: 100,
      maxWidth: 300,
    },
  };

  const columns = [];

  Object.keys(columnObjects).forEach((key) => {
    const column = columnObjects[key];
    column.id = column.id || key;
    columns.push(newColumn(column));
  });

  function newColumn(column) {
    function defaultValue(key, value) {
      if (column[key] === undefined) {
        column[key] = value;
      }
    }
    defaultValue('name', column.id);
    defaultValue('field', column.id);
    defaultValue('formatter', Slick.Formatters.Text);
    defaultValue('resizable', true);
    defaultValue('sortable', true);
    defaultValue('filterable', true);
    defaultValue('filter', 'Text');
    return column;
  }

  let grid = {},
    gridfactory;

  initGrid(modelService.getModel());

  function init(model) {
    grid = model.grid;
    return grid.PLANTEKENINGEN || grid.PROJECTKAARTEN;
  }

  function initGrid(model) {
    if (init(model)) {
      const options = {
        editable: true,
        autoEdit: true,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 25,
        enableColumnReorder: true,
        showHeaderRow: true,
        enableAsyncPostRender: true,
      };

      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(grid.value, columns, options, grid.key)
        .then(() => {
          setView(0);
          requestPlantekeningData(model);
        });
    }
  }

  $rootScope.$on('model-grid', (_, model) => {
    initGrid(model);
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      requestPlantekeningData(model);
    }
  });

  let plantekeningen;

  function getPlantekeningen(ids) {
    return (plantekeningen || []).filter(
      (item) => !ids || ids.includes(0 + item.id),
    ); // "1"
  }

  function refresh(model) {
    return requestPlantekeningData(model);
  }

  function requestPlantekeningData(model) {
    gridfactory
      .getPlantekeningen(model)
      .then((data) => {
        plantekeningen = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const groupings = [{ id: 'geen', label: 'Niets' }];

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping, options) {
    gridfactory.setGrouping(grouping, options);
  }

  const views = [
    {
      id: 'Standaard',
      label: 'Standaard',
      columns: Object.keys(columnObjects),
    },
  ];

  function getViews() {
    return views;
  }

  function setView(viewIndex) {
    gridfactory.setView(grid.value, columns, views, viewIndex);
  }

  return API;
}
