const angular = window.angular;

angular.module('LiveSearch', ['ng']).directive('liveSearch', [
  '$rootScope',
  '$compile',
  '$timeout',
  function ($rootScope, $compile, $timeout) {
    'use strict';
    return {
      restrict: 'E',
      replace: true,
      scope: {
        liveSearchCallback: '=',
        liveSearchSelect: '=?',
        liveSearchItemTemplate: '@',
        liveSearchWaitTimeout: '=?',
        liveSearchMaxResultSize: '=?',
      },
      template: `<input
                type="text"
                style="padding-left: .3rem;"
            />`,
      /*jshint unused: vars */
      setElement: function () {},
      link: function (scope, element) {
        scope.results = [];
        scope.visible = false;
        scope.selectedIndex = -1;

        scope.select = function (index) {
          var item = scope.results[index];
          scope.selectedIndex = index;
          scope.liveSearchCallback.call(null, { selected: item });
          finish(item.caption);
        };

        scope.liveSearchSelect = (clear) => {
          if (clear) {
            scope.results = [];
            scope.selectedIndex = -1;
            finish('');
          } else if (scope.selectedIndex >= 0) {
            scope.select(scope.selectedIndex);
            return true;
          } else if (scope.results.length > 0) {
            scope.select(0);
            return true;
          }
        };

        var oldVal = '';
        function finish(val) {
          if (val || val === '') {
            element.val(val);
            oldVal = val;
          } else {
            element.val(oldVal);
          }
          scope.visible = false;
          element[0].blur();
          resizeInput();
        }

        function resizeInput() {
          element[0].size = element.val().length || 1;
        }

        element[0].addEventListener('input', resizeInput);
        // event above not fired on $scope.Search = xxx
        setInterval(resizeInput, 500);

        scope.$watch('visible', function (newValue, oldValue) {
          if (newValue !== oldValue) {
            if (newValue === false) {
              return;
            }
            scope.width = element[0].clientWidth;
            const offset = getPosition(element[0]),
              top = offset.y + element[0].clientHeight + 1,
              left = offset.x;
            scope.top = top + 'px';
            scope.left = left + 'px';
            scope.maxHeight = window.innerHeight - top + 'px';
            scope.maxWidth = window.innerWidth - left + 'px';
          }
        });

        element[0].onkeydown = function (e) {
          switch (e.key) {
            case 'ArrowDown':
              if (scope.selectedIndex + 1 === scope.results.length) {
                scope.selectedIndex = 0;
              } else {
                scope.selectedIndex++;
              }
              scope.$apply();
              break;
            case 'ArrowUp':
              if (scope.selectedIndex === 0) {
                scope.selectedIndex = scope.results.length - 1;
              } else if (scope.selectedIndex === -1) {
                scope.selectedIndex = 0;
              } else {
                scope.selectedIndex--;
              }
              scope.$apply();
              break;
            case ' ': // Space is seen as click event and by default does not get put into the textbox, we do want that here
              e.stopPropagation();
              break;
          }
        };

        element[0].onpaste = (e) => {
          search(e.clipboardData.getData('text'));
        };

        element[0].onkeyup = function (e) {
          if (e.key === 'Enter' || e.key === 'Tab') {
            if (scope.liveSearchSelect()) {
              scope.$apply();
            }
            return false;
          }
          if (e.key === 'Escape') {
            finish();
            return false;
          }
          // Ctrl as in Ctrl-V, and otherwise: only accept single character
          // input keys (letters, digits, symbols, space); not "special" keys.
          // But, on Chrome Android, e.key is always 'Unidentified', and e.code
          // is always '' (and e.keyCode is always 229).
          // https://www.google.com/search?q=android+chrome+keyboardevent+keycode+site:stackoverflow.com
          if (e.code && (e.ctrlKey || e.key.length > 1)) {
            return false;
          }

          var target = element;
          // Set Search String
          search(target.val());
        };

        let timeout;
        let currentQuery;

        function search(query) {
          query = query.trim();
          currentQuery = query;
          $timeout.cancel(timeout);
          scope.results = [];
          scope.selectedIndex = -1;
          scope.visible = false;
          scope.$apply();
          timeout = $timeout(
            () =>
              scope.liveSearchCallback.call(null, {
                query,
                feed: query.length >= 3 ? feed : undefined,
              }),
            scope.liveSearchWaitTimeout || 100,
          );
        }

        function feed(givenQuery, data) {
          // prevent appending trailing results from earlier queries
          if (givenQuery === currentQuery) {
            scope.results = scope.results.concat(
              (data || []).slice(0, (scope.liveSearchMaxResultSize || 20) - 1),
            );
            scope.visible = !!scope.results.length;
            scope.$apply();
          }
        }

        var getPosition = function (element) {
          var xPosition = 0;
          var yPosition = 0;

          while (element) {
            xPosition +=
              element.offsetLeft - element.scrollLeft + element.clientLeft;
            yPosition +=
              element.offsetTop - element.scrollTop + element.clientTop;
            element = element.offsetParent;
          }
          return { x: xPosition, y: yPosition };
        };

        var itemTemplate =
          element.attr('live-search-item-template') || '{{result}}';
        var template = `
                    <ul
                        ng-show='visible'
                        ng-style="{'top':top, 'left':left, 'min-width':width, 'max-height':maxHeight, 'max-width':maxWidth, 'overflow':'auto', 'white-space':'nowrap', 'padding':'0.5em'}"
                        class='searchresultspopup'
                    >
                        <li
                            ng-class="selectedIndex === $index ? 'selected' : ''"
                            ng-click='select($index)'
                            ng-repeat='result in results'
                        >
                            ${itemTemplate}
                        </li>
                    </ul>
                `;
        var searchPopup = $compile(template)(scope);
        document.body.appendChild(searchPopup[0]);
      },
    };
  },
]);
