﻿import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import * as olProj from 'ol/proj';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import Collection from 'ol/Collection';
import xyzDialogHtml from './xyzDialog.html?url';

const toastr = window.toastr;

window.app.service('xyzService', [
  '$rootScope',
  '$mdDialog',
  '$sce',
  'userAccountService',
  'mapService',
  'utilService',
  'gridOnderzoeken',
  xyzService,
]);

function xyzService(
  $rootScope,
  $mdDialog,
  $sce,
  userAccountService,
  mapService,
  utilService,
  gridOnderzoeken,
) {
  const Service = {
    run,
    loadLayer,
    removeLayer: () => setLayerActive(false),
  };

  function loadLayer(fileOrMatrix) {
    if (fileOrMatrix._loaded) {
      setLayerActive(true, fileOrMatrix);
      return;
    }
    run(fileOrMatrix, (err, result) => {
      if (err) {
        alert(err.message);
      } else {
        setLayerActive(true, result);
        fileOrMatrix._loaded = true;
      }
    });
  }

  function setLayerActive(active, opt_result) {
    if (layer) {
      layer.setMap(null);
      // layer.getSource().clear();
      source.clear();
      zoomExtent = null;
    }
    if (active && opt_result) {
      opt_result.forEach((e, i) =>
        source.addFeature(
          new Feature({
            id: i,
            naam: e.naam,
            geometry: new Point([e.x, e.y]),
          }),
        ),
      );
      layer.setMap(window.map);
      zoom();
    }
  }

  const source = new VectorSource({
    features: new Collection([]),
  });

  const layer = new VectorLayer({
    title: 'xyzFile',
    visible: true,
    source,
    style,
  });

  const getDefaultStyles = new VectorLayer().getStyleFunction();

  function textStyle(text) {
    return new Text({
      text,
      font: '500 14px system-ui',
      offsetY: 22,
      stroke: new Stroke({
        width: 1,
        color: 'white',
      }),
    });
  }

  function style(feature, resolution) {
    const defaultStyle = getDefaultStyles(feature, resolution)[0],
      image = new CircleStyle({
        fill: defaultStyle.getFill(),
        stroke: defaultStyle.getStroke(),
        radius: 10,
      }),
      text = resolution > 2 ? null : textStyle(feature.get('naam'));
    return new Style({ text, image });
  }

  let zoomExtent;
  const ZOOM_MARGIN = 0.1;
  function zoom() {
    function getExtent() {
      if (!zoomExtent) {
        const ex = source.getExtent();
        const minXY = { x: ex[0], y: ex[1] };
        const maxXY = { x: ex[2], y: ex[3] };
        const xMargin = (maxXY.x - minXY.x) * ZOOM_MARGIN;
        const yMargin = (maxXY.y - minXY.y) * ZOOM_MARGIN;
        zoomExtent = [
          minXY.x - xMargin,
          minXY.y - yMargin,
          maxXY.x + xMargin,
          maxXY.y + yMargin,
        ];
      }
      return zoomExtent;
    }
    mapService.fitToExtent(getExtent());
  }

  async function run(fileOrMatrix, callback, projectId) {
    let data = fileOrMatrix,
      file = fileOrMatrix;
    if (file instanceof File) {
      data = await userAccountService.postXYZ(file);
    }
    if (data) {
      show(file, data, callback, projectId);
    }
  }

  function show(file, data, callback, projectId) {
    var sheets = data.sheets;
    var matrix = sheets ? [] : data;
    $mdDialog.show({
      templateUrl: $sce.trustAsResourceUrl(xyzDialogHtml),
      controllerAs: 'xyz',
      controller: controller(file, sheets, matrix, callback, projectId),
    });
  }

  function controller(file, sheets, matrix, callback, projectId) {
    function controller($scope, $mdDialog) {
      const dollarScope = $scope;
      var scope = this;
      this.projectId = projectId;
      this.refdata = $rootScope.refdata;
      this.file = file;
      this.nav = 'data';
      this.choices = [];
      this.selectedColumns =
        projectId && !matrixHas3Columns()
          ? [
              'inmeetlocatie',
              'naam',
              'x',
              'y',
              'z',
              'tagklant',
              'diepte',
              'behaalde_diepte_mmv',
              'behaalde_diepte_mnap',
              'nge',
              'schouwresultaten',
              'eigenaar',
              'onderzoeksbron',
              'opmerkingen',
              'opmerkingen2',
              'opmerkingen3',
            ]
          : ['naam', 'x', 'y'];
      this.selectedColumn = function (index) {
        var val = this.selectedColumns[index];
        scope.selectedColumns.forEach(function (e, i, a) {
          if (e === val && i !== index) {
            a[i] = '';
          }
        });
      };
      function setSelectedColumn(index, value) {
        scope.selectedColumns[index] = value;
        scope.selectedColumn(index);
      }
      scope.initMatrix = function (matrix) {
        this.matrix = matrix;
        this.numRows = matrix.length;
        var columns;
        this.columns = function () {
          if (columns) {
            return columns;
          }
          columns = [];
          matrix.forEach(function (row) {
            while (row.length > columns.length) {
              columns.push(columns.length);
            }
          });
          scope.selectedColumns.length = columns.length;
          return columns;
        };
        const firstRow = matrix[0] || [];
        const columnDefs = gridOnderzoeken.getColumns();
        firstRow.forEach((value, index) => {
          const columnDef = columnDefs.find(
            ({ id, name }) => id === value || name === value,
          );
          if (columnDef) {
            setSelectedColumn(index, columnDef.id);
          }
        });
      };
      scope.initMatrix(matrix);
      this.sheets = sheets;
      this.xlsx = function () {
        userAccountService
          .postXYZ(scope.file, scope.sheet)
          .then(function (matrix) {
            scope.sheets = null;
            scope.initMatrix(matrix);
          });
      };
      this.close = function () {
        $mdDialog.cancel();
      };
      this.maxNaamLength = 0;
      this.z = false;
      this.tagklant = false;
      this.inmeetlocatie = false;
      function Choice(
        naam,
        x,
        y,
        z,
        tagklant,
        inmeetlocatie,
        diepte,
        behaalde_diepte_mmv,
        behaalde_diepte_mnap,
        nge,
        schouwresultaten,
        eigenaar,
        onderzoeksbron,
        opmerkingen,
        opmerkingen2,
        opmerkingen3,
        xystatus,
        xymethode,
        zoorsprong,
        zsysteem,
        zmethode,
        match,
      ) {
        function values() {
          return {
            naam,
            x,
            y,
            z,
            tagklant,
            inmeetlocatie,
            diepte,
            behaalde_diepte_mmv,
            behaalde_diepte_mnap,
            nge,
            schouwresultaten,
            eigenaar,
            onderzoeksbron,
            opmerkingen,
            opmerkingen2,
            opmerkingen3,
          };
        }
        this.start = values();
        this.new = values();
        this.match = match;
        this.selected = true;
        this.use = match ? 'match' : 'new';
        this.xystatus = xystatus;
        this.xymethode = xymethode;
        this.zoorsprong = zoorsprong;
        this.zmethode = zmethode;
        this.zsysteem = zsysteem;
        this.naam = function () {
          if (this.use === 'match') {
            return this.match.naam.toUpperCase();
          } else {
            return this.new.naam.toUpperCase();
          }
        };
      }
      this.duplicates = function () {
        var namen = {};
        scope.choices.forEach(function (c) {
          var naam = c.naam();
          if (c.selected) {
            namen[naam] = namen[naam] ? ++namen[naam] : 1;
          }
        });
        scope.choices.forEach(function (c) {
          c.duplicate = namen[c.naam()] > 1;
        });
      };
      function processInput() {
        function numberFormat(text, decimals) {
          if (!text) {
            return NaN;
          }
          // Vervang komma's door punten
          text = text.replace(',', '.');
          // Verwijder alle punten, behalve de laatste.
          // Nodig door uitlezen getallen van Excel.
          // Toegepast voor x, y, z.
          var index = text.lastIndexOf('.');
          if (index >= 0) {
            var head = text.slice(0, index);
            var tail = text.slice(index);
            text = head.replace(/\./g, '') + tail;
          }
          const number = Number(text);
          return isNaN(number)
            ? NaN
            : number.toFixed(decimals || (lonLat ? 5 : 3));
        }
        var columns = {
          naam: scope.selectedColumns.indexOf('naam'),
          x: scope.selectedColumns.indexOf('x'),
          y: scope.selectedColumns.indexOf('y'),
          z: scope.selectedColumns.indexOf('z'),
          tagklant: scope.selectedColumns.indexOf('tagklant'),
          inmeetlocatie: scope.selectedColumns.indexOf('inmeetlocatie'),
          diepte: scope.selectedColumns.indexOf('diepte'),
          behaalde_diepte_mmv: scope.selectedColumns.indexOf(
            'behaalde_diepte_mmv',
          ),
          behaalde_diepte_mnap: scope.selectedColumns.indexOf(
            'behaalde_diepte_mnap',
          ),
          nge: scope.selectedColumns.indexOf('nge'),
          schouwresultaten: scope.selectedColumns.indexOf('schouwresultaten'),
          eigenaar: scope.selectedColumns.indexOf('eigenaar'),
          onderzoeksbron: scope.selectedColumns.indexOf('onderzoeksbron'),
          opmerkingen: scope.selectedColumns.indexOf('opmerkingen'),
          opmerkingen2: scope.selectedColumns.indexOf('opmerkingen2'),
          opmerkingen3: scope.selectedColumns.indexOf('opmerkingen3'),
        };
        scope.xy = columns.x != -1 && columns.y != -1;
        scope.z = columns.z != -1;
        scope.tagklant = columns.tagklant != -1;
        scope.inmeetlocatie = columns.inmeetlocatie != -1;
        scope.diepte = columns.diepte != -1;
        scope.behaalde_diepte_mmv = columns.behaalde_diepte_mmv != -1;
        scope.behaalde_diepte_mnap = columns.behaalde_diepte_mnap != -1;
        scope.nge = columns.nge != -1;
        scope.schouwresultaten = columns.schouwresultaten != -1;
        scope.eigenaar = columns.eigenaar != -1;
        scope.onderzoeksbron = columns.onderzoeksbron != -1;
        scope.opmerkingen = columns.opmerkingen != -1;
        scope.opmerkingen2 = columns.opmerkingen2 != -1;
        scope.opmerkingen3 = columns.opmerkingen3 != -1;
        var maxX = -180,
          maxY = -180;
        scope.matrix.forEach(function (row) {
          const x = numberFormat(row[columns.x]),
            y = numberFormat(row[columns.y]);
          maxY = y > maxY ? y : maxY;
          maxX = x > maxX ? x : maxX;
        });
        var lonLat = maxX < 180 && maxY < 180;
        if (lonLat && maxX > maxY) {
          var xColumn = columns.x,
            yColumn = columns.y;
          columns.x = yColumn;
          columns.y = xColumn;
        }
        scope.matrix = scope.matrix
          .map(function (row) {
            const x = numberFormat(row[columns.x]),
              y = numberFormat(row[columns.y]);
            if (!isNaN(x) && !isNaN(y)) {
              const coordinate = lonLat
                ? olProj.fromLonLat(
                    [x, y],
                    window.map.getView().getProjection(),
                  )
                : [x, y];
              row.x = coordinate[0];
              row.y = coordinate[1];
              row[columns.x] = row.x;
              row[columns.y] = row.y;
            }
            row.z = numberFormat(row[columns.z], 3);
            row[columns.z] = row.z;
            row.naam = row[columns.naam];
            row.tagklant = row[columns.tagklant];
            row.inmeetlocatie = row[columns.inmeetlocatie];
            row.diepte = row[columns.diepte];
            row.behaalde_diepte_mmv = numberFormat(
              row[columns.behaalde_diepte_mmv],
              3,
            );
            row.behaalde_diepte_mnap = numberFormat(
              row[columns.behaalde_diepte_mnap],
              3,
            );
            row.nge = row[columns.nge];
            row.schouwresultaten = row[columns.schouwresultaten];
            row.eigenaar = row[columns.eigenaar];
            row.onderzoeksbron = row[columns.onderzoeksbron];
            row.opmerkingen = row[columns.opmerkingen];
            row.opmerkingen2 = row[columns.opmerkingen2];
            row.opmerkingen3 = row[columns.opmerkingen3];
            return row;
          })
          .filter((row) => {
            return row;
          });
      }
      this.read = function () {
        processInput();
        const result = [];
        scope.matrix.forEach(function (row) {
          var x = row.x,
            y = row.y,
            naam = row.naam;
          result.push({
            x: x,
            y: y,
            naam: naam || '(' + x + ',' + y + ')',
          });
        });
        const err = result.length
          ? null
          : { message: 'Geen meetpunten gelezen' };
        setTimeout(() => {
          callback(err, result);
        });
        $mdDialog.hide();
      };
      this.match = function () {
        processInput();
        scope.matching = 'busy';
        var naamIndex = {};
        var naamLijst = [];
        scope.matrix.forEach(function (row, i) {
          var naam = row.naam;
          naamIndex[naam] = naamIndex[naam] || [];
          naamIndex[naam].push(i);
          naamLijst.push(naam);
        });
        userAccountService
          .matchOnderzoeken(projectId, naamLijst)
          .then(function (results) {
            var choices = [];
            results.forEach(function (result) {
              scope.maxNaamLength = Math.max(
                scope.maxNaamLength,
                result.naam.length,
              );
              naamIndex[result.naam].forEach(function (i) {
                var row = scope.matrix[i];
                choices[i] = new Choice(
                  result.naam,
                  row.x,
                  row.y,
                  row.z,
                  row.tagklant,
                  row.inmeetlocatie,
                  row.diepte,
                  row.behaalde_diepte_mmv,
                  row.behaalde_diepte_mnap,
                  row.nge,
                  row.schouwresultaten,
                  row.eigenaar,
                  row.onderzoeksbron,
                  row.opmerkingen,
                  row.opmerkingen2,
                  row.opmerkingen3,
                  result.xystatus,
                  result.xymethode,
                  result.zoorsprong,
                  result.zsysteem,
                  result.zmethode,
                  result.id
                    ? {
                        id: result.id,
                        naam: result.bestaand,
                        x: result.x,
                        y: result.y,
                        z: result.z,
                      }
                    : null,
                );
              });
            });
            choices.forEach(function (c) {
              //sparse
              if (c && (scope.xy || c.match)) {
                scope.choices.push(c); //dense
              }
            });
            scope.duplicates();
            scope.matching = 'done';
            scope.matrix = [];
            try {
              dollarScope.$apply();
              // eslint-disable-next-line no-empty
            } catch {}
          });
      };
      this.distance = function (choice) {
        if (choice && choice.match) {
          var distance = Math.sqrt(
            Math.pow(choice.new.x - choice.match.x, 2) +
              Math.pow(choice.new.y - choice.match.y, 2),
          );
          return Number(distance).toFixed(3);
        } else {
          return '';
        }
      };
      this.selected = true;
      this.setAll = function (key) {
        scope.choices.forEach(function (c) {
          c[key] = scope[key];
        });
        if (key !== 'selected') {
          scope[key] = undefined;
        }
      };
      this.ignoreErrors = true;
      this.save = function () {
        scope.saving = 'busy';
        var processed = 0;
        function process(num) {
          processed += num;
          if (processed === scope.choices.length) {
            var remaining = [];
            scope.choices.forEach(function (c) {
              if (!c.saved) {
                remaining.push(c);
              }
            });
            scope.choices = remaining;
            scope.saving = 'done';
            setTimeout(callback);
            if (scope.choices.length === 0) {
              $mdDialog.hide();
            } else if (scope.ignoreErrors) {
              scope.ignoreErrors = false;
              setTimeout(scope.save, 1000);
            }
            try {
              dollarScope.$apply();
              // eslint-disable-next-line no-empty
            } catch {}
          }
        }
        var updates = [];
        scope.choices
          .sort(function (a, b) {
            if (a.new.naam < b.new.naam) {
              return -1;
            } else if (a.new.naam > b.new.naam) {
              return 1;
            } else {
              return 0;
            }
          })
          .forEach(function (c) {
            if (!c.selected) {
              process(1);
              return;
            }
            if (c.use === 'match') {
              var onderzoek = {
                id: 1 * c.match.id,
              };
              if (scope.xy) {
                onderzoek.x = 1 * c.new.x;
                onderzoek.y = 1 * c.new.y;
                onderzoek.xystatus = c.xystatus;
                onderzoek.xymethode = c.xymethode;
                onderzoek.xyopmerking = scope.file.name;
              }
              if (c.new.z && c.new.z.length) {
                onderzoek.z = 1 * c.new.z;
                onderzoek.zoorsprong = c.zoorsprong;
                onderzoek.zsysteem = c.zsysteem;
                onderzoek.zmethode = c.zmethode;
                onderzoek.zopmerking = scope.file.name;
              }
              if (c.new.tagklant && c.new.tagklant.length) {
                onderzoek.tagklant = c.new.tagklant;
              }
              if (c.new.inmeetlocatie && c.new.inmeetlocatie.length) {
                onderzoek.inmeetlocatie = c.new.inmeetlocatie;
              }
              if (c.new.diepte && c.new.diepte.length) {
                onderzoek.diepte = c.new.diepte;
              }
              if (
                c.new.behaalde_diepte_mmv &&
                c.new.behaalde_diepte_mmv.length
              ) {
                onderzoek.behaalde_diepte_mmv = c.new.behaalde_diepte_mmv;
              }
              if (
                c.new.behaalde_diepte_mnap &&
                c.new.behaalde_diepte_mnap.length
              ) {
                onderzoek.behaalde_diepte_mnap = c.new.behaalde_diepte_mnap;
              }
              if (c.new.nge && c.new.nge.length) {
                onderzoek.nge = c.new.nge;
              }
              if (c.new.schouwresultaten && c.new.schouwresultaten.length) {
                onderzoek.schouwresultaten = c.new.schouwresultaten;
              }
              if (c.new.eigenaar && c.new.eigenaar.length) {
                onderzoek.eigenaar = c.new.eigenaar;
              }
              if (c.new.onderzoeksbron && c.new.onderzoeksbron.length) {
                onderzoek.onderzoeksbron = c.new.onderzoeksbron;
              }
              if (c.new.opmerkingen && c.new.opmerkingen.length) {
                onderzoek.opmerkingen = c.new.opmerkingen;
              }
              if (c.new.opmerkingen2 && c.new.opmerkingen2.length) {
                onderzoek.opmerkingen2 = c.new.opmerkingen2;
              }
              if (c.new.opmerkingen3 && c.new.opmerkingen3.length) {
                onderzoek.opmerkingen3 = c.new.opmerkingen3;
              }
              updates.push(onderzoek);
            } else if (!validate(c.new, 'naam', true)) {
              process(1);
            } else if (!validate(c.new, 'tagklant')) {
              process(1);
            } else {
              // console.log(c.new.naam);
              const onderzoek = Object.assign(
                {
                  subproject: projectId,
                  xystatus: c.xystatus,
                  xymethode: c.xymethode,
                  zoorsprong: c.zoorsprong,
                  zsysteem: c.zsysteem,
                  zmethode: c.zmethode,
                  xyopmerking: scope.file.name,
                  zopmerking: scope.file.name,
                },
                c.new,
              );
              userAccountService
                .postOnderzoek(onderzoek, scope.ignoreErrors)
                .then(function () {
                  c.saved = true;
                })
                .finally(function () {
                  process(1);
                });
            }
          });
        userAccountService
          .putOnderzoeken(updates)
          .then(function () {
            scope.choices.forEach(function (c) {
              if (c.use === 'match') {
                c.saved = true;
              }
            });
          })
          .finally(function () {
            process(updates.length);
          });
      };
    }
    controller.$inject = ['$scope', '$mdDialog'];
    return controller;

    function matrixHas3Columns() {
      if (matrix.length === 0) {
        return false;
      }
      var idxShortestArray = matrix.reduce(
        (p, c, i, a) => (a[p].length < c.length ? p : i),
        0,
      );
      if (matrix[idxShortestArray].length === 3) {
        var idxLongestArray = matrix.reduce(
          (p, c, i, a) => (a[p].length > c.length ? p : i),
          0,
        );
        if (matrix[idxLongestArray].length > 3) {
          var longrowsEndsEmpty = true;
          for (var i = 3; i < matrix[idxLongestArray].length; i++) {
            if (matrix[idxLongestArray][i].length > 0) {
              longrowsEndsEmpty = false;
            }
          }
          if (longrowsEndsEmpty) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
      return false;
    }
  }

  function validate(object, field, required, validator) {
    validator = validator || utilService.noKommaFieldValidator;
    const value = object[field];
    let [valid, msg] = [true, null];
    if (required && !value) {
      [valid, msg] = [false, 'verplicht'];
    } else {
      ({ valid, msg } = validator(value));
    }
    if (valid) {
      return true;
    } else {
      toastr.error(`${field}: ${msg}`);
      return false;
    }
  }

  return Service;
}
