﻿import Feature from 'ol/Feature';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Polygon from 'ol/geom/Polygon';
import Draw from 'ol/interaction/Draw';
import Collection from 'ol/Collection';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import KML from 'ol/format/KML';
import Point from 'ol/geom/Point';

import src from './notities.html?url';
import notitiesUpdateDialogHtml from './notitiesUpdateDialog.html?url';
import notitiesCreateDialogHtml from './notitiesCreateDialog.html?url';

import closeSvg from '../../images/svgs/close.svg';
import icSaveSvg from '../../images/svgs/ic_save.svg';

const angular = window.angular;

window.app.controller('gridNotitiesCtrl', [
  '$rootScope',
  '$scope',
  '$sce',
  '$mdDialog',
  '$mdToast',
  'modelService',
  'mapService',
  'gridNotities',
  'gridFactory',
  gridNotitiesCtrl,
]);

function gridNotitiesCtrl(
  $rootScope,
  $scope,
  $sce,
  $mdDialog,
  $mdToast,
  modelService,
  mapService,
  gridNotities,
  gridFactory,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.views = gridNotities.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridNotities.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  $scope.disabled = true;
  function setDisabled(value) {
    $scope.disabled = value;
  }

  let project;
  function setProject(model) {
    setDisabled(true);
    project = model.project;
    $scope.projectId = model.project ? model.project.id : undefined;
  }

  function init(model) {
    return !!model.grid.NOTITIES;
  }

  $rootScope.$on('model-selectie', (_, model) => {
    if (init(model)) {
      setDisabled(model.selectie.length === 0);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-project', (_, model) => {
    if (init(model)) {
      setProject(model);
      $scope.$apply();
    }
  });

  $rootScope.$on('model-grid', (_, model) => {
    if (init(model)) {
      setProject(model);
      mapService.setHighlight('Notities', (ids) =>
        gridNotities
          .getNotities(ids)
          .map((notitie) => new Feature(notitie.hull)),
      );
      $scope.$apply();
    }
  });

  function getSelectedNotities() {
    return gridFactory.getRowsFromRange().map((row) => row.item);
  }

  $scope.zoomToNotities = () => {
    const notities = getSelectedNotities(),
      bboxes = notities.map((item) => item.bbox),
      geometryCollection = new GeometryCollection(bboxes),
      extent = geometryCollection.getExtent();
    mapService.fitToExtent(extent, {
      layer: 'Notities',
      maxZoom: 15,
      center: [notities[0].x, notities[0].y],
    });
  };

  $scope.deleteNotities = () => {
    const ids = getSelectedNotities().map((item) => item.id);
    if (confirm('Geselecteerde notities verwijderen?')) {
      gridNotities.deleteNotities(ids).then(refresh);
    }
  };

  $scope.editNotitie = () => {
    const notitie = getSelectedNotities()[0];
    $mdDialog.show({
      controller: updateDialogController,
      templateUrl: $sce.trustAsResourceUrl(notitiesUpdateDialogHtml),
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: { notitie },
    });
  };

  function updateDialogController($scope, $mdDialog, notitie) {
    $scope.closeSvg = closeSvg;
    $scope.icSaveSvg = icSaveSvg;

    $scope.item = notitie;
    Object.assign($scope.item, defaultSettings(notitie));
    $scope.cancel = function () {
      $mdDialog.hide($scope.items);
    };
    $scope.colors = getColornames();
    $scope.setColor = function (key, color) {
      $scope.item[key] = colors[color];
    };
    $scope.save = function () {
      putNotitie($scope.item).then(refresh);
      $scope.cancel();
    };
  }
  updateDialogController.$inject = ['$scope', '$mdDialog', 'notitie'];

  function refresh() {
    const model = modelService.getModel();
    gridNotities.refresh(model);
    mapService.refresh('Notities');
  }

  let tools;
  $rootScope.$on('model-tools', (_, model) => {
    tools = model.tools;
    $scope.$apply();
  });
  $scope.hasActiveTools = () => tools && tools.getSize();
  $scope.hasActiveTool = (tool) => tools && tools.has(tool);

  $scope.addPolygon = function () {
    start('polygon', 'polygoon');
  };

  $scope.addLine = function () {
    start('line', 'rechte lijn');
  };

  $scope.addPolyLine = function () {
    start('polyline', 'lijn', 'Rijroute');
  };

  function start(action, thing, text) {
    modelService.update('model-tools', 'NOTITIES', 'ADD');
    $mdToast.showSimple(`Teken een ${thing} op de kaart.`);
    create[action]().then((geom) =>
      $mdDialog.show({
        controller: createDialogController,
        templateUrl: $sce.trustAsResourceUrl(notitiesCreateDialogHtml),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: { geom, text },
      }),
    );
  }

  function createDialogController($scope, $mdDialog, geom, text) {
    $scope.closeSvg = closeSvg;
    $scope.icSaveSvg = icSaveSvg;

    $scope.text = text;
    function hide() {
      modelService.update('model-tools', 'NOTITIES', 'DELETE');
      $mdDialog.hide();
    }
    $scope.cancel = function () {
      hide();
    };
    $scope.save = function () {
      const text =
        geom instanceof Polygon
          ? project.subprojectnr + ': ' + $scope.text
          : $scope.text;
      postNotitie(project.id, geom, text).then(refresh);
      hide();
    };
  }
  createDialogController.$inject = ['$scope', '$mdDialog', 'geom', 'text'];

  document.addEventListener('keydown', function (event) {
    if (activeInteraction && event.key === 'Escape') {
      removeInteraction();
      modelService.update('model-tools', 'NOTITIES', 'DELETE');
    }
  });

  let activeInteraction;

  function addInteraction(interaction) {
    return new Promise((resolve) => {
      interaction.on('drawend', function (evt) {
        removeInteraction();
        resolve(evt.feature.getGeometry());
      });
      activeInteraction = interaction;
      const map = mapService.getMap();
      map.addInteraction(activeInteraction);
    });
  }

  function removeInteraction() {
    mapService.getMap().removeInteraction(activeInteraction);
    activeInteraction = null;
  }

  const create = {
    polygon: () => {
      return addInteraction(
        new Draw({
          features: polygonFeatures,
          style: polygonOKStyle,
          type: 'Polygon',
        }),
      );
    },
    line: () => {
      return addInteraction(
        new Draw({
          features: lineFeatures,
          style: polygonOKStyle,
          type: 'LineString',
          maxPoints: 2,
        }),
      );
    },
    polyline: () => {
      return addInteraction(
        new Draw({
          features: lineFeatures,
          style: polygonOKStyle,
          type: 'LineString',
          minPoints: 3,
        }),
      );
    },
  };

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridNotities.setView(index);
    }
  };

  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridNotities.setGrouping($scope.selectedGrouping);
  };

  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };

  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };

  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel();
  };

  $scope.getDocument = function (link) {
    window.open(link, '_blank', 'fullscreen=yes');
  };

  var polygonFeatures = new Collection();
  var lineFeatures = new Collection();
  var polygonOKStyle = new Style({
    fill: new Fill({ color: 'rgba(124,178,170,0.3)' }),
    stroke: new Stroke({ color: 'rgba(124,178,170,0.6)', width: 1 }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({ color: 'rgba(104, 209, 0, 0.6)' }),
      fill: new Fill({ color: 'rgba(124,178,170, 0.3)' }),
    }),
  });

  var colors = {
    zwart: [0, 0, 0],
    donkergrijs: [87, 87, 87],
    rood: [173, 35, 35],
    blauw: [42, 75, 215],
    groen: [29, 105, 20],
    bruin: [129, 74, 25],
    paars: [129, 38, 192],
    lichtgrijs: [160, 160, 160],
    lichtgroen: [129, 197, 122],
    lichtblauw: [157, 175, 255],
    cyaan: [41, 208, 208],
    oranje: [255, 146, 51],
    geel: [255, 146, 51],
    huid: [233, 222, 187],
    roze: [255, 205, 243],
    wit: [255, 255, 255],
  };

  function getColornames() {
    return Object.keys(colors);
  }

  function defaultSettings({ type, geom, text }) {
    type =
      type ||
      (geom instanceof Polygon
        ? 'Opmerking'
        : geom.getCoordinates().length === 2
        ? 'Straatnaam'
        : 'Rijroute');
    function defaultColor() {
      return type === 'Opmerking' ? colors.rood : colors.oranje;
    }
    const value = {
      type,
      geom,
      text,
      strokeColor: defaultColor(),
      strokeWidth: type === 'Opmerking' ? 3 : 8,
      fillColor: defaultColor(),
      fillOpacity: type === 'Opmerking' ? 0.1 : 0.0,
      textColor: defaultColor(),
      fontWeight: 400,
      addToLegend: false,
    };
    return value;
  }

  // http://stackoverflow.com/questions/14484787/wrap-text-in-javascript
  function stringDivider(str, width, spaceReplacer) {
    width = width || 24;
    spaceReplacer = spaceReplacer || '\n';
    str = str.replace(spaceReplacer, ' ');
    if (str.length > width) {
      var p = width;
      while (p > 0 && str[p] != ' ' && str[p] != '-') {
        p--;
      }
      if (p > 0) {
        var left;
        if (str.substring(p, p + 1) == '-') {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        var right = str.substring(p + 1);
        return (
          left + spaceReplacer + stringDivider(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  let _KMLformat;
  function KMLformat() {
    _KMLformat = _KMLformat || new KML();
    return _KMLformat;
  }

  let _straatnaamFill;
  function straatnaamFill() {
    _straatnaamFill =
      _straatnaamFill || new Fill({ color: colors.donkergrijs.concat([1]) });
    return _straatnaamFill;
  }

  function writeKml({
    geom,
    text,
    type,
    textColor,
    fontWeight,
    strokeColor,
    strokeWidth,
    fillColor,
    fillOpacity,
  }) {
    const shapeFeature = new Feature(geom),
      pointFeature = new Feature(new Point(geom.getFirstCoordinate())),
      stroke = strokeColor
        ? new Stroke({
            color: strokeColor.concat([1]),
            width: strokeWidth,
          })
        : null,
      fill = fillColor
        ? new Fill({
            color: fillColor.concat([fillOpacity]),
          })
        : null,
      textStyle =
        textColor && text
          ? new Text({
              text,
              fill: new Fill({
                color:
                  fontWeight > 0
                    ? textColor.concat([1])
                    : textColor.concat([0]),
              }),
            })
          : null;
    shapeFeature.setStyle(new Style({ fill, stroke }));
    pointFeature.setStyle(new Style({ text: textStyle }));
    if (type === 'Opmerking') {
      pointFeature.setGeometry(geom.getInteriorPoint());
      textStyle.setText(stringDivider(textStyle.getText()));
    } else if (type === 'Straatnaam') {
      textStyle.setFill(straatnaamFill());
    }
    const kml = KMLformat().writeFeatures([shapeFeature, pointFeature], {
      featureProjection: window.map.getView().getProjection(),
    });
    return kml;
  }

  function postNotitie(subproject, geom, text) {
    var data = defaultSettings({ geom, text });
    return gridNotities.postNotitie(subproject, writeKml(data));
  }

  function putNotitie(data) {
    return gridNotities.putNotitie(data.id, writeKml(data));
  }
}
