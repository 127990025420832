import './style.scss';

import toastr from 'toastr';
toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  'body-output-type': 'trustedHtml',
};
window.toastr = toastr;

import proj4 from 'proj4';
import epsg28992 from 'epsg-index/s/28992';
proj4.defs('EPSG:28992', epsg28992.proj4);
import { register } from 'ol/proj/proj4';
register(proj4);
// May be needed for Streetsmart.
window.proj4 = proj4;

// remove Vite atrifacts that contain no visible content, but render as
// something taking up line space (height), creating horizontal "gaps"
setInterval(() => {
  document.querySelectorAll('script.ng-scope').forEach(({ nextSibling }) => {
    if (nextSibling) {
      const { nodeName, textContent } = nextSibling;
      if (nodeName === '#text' && textContent.replace(/\W/g, '').length === 0) {
        nextSibling.remove();
      }
    }
  });
}, 500);

// eslint-disable-next-line no-unused-vars
const jQuery = window.jQuery;
// includes jQueryUI Sortable
import 'slickgrid/lib/jquery-ui-1.11.3';
// installed as slickgrid dependency
import 'jquery-ui/themes/base/all.css';
// own customisations
import './lib/slickgrid/lib/jquery-ui.custom.css';

import 'slickgrid/lib/jquery.event.drag-2.3.0';
import 'slickgrid/lib/jquery.event.drop-2.3.0';
import 'slickgrid/lib/jquery.tmpl.min.js';

import 'slickgrid';
import 'slickgrid/slick-default-theme.css';
import 'slickgrid/slick.grid';
import 'slickgrid/slick.grid.css';
import 'slickgrid/slick.editors';
import 'slickgrid/slick.dataview';
import 'slickgrid/slick.groupitemmetadataprovider';
import 'slickgrid/slick.compositeeditor';

import 'slickgrid/plugins/slick.cellselectionmodel';
import 'slickgrid/plugins/slick.cellrangeselector';
import 'slickgrid/plugins/slick.cellrangedecorator';
import 'slickgrid/plugins/slick.headerbuttons';
import 'slickgrid/plugins/slick.headerbuttons.css';

import './lib/slickgrid/plugins/slick.selectcolumnbuttons';
import './lib/slickgrid/plugins/slick.selectcolumnbuttons.css';
import './lib/slickgrid/slick.formatters';
