﻿import fullscreenSvg from '../images/svgs/ic_fullscreen_48px.svg';
import fullscreenExitSvg from '../images/svgs/ic_fullscreen_exit_48px.svg';
import handleidingGeoLoketPdf from '../files/HandleidingGeoLoket.pdf';
import releasenotesGeoLoketPdf from '../files/Releasenotes GeoLoket.pdf';

window.app.controller('mainCtrl', [
  'modelService',
  'userAccountService',
  'gridFactory',
  'streetsmartService',
  'streetviewService',
  '$scope',
  '$rootScope',
  '$mdDialog',
  mainCtrl,
]);

function mainCtrl(
  modelService,
  userAccountService,
  gridFactory,
  streetsmartService,
  streetviewService,
  $scope,
  $rootScope,
  $mdDialog,
) {
  //$timeout
  /* jshint unused:vars */
  //used from map.js:
  /*global map */

  // Using 'Controller As' syntax, so we assign this to the vm variable (for viewmodel).
  var vm = this;

  vm.LOGIN = window.LOGIN;
  vm.ROLES = [];
  vm.INTERNAL_USER = false;
  window.GEOLOKET_LOGIN.then((user) => {
    window.ROLES = user.roles;
    window.INTERNAL_USER = window.ROLES.includes('WEPinternal');
    vm.ROLES = window.ROLES;
    vm.INTERNAL_USER = window.INTERNAL_USER;
    vm.userData = {
      userName: user.name,
      userInitials: user.initials,
    };
    localStorage.setItem('geowep.userName', user.name);
    userAccountService.getRefdata().then(function (data) {
      $rootScope.refdata = data;
      vm.loggedIn = true;
      setTimeout(init);
    });
  });

  function init() {
    vm.handleidingGeoLoketPdf = handleidingGeoLoketPdf;
    vm.releasenotesGeoLoketPdf = releasenotesGeoLoketPdf;

    // Bindable properties and functions are placed on vm.
    vm.title = 'mainCtrl';

    const model = modelService.getModel();

    function setModus(model) {
      vm.modus = model.modus();
      $scope.$apply();
    }
    $rootScope.$on('model-modus', (_, model) => {
      setModus(model);
    });
    setModus(model);

    function setClearGridFilterDisabled(_, model) {
      vm.clearGridFilterDisabled = !model.filter.GRID;
      $scope.$apply();
    }
    $rootScope.$on('model-filter-grid', setClearGridFilterDisabled);
    $rootScope.$on('model-project', setClearGridFilterDisabled);
    vm.clearGridFilterDisabled = true;
    vm.clearGridFilter = () => {
      if (!vm.clearGridFilterDisabled) {
        modelService.update('model-filter-grid');
      }
    };

    function setClearKaartFilterDisabled(_, model) {
      vm.clearKaartFilterDisabled = !model.filter.KAART;
      $scope.$apply();
    }
    $rootScope.$on('model-filter-kaart', setClearKaartFilterDisabled);
    $rootScope.$on('model-project', setClearKaartFilterDisabled);
    vm.clearKaartFilterDisabled = true;
    vm.clearKaartFilter = () => {
      if (!vm.clearKaartFilterDisabled) {
        modelService.update('model-filter-kaart');
      }
    };

    vm.layout = {
      layerListVisible: true,
      iconLayersClass: 'iconfull',

      miscHeight: 0,
      miscVisible: false,
      iconMiscClass: 'iconempty',

      //miscHeight: parseInt(window.getComputedStyle(document.getElementById("boxLayers"), null).getPropertyValue('height')) / 3 - window.getComputedStyle((document.getElementsByTagName('footer')[0])).getPropertyValue('height') + 'px',
      //miscVisible: true,
      //iconMiscClass: "iconfull",
      ////document.getElementById("boxMisc").style['height'] = vm.layout.miscHeight + 'px';
      ////document.getElementById("boxLayers").style['height'] = parseInt(window.getComputedStyle(document.getElementById("boxLayers"), null).getPropertyValue('height')) - vm.layout.miscHeight + 'px';
      ////document.getElementById("boxMisc").classList.remove('hidden');
      ////document.getElementById("boxLayers").setAttribute('r-disabled', 'false');

      mapViewVisible: true,
      iconMapClass: 'iconfull',

      informationHeight: 0,
      informationViewVisible: false,
      iconInformationClass: 'iconempty',
      informationHeightIcon: fullscreenSvg,

      pointEditorViewVisible: false,

      showStreetsmart: false,
    };

    vm.logoutUser = userAccountService.logoutUser;
    vm.showInfo = showInfo;
    vm.openBrowserTab = openBrowserTab;
    vm.isFullScreen = false;
    vm.fullScreenIcon = fullscreenSvg;

    vm.toggleFullScreen = toggleFullScreen;

    //TODO als layer EN misc uitstaan dan map en/of informatie breder
    vm.toggleLayerList = function () {
      if (vm.layout.layerListVisible === true) {
        vm.layout.layerListVisible = false;
        vm.layout.iconLayersClass = 'iconempty';
        document.getElementById('boxLayers').classList.add('hidden');
      } else {
        vm.layout.layerListVisible = true;
        vm.layout.iconLayersClass = 'iconfull';
        document.getElementById('boxLayers').classList.remove('hidden');
      }
      setTimeout(function () {
        map.updateSize();
      }, 200);
    };

    vm.toggleMisc = function () {
      if (vm.layout.miscVisible === true) {
        vm.layout.miscHeight = parseInt(
          window
            .getComputedStyle(document.getElementById('boxMisc'), null)
            .getPropertyValue('height'),
        );

        vm.layout.miscVisible = false;
        vm.layout.iconMiscClass = 'iconempty';
        //document.getElementById("boxMisc").style['height'] = '0px';//vm.layout.miscHeight + 'px';
        document.getElementById('boxLayers').style['height'] =
          parseInt(
            window
              .getComputedStyle(document.getElementById('boxLayers'), null)
              .getPropertyValue('height'),
          ) +
          vm.layout.miscHeight +
          'px';

        document.getElementById('boxMisc').classList.add('hidden');
        document.getElementById('boxLayers').setAttribute('r-disabled', 'true');
      } else {
        if (vm.layout.miscHeight === 0 || isNaN(vm.layout.miscHeight)) {
          vm.layout.miscHeight =
            parseInt(
              window
                .getComputedStyle(document.getElementById('boxLayers'), null)
                .getPropertyValue('height'),
            ) /
              3 -
            window
              .getComputedStyle(document.getElementsByTagName('footer')[0])
              .getPropertyValue('height') +
            'px';
        }
        vm.layout.miscVisible = true;
        vm.layout.iconMiscClass = 'iconfull';

        document.getElementById('boxMisc').style['height'] =
          vm.layout.miscHeight + 'px';
        document.getElementById('boxLayers').style['height'] =
          parseInt(
            window
              .getComputedStyle(document.getElementById('boxLayers'), null)
              .getPropertyValue('height'),
          ) -
          vm.layout.miscHeight +
          'px';

        document.getElementById('boxMisc').classList.remove('hidden');
        document
          .getElementById('boxLayers')
          .setAttribute('r-disabled', 'false');
      }
      //setTimeout(function () { map.updateSize(); }, 200);
    };
    // vm.toggleMisc();
    $scope.$on('CallShowMiscview', function () {
      //event, data
      //check if miscellaneous panel is open if not show it
      if (vm.layout.miscVisible != true) {
        vm.toggleMisc();
      }
    });

    vm._old_toggleInformationFullHeight = function () {
      if (vm.layout.mapViewVisible === true) {
        //if (vm.layout.informationHeight === 0 || isNaN(vm.layout.informationHeight)) {
        //    vm.layout.informationHeight = parseInt(window.getComputedStyle(document.getElementById("columnright"), null).getPropertyValue('height')) / 3 - window.getComputedStyle(document.getElementsByTagName('footer')[0]).getPropertyValue('height') + 'px';
        //}
        vm.layout.mapViewVisible = false;
        vm.layout.informationHeightIcon = fullscreenExitSvg;

        document.getElementById('boxMap').classList.add('hidden');
        document.getElementById('boxMap').classList.remove('small');
        document.getElementById('boxInformatie').classList.remove('small');
        document.getElementById('boxInformatie').classList.add('big');
        //if (vm.layout.informationViewVisible === true) {
        //    document.getElementById("boxInformatie").style['height'] = parseInt(window.getComputedStyle(document.getElementById("columnright"), null).getPropertyValue('height'));
        //}
      } else {
        vm.layout.mapViewVisible = true;
        vm.layout.informationHeightIcon = fullscreenSvg;

        document.getElementById('boxMap').classList.remove('hidden');
        document.getElementById('boxMap').classList.add('small');
        document.getElementById('boxInformatie').classList.add('small');
        document.getElementById('boxInformatie').classList.remove('big');
        //if (vm.layout.informationViewVisible === true) {
        //    document.getElementById("boxInformatie").style['height'] = vm.layout.informationHeight + 'px';
        //}
        //document.getElementById("boxMap").setAttribute('r-disabled', 'false');
        setTimeout(function () {
          map.updateSize();
        }, 200);
      }
      setTimeout(function () {
        gridFactory.resizeGrid();
      }, 200);
    };

    vm.toggleInformationFullHeight = function () {
      var gridStyle = document.getElementById('grid').style;
      var mapStyle = document.getElementById('map').style;
      vm.informationFullHeight = !vm.informationFullHeight;
      if (vm.informationFullHeight) {
        vm.layout.informationHeightIcon = fullscreenExitSvg;
        gridStyle.height = '99%'; // '99%'; // '100%';
        mapStyle.display = 'none'; // '1%'; // 'none';
      } else {
        vm.layout.informationHeightIcon = fullscreenSvg;
        gridStyle.height = '40%';
        mapStyle.display = 'block';
      }
      setTimeout(gridFactory.resizeGrid, 200);
      map.updateSize();
    };

    vm.toggleInformationView = function () {
      var gridStyle = document.getElementById('grid').style;
      var mapStyle = document.getElementById('map').style;
      vm.layout.informationViewVisible = !vm.layout.informationViewVisible;
      if (vm.layout.informationViewVisible) {
        modelService.update('model-tabs', true);
        if (vm.layout.pointEditorViewVisible) {
          vm.togglePointEditorView();
        }
        vm.layout.iconInformationClass = 'iconfull';
        mapStyle.height = '60%';
        gridStyle.height = '40%';
        gridStyle.display = 'block';
      } else {
        vm.layout.iconInformationClass = 'iconempty';
        mapStyle.height = '99%';
        gridStyle.display = 'none';
        streetsmartService.destroyStreetsmart();
        streetviewService.destroyStreetview();
        modelService.update('model-tabs', false);
      }
      map.updateSize();
    };

    vm.togglePointEditorView = function () {
      vm.layout.pointEditorViewVisible = !vm.layout.pointEditorViewVisible;
      if (vm.layout.pointEditorViewVisible) {
        if (vm.layout.informationViewVisible) {
          vm.toggleInformationView();
        }
        modelService.update('model-tools', 'POINTEDITOR', 'ADD');
      } else {
        modelService.update('model-tools', 'POINTEDITOR', 'DELETE');
        document.getElementById('pointeditor').style.width = '0px';
      }
      modelService.update('model-grid', 'NONE');
    };
    $rootScope.$on('model-tools', function (event, model) {
      if (!model.tools.has('POINTEDITOR')) {
        if (vm.layout.pointEditorViewVisible) {
          vm.togglePointEditorView();
        }
      }
    });

    $rootScope.$on('model-project', (_, { project }) => {
      if (project && project.showTab && !vm.layout.informationViewVisible) {
        vm.toggleInformationView();
      }
    });

    $rootScope.$on('model-grid', (_, { grid }) => {
      if (!grid.NONE && !vm.layout.informationViewVisible) {
        vm.toggleInformationView();
      }
    });

    function openBrowserTab(name) {
      window.open(name, '_blank');
    }

    function showInfo(string) {
      let title, content, alert;
      switch (string) {
        case 'disclaimer':
          title = 'disclaimer';
          content = $rootScope.geowepSettings.disclaimer;
          break;
        case 'releasenotes':
          title = 'releasenotes';
          content = $rootScope.geowepSettings.releasenotes;
          break;
        default:
          title = '';
          content = '';
          break;
      }
      if (title !== '') {
        alert = $mdDialog.alert({
          title: string,
          htmlContent: content,
          ok: 'Close',
          onComplete: function afterShowAnimation() {
            var $dialog = window.angular.element(
              document.querySelector('md-dialog'),
            );
            var $actionsSection = $dialog.find('md-dialog-actions');
            var $confirmButton = $actionsSection.children()[0];
            window.angular.element($confirmButton).addClass('md-raised');
          },
        });
        $mdDialog.show(alert).finally(function () {
          alert = undefined;
        });
      }
    }

    function toggleFullScreen() {
      vm.isFullScreen = !vm.isFullScreen;
      vm.fullScreenIcon = vm.isFullScreen ? fullscreenExitSvg : fullscreenSvg;
      // more info here: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
      var doc = window.angular.element(document)[0]; //$document[0];
      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        if (doc.documentElement.requestFullscreen) {
          doc.documentElement.requestFullscreen();
        } else if (doc.documentElement.msRequestFullscreen) {
          doc.documentElement.msRequestFullscreen();
        } else if (doc.documentElement.mozRequestFullScreen) {
          doc.documentElement.mozRequestFullScreen();
        } else if (doc.documentElement.webkitRequestFullscreen) {
          doc.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT,
          );
        }
      } else {
        if (doc.exitFullscreen) {
          doc.exitFullscreen();
        } else if (doc.msExitFullscreen) {
          doc.msExitFullscreen();
        } else if (doc.mozCancelFullScreen) {
          doc.mozCancelFullScreen();
        } else if (doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen();
        }
      }
    }

    $rootScope.vm = vm;

    vm.presenterColumns = modelService.getModel().presentatie.getType().options;
    vm.selectedPresenterColumn = vm.presenterColumns[0].key;
    vm.setPresenterColumn = (presentatie) => {
      modelService.update('model-presentatie', presentatie);
    };
    $rootScope.$on('model-presentatie', (_, model) => {
      vm.selectedPresenterColumn = model.presentatie.key;
      $scope.$apply();
    });

    vm.legendMP = {};
    function setLegendMP(model) {
      vm.legendMP.subTitle = model.presentatie.value;
      vm.legendMP.url = new URL(
        `../images/legends/${model.presentatie.key}.png`,
        import.meta.url,
      ).href;
    }
    $rootScope.$on('model-presentatie', (_, model) => {
      setLegendMP(model);
      $scope.$apply();
    });
    setLegendMP(model);
  }
}
