﻿window.app.service('utilService', ['$rootScope', utilService]);

function utilService($rootScope) {
  var Util = {
    OnderzoeknaamComparer,
    noKommaFieldValidator,
    elementPromise,
    changesById,
    dateStringForFilenames,
  };
  return Util;

  function changesById(item = {}, oldItems = []) {
    const oldItem = oldItems.find(({ id }) => id === item.id);
    const changes = { id: item.id };
    for (const [key, value] of Object.entries(item)) {
      if (oldItem[key] !== value) {
        changes[key] = value;
      }
    }
    return changes;
  }

  function elementPromise(query) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const element = document.querySelector(query);
        if (element) {
          clearInterval(interval);
          resolve(element);
        }
      });
    });
  }

  function noKommaFieldValidator(value) {
    let [valid, msg] = [true, null];
    if (/,|;|<|>|:|"|\/|\\|\||\?|\*/.exec(value)) {
      [valid, msg] = [false, 'karakers , ; < > : " / \\ | ? * niet toegestaan'];
    }
    return { valid, msg };
  }

  function OnderzoeknaamComparer(a, b) {
    var steps = [
      function projectnr(item) {
        return item.projectnr;
      },
      function pot(item) {
        var refdata = $rootScope.refdata;
        var ot = refdata.onderzoekstype[item.onderzoekstype];
        var pot = ot
          ? refdata.primair_onderzoekstype[ot.primair_onderzoekstype]
          : undefined;
        return pot ? pot.volgorde : 0;
      },
      function volgnr(item) {
        var match = /\d+/.exec(item.naam);
        var volgnr = parseInt(match);
        return isNaN(volgnr) ? 0 : volgnr;
      },
      function naam(item) {
        return item.naam.toUpperCase();
      },
    ];
    return compare(a, b, steps);
  }

  function compare(a, b, steps) {
    for (var i = 0; i < steps.length; i++) {
      var fn = steps[i];
      var values = [fn(a), fn(b)];
      if (values[0] > values[1]) {
        return 1;
      }
      if (values[0] < values[1]) {
        return -1;
      }
    }
    return 0;
  }

  function dateStringForFilenames(date, short = false) {
    function format(value) {
      if (value < 10) {
        value = '0' + value;
      }
      return value;
    }
    const year = short
      ? date.getFullYear().toString().substr(-2)
      : date.getFullYear();
    const month = format(date.getMonth() + 1);
    const day = format(date.getDate());
    return short ? `${day}.${month}.${year}` : `${year}.${month}.${day}`;
  }
}
