import config from './src/config';

const environment = config.local ? config.prefix : undefined;
const script = document.createElement('script');
const path = '/geowep/api/geoloket.js';
script.src = config.prefix + path;
document.body.appendChild(script);
window.GEOLOKET_LOGIN = new Promise((resolve) => {
  const interval = setInterval(() => {
    if (window.GeoLoket) {
      clearInterval(interval);
      window.GeoLoket(environment).then((result) => {
        resolve(result);
      });
    }
  }, 10);
});
