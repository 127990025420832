﻿window.app.controller('selectionCtrl', [
  '$scope',
  '$rootScope',
  '$mdDialog',
  '$sce',
  'selectionService',
  'mapToolsService',
  'mapService',
  'userAccountService',
  'modelService',
  selectionCtrl,
]);

function selectionCtrl(
  $scope,
  $rootScope,
  $mdDialog,
  $sce,
  selectionService,
  mapToolsService,
  mapService,
  userAccountService,
  modelService,
) {
  $scope.hasSelections = function () {
    return selectionService.hasSelections();
  };
  $scope.clearSelections = function () {
    selectionService.clearSelections();
    mapToolsService.EraseSelectionPolygons();
    mapToolsService.setMousePointerSwitcher('default');
    modelService.update('model-filter-kaart');
  };
  $rootScope.$on('model-filter-kaart', (_, model) => {
    if (!model.filter.KAART) {
      $scope.clearSelections();
      $scope.$apply();
    }
  });
  $rootScope.$on('model-project', () => {
    $scope.clearSelections();
    $scope.$apply();
  });

  $scope.DrawSelectPolygon = function () {
    //document.getElementById("btnDrawSelectPolygon").classList.add("maptool-active"); //TODO all others should be set inactive
    modelService.update('model-tools', 'SELECTION', 'ADD');
    selectionService.addSelection('SELECTION');
    mapToolsService.setMousePointerSwitcher('draw');
    mapToolsService.AddDrawPolygon();
  };

  $scope.items = null;
}
