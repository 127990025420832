﻿import config from '../config';

import icVisibilitySvg from '../../images/svgs/ic_visibility.svg';
import editSvg from '../../images/svgs/edit.svg';

const toastr = window.toastr;
const $ = window.$;
const Slick = window.Slick;

window.app.factory('gridOnderzoeken', [
  '$rootScope',
  '$mdDialog',
  'gridFactory',
  'userAccountService',
  'mapService',
  'utilService',
  gridOnderzoeken,
]);

function gridOnderzoeken(
  $rootScope,
  $mdDialog,
  gridFactory,
  userAccountService,
  mapService,
  utilService,
) {
  var groupings;
  var columns;
  const views = [];
  var gridfactory;
  var onderzoekstypeList;
  initArrays();
  initColumns();

  $rootScope.$on('model-grid', (_, model) => {
    if (model.grid.ONDERZOEKEN) {
      const options = {
        editable: false,
        autoEdit: true,
        enableAddRow: false,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        forceFitColumns: false,
        topPanelHeight: 30,
        enableColumnReorder: true,
        showHeaderRow: true,
        defaultColumnWidth: 75,
        onderzoekstypeList: onderzoekstypeList,
        enableAsyncPostRender: true,
        autoHeight: false,
      };

      gridfactory = Object.create(gridFactory);
      gridfactory
        .createGrid(model.grid.ONDERZOEKEN, columns, options, 'ONDERZOEKEN')
        .then(() => {
          setView(0);
          setOverlayScrollbars();
          gridfactory.getData(model);
        });
    }
  });

  $rootScope.$on('model-selectie', (_, model) => {
    if (model.grid.ONDERZOEKEN && model.onderzoekSelectedFromMap) {
      gridFactory.highlightRow(model.selectie[0]);
    }
  });

  $rootScope.$on('model-getFeatures', (_, model) => {
    if (
      model.grid.ONDERZOEKEN &&
      model.modus().startsWith('K') &&
      !model.filter.KAART
    ) {
      gridFactory.getData(model);
    }
  });

  $rootScope.$on('model-modus', (_, model) => {
    if (model.grid.ONDERZOEKEN) {
      gridFactory.getData(model);
    }
  });

  // No model-modus on P to P
  $rootScope.$on('model-project', (_, model) => {
    if (model.grid.ONDERZOEKEN && model.modus() === 'P') {
      gridFactory.getData(model);
    }
  });

  // No model-modus on PF to PF
  $rootScope.$on('model-filter-kaart', (_, model) => {
    if (model.grid.ONDERZOEKEN && model.modus() === 'PF') {
      gridFactory.getData(model);
    }
  });

  function initArrays() {
    groupings = [
      { id: 'geen', label: 'Niets' },
      { id: 'onderzoekstype', label: 'Type onderzoek' },
      { id: 'onderzoeksstatus', label: 'Status onderzoek' },
      { id: 'xystatus', label: 'Status XY' },
    ];

    views.push(
      {
        id: 'Default',
        label: 'Default',
        columns: [
          '_checkbox_selector',
          'pdf',
          'projectnr',
          'sub',
          'naam',
          'tagklant',
          'onderzoekstype',
          'onderzoekstatus',
          'onderzoekstatusopmerking',
          'veldwerkdatum',
          'klasse',
          'diepte',
          'behaalde_diepte_mmv',
          'behaalde_diepte_mnap',
          'nge',
          'schouwresultaten',
          'eigenaar',
          'bron',
          'opmerkingen',
          'opmerkingen2',
          'opmerkingen3',
        ],
      },
      {
        id: 'Xyz',
        label: 'XYZ',
        columns: [
          '_checkbox_selector',
          'pdf',
          'projectnr',
          'sub',
          'naam',
          'tagklant',
          'x',
          'y',
          'srname',
          'xystatus',
          'xymethode',
          'z',
          'zoorsprong',
          'zmethode',
          'zsysteem',
          'blokcode',
          'inmeetlocatie',
        ],
      },
      {
        id: 'Alles',
        label: 'Alles',
        columns: [
          '_checkbox_selector',
          'pdf',
          'projectnr',
          'sub',
          'naam',
          'tagklant',
          'onderzoekstype',
          'onderzoekstatus',
          'onderzoekstatusopmerking',
          'veldwerkdatum',
          'klasse',
          'diepte',
          'behaalde_diepte_mmv',
          'behaalde_diepte_mnap',
          'nge',
          'schouwresultaten',
          'eigenaar',
          'bron',
          'opmerkingen',
          'opmerkingen2',
          'opmerkingen3',
          'x',
          'y',
          'srname',
          'xystatus',
          'xymethode',
          'z',
          'zoorsprong',
          'zmethode',
          'zsysteem',
          'blokcode',
          'inmeetlocatie',
        ],
      },
    );

    const veldwerkView = {
      id: 'Veldwerk',
      label: 'Veldwerk',
      columns: [
        '_checkbox_selector',
        'naam',
        'schouwresultaten',
        'nge',
        'klasse',
        'diepte',
        'behaalde_diepte_mmv',
        'behaalde_diepte_mnap',
        'onderzoekstatus',
        'onderzoekstatusopmerking',
        'opmerkingen',
        'opmerkingen2',
        'opmerkingen3',
        'eigenaar',
        'x',
        'y',
        'z',
        'veldwerkdatum',
        'tagklant',
      ],
    };

    if (window.ROLES.includes('Veldwerker')) {
      // add as first
      views.unshift(veldwerkView);
    } else {
      // add as last
      views.push(veldwerkView);
    }

    if (!window.INTERNAL_USER) {
      const start = 1;
      const deleteCount = 0;
      const insertItem = 'aangeboden';
      for (const view of views) {
        view.columns.splice(start, deleteCount, insertItem);
      }
    }
  }

  function initColumns() {
    var selectColumnButton = {
      selectbutton: [
        {
          image: editSvg,
          showOnHover: false,
          tooltip: 'selecteer hele kolom',
          command: 'SELECT',
          //handler: function (e) {
          //    alert('Help');
          //}
        },
      ],
    };

    function SelectEditor(args) {
      var $input;
      var defaultValue;
      this.keyCaptureList = [
        Slick.keyCode.UP,
        Slick.keyCode.DOWN,
        Slick.keyCode.ENTER,
      ];
      this.init = function () {
        $input = $('<select></select>');
        populateSelect(
          $input[0],
          args.column.dataSource,
          args.item,
          args.column.field,
          false,
        );
        $input.appendTo(args.container);
        $input.focus().select();
        $input.select2({
          placeholder: '-',
          allowClear: false,
          width: '100%',
          minimumResultsForSearch: Infinity,
        });
      };
      this.destroy = function () {
        $input.select2('destroy');
        $input.remove();
      };
      this.show = function () {};
      this.hide = function () {
        $input.select2('close');
      };
      this.position = function () {};
      this.focus = function () {};
      this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.trigger('change.select2');
      };
      this.serializeValue = function () {
        var test = $input.val();
        return test;
      };
      this.applyValue = function (item, state) {
        item[args.column.field] = state;
      };
      this.isValueChanged = function () {
        return (
          !($input.val() == '' && defaultValue == null) &&
          $input.val() != defaultValue
        );
      };
      this.validate = function () {
        return {
          valid: true,
          msg: null,
        };
      };
      this.init();
    }

    function DateEditor(args) {
      var $input;
      var defaultValue;
      var calendarOpen = false;

      this.init = function () {
        $input = $("<INPUT type=text class='editor-text' />");
        $input.appendTo(args.container);
        $input.focus().select();
        $input.datepicker({
          showOn: 'button',
          currentText: 'Gisteren',
          closeText: 'Annuleren',
          buttonText: '',
          //buttonImageOnly: true,
          beforeShow: function () {
            calendarOpen = true;
          },
          changeMonth: true,
          changeYear: true,
          dateFormat: 'dd-mm-yy',
          dayNames: [
            'zondag',
            'maandag',
            'dinsdag',
            'woensdag',
            'donderdag',
            'vrijdag',
            'zaterdag',
          ],
          dayNamesMin: ['z', 'm', 'd', 'w', 'd', 'v', 'z'],
          dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
          firstday: 1,
          monthNames: [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december',
          ],
          monthNamesShort: [
            'jan',
            'feb',
            'mrt',
            'apr',
            'mei',
            'jun',
            'jul',
            'aug',
            'sep',
            'okt',
            'nov',
            'dec',
          ],
          onClose: function () {
            calendarOpen = false;
          },
          showButtonPanel: true,
        });
        $input.width($input.width() - 18);
      };

      this.destroy = function () {
        $.datepicker.dpDiv.stop(true, true);
        $input.datepicker('hide');
        $input.datepicker('destroy');
        $input.remove();
      };

      this.show = function () {
        if (calendarOpen) {
          $.datepicker.dpDiv.stop(true, true).show();
        }
      };

      this.hide = function () {
        if (calendarOpen) {
          $.datepicker.dpDiv.stop(true, true).hide();
        }
      };

      this.position = function (position) {
        if (!calendarOpen) {
          return;
        }
        $.datepicker.dpDiv
          .css('top', position.top + 30)
          .css('left', position.left);
      };

      this.focus = function () {
        $input.focus();
      };

      this.loadValue = function () {
        defaultValue = new Date(Date.now());
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
        $input.datepicker('setDate', new Date(defaultValue));
      };

      this.serializeValue = function () {
        return $input.val();
      };

      this.applyValue = function (item, state) {
        var components = state.split('-');
        var date = components[0],
          month = components[1],
          year = components[2];
        var ret = year + '-' + month + '-' + date;
        var now = new Date();
        if (
          now.getDate() == date &&
          now.getMonth() + 1 == month &&
          now.getFullYear() == year
        ) {
          // ('06' == 6) === true
          ret = now.toISOString();
        }
        item[args.column.field] = ret;
      };

      this.isValueChanged = function () {
        return (
          !($input.val() == '' && defaultValue == null) &&
          $input.val() != defaultValue
        );
      };

      this.validate = function () {
        if (args.column.validator) {
          var validationResults = args.column.validator($input.val());
          if (!validationResults.valid) {
            return validationResults;
          }
        }

        return {
          valid: true,
          msg: null,
        };
      };

      $(document).on('click', 'button.ui-datepicker-current', function () {
        var yesterday = new Date(Date.now() - 86400000);
        $.datepicker._curInst.input.datepicker('setDate', yesterday);
      });

      this.init();
    }

    $.extend(Slick.Editors, {
      Select: SelectEditor,
      Date: DateEditor,
    });

    function populateSelect(select, dataSource, item, field, addBlank) {
      if (addBlank) {
        select.appendChild(new Option('', ''));
      }
      const list = dataSource(item);
      const keys = Object.keys(list);
      const options = [];
      for (const key of keys) {
        const value = list[key];
        const volgnummer = value.volgnummer;
        const text = value.omschrijving;
        options.push({ volgnummer, text, key });
      }
      const sortedOptions = options.sort(sortOptionsByVolgnummer);
      for (const option of sortedOptions) {
        const optionElement = new Option(option.text, option.key);
        select.appendChild(optionElement);
      }
    }

    function sortOptionsByVolgnummer(optionA, optionB) {
      const volgnummerA = optionA.volgnummer;
      const volgnummerB = optionB.volgnummer;
      if (volgnummerA < volgnummerB) {
        return -1;
      }
      if (volgnummerA > volgnummerB) {
        return 1;
      }
      return 0;
    }

    $.extend(Slick.Formatters, {
      Purchase: function (
        row,
        cell,
        value,
        columnDef,
        { aangeboden, aangekocht },
      ) {
        let title, icon, style;
        if (aangeboden === null) {
          title = 'Overleggen voor Profiler en GeoIndex';
          icon = 'info';
        } else if (aangeboden) {
          title = 'Aanschaffen voor Profiler en GeoIndex';
          icon = 'shopping_cart';
        } else if (aangekocht) {
          title = 'Aangeschaft';
          icon = 'check_circle';
          style = 'color: green; opacity: 0.5;';
        } else {
          return '';
        }
        return gridFactory.icon({ title, icon, style });
      },
    });

    function purchasePostRender(
      cellNode,
      row,
      { id, naam, projectnr, aangeboden },
    ) {
      let onclick;
      if (aangeboden === null) {
        onclick = requestOnderzoek;
      } else if (aangeboden) {
        onclick = purchaseOnderzoek;
      } else {
        return;
      }
      const element = cellNode[0];
      element.firstElementChild.onclick = onclick;

      function purchaseOnderzoek() {
        const grid = gridFactory.getGrid();
        const onderzoek = grid.getData().getItemById(id);
        let fieldRows = '';
        [
          'onderzoekstype',
          'veldwerkdatum',
          'klasse',
          'behaalde_diepte_mmv',
          'behaalde_diepte_mnap',
        ].forEach((id) => {
          const column = grid.getColumns()[grid.getColumnIndex(id)];
          const { field, name, formatter } = column;
          const value = onderzoek[field];
          const format = formatter || (() => value);
          fieldRows += `<tr><td>${name}:</td><td>${format(
            null,
            null,
            value,
            column,
            onderzoek,
          )}</td></tr>`;
        });
        const content = `
          <div layout>
            <div flex="50">
              <img src="{{::src}}" ng-click="preview()" style="max-height: 320px; max-width: 100%; cursor: zoom-in;" />
            </div>
            <div flex="50" style="padding: 1rem;">
              <table>
                <tbody>
                  ${fieldRows}
                </tbody>
              </table>
              <md-input-container class="md-blue-theme" md-no-float="" style="width: 100%;">
                <input type="text" ng-model="result" placeholder="{{::label}}" />
              </md-input-container>
              <p>
                Bij aanschaf worden er kosten in rekening gebracht aan uw organisatie.
              </p>
              <p>
                Klik op de preview om te vergroten.
              </p>
            </div>
          </div>
        `;
        const template = `
          <md-dialog-content class="md-dialog-content">
            <h2 class="md-title">{{::title}}</h2>
            <div style="overflow: auto; max-height: 324px;">
              ${content}
            </div>
          </md-dialog-content>
          <md-dialog-actions>
            <button
              class="md-primary md-cancel-button md-button md-blue-theme md-ink-ripple"
              type="button"
              ng-click="abort()"
            >
              {{::cancel}}
            </button>
            <button
              class="md-primary md-confirm-button md-button md-ink-ripple md-blue-theme"
              type="button"
              ng-click="hide()"
            >
              {{::ok}}
            </button>
          </md-dialog-actions>
        `;
        function controller($scope, $mdDialog) {
          $scope.src = `${config.prefix}/geowep/GeoIndex/api/geoloket/preview?id=${id}`;
          // $scope.src = `/geowep/app/preview.png`;
          $scope.title = `Onderzoek ${onderzoek.naam} aanschaffen?`;
          $scope.label = 'Uw kenmerk (optioneel)';
          $scope.ok = 'Aanschaf vastleggen';
          $scope.cancel = 'Annuleren';
          $scope.abort = () => $mdDialog.cancel();
          $scope.hide = () => $mdDialog.hide($scope.result);
          $scope.preview = () => {
            function controller($scope, $mdDialog) {
              $scope.hide = () => $mdDialog.hide();
            }
            controller.$inject = ['$scope', '$mdDialog'];
            $mdDialog.show({
              controller,
              multiple: true,
              // no effect:
              // fullscreen: true,
              clickOutsideToClose: true,
              template: `<img src="${$scope.src}" style="cursor: zoom-out;" ng-click="hide()" />`,
            });
          };
        }
        controller.$inject = ['$scope', '$mdDialog'];
        $mdDialog
          .show({ template, controller })
          .then(async (referentie) => {
            await userAccountService.purchaseOnderzoek(id, referentie);
            mapService.refresh('Meetpunten');
          })
          .catch((error) => {
            console.error(error);
            toastr.error('Aanschaf vastleggen mislukt.');
          });
      }

      async function requestOnderzoek() {
        const onderzoek = `Onderzoek: ${naam}, Projectnr: ${projectnr}.`,
          email = `E-mail: <a href="mailto:info@wiertsema.nl?subject=Aanvraag gegevens ${onderzoek}">info@wiertsema.nl</a>`,
          tel = 'Tel: <a href="tel:+31594516864">0594 – 51 68 64</a>',
          prompt = $mdDialog
            .alert()
            .theme('blue')
            .title('Gegevens op aanvraag.')
            .htmlContent(
              `<p>${onderzoek}</p><p>Informeer bij Wiertsema&Partners voor gegevens betreffende dit onderzoek.</p><p>${email}<br>${tel}</p>`,
            )
            .ok('OK');
        await $mdDialog.show(prompt);
      }
    }

    $.extend(Slick.Formatters, {
      Pdf: function (
        row,
        cell,
        value,
        columnDef,
        { aangeboden, bron, primair_onderzoekstype },
      ) {
        if (
          aangeboden ||
          bron === 'DINO' ||
          !(primair_onderzoekstype === 'B' || primair_onderzoekstype === 'S')
        ) {
          return '';
        } else {
          return `
            <a class="anchor" href="document">PDF</a>
            <div class="loading" style="display: none">laden...</div>
          `;
        }
      },
    });

    function pdfPostRender(cellNode, row, { id, naam }) {
      const element = cellNode[0];
      const anchor = element.querySelector('.anchor');
      if (!anchor) {
        return;
      }
      const loading = element.querySelector('.loading');
      function toggleSpinner() {
        const intermediate = anchor.style.display;
        anchor.style.display = loading.style.display;
        loading.style.display = intermediate;
      }
      function download(url) {
        return new Promise((resolve, reject) => {
          toggleSpinner();
          fetch(url, { credentials: 'include' })
            .then((response) => {
              if (response.ok) {
                resolve(window.open(url, '_blank'));
              } else {
                reject(response);
              }
            })
            .catch((error) => {
              throw error;
            })
            .finally(toggleSpinner);
        });
      }
      anchor.onclick = () => {
        try {
          // Try to find the document on "the VDIR"
          download(
            `${config.prefix}/api/project/onderzoek/document?onderzoek_id=${id}`,
          ).catch(() => {
            // Otherwise, try if GeoIndex can generate the document
            download(
              `${config.prefix}/GeoIndex/api/geoloket/tests/pdf?ids=${id}`,
            ).catch(() => {
              toastr.warning(`${naam}: geen document beschikbaar.`);
            });
          });
        } catch (error) {
          console.error(error);
          toastr.error('Verbinding mislukt.');
        }
      };
    }

    function requiredFieldValidatorDecorator(validator) {
      return (value) => {
        const required = requiredFieldValidator(value);
        if (!required.valid) {
          return required;
        } else {
          return validator(value);
        }
      };
    }

    const refdata = $rootScope.refdata;
    const defaultDataSource = (key) => {
      return () => {
        const list = refdata[key];
        return list;
      };
    };
    function klasseDataSource(item) {
      const { primair_onderzoekstype, primair } = item;
      const { categorie } =
        refdata.primair_onderzoekstype[primair_onderzoekstype];
      let result;
      const primairs = refdata.beoogde_klasse[categorie];
      if (primairs) {
        result = primairs.primair[primair];
      }
      return result || [];
    }
    function onderzoekstatusDataSource(item) {
      const { onderzoekstype } = item;
      const list = refdata.onderzoekstype[onderzoekstype].onderzoekstatus;
      return list;
    }

    columns = [
      {
        id: '_checkbox_selector',
        name: `<image src="${icVisibilitySvg}" id="grid-extraFilter">`,
        toolTip: '',
        field: 'id',
        width: 25,
        formatter: Slick.Formatters.Selector,
        validator: requiredFieldValidator,
        sortable: false,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'aangeboden',
        name: gridFactory.icon({ icon: 'shopping_cart' }),
        field: 'aangeboden',
        width: 25,
        formatter: Slick.Formatters.Purchase,
        asyncPostRender: purchasePostRender,
        sortable: true,
        filterable: false,
        multiEdit: false,
      },
      { id: 'aangekocht', field: 'aangekocht' },
      {
        id: 'naam',
        name: 'Naam onderzoek',
        toolTip: '',
        field: 'naam',
        minWidth: 225,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidatorDecorator(
          utilService.noKommaFieldValidator,
        ),
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      //{ id: "tagklant", name: "Tag klant", toolTip: "", field: "tagklant", minWidth: 225, editor: Slick.Editors.Text, sortable: true, filterable: true, filter: "Text", multiEdit: false },
      {
        id: 'tagklant',
        name: 'Tag klant',
        toolTip: '',
        field: 'tagklant',
        minWidth: 225,
        editor: Slick.Editors.Text,
        validator: utilService.noKommaFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'coordinate',
        field: 'coordinate',
      },
      {
        id: 'x',
        name: 'X',
        field: 'x',
        toolTip: '',
        minWidth: 110,
        formatter: Slick.Formatters.Float3Null,
        editor: Slick.Editors.Float,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: false,
        multiEdit: false,
      },
      {
        id: 'y',
        name: 'Y',
        field: 'y',
        toolTip: '',
        minWidth: 110,
        formatter: Slick.Formatters.Float3Null,
        editor: Slick.Editors.Float,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: false,
        multiEdit: false,
      },
      {
        id: 'srid',
        name: 'srid',
        field: 'srid',
      },
      {
        id: 'srname',
        name: 'Projectie',
        field: 'srname',
        toolTip: '',
        minWidth: 150,
        sortable: true,
        filterable: true,
      },
      {
        id: 'xystatus',
        name: 'XY(Z)status',
        toolTip: '',
        field: 'xystatus',
        minWidth: 175,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('xystatus'),
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'bron',
        name: 'Bron',
        toolTip: 'Onderzoeksbron',
        field: 'bron',
        minWidth: 175,
        formatter: Slick.Formatters.Omschrijving,
        editor: undefined,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('onderzoeksbron'),
        multiEdit: false,
      },
      {
        id: 'veldwerkdatum',
        name: 'Veldwerkdatum',
        toolTip: '',
        field: 'uitgevoerd',
        minWidth: 175,
        formatter: Slick.Formatters.Date,
        sortable: true,
        filterable: true,
        filter: 'Date',
      },
      {
        id: 'klasse',
        name: 'Beoogde klasse',
        toolTip: '',
        field: 'klasse',
        minWidth: 150,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: klasseDataSource,
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'diepte',
        name: 'Beoogde diepte',
        toolTip: '',
        field: 'diepte',
        minWidth: 150,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'behaalde_diepte_mmv',
        name: 'Diepte m-mv (behaald)',
        toolTip: 'Behaalde diepte onder maaiveld',
        field: 'behaalde_diepte_mmv',
        minWidth: 210,
        formatter: Slick.Formatters.Float2,
        editor: Slick.Editors.Float,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'behaalde_diepte_mnap',
        name: 'Diepte m NAP (behaald)',
        toolTip: 'Behaalde diepte Normaal Amsterdams Peil',
        field: 'behaalde_diepte_mnap',
        minWidth: 210,
        formatter: Slick.Formatters.Float2,
        editor: Slick.Editors.Float,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'onderzoekstatus',
        name: 'Status onderzoek',
        toolTip: '',
        field: 'onderzoekstatus',
        minWidth: 175,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: onderzoekstatusDataSource,
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'onderzoekstatusopmerking',
        name: 'Opmerking status onderzoek',
        toolTip: '',
        field: 'onderzoekstatusopmerking',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      //{ id: "onderzoekstatusopmerking", name: "Opmerking status onderzoek", toolTip: "", field: "onderzoekstatusopmerking", minWidth: 250, formatter: Slick.Formatters.Text, editor: Slick.Editors.PrefilledText, prefill: "", sortable: true, filterable: true, filter: "Text", multiEdit: true, header: selectColumnButton },
      {
        id: 'onderzoekstatusdatum',
        name: 'Datum status onderzoek',
        toolTip: '',
        field: 'onderzoekstatusdatum',
        formatter: Slick.Formatters.Date,
        editor: Slick.Editors.Date,
      },
      {
        id: 'onderzoekstype',
        name: 'Type onderzoek',
        toolTip: '',
        field: 'onderzoekstype',
        minWidth: 175,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Onderzoekstype',
        dataSource: defaultDataSource('onderzoekstype'),
        multiEdit: true,
        header: selectColumnButton,
      }, //, groupTotalsFormatter: onderzoekstypeFormatter },
      {
        id: 'projectnr',
        name: 'Projectnr',
        toolTip: '',
        field: 'projectnr',
        minWidth: 100,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'sub',
        name: 'Sub',
        toolTip: '',
        field: 'sub',
        minWidth: 50,
        editor: Slick.Editors.Text,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: false,
      },
      {
        id: 'xymethode',
        name: 'XYmethode',
        toolTip: '',
        field: 'xymethode',
        minWidth: 125,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('xymethode'),
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'z',
        name: 'Z',
        toolTip: '',
        field: 'z',
        minWidth: 100,
        formatter: Slick.Formatters.Float3Null,
        editor: Slick.Editors.Float,
        sortable: true,
        filterable: false,
        multiEdit: false,
      },
      {
        id: 'zoorsprong',
        name: 'Zoorsprong',
        toolTip: '',
        field: 'zoorsprong',
        minWidth: 125,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('zoorsprong'),
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'zmethode',
        name: 'Zmethode',
        toolTip: '',
        field: 'zmethode',
        minWidth: 125,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('zmethode'),
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'zsysteem',
        name: 'Zsysteem',
        toolTip: '',
        field: 'zsysteem',
        minWidth: 125,
        formatter: Slick.Formatters.Omschrijving,
        editor: Slick.Editors.Select,
        validator: requiredFieldValidator,
        sortable: true,
        filterable: true,
        filter: 'Text',
        dataSource: defaultDataSource('zsysteem'),
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'pdf',
        name: 'pdf',
        toolTip: '',
        field: undefined,
        minWidth: 100,
        formatter: Slick.Formatters.Pdf,
        asyncPostRender: pdfPostRender,
        validator: requiredFieldValidator,
        sortable: false,
        filterable: false,
        multiEdit: false,
      },
      { id: 'primair', name: 'primair', toolTip: '', field: 'primair' },
      {
        id: 'primaironderzoek',
        name: 'primair onderzoek',
        toolTip: '',
        field: 'primair_onderzoek',
      },
      {
        id: 'primaironderzoekstype',
        name: 'primair onderzoekstype',
        toolTip: '',
        field: 'primair_onderzoekstype',
      },
      {
        id: 'blokcode',
        name: 'Blokcode',
        toolTip: 'Blokcode',
        field: 'blokcode',
        minWidth: 125,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
      },
      {
        id: 'inmeetlocatie',
        name: 'Inmeetlocatie',
        toolTip: 'Inmeetlocatie',
        field: 'inmeetlocatie',
        minWidth: 125,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
      },
      {
        id: 'nge',
        name: 'NGE',
        toolTip: 'NGE',
        field: 'nge',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'opmerkingen',
        name: 'Opmerkingen',
        toolTip: 'Opmerkingen',
        field: 'opmerkingen',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'opmerkingen2',
        name: 'Opmerkingen 2',
        toolTip: 'Opmerkingen2',
        field: 'opmerkingen2',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'opmerkingen3',
        name: 'Opmerkingen 3',
        toolTip: 'Opmerkingen3',
        field: 'opmerkingen3',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'eigenaar',
        name: 'Eigenaar',
        toolTip: 'Eigenaar',
        field: 'eigenaar',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.Text,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
      },
      {
        id: 'schouwresultaten',
        name: 'Schouwresultaten',
        toolTip: 'Schouwresultaten',
        field: 'schouwresultaten',
        minWidth: 250,
        formatter: Slick.Formatters.Text,
        editor: Slick.Editors.MultilineText,
        sortable: true,
        filterable: true,
        filter: 'Text',
        multiEdit: true,
        header: selectColumnButton,
        showTooltip: true,
      },
    ];
  }

  function getColumns(viewId) {
    const view = views.find((view) => view.id === viewId);
    if (view) {
      return view.columns.map((columnId) => {
        return columns.find(({ id }) => id === columnId);
      });
    } else {
      return columns;
    }
  }

  function getColumn(columnId) {
    return columns.find(({ id }) => id === columnId);
  }

  function requiredFieldValidator(value) {
    if (value == null || value == undefined || !value.length) {
      return { valid: false, msg: 'This is a required field' };
    } else {
      return { valid: true, msg: null };
    }
  }

  function getGroupings() {
    return groupings;
  }

  function setGrouping(grouping) {
    gridfactory.setGrouping(grouping);
  }

  function getViews() {
    return views;
  }

  function setOverlayScrollbars() {
    var is_coarse = matchMedia('(pointer:coarse)').matches;
    if (is_coarse) {
      var viewports = document.getElementsByClassName(
        'slick-viewport slick-viewport-top slick-viewport-left',
      );
      var viewport = viewports[0];
      viewport.style.overflow = 'overlay';
    }
  }

  function setView(viewIndex) {
    gridfactory.setView('#gridOnderzoekenCtrl', columns, views, viewIndex);
  }

  function saveItems(onderzoeken) {
    //var t = onderzoeken[0];
    //var tijdstip;
    //try {
    //    tijdstip = new Date(t["onderzoekstatusdatum"]);
    //} catch (ex) {
    //    tijdstip = new Date.now();
    //}
    userAccountService.putOnderzoeken(onderzoeken).then(
      function () {
        toastr.success('Wijziging geslaagd');
        invalidateAll();
      },
      function () {
        invalidateAll();
      },
    );
  }

  function invalidateAll() {
    gridfactory.getData();
    gridfactory.invalidate();
    mapService.refresh(['Meetpunten', 'Meetpunt labels']);
  }

  return {
    setGrouping,
    getGroupings,
    getViews,
    setView,
    saveItems,
    invalidateAll,
    getColumn,
    getColumns,
  };
}
