﻿import Rotate from 'ol/control/Rotate';
import ScaleLine from 'ol/control/ScaleLine';
import Zoom from 'ol/control/Zoom';
import ZoomSlider from 'ol/control/ZoomSlider';
import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Layer from 'ol/layer/Layer';
import View from 'ol/View';
import Control from 'ol/control/Control';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import MousePosition from 'ol/control/MousePosition';
import * as olCoordinate from 'ol/coordinate';
import TileWMS from 'ol/source/TileWMS';
import Attribution from 'ol/control/Attribution';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import * as olLoadingstrategy from 'ol/loadingstrategy';
import EsriJSON from 'ol/format/EsriJSON';
import * as olProj from 'ol/proj';
import KML from 'ol/format/KML';
import LayerGroup from 'ol/layer/Group';
import * as olExtent from 'ol/extent';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Map from 'ol/Map';
import * as olControl from 'ol/control';
import * as olInteraction from 'ol/interaction';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import Text from 'ol/style/Text';
import CircleStyle from 'ol/style/Circle';
import MultiPoint from 'ol/geom/MultiPoint';

import JSZip from 'jszip';
import debounce from 'debounce';

import config from '../config';

import icLocationSvg from '../../images/svgs/ic_location.svg';
import merkatorLogoKleinPng from '../../images/merkator_logo_klein.png';

const angular = window.angular;
const toastr = window.toastr;
const $ = window.$;
let map;

window.app.service('mapService', [
  '$rootScope',
  'modelService',
  'mapSelectionService',
  mapService,
]);

function mapService($rootScope, modelService, mapSelectionService) {
  /** VARIABLES */

  var pointsLayer;
  var largeView;
  var srid;
  var resolutions;
  let mousePositionControl;
  let sridControl;
  var northarrow = document.createElement('span');
  northarrow.className = '';
  northarrow.innerHTML = '&#8679';
  var Proxy_URL;
  $rootScope.mapexport = false;
  var start_date = null;
  var end_date = null;
  var blnfilterontime = false;
  var default_start_date = null;
  var default_end_date = null;
  var default_filterontime = false;
  var styleCache = {};

  const svgColors = {
    highlight1: '#FFFF00',
    highlight2: '#00FF00',
    0: '#000000', // zwart        #000000 rgb(0, 0, 0)
    1: '#DA9602', // oranje       #da9602 rgb(218, 150, 2)
    2: '#E4E816', // geel         #e4e816 rgb(228, 232, 22)
    3: '#6BD62C', // licht groen  #6bd62c rgb(107, 214, 44)
    4: '#1B7D1B', // donker groen #1b7d1b rgb(27, 125, 27)
    5: '#0000FF', // blauw        #0000FF rgb(0, 0, 255)
    6: '#00FFFF', // cyan         #00FFFF rgb(0, 255, 255)
    8: '#7A7D82', // grijs        #7a7d82 rgb(122, 125, 130)
    9: '#FF0000', // rood         #FF0000 rgb(255, 0, 0)
  };

  const svgs = {
    default: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:5; fill:none' cx='50' cy='50' r='10' />",
    },
    S_NS: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' />",
    },
    B_NS: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style='stroke:#000000; stroke-width:1; fill:#000000'  d='M36.1,63.9 A19.7,19.7 1 1 0 63.9,36.1' />",
    },
    D: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' />",
    },
    DKM: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMP: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMG: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMPM: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMPS: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMPG: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    MF: {
      size: 120,
      path: "<path style='fill:#000000'  d='M42.9,14.2 H77.1 L60,60 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M40.2,71.7 L79.8,3.1 H104.1' /> ",
    },
    // "MFBRL": { size: 120, path : "<path style='fill:#000000'  d='M42.9,14.2 H77.1 L60,60 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M40.2,71.7 L79.8,3.1 H104.1' /> " },
    PB: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M30.2,84 L69.8,16 H94.1' />",
    },
    BR: {
      size: 140,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='70' cy='70' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:none'  d = 'M65.1,89.1 A32.4,32.4 1 1 1 65.1,50.9' /> ",
    },
    // "X":     { size: 100, path : "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style='stroke:#000000; stroke-width:1' d='M47.1,47.1 L41.3,41.3' /><path style='stroke:#000000; stroke-width:1' d='M50,45.9 V37.8' /><path style='stroke:#000000; stroke-width:1' d='M52.9,47.1 L58.7,41.3' /><path style='stroke:#000000; stroke-width:1' d='M54.1,50 H62.2' /><path style='stroke:#000000; stroke-width:1' d='M52.9,52.9 L58.7,58.7' /><path style='stroke:#000000; stroke-width:1' d='M50,54.1 V62.2' /><path style='stroke:#000000; stroke-width:1' d='M47.1,52.9 L41.3,58.7' /><path style='stroke:#000000; stroke-width:1' d='M45.9,50 H37.8' />" },
    DB: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><circle style = 'fill:#000000;'  cx = '50' cy = '50' r = '6.1' /> ",
    },
    DKMM: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    DKMS: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M25.5,50 H74.5' /> ",
    },
    HS: {
      size: 100,
      path: "<path style='fill:#000000'  d='M32.9,4.2 H67.1 L50,50 Z' />",
    },
    WSMB: {
      size: 80,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none;'  cy='40' cx='40' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M12.1,67.9 L78.3,1.8' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M52.4,5.4 L78.3,1.8' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M78.3,1.8 L74.6,27.6' /> ",
    },
    H_REF: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M 19,68.2 V 31.8 H81 V68.2 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M 19,68.2 L81,31.8' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M 19,31.8 L81,68.2' /> ",
    },
    HM: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M 19,68.2 V 31.8 H81 V68.2 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M 19,68.2 L81,31.8' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M 19,31.8 L81,68.2' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M 5.8,50 H94.2' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M 50,19.4 V80.6' /> ",
    },
    OM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='23.2'  /><circle style = 'stroke:#000000; stroke-width:1; fill:none'  cx = '50' cy = '50' r = '7.88' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M26.8,22.8 H73.2 V77.2 H26.8 Z' /><path style = 'stroke:#000000; stroke-width:1.5'  d = 'M14.3,50 H85.7' /><path style = 'stroke:#000000; stroke-width:1.5'  d = 'M50,14.3 V85.7' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M42.1,45.5 L50,40.9 L57.9,45.5 V54.5 L50,59.1 L42.1,54.5 Z' />",
    },
    DM: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M 33,59.8 H67 L50,30.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /> ",
    },
    TM: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /> ",
    },
    TR: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M15.7,50 H98.9' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,1.1 V84.4' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M34.3,21.9 L50,1.1 L65.7,21.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M78.1,34.3 L98.9,50 L78.1,65.7' /> ",
    },
    HMB: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M15.7,50 H98.9' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,1.1 V84.4' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M34.3,21.9 L50,1.1 L65.7,21.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M78.1,34.3 L98.9,50 L78.1,65.7' /> ",
    },
    HMK: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M30.4,30.4 H69.6 V69.6 H30.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M15.7,50 H98.9' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,1.1 V84.4' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M34.3,21.9 L50,1.1 L65.7,21.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M78.1,34.3 L98.9,50 L78.1,65.7' /> ",
    },
    ZB: {
      size: 120,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M60,60 L52.5,3.9 L67.5,3.9 Z' />",
    },
    PDS: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '18.4' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '3.6' /> ",
    },
    LV: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5' d='M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M33,44 H67' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M33,56 H67' /> ",
    },
    SM: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5' d='M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /><path style = 'stroke:#000000; stroke-width:1; fill:none' d = 'M27.6,42.1 H72.4 V57.9 H27.6 Z' /><path style = 'stroke:#000000; stroke-width:1; fill:none' d = 'M41.5,46 H58.5 V54 H41.5 Z' /><circle style = 'stroke:#000000; fill:none'  cx = '30.5' cy = '46' r = '2' /><circle style = 'stroke:#000000; fill:none'  cx = '69.5' cy = '46' r = '2' /><circle style = 'stroke:#000000; fill:none'  cx = '30.5' cy = '54' r = '2' /><circle style = 'stroke:#000000; fill:none'  cx = '69.5' cy = '54' r = '2' /> ",
    },
    FM: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M19.1,19.1 H80.9 V80.9 H19.1 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M30.4,30.4 H69.6 V69.6 H30.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M19.1,19.1 L30.4,30.4' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M80.9,19.1 L69.6,30.4' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M80.9,80.9 L69.6,69.6' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M19.1,80.9 L30.4,69.6' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M31.9,50 H40.3' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M59.7,50 H68.1' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,31.9 V40.3' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,59.7 V68.1' /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '6.5' /> ",
    },
    SR: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '18.4' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '3.6' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M28.9,28.9 L36.1,36.1' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M71.1,28.9 L63.9,36.1' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M63.9,63.9 L71.1,71.1' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M28.9,71.1 L36.1,63.9' /> ",
    },
    DH: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '18.4' /><circle style = 'stroke:#000000; stroke-width:1.2; fill:none'  cx = '50' cy = '50' r = '19' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '2.5' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M31.6,50 H41.8' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M58.2,50 H68.4' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,31.6 V41.8' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,58.2 V68.4' /> ",
    },
    LL: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1' d = 'M0.3,38.8 H33.9' /><path style = 'stroke:#000000; stroke-width:1' d = 'M66.2,38.8 H99.7' /><path style = 'stroke:#000000; stroke-width:1' d = 'M0.3,40.3 H32.9' /><path style = 'stroke:#000000; stroke-width:1' d = 'M67.1,40.3 H99.7' /><path style = 'stroke:#000000; stroke-width:1' d = 'M0.3,59.8 H32.9' /><path style = 'stroke:#000000; stroke-width:1' d = 'M67.1,59.8 H99.7' /><path style = 'stroke:#000000; stroke-width:1' d = 'M0.3,61.3 H33.9' /><path style = 'stroke:#000000; stroke-width:1' d = 'M66.2,61.3 H99.7' /> ",
    },
    SP: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M 33,59.8 H67 L50,30.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /> ",
    },
    PDD: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '18.4' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '3.6' /> ",
    },
    FI: {
      size: 100,
      path: "<path style='fill:#000000; stroke:#000000; stroke-width:1' d='M30.4,30.4 H69.6 V69.6 H30.4 Z' />",
    },
    TC: {
      size: 100,
      path: "<circle style='fill:#000000; stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><path style = 'fill:#000000; stroke:#000000; stroke-width:1.5' d = 'M15.7,50 H98.9' /><path style = 'fill:#000000; stroke:#000000; stroke-width:1.5' d = 'M50,1.1 V84.4' /><path style = 'fill:#000000; stroke:#000000; stroke-width:1.5; fill:none' d = 'M34.3,21.9 L50,1.1 L65.7,21.9' /><path style = 'fill:#000000; stroke:#000000; stroke-width:1.5; fill:none' d = 'M78.1,34.3 L98.9,50 L78.1,65.7' /><path style = 'fill:#000000; stroke:#000000; stroke-width:1.5' d = 'M33.7,40.2 H66.3 L50,69.6 Z' /> ",
    },
    MP_REF: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '14.2' /><circle style = 'stroke:#000000; stroke-width:4.5; fill:none'  cx = '50' cy = '50' r = '16.9' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '3.2' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M10.7,50 H89.3' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,10.7 V89.3' /> ",
    },
    AZB: {
      size: 200,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M100,100 L92.5,43.9 H107.5 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M100,36.4 V43.9' /><circle style = 'stroke:#000000'  cx = '100' cy = '32.7' r = '3.8' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M91.5,24.2 A12,12 1,0,1 108.5,24.2' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M86.2,18.9 A19.5,19.5 1,0,1 113.8,18.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M80.9,13.6 A27,27 1,0,1 119.1,13.6' /> ",
    },
    OAZB: {
      size: 200,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M100,100 L92.5,43.9 H107.5 Z' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M100,36.4 V43.9' /><circle style = 'stroke:#000000'  cx = '100' cy = '32.7' r = '3.8' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M91.5,24.2 A12,12 1,0,1 108.5,24.2' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M86.2,18.9 A19.5,19.5 1,0,1 113.8,18.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M80.9,13.6 A27,27 1,0,1 119.1,13.6' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d='M82.5,48.9 v-5 H117.5 v5' />",
    },
    OGM: {
      size: 100,
      path: "<path style='fill:#000000; stroke:#000000; stroke-width:1' d='M30.4,30.4 H50 V40.2 H40.2 V59.8 H59.8 V50 H69.6 V69.6 H30.4 Z' /><path style = 'fill:#000000; stroke:#000000; stroke-width:1' d = 'M52.1,52.1 L47.9,47.9 L61.7,34.1 L54,26.4 H73.6 V46 L65.9,38.3 Z' /> ",
    },
    CCHP: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6'  /><circle style = 'stroke:#000000; fill:none'  cx = '50' cy = '50' r = '18.4' /><circle style = 'stroke:#000000; stroke-width:1.2; fill:none'  cx = '50' cy = '50' r = '19' /><circle style = 'stroke:#000000; fill:#000000'  cx = '50' cy = '50' r = '2.5' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M31.6,50 H41.8' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M58.2,50 H68.4' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,31.6 V41.8' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,58.2 V68.4' /> ",
    },
    GLV: {
      size: 150,
      path: "<circle style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  cx = '75' cy = '75' r = '7.9' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '75' cy = '75' r = '13.1' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M61.9,133.3 A13.1,13.1 1 1 0 88.1,133.3' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M61.9,133.3 V75' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M88.1,133.3 V75' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M67.1,133.3 A7.9,7.9 1 1 0 82.9,133.3' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000' d = 'M82.9,91.8 V133.3 H67.1 V97.4 Z' />",
    },
    WSM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M22.2,77.8 L88.1,11.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M62.3,15.5 L88.1,11.9 L84.5,37.7' /> ",
    },
    // "PBS":   { size: 100, path : "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M30.2,84 L69.8,16 H94.1' />" },
    VC: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:#000000'  cx='50' cy='50' r='19.7'  />",
    },
    BC: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:#000000'  cx='50' cy='50' r='19.7'  />",
    },
    ASM: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5' d='M22,50 H78' /><path style = 'stroke:#000000; stroke-width:1.5' d = 'M50,22 V78' /><path style = 'stroke:#000000; stroke-width:1' d = 'M30.5,48 H55.5' /><path style = 'stroke:#000000; stroke-width:1' d = 'M30.5,52 H55.5' /><path style = 'stroke:#000000; stroke-width:1; fill:none' d = 'M27.6,42.1 H72.4 V57.9 H27.6 Z' /><path style = 'stroke:#000000; stroke-width:1; fill:#000000' d = 'M55.5,46 H72.4 V54 H55.5 Z' /><circle style = 'stroke:#000000; fill:#ffffff'  cx = '69.5' cy = '50' r = '2.5' /><circle style = 'stroke:#000000; fill:#000000'  cx = '30.5' cy = '50' r = '2' /> ",
    },
    BM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:#000000'  d = 'M36.1,63.9 A19.7,19.7 1 1 0 63.9,36.1' />",
    },
    WBM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M50,50 L50,30.4' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M50,50 L68.7,43.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M50,50 L61.6,65.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M50,50 L38.5,65.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000'  d = 'M50,50 L31.3,43.9' />",
    },
    VH: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1; fill:#000000'  d='M30.3,50 A19.7,19.7 1 0 0 69.7,50' /><path style = 'stroke:white; stroke-width:0; fill:white'  d = 'M47.1,69.5 L52.9,69.5 L50,50 z' /><path style = 'stroke:#000000; stroke-width:1; fill:white'  d = 'M47.1,69.5 A19.7,19.7 1 0 0 52.9,69.5' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M 8.8,50 h82.5' /><path style = 'stroke:#000000; stroke-width:1.5;' d = 'M 50,8.8 v82.5' />",
    },
    PBM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:#000000'  d = 'M40.1,67 A19.7,19.7 1 1 0 59.9,32.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M30.2,84 L69.8,16 H94.1' />",
    },
    SL: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:#000000' d='M 0.1,35 h99.8 v30 h-99.8 Z' />",
    },
    IG: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M30.4,30.4 H69.6 V69.6 H30.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M30.4,30.4 L69.6,69.6' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M30.4,69.6 L69.6,30.4' />",
    },
    ONTL: {
      size: 120,
      path: "<circle style='stroke:#000000; stroke-width:1.5; fill:none'  cx='17.5' cy='62.5' r='15'  /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '47.5' cy = '62.5' r = '15' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '77.5' cy = '62.5' r = '15' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '107.5' cy = '62.5' r = '15' />",
    },
    AFLV: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M9.5,30.1 H90.5 V69.9 H9.5 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M9.5,69.9 L90.5,30.1' /> ",
    },
    OVET: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M9.5,30.1 H90.5 V69.9 H9.5 Z' /><path style = 'stroke:#000000; stroke-width:1; fill:#000000' d = 'M9.5,30.5 V69.4 L50,50 Z' /><path style = 'stroke:#000000; stroke-width:1; fill:#000000' d = 'M90.5,30.5 V69.4 L50,50 Z' />",
    },
    VULP: {
      size: 125,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M0.1,44.7 H124.9 V79.8 H0.1 Z' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '17.5' cy = '62.5' r = '15' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '47.5' cy = '62.5' r = '15' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '77.5' cy = '62.5' r = '15' /><circle style = 'stroke:#000000; stroke-width:1.5; fill:none'  cx = '107.5' cy = '62.5' r = '15' />",
    },
    BTNK: {
      size: 150,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:white'  d='M12.2,55.1 A30.2,30.2 1 0 0 12.2,94.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:white'  d = 'M137.8,55.1 A30.2,30.2 1 0 1 137.8,94.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M12.4,55.1 H137.6 V94.9 H12.4 Z' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M12.4,94.9 L137.6,55.1' />",
    },
    OTNK: {
      size: 150,
      path: "<path style='stroke:#000000; stroke-dasharray:4; stroke-width:1.5; fill:white'  d='M12.2,55.1 A30.2,30.2 1 0 0 12.2,94.9' /><path style = 'stroke:#000000; stroke-dasharray:4; stroke-width:1.5; fill:white'  d = 'M137.8,55.1 A30.2,30.2 1 0 1 137.8,94.9' /><path style = 'stroke:#000000; stroke-dasharray:4; stroke-width:1.5; fill:none' d = 'M12.4,55.1 H137.6 V94.9 H12.4 Z' /><path style = 'stroke:#000000; stroke-dasharray:4; stroke-width:1.5; fill:none' d = 'M12.4,94.9 L137.6,55.1' />",
    },
    FOTO: {
      size: 200,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M41,100 H159' /><path style = 'stroke:#000000; stroke-width:1.5; fill:#000000' d = 'M159,86.4 V113.6 L190.7,100 Z' />",
    },
    PF: {
      size: 120,
      path: "<path style='fill:#000000'  d='M42.9,14.2 H77.1 L60,60 Z' /><path style='stroke:#000000; stroke-width:1; fill:none' d='M40.2,71.7 L79.8,3.1 H104.1' />",
    },
    TV: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7' /><path style='stroke:#000000; stroke-width:1' d='M47.1,47.1 L41.3,41.3' /><path style='stroke:#000000; stroke-width:1' d='M50,45.9 V37.8' /><path style='stroke:#000000; stroke-width:1' d='M52.9,47.1 L58.7,41.3' /><path style='stroke:#000000; stroke-width:1' d='M54.1,50 H62.2' /><path style='stroke:#000000; stroke-width:1' d='M52.9,52.9 L58.7,58.7' /><path style='stroke:#000000; stroke-width:1' d='M50,54.1 V62.2' /><path style='stroke:#000000; stroke-width:1' d='M47.1,52.9 L41.3,58.7' /><path style='stroke:#000000; stroke-width:1' d='M45.9,50 H37.8' />",
    },
    OW: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5; fill:none' d='M30.2,84 L69.8,16 H94.1' />",
    },
    DR: {
      size: 100,
      path: "<circle style='stroke:#000000; fill:none'  cx='50' cy='50' r='19.6' /><circle style='stroke:#000000; fill:none' cx='50' cy='50' r='18.4' /><circle style='stroke:#000000; stroke-width:1.2; fill:none' cx='50' cy='50' r='19' /><circle style='stroke:#000000; fill:#000000' cx='50' cy='50' r='2.5' /><path style='stroke:#000000; stroke-width:1.5' d='M31.6,50 H41.8' /><path style='stroke:#000000; stroke-width:1.5' d='M58.2,50 H68.4' /><path style='stroke:#000000; stroke-width:1.5' d='M50,31.6 V41.8' /><path style='stroke:#000000; stroke-width:1.5' d='M50,58.2 V68.4' />",
    },
    EX: {
      size: 100,
      path: "<path style='stroke:#000000; stroke-width:1.5;' d='M 21.9,50 H78.1' /><path style='stroke:#000000; stroke-width:1.5;' d='M 50,21.9 V78.1' /><path style='stroke:#000000; fill:none ;stroke-width:1.5;' d='M 44.8,34.2 H55.3 V37.2 H44.8 Z' /><path style='stroke:#000000; fill:none ;stroke-width:1.5;' d='M 44.8,62.8 H55.3 V65.8 H44.8 Z' /><path style='stroke:#000000; stroke-width:1.5;' d='M 47.8,37.2 V62.8' /><path style='stroke:#000000; stroke-width:1.5;' d='M 52.3,37.2 V62.8' /><path style='stroke:#000000; fill:none ;stroke-width:1.5;' d='M 47.8,65.8 V71.9 H52.3 V65.8' />",
    },
    HB: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:#000000'  d = 'M40.1,67 A19.7,19.7 1 1 0 59.9,32.9' />",
    },
    MB: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:#000000'  cx='50' cy='50' r='19.7'  />",
    },
    SB: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:#000000'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M25.6,74.4 A34.5,34.5 1 0 1 25.6,25.6' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M30.9,69.1 A27,27 1 0 1 30.9,30.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M69.1,69.1 A27,27 1 0 0 69.1,30.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M74.4,74.4 A34.5,34.5 1 0 0 74.4,25.6' /> ",
    },
    //"HBS": { size: 100, path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:#000000'  d = 'M36.1,63.9 A19.7,19.7 1 1 0 63.9,36.1' /> " },
    //"HBS": { size: 100, path: "<circle style='stroke:#000000; stroke-width:1; fill:#000000' cx='50' cy='50' r='19.7' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M25.6,74.4 A34.5,34.5 1 0 1 25.6,25.6' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none' d = 'M30.9,69.1 A27,27 1 0 1 30.9,30.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M69.1,69.1 A27,27 1 0 0 69.1,30.9' /><path style = 'stroke:#000000; stroke-width:1.5; fill:none'  d = 'M74.4,74.4 A34.5,34.5 1 0 0 74.4,25.6' /> " },
    HBS: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:1; fill:none'  cx='50' cy='50' r='19.7'  /><path style = 'stroke:#000000; stroke-width:1; fill:#000000'  d = 'M40.1,67 A19.7,19.7 1 1 0 59.9,32.9' />",
    },
    RM: {
      size: 100,
      path: "<circle style='stroke:#000000; stroke-width:5; fill:none' cx='50' cy='50' r='10' />",
    },
  };
  svgs['B'] = svgs.HB;
  svgs['B '] = svgs.MB;
  svgs['B  '] = svgs.HBS;
  svgs['B   '] = svgs.SB;
  svgs['B    '] = svgs.SB;
  svgs['BVM'] = svgs.BC;
  svgs['PC'] = svgs.BC;
  svgs['GC'] = svgs.BC;

  /** SERVICE */

  var mapService = {
    getMap: () => {
      return map;
    },
    addOverlay,
    getPointsLayer,
    initSettings,
    checkMap,
    AddToStyleCache,
    addMapLayer,
    getLayer,
    getLayers,
    refresh,
    removeLayer,
    toggleLayer,
    changeLayerTransparency,
    setView,
    fitToExtent,
    timeFilterMapLayers,
    pickCoordinaat: {
      start: pickCoordinaatStart,
      cancel: pickCoordinaatCancel,
    },
    pickMeetpunt: {
      start: pickMeetpuntStart,
      cancel: pickMeetpuntCancel,
    },
    setLayerParam,
    getLayerParam,
    getLayerParams,
    getLayerFilter,
    getSvg,
    getBaseSvg,
    setHighlight,
  };

  function setLayerParam(layer, key, value) {
    layer = getLayer(layer);
    const params = layer.getSource().VIEWPARAMS;
    if (value !== undefined) {
      params.setItem(key, value);
    } else {
      params.unsetItem(key);
    }
    refresh([layer]);
  }

  function getLayerParam(layer, key) {
    const params = getLayer(layer).getSource().VIEWPARAMS;
    return params.getItem(key);
  }

  function getLayerParams(layer) {
    const viewParams = getLayer(layer).getSource().VIEWPARAMS;
    return viewParams ? viewParams.clone() : null;
  }

  function getLayerFilter(layer) {
    const cqlFilter = getLayer(layer).getSource().CQL_FILTER;
    return cqlFilter ? cqlFilter.clone() : null;
  }

  var pickCoordinaatDrawInteraction;

  function pickCoordinaatStart(callback, multi) {
    var draw = pickCoordinaatDrawInteraction;
    if (draw) {
      callback(new Error('Coördinaatprikken is al actief'));
    } else {
      draw = new Draw({
        type: 'Point',
        stopClick: true,
        handleMoveEvent: (event) => {
          callback(null, false, event.coordinate);
        },
      });
      draw.on('drawend', (event) => {
        const coordinate = event.feature.getGeometry().getFirstCoordinate();
        if (!multi) {
          pickCoordinaatCancel();
        }
        callback(null, true, coordinate);
      });
      pickCoordinaatDrawInteraction = draw;
    }
    map.addInteraction(draw);
    draw.setActive(true);
  }

  function pickCoordinaatCancel() {
    if (pickCoordinaatDrawInteraction) {
      map.removeInteraction(pickCoordinaatDrawInteraction);
      pickCoordinaatDrawInteraction = null;
    }
  }

  var pickMeetpuntSnapInteraction;

  function pickMeetpuntSnapStart() {
    var snap = pickMeetpuntSnapInteraction;
    if (!snap) {
      snap = new Snap({
        source: getLayer('Meetpunten').getSource(),
      });
      pickMeetpuntSnapInteraction = snap;
    }
    map.addInteraction(snap);
    snap.setActive(true);
  }

  function pickMeetpuntSnapCancel() {
    var snap = pickMeetpuntSnapInteraction;
    if (snap) {
      map.removeInteraction(snap);
    }
  }

  function pickMeetpuntStart(callback, multi) {
    pickCoordinaatStart(function (err, finished, coordinate) {
      if (err) {
        return callback(err);
      } else if (!finished) {
        return;
      }
      const source = getLayer('Meetpunten').getSource();
      const features = source.getFeaturesInExtent(
        coordinate
          .map((e) => {
            return e - 0.0001;
          })
          .concat(
            coordinate.map((e) => {
              return e + 0.0001;
            }),
          ),
      );
      var primair_onderzoek;
      for (let i = 0; i < features.length; i++) {
        const feature = features[i];
        const ot = feature.get('onderzoekstype');
        if ($rootScope.refdata.onderzoekstype[ot].primair) {
          primair_onderzoek = feature;
          break;
        }
      }
      if (primair_onderzoek) {
        callback(null, primair_onderzoek.get('id'), primair_onderzoek);
      } else {
        callback(
          new Error(
            'Geen primair onderzoek in beeld op coördinaat: ' + coordinate,
          ),
        );
      }
      if (!multi) {
        pickMeetpuntSnapCancel();
      }
    }, multi);
    pickMeetpuntSnapStart();
  }

  function pickMeetpuntCancel() {
    pickMeetpuntSnapCancel();
    pickCoordinaatCancel();
  }

  function getLayer(titleOrLayer) {
    if (titleOrLayer instanceof Layer) {
      return titleOrLayer;
    }
    var layers = map.getLayers().getArray();
    var num = layers.length;
    for (var i = 0; i < num; i++) {
      var layer = layers[i];
      if (layer.get('title') === titleOrLayer) {
        return layer;
      }
    }
  }

  function getLayers() {
    var layers = map.getLayers().getArray();
    return layers;
  }

  function CqlFilter(key, sql) {
    // FIXME: gaat uit van één laag in LAYERS param
    var that = this;
    this.items_ = {};
    this.setItem = function (key, sql) {
      if (sql === undefined) {
        return that.unsetItem(key);
      }
      that.items_[key] = sql;
      return that;
    };
    if (key && sql && sql != '') {
      that.setItem(key, sql);
    }
    this.unsetItem = function (key) {
      delete that.items_[key];
      return that;
    };
    this.toString = function (unencoded) {
      var value = '1=1';
      Object.keys(that.items_).forEach(function (key) {
        var sql = that.items_[key];
        value += ' and (' + sql + ')';
      });
      return unencoded ? value : encodeURIComponent(value);
    };
    this.unEncoded = function () {
      return that.toString(true);
    };
    this.clone = function () {
      const clone = new CqlFilter();
      clone.items_ = angular.copy(that.items_);
      return clone;
    };
  }

  function ViewParams(params) {
    var that = this;

    this.items_ = {};

    this.setItem = function (key, value) {
      if (value === undefined) {
        return that.unsetItem(key);
      }
      that.items_[key] = value;
      return that;
    };

    this.setItem('srid', srid.split(':')[1]);

    this.getItem = function (key) {
      const val = that.items_[key];
      return val;
    };

    if (typeof params === 'string') {
      params.split(';').forEach((param) => {
        const kv = param.split(':');
        if (kv.length === 2) {
          that.setItem(kv[0], kv[1]);
        }
      });
    }

    this.unsetItem = function (key) {
      delete that.items_[key];
      return that;
    };

    this.toString = function (unencoded) {
      var ret = '1:1';
      Object.keys(that.items_).forEach(function (key) {
        var value = that.items_[key];
        ret += ';' + key + ':' + value;
      });
      return unencoded ? ret : encodeURIComponent(ret);
    };

    this.unEncoded = function () {
      return that.toString(true);
    };

    this.clone = function () {
      const clone = new ViewParams();
      clone.items_ = angular.copy(that.items_);
      return clone;
    };

    this.setExtent = function (extent) {
      that.setItem('minx', extent[0]);
      that.setItem('miny', extent[1]);
      that.setItem('maxx', extent[2]);
      that.setItem('maxy', extent[3]);
      return that;
    };
  }

  function refresh(layers) {
    function refresher(layer) {
      layer = getLayer(layer);
      const source = layer.getSource();
      return () => source.refresh();
    }
    [].concat(layers).forEach((layer) => setTimeout(refresher(layer), 100));
  }

  function initSettings(params) {
    if (params.initial) {
      initmap();
      delete params.initial;
      setView(params);
    } else {
      // A project was selected that has a different projection than the current
      // map view; adjust the map view to use the project's projection.
      const project = params;

      const projection = `EPSG:${project.srid}`;
      const currentProjection = olProj.get(srid);
      const newProjection = olProj.get(projection);

      // There's no obvious way to change a view's projection. Therefore:
      // construct a new view based on the properties of the current view.
      const properties = largeView.getProperties();

      properties.projection = newProjection;

      properties.center = olProj.transform(
        largeView.getCenter(),
        currentProjection,
        newProjection,
      );

      const factor =
        currentProjection.getMetersPerUnit() / newProjection.getMetersPerUnit();
      properties.resolution *= factor;
      properties.resolutions = properties.resolutions.map(
        (resolution) => resolution * factor,
      );

      setView(properties);

      // Update the controls' projection.
      mousePositionControl.setProjection(newProjection);
      sridControl.setText(project.srname);

      zoomToProject(project);
    }

    function setView(options) {
      // Create a new view for the map to use.
      largeView = new View(options);
      map.setView(largeView);

      // Set global variables.
      if (options.projection.getCode) {
        srid = options.projection.getCode();
      } else {
        srid = options.projection;
      }
      resolutions = options.resolutions;

      // Trigger the initialisation of the map's layers.
      $rootScope.$emit('map-init');
    }
  }

  function getPointsLayer() {
    return pointsLayer;
  }

  function checkMap() {
    return !!map;
  }

  function addOverlay(overlay) {
    if (map) {
      map.addOverlay(overlay);
    }
  }

  function AddToStyleCache(
    id,
    stylename,
    strokecolor,
    strokewidth,
    fillcolor,
    type,
    iconname,
    iconwidth,
    iconheight,
    iconscale,
  ) {
    var style;
    if (fillcolor === undefined || fillcolor === null) {
      fillcolor = strokecolor;
    }
    if (type.toLowerCase() === 'point') {
      //MultiPoint
      style = createPointStyle(strokecolor, strokewidth, fillcolor);
    } else if (type.toLowerCase() === 'icon') {
      style = createPointStyleIcon(iconname, iconwidth, iconheight, iconscale);
    } else if (type.toLowerCase() === 'line') {
      //LineString, MultiLineString
      style = createPolylineStyle(strokecolor, strokewidth);
    } else if (type.toLowerCase() === 'polygon') {
      //MultiPolygon
      style = createPolygonStyle(strokecolor, strokewidth, fillcolor);
    } else {
      style = createPolygonStyle(strokecolor, strokewidth, fillcolor);
    }
    style.name = stylename;
    styleCache[id] = style;
  }

  async function addMapLayer(
    layerId,
    name,
    projection_code,
    main_url,
    main_layers,
    main_querylayers,
    main_format,
    main_unique_id,
    main_cql_filter,
    main_min_level,
    main_max_level,
    main_max_zoom,
    secondary_url,
    secondary_max_level,
    secondary_max_zoom,
    visible_on_start,
    layer_type,
    layer_attributions,
    layer_opacity,
    property_page_url,
    layergroup,
    z_index,
    parent_layer_id,
    main_layerparam,
    secondary_layerparam,
    sld_url,
    useproxy,
    field_date,
    field_geometry,
    stylefield,
  ) {
    var maplayers = map.getLayers(); //.getArray();
    //var mapgroup;
    var ibefore = -1;
    let setAt = -1;
    for (var i = 0; i < maplayers.getLength(); i++) {
      var layer = maplayers.item(i);
      if (layer.get('title') === name) {
        // This layer alread exists. It needs to get upated in the stack, not
        // inserted.
        setAt = i;
      }
      if (ibefore === -1 && layer.get('zIndex') > z_index) {
        // need to insert this new layer before this one
        ibefore = i;
      }
    }
    if (!layer_opacity || !(0.0 < layer_opacity && layer_opacity <= 1.0)) {
      // console.log("setting default opacity");
      layer_opacity = 0.8;
    }

    if (!visible_on_start) {
      //no longer showing switch but slider is showing visibility, so not visible is 0
      layer_opacity = 0.0;
    }

    if (!z_index) {
      //console.log("setting default z-index");
      z_index = 1;
    }
    if (!main_min_level) {
      main_min_level = Math.min(
        resolutions[0],
        resolutions[resolutions.length - 1],
      );
      //console.log("use as min resolution:" + main_min_level);
    }
    if (!main_max_level) {
      main_max_level = Math.max(
        resolutions[0],
        resolutions[resolutions.length - 1],
      );
      //console.log("use as max resolution:" + main_max_level);
    }
    if (!secondary_max_level) {
      secondary_max_level = Math.max(
        resolutions[0],
        resolutions[resolutions.length - 1],
      );
      //console.log("use as max resolution:" + secondary_max_level);
    }
    main_url = main_url || '';
    var blnShow = visible_on_start;
    //console.log("visible = " + blnShow);
    var newLayer;
    var layer_source;
    if (main_layerparam !== null) {
      main_layerparam = main_layerparam.trim();
    }

    var cqlFilter = '';
    if (main_cql_filter !== null && main_cql_filter.length > 1) {
      cqlFilter = main_cql_filter.trim();
    }

    var urlRequest = '';

    urlRequest += main_url.trim();
    if (urlRequest.startsWith('/')) {
      urlRequest = config.prefix + urlRequest;
      main_url = config.prefix + main_url;
    }

    if (!main_url.includes('?')) {
      urlRequest += '?1=1';
    }

    var parameters = {};
    parameters.FORMAT = main_format;
    parameters.LAYERS = main_layers;
    parameters.TRANSPARENT = true;
    parameters.TILED = true;
    parameters.TILESORIGIN = '0,0';
    if (cqlFilter !== null && cqlFilter.length > 0) {
      parameters.cql_filter = cqlFilter;
    }

    // FIXME!
    const layerextent = undefined;

    layer_source = new TileWMS({
      url: urlRequest,
      params: parameters,
      attributions: [
        new Attribution({
          html: layer_attributions,
        }),
      ],
    });

    if (layer_type === 'IMAGEWMS') {
      layer_source = new ImageWMS({
        url: urlRequest,
        params: {
          LAYERS: main_layers,
          FORMAT: main_format,
          TRANSPARENT: true,
        },
        ratio: 1,
        serverType: 'geoserver',
        attributions: [new Attribution({ html: layer_attributions })],
      });
      const CQL_FILTER = new CqlFilter('INIT', cqlFilter);
      const VIEWPARAMS = new ViewParams(main_layerparam);
      layer_source.CQL_FILTER = CQL_FILTER;
      layer_source.VIEWPARAMS = VIEWPARAMS;
      setImageLoadFunction(layer_source, (src, image) => {
        const extent = image.getExtent();
        VIEWPARAMS.setExtent(extent);
        src = `${src}&CQL_FILTER=${CQL_FILTER}&VIEWPARAMS=${VIEWPARAMS}`;
        // TIMESTAMP is to force refresh, i.e. disable cache
        // Maybe needs to get more flexible later, e.g. layer_source.refresh(force)
        src = `${src}&TIMESTAMP=${encodeURIComponent(
          new Date().toISOString(),
        )}`;
        return src;
      });
      newLayer = new ImageLayer({
        title: name,
        source: layer_source,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        datefield: field_date,
        extent: layerextent,
        crossOrigin: 'anonymous',
      });
    } else if (layer_type === 'WMS') {
      layer_source = new TileWMS({
        url: urlRequest,
        projection: projection_code,
        params: parameters,
        attributions: [new Attribution({ html: layer_attributions })],
      });
      const CQL_FILTER = new CqlFilter('INIT', cqlFilter);
      const VIEWPARAMS = new ViewParams(main_layerparam);
      layer_source.CQL_FILTER = CQL_FILTER;
      layer_source.VIEWPARAMS = VIEWPARAMS;
      setTileLoadFunction(
        layer_source,
        (src) => `${src}&CQL_FILTER=${CQL_FILTER}&VIEWPARAMS=${VIEWPARAMS}`,
      );
      newLayer = new TileLayer({
        title: name,
        source: layer_source,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        datefield: field_date,
        extent: layerextent,
        crossOrigin: 'anonymous',
      });
    } else if (layer_type === 'WMTS') {
      newLayer = new TileLayer({
        title: name,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        layerId: layerId,
        parentId: parent_layer_id,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        crossOrigin: 'anonymous',
        source: await wmtsSourceFromCapabilities(
          urlRequest,
          main_layers,
          main_format,
        ),
      });
    } else if (layer_type === 'OSM') {
      const source = new OSM({
        url: main_url || undefined,
        maxZoom: main_max_zoom || undefined,
      });
      if (main_max_zoom) {
        // https://wiki.openstreetmap.org/wiki/Zoom_levels
        let top = 156412;
        // getResolutions appears to be there, but it's not documented in
        // https://openlayers.org/en/v6.15.1/apidoc/module-ol_source_OSM-OSM.html.
        if (source.getResolutions) {
          const resolutions = source.getResolutions();
          if (resolutions.length) {
            top = resolutions[0];
          }
        }
        // For MapFish.
        source.RESOLUTIONS = [top];
        for (let i = 1; i <= main_max_zoom; i++) {
          source.RESOLUTIONS.push(top / Math.pow(2, i));
        }
      }
      newLayer = new TileLayer({
        title: name,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        layerId: layerId,
        parentId: parent_layer_id,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        crossOrigin: 'anonymous',
        source,
      });
    } else if (layer_type === 'WFS') {
      layer_source = new VectorSource({
        strategy: olLoadingstrategy.bbox, // might cause duplicate features on move if not fid in features!
        useSpatialIndex: true,
      });
      const CQL_FILTER = new CqlFilter('INIT', cqlFilter);
      const VIEWPARAMS = new ViewParams(main_layerparam);
      layer_source.CQL_FILTER = CQL_FILTER;
      layer_source.VIEWPARAMS = VIEWPARAMS;
      const baseUrl =
        `${urlRequest}&service=WFS&request=GetFeature&version=1.0.0&outputFormat=json` +
        `&typename=${main_layers}` +
        `&srsname=${projection_code}`;
      const geoJSON = new GeoJSON({
        dataProjection: projection_code,
        featureProjection: srid,
      });
      layer_source.setLoader((extent) => {
        // Bbox and cql_filter are mutually exclusive, so bbox needs to be
        // within the cqlfilter.
        const geometry = field_geometry || 'geometry';
        CQL_FILTER.setItem('bbox', `BBOX(${geometry},${extent},'${srid}')`);
        VIEWPARAMS.setExtent(extent);
        const url = `${baseUrl}&cql_filter=${CQL_FILTER}&viewparams=${VIEWPARAMS}`;
        fetch(url, { credentials: 'include' })
          .then((response) => response.json())
          .then((data) => {
            layer_source.addFeatures(
              geoJSON
                .readFeatures(data)
                .filter(layer_source.CLIENT_FILTER || (() => true)),
            );
          });
      });
      var layerstyle;
      if (stylefield !== null) {
        layerstyle = getStyleForLayerViaStylefield(stylefield);
      } else {
        layerstyle = getStyleForLayer(name);
      }
      newLayer = new VectorLayer({
        title: name,
        source: layer_source,
        style: layerstyle,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        datefield: field_date,
        crossOrigin: 'anonymous',
      });
    } else if (layer_type === 'WFS_EXTERN') {
      console.log('Externe WFS gevonden');
      layer_source = new VectorSource({
        format: new GeoJSON({
          dataProjection: projection_code,
          featureProjection: srid,
        }),
        url: function (extent) {
          const url =
            `${urlRequest}&service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json` +
            `&typename=${main_layers}` +
            `&srsname=${projection_code}` +
            `&bbox=${extent},${srid}`;
          return url;
        },
        strategy: olLoadingstrategy.bbox,
      });
      newLayer = new VectorLayer({
        title: name,
        source: layer_source,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        datefield: field_date,
        crossOrigin: 'anonymous',
        style: getVectorStyle(stylefield),
      });
    } else if (layer_type === 'EsriJSON') {
      layer_source = new VectorSource({
        format: new EsriJSON(),
        loader: function (extent, resolution, projection) {
          if (projection.getCode() != projection_code) {
            console.warn('verschillende projecties');
          }
          //note bbox and cql_filter are mutually exclusive, so bbox needs to be within the cqlfilter
          if (field_geometry === null) {
            field_geometry = 'geometry';
          }
          var url =
            urlRequest +
            main_layers +
            '/query/?f=json&' +
            'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
            encodeURIComponent(
              '{"xmin":' +
                extent[0] +
                ',"ymin":' +
                extent[1] +
                ',"xmax":' +
                extent[2] +
                ',"ymax":' +
                extent[3] +
                ',"spatialReference":{"wkid":102100}}',
            ) +
            '&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*' +
            '&outSR=102100';
          // main_paramlayer verwijderd
          $.ajax({
            url: url,
            dataType: 'jsonp',
          }).done(loadFeatures);
        },
        strategy: olLoadingstrategy.bbox, // might cause duplicate features on move if not fid in features!
        projection: projection_code,
        useSpatialIndex: true,
      });
      // Executed when data is loaded by the $.ajax method
      var loadFeatures = function (response) {
        layer_source.addFeatures(new EsriJSON().readFeatures(response));
      };
      if (stylefield !== null) {
        layerstyle = getStyleForLayerViaStylefield(stylefield);
      } else {
        layerstyle = getStyleForLayer(name); //layerId
      }
      newLayer = new VectorLayer({
        title: name,
        source: layer_source,
        style: layerstyle,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        datefield: field_date,
        crossOrigin: 'anonymous',
      });
    } else if (layer_type === 'KMLSERVICE') {
      let controller = new AbortController();
      // eslint-disable-next-line no-inner-declarations
      async function fetchKML(url) {
        // cancel any pending requests
        controller.abort();
        // issue new request
        controller = new AbortController();
        const response = await fetch(url, {
          signal: controller.signal,
          credentials: 'include',
        });
        let kml;
        if (response.headers.get('content-type').endsWith('.kmz')) {
          // application/vnd.google-earth.kmz
          const blob = await response.blob(),
            zip = await JSZip.loadAsync(blob),
            file = zip.file(/.kml$/i)[0];
          kml = await file.async('text');
        } else {
          // application/vnd.google-earth.kml+xml
          kml = await response.text();
        }
        // to rewrite icon urls from the original source to a
        // route on our reversed proxy:
        kml = kml.replaceAll(secondary_url, main_url);
        // to prevent warnings about Mixed Content:
        kml = kml.replaceAll(
          'http://maps.google.com',
          'https://maps.google.com',
        );
        return kml;
      }
      const view = map.getView(),
        featureProjection = view.getProjection(),
        dataProjection = olProj.get(projection_code),
        kmlFormat = new KML(),
        readFeatures = (kml) => {
          const features = kmlFormat.readFeatures(kml, {
            dataProjection,
            featureProjection,
          });
          // list all balloon styles by their parent's style id attribute
          const balloonStyles = [
            ...kml.matchAll(
              /<Style.*?(?:\sid="(?<id>.*?)")?.*?>.*?.(?<BalloonStyle><BalloonStyle>.*?<\/BalloonStyle>).*?<\/Style>/gs,
            ),
          ].reduce(
            (accumulator, style) =>
              Object.defineProperty(accumulator, style.groups.id, {
                value: style.groups.BalloonStyle,
              }),
            {},
          );
          // create a balloonStyle property per feature
          // { bgColor, textColor, displayMode, text }
          features.forEach((feature) => {
            // find zero or one balloon styles by matching
            // the style id with the feature's styleUrl hash
            // value (i.e. the "fragment identifier")
            const properties = feature.getProperties(),
              styleUrl = properties.styleUrl || '',
              parts = styleUrl.split('#'),
              xml = balloonStyles[parts.pop()],
              balloonStyle = {};
            function addProperty(regex) {
              const matches = xml.match(regex);
              if (matches) {
                Object.assign(balloonStyle, matches.groups);
              }
            }
            if (parts.length > 1 && xml) {
              feature.set('balloonStyle', balloonStyle);

              addProperty(/<bgColor>\s*(?<bgColor>.*?)\s*<\/bgColor>/s);
              addProperty(/<textColor>\s*(?<textColor>.*?)\s*<\/textColor>/s);
              addProperty(
                /<displayMode>\s*(?<displayMode>.*?)\s*<\/displayMode>/s,
              );
              addProperty(
                /<text>(?:<!\[CDATA\[)\s*(?<text>.*?)\s*(?:\]\]>)<\/text>/s,
              );

              // transform KML's aarrggbb colors to OpenLayer's #rrggbbaa ColorLike
              ['bgColor', 'textColor'].forEach((key) => {
                const kml = balloonStyle[key];
                if (kml) {
                  balloonStyle[key] = `#${kml.slice(2, 4)}${kml.slice(
                    4,
                    6,
                  )}${kml.slice(6, 8)}${kml.slice(0, 2)}`;
                }
              });

              // substitute property values in text element
              for (const [key, value] of Object.entries(properties)) {
                balloonStyle.text = balloonStyle.text.replaceAll(
                  `$[${key}]`,
                  value,
                );
              }
            }
          });
          return features;
        };
      // https://developers.google.com/kml/documentation/kmlreference#abstractview
      const GOOGLE_EARTH_FIELD_OF_VIEW = 60;
      // https://math.stackexchange.com/a/1907854
      const FACTOR =
        2 * Math.tan(((GOOGLE_EARTH_FIELD_OF_VIEW / 2) * Math.PI) / 180);
      let cameraAltPct = 100,
        loadedFeatures = null;
      // eslint-disable-next-line no-inner-declarations
      async function loadFeatures(loaderExtent) {
        loaderExtent = loaderExtent || view.calculateExtent();
        const source = featureProjection,
          destination = dataProjection,
          extent = olProj.transformExtent(loaderExtent, source, destination),
          width = extent[2] - extent[0],
          height = extent[3] - extent[1],
          stretch = Math.round(
            Math.max(width, height) * dataProjection.getMetersPerUnit(),
          ),
          cameraAlt = ((stretch / FACTOR) * cameraAltPct) / 100,
          cameraLat = (extent[3] + extent[1]) / 2,
          cameraLon = (extent[2] + extent[0]) / 2,
          lookatLat = cameraLat,
          lookatLon = cameraLon,
          url = `${main_url}/${main_layers}?BBOX=${extent}&CAMERA=${cameraAlt},${cameraLat},${cameraLon},${lookatLat},${lookatLon}`,
          kml = await fetchKML(url),
          features = readFeatures(kml);
        loadedFeatures = features;
      }
      let previousResolution;
      layer_source = new VectorSource({
        loader: async (extent, resolution) => {
          if (!loadedFeatures) {
            try {
              await loadFeatures(extent);
            } finally {
              if (resolution > previousResolution) {
                previousResolution = resolution;
                // otherwise, on zoom out, any larger-scale
                // (more detailed) features would remain in
                // the source
                layer_source.clear(true);
              }
            }
          }
          layer_source.addFeatures(loadedFeatures);
          loadedFeatures = null;
        },
        strategy: olLoadingstrategy.bbox,
        useSpatialIndex: false,
      });
      $rootScope.$on('map-moveend', () => {
        const resolution = map.getView().getResolution();
        previousResolution = previousResolution || resolution;
        if (resolution < previousResolution) {
          previousResolution = resolution;
          // need to call refresh since otherwise, on zoom in,
          // any loadingstrategy would think it already knows
          // all the features, while WE know it has to fetch
          // more detailed features
          layer_source.refresh();
        }
      });
      layer_source.origRefresh = layer_source.refresh;
      layer_source.refresh = async () => {
        if (newLayer.getVisible() && newLayer.getOpacity() > 0) {
          // preload the new features before having the old
          // features cleared by the refresh()
          await loadFeatures();
          layer_source.origRefresh();
        }
      };
      layer_source.detail = (deltaPct) => {
        if (deltaPct) {
          // more detail => less altitude
          if (cameraAltPct >= deltaPct) {
            cameraAltPct -= deltaPct;
            layer_source.refresh();
          }
        }
        return 100 - cameraAltPct;
      };
      newLayer = new VectorLayer({
        title: name,
        source: layer_source,
        style: layerstyle,
        layerId: layerId,
        parentId: parent_layer_id,
        opacity: layer_opacity,
        zIndex: z_index,
        visible: blnShow,
        minResolution: main_min_level,
        maxResolution: main_max_level,
        layer_property_page_url: property_page_url,
        layergroup: layergroup,
        extent: layerextent,
        datefield: field_date,
        crossOrigin: 'anonymous',
      });
    }

    if (newLayer) {
      newLayer.set('firstMaxResolution', main_max_level);
      newLayer.set('secondMaxResolution', secondary_max_level);
      if (srid !== 'EPSG:28992') {
        const FACTOR = 3;
        ['Meetpunten', 'Meetpunt labels', 'KLIC'].forEach((title) => {
          if (newLayer.get('title') === title) {
            newLayer.set(
              'firstMaxResolution',
              newLayer.get('firstMaxResolution') * FACTOR,
            );
            newLayer.set(
              'secondMaxResolution',
              newLayer.get('secondMaxResolution') * FACTOR,
            );
            newLayer.setMaxResolution(newLayer.get('firstMaxResolution'));
          }
        });
        // One specific override.
        const SECOND_MAX_RESOLUTION_MEETPUNT_LABELS = 14;
        if (newLayer.get('title') === 'Meetpunt labels') {
          newLayer.set(
            'secondMaxResolution',
            SECOND_MAX_RESOLUTION_MEETPUNT_LABELS,
          );
        }
      }

      //make sure it is added in the correct z-order
      if (setAt !== -1) {
        map.getLayers().setAt(setAt, newLayer);
      } else if (ibefore !== -1) {
        //console.log("adding layer at position:" + ibefore + " because of z-index=" + z_index);
        map.getLayers().insertAt(ibefore, newLayer);
      } else {
        //console.log("adding layer at end of maplayers, z-index=" + z_index);
        maplayers.push(newLayer);
      }
      if (name === 'Meetpunten') {
        pointsLayer = newLayer;
      }
    }
    return newLayer;
  }

  function toggleLayer(name, oldstatus) {
    var status = !oldstatus;
    var maplayers = map.getLayers();
    for (var im = 0; im < maplayers.getLength(); im++) {
      var layer = maplayers.item(im);
      if (layer.get('title') === name) {
        var layergroup = layer.get('layergroup');
        if (layergroup !== null && status) {
          //found a group, and turning layer on, so turn off others in the group
          //console.log("is member of a layergroup");

          // first switches
          var inputEl = document.getElementById('maplayer_slider_' + name);
          var scope = angular.element(inputEl).scope();
          scope.TurnLayerSwitchesOff(name, layergroup);

          // now also the other maplayers off in this group
          for (var imm = 0; imm < maplayers.getLength(); imm++) {
            var layeringroup = maplayers.item(imm);
            if (
              layeringroup.get('layergroup') === layergroup &&
              layeringroup !== layer
            ) {
              layeringroup.setVisible(false);
              highlight.setVisible(layeringroup);
              if (layeringroup instanceof VectorLayer) {
                layeringroup.get('source').dispatchEvent('change');
              }
            }
          }
        }
        layer.setVisible(status);
        highlight.setVisible(layer);
        if (layer instanceof VectorLayer) {
          layer.get('source').dispatchEvent('change');
        }
        break;
      }

      // Having doubts about this section. Is it in use at all?
      if (layer instanceof LayerGroup) {
        var sublayers = layer.getLayers();
        for (var is = 0; is < sublayers.getLength(); is++) {
          var sublayer = sublayers.item(is);
          if (sublayer.get('title') === name) {
            sublayer.setVisible(status);
            layer.setVisible(status); //all others in group ...
            if (layer instanceof VectorLayer) {
              layer.get('source').dispatchEvent('change');
            }
            break;
          }
        }
      }
    }

    map.updateSize();
  }

  function changeLayerTransparency(name, transparency) {
    var maplayers = map.getLayers();
    for (var i = 0; i < maplayers.getLength(); i++) {
      const maplayer = maplayers.item(i);
      if (maplayer.get('title') === name) {
        maplayer.setOpacity(transparency);
        if (!maplayer.getVisible() && transparency > 0) {
          // from invisible to visible, because transparancy demands visible
          toggleLayer(name, false);
        }
        if (maplayer.getVisible() && transparency == 0) {
          // from visible to invisible, because visible layers could cause "tainted layers" in canvas.ToBlob
          toggleLayer(name, true);
        }
        return true;
      }
    }
  }

  function removeLayer(name) {
    const layer = getLayer(name);
    if (layer) {
      map.removeLayer(layer);
    }
    map.updateSize();
  }

  function setView(mapinfo) {
    const view = map.getView();
    view.setCenter(mapinfo.center || view.getCenter());
    view.setZoom(mapinfo.zoom || view.getZoom());
    view.setRotation(0);
  }

  function zoomInToLayer({ layer, center, maxZoom, duration }) {
    layer = getLayer(layer);
    const view = map.getView(),
      maxResolution = layer.getMaxResolution() * 0.999, // in practice, resolution limits prove not inclusive
      minResolution = Math.max(
        layer.getMinResolution() * 1.001, // in practice, resolution limits prove not inclusive
        maxZoom ? view.getResolutionForZoom(maxZoom) : 0,
      ),
      resolution = view.getResolution(),
      animations = {};
    if (resolution < minResolution) {
      animations.resolution = minResolution;
    } else if (resolution > maxResolution) {
      animations.resolution = maxResolution;
    }
    if (center) {
      animations.center = center;
    }
    if (Object.keys(animations).length) {
      animations.duration = duration;
      view.animate(animations);
    }
  }

  function fitToExtent(geometryOrExtent, opt_options) {
    const options = opt_options || {},
      view = map.getView();
    options.duration = options.duration || 500;
    options.callback = () => {
      if (options.layer) {
        zoomInToLayer(options);
      }
    };
    view.fit(geometryOrExtent, options);
  }

  function timeFilterMapLayers(startdate, enddate, filter) {
    //format the dates to the desired format to use in the cql filter
    blnfilterontime = filter;
    var maplayers = map.getLayers(); //.getArray();
    var i;
    if (blnfilterontime && startdate !== null && enddate !== null) {
      start_date = formatDate(startdate);
      end_date = formatDate(enddate);
      //add timefilters
      for (i = 0; i < maplayers.getLength(); i++) {
        var layersource = maplayers.item(i).getSource();
        var field = maplayers.item(i).get('datefield');
        if (field !== null) {
          //console.log("datefield:" + field);
          var timefilter =
            'if_then_else(isNull(' +
            field +
            "), '2111-11-11'', " +
            field +
            ") >= '" +
            start_date +
            "' AND if_then_else(isNull(" +
            field +
            "), '1111-11-11', " +
            field +
            ") <= '" +
            end_date +
            "'";
          if (
            layersource !== undefined &&
            Object.prototype.hasOwnProperty.call(layersource, 'params_')
          ) {
            //WMS layers
            var query = layersource.getParams().cql_filter;
            //if_then_else(isNull(" + field + "), '2111-11-11', " + field + ") is used to handle empty datefield
            //we use it here also to search in the filter and see if cql filter is a date filter
            if (query === undefined) {
              query = timefilter;
            } else {
              //see if timefilter is in the existing filter
              var n = query.indexOf(
                'if_then_else(isNull(' +
                  field +
                  "), '2111-11-11', " +
                  field +
                  ')',
              );
              if (n === -1) {
                //existing filter is not a time filter, add the timefilter
                query += ' AND ' + timefilter;
              } else {
                //found it
                if (n === 0) {
                  //it is the timefilter, replace it
                  query = timefilter;
                } else {
                  // timefilter is not the only filter, but timefilter is always at the end, so strip and add new version again
                  query = query.substring(0, n) + ' AND ' + timefilter;
                }
              }
            }
            //update the filter
            layersource.updateParams({
              cql_filter: query,
            });
          }
          if (
            layersource !== undefined &&
            Object.prototype.hasOwnProperty.call(layersource, 'loader_')
          ) {
            //is WFS
            layersource.clear(true); //zorgen dat herladen wordt
          }
        } //else has no datefield so no need to update the filter
      }
    } else {
      blnfilterontime = false;
      start_date = null;
      end_date = null;
      //remove the timefilters
      for (i = 0; i < maplayers.getLength(); i++) {
        var layersource2 = maplayers.item(i).getSource();
        var field2 = maplayers.item(i).get('datefield');
        if (field2 !== null) {
          //console.log("datefield:" + field);
          if (
            layersource2 !== undefined &&
            Object.prototype.hasOwnProperty.call(layersource2, 'params_')
          ) {
            //WMS layers
            var query2 = layersource2.params_.cql_filter;

            //see if timefilter is in the existing filter
            var nn = query2.indexOf(
              'if_then_else(isNull(' +
                field2 +
                "), '2111-11-11', " +
                field2 +
                ')',
            );
            if (nn !== -1) {
              //existing filter contains the time filter
              {
                //found it
                if (nn === 0) {
                  //it is the timefilter,
                  query2 = '';
                } else {
                  // timefilter is not the only filter, but timefilter is always at the end, so strip
                  query2 = query2.substring(0, nn);
                }
              }
              //use the default/initial timefilter
              if (
                default_filterontime &&
                default_start_date !== null &&
                default_end_date !== null
              ) {
                var timefilter2 =
                  'if_then_else(isNull(' +
                  field2 +
                  "), '2111-11-11', " +
                  field2 +
                  ") >= '" +
                  default_start_date +
                  "' AND if_then_else(isNull(" +
                  field2 +
                  "), '1111-11-11', " +
                  field2 +
                  ") <= '" +
                  default_end_date +
                  "'";
                query2 += ' AND ' + timefilter2;
              }
              //update the filter
              layersource2.updateParams({
                cql_filter: query2,
              });
            }
          }
          if (
            layersource2 !== undefined &&
            Object.prototype.hasOwnProperty.call(layersource2, 'loader_')
          ) {
            //is WFS
            layersource2.clear(true); //zorgen dat herladen wordt
          }
        } //else has no datefield so no need to update the filter
      }
    }
  }

  /** PRIVATE */

  function setMaxResolutions(model) {
    const key = model.modus().startsWith('P')
      ? 'secondMaxResolution'
      : 'firstMaxResolution';
    [meetpunten, labels, klic].forEach((layer) => {
      layer.setMaxResolution(layer.get(key));
    });
  }

  $rootScope.$on('model-modus', (_, model) => {
    setMaxResolutions(model);
  });

  function zoomToProject(project) {
    const { minx, miny, maxx, maxy } = project;

    if (project.srid === 28992 && minx < -7000 && maxy > 629000) {
      // Cater for some tweaked projects that have a certain fake location.
      return;
    }

    const mapExtent = map.getView().calculateExtent();
    const projectBottomLeftCornerIsInMap = olExtent.containsXY(
      mapExtent,
      minx,
      miny,
    );
    const projectTopLeftCornerIsInMap = olExtent.containsXY(
      mapExtent,
      minx,
      maxy,
    );
    const projectTopRightCornerIsInMap = olExtent.containsXY(
      mapExtent,
      maxx,
      maxy,
    );
    const projectBottomRightCornerIsInMap = olExtent.containsXY(
      mapExtent,
      maxx,
      miny,
    );

    function projectIsCompletelyInMap() {
      return (
        projectBottomLeftCornerIsInMap &&
        projectTopLeftCornerIsInMap &&
        projectTopRightCornerIsInMap &&
        projectBottomRightCornerIsInMap
      );
    }

    function projectIsCompletelyOutMap() {
      return (
        !projectBottomLeftCornerIsInMap &&
        !projectTopLeftCornerIsInMap &&
        !projectTopRightCornerIsInMap &&
        !projectBottomRightCornerIsInMap
      );
    }

    if (projectIsCompletelyInMap() || projectIsCompletelyOutMap()) {
      fitToExtent([minx, miny, maxx, maxy]);
    }
  }

  const meetpuntenStyleCache = {};
  meetpuntenStyleCache.get = (svgSize, svgPath, svgColor) => {
    const key = svgSize + svgPath + svgColor;
    return meetpuntenStyleCache[key] || newStyle();
    function newStyle() {
      const svg = getSvg(svgSize, svgPath, svgColor);
      const src = 'data:image/svg+xml;utf,' + encodeURIComponent(svg);
      const style = new Style({
        image: new Icon({
          src,
          imgSize: [svgSize, svgSize],
          scale: 0.4,
        }),
      });
      meetpuntenStyleCache[key] = style;
      return style;
    }
  };

  function meetpuntenStyle(model) {
    const include = model.filter.GRID
      ? (id) => model.filter.GRID.includes(id)
      : () => true;
    return (feature) => {
      const styles = [];
      const id = feature.get('id');
      if (include(id)) {
        const name = feature.get('onderzoekstype');
        const categorie = model.presentatie.key;
        const colorIndex =
          feature.get(
            'categorie_' +
              (categorie === 'xystatus' ? 'meetpuntstatus' : categorie),
          ) || 0;
        const svg = getBaseSvg(name, colorIndex);
        const white = '#b12db5';
        const color = feature.get('aangeboden') === false ? svg.color : white;
        styles.push(meetpuntenStyleCache.get(svg.size, svg.path, color));
      }
      return styles;
    };
  }

  function meetpuntenRedraw(model) {
    meetpunten.setStyle(meetpuntenStyle(model));
    // Workaround style not always completely applied:
    setTimeout(() => meetpunten.changed());
  }
  $rootScope.$on('model-presentatie', (_, model) => meetpuntenRedraw(model));

  function filterGrid(model, refreshNow) {
    // Voor meetpunten (vector) wordt model.filter.GRID lokaal in de
    // stylefunction gedaan.
    // Waarom?
    // Omdat het Grid (ook) zelf de rijen filtert op basis van de complete
    // set. En het Grid bij het verschuiven van de Kaart ook weer een
    // nieuwe complete set moet krijgen, waar het Grid zélf weer het filter
    // op gaat toepassen.
    meetpuntenRedraw(model);
    // Voor labels (image) moet de server alles weten.
    setCqlFilter(
      'GRID',
      labelsSource,
      model.filter.GRID,
      () => 'id in (' + model.filter.GRID.concat(0) + ')',
    );
    return refreshNow ? refresh([labels]) : undefined;
  }

  function setCqlFilter(name, source, setValue, getValue) {
    // Set or unset a named item in the source's CQL filter,
    // calling the getValue function (only) if the item's going to be set.
    if (setValue) {
      source.CQL_FILTER.setItem(name, getValue());
    } else {
      source.CQL_FILTER.unsetItem(name);
    }
  }

  function filterKaart(model) {
    // Voor meetpunten (vector) wordt model.filter.KAART lokaal in de
    // CLIENT_FILTER gedaan.
    // Waarom?
    // Because in the case of a filter geometry loaded from a Shape file,
    // the geometry's WKT can become too large to be valid as a CQL_FILTER
    // parameter.
    if (model.filter.KAART) {
      meetpuntenSource.CLIENT_FILTER = (feature) => {
        const geom = feature.getGeometry(),
          coord = geom.getFirstCoordinate();
        return model.filter.KAART.intersectsCoordinate(coord);
      };
    } else {
      delete meetpuntenSource.CLIENT_FILTER;
      setCqlFilter('KAART', labelsSource);
      refresh([labels]);
    }
    refresh([meetpunten]);
  }
  $rootScope.$on('model-getFeatures', (_, model) => {
    setCqlFilter('KAART', labelsSource, model.filter.KAART, () => {
      setTimeout(refresh([labels]));
      // Note that this list could become too large as well,
      // in which case the request for labels will fail.
      // Should resolve itself after zooming in.
      return (
        'id in (' +
        model
          .getFeatures()
          .map((feature) => feature.get('id'))
          .concat(0) +
        ')'
      );
    });
  });

  function klicUntag(refreshNow) {
    if (window.INTERNAL_USER) {
      // Internal users see all KLICs of all projects when no
      // specific project is selected.
      klicSource.VIEWPARAMS.unsetItem('tag');
    } else {
      // Since the KLIC Service doesn't know which projects
      // belong to which customers, when no project is selected,
      // we hide all KLICs for external users
      klicSource.VIEWPARAMS.setItem('tag', '--hide-all');
      if (refreshNow) {
        refresh([klic]);
      }
    }
  }

  function excludePlan(model) {
    const exclude = model.exclude.PLAN;
    setCqlFilter(
      'EXCLUDE',
      plantekeningenSource,
      exclude && exclude.length,
      () => `features not in (${exclude})`,
    );
    refresh([plantekeningen]);
  }

  function excludeProject(model) {
    const exclude = model.exclude.PROJECT;
    setCqlFilter(
      'EXCLUDE',
      projectkaartenSource,
      exclude && exclude.length,
      () => `features not in (${exclude})`,
    );
    refresh([projectkaarten]);
  }

  function excludeKlic(model) {
    const exclude = model.exclude.KLIC;
    function values() {
      return exclude.map((klicmeldnummer) => `'${klicmeldnummer}'`);
    }
    setCqlFilter(
      'EXCLUDE',
      klicSource,
      exclude && exclude.length,
      () => `klicmeldnummer not in (${values()})`,
    );
    refresh([klic]);
  }

  function excludeNotitie(model) {
    const exclude = model.exclude.NOTITIE;
    setCqlFilter(
      'EXCLUDE',
      notitiesSource,
      exclude && exclude.length,
      () => `fid not in (${exclude})`,
    );
    refresh([notities]);
  }

  $rootScope.$on('model-filter-grid', (_, model) => filterGrid(model, true));
  $rootScope.$on('model-filter-kaart', (_, model) => filterKaart(model));
  $rootScope.$on('model-exclude-plan', (_, model) => excludePlan(model));
  $rootScope.$on('model-exclude-project', (_, model) => excludeProject(model));
  $rootScope.$on('model-exclude-klic', (_, model) => excludeKlic(model));
  $rootScope.$on('model-exclude-notitie', (_, model) => excludeNotitie(model));
  $rootScope.$on('model-project', (_, model) => {
    const { project } = model;
    if (project) {
      if (srid === `EPSG:${project.srid}`) {
        // The map projection is not going to change; trigger the zoom from
        // here.
        zoomToProject(project);
      }
      notitiesSource.VIEWPARAMS.setItem('subproject', project.id);
      plantekeningenSource.VIEWPARAMS.setItem('subproject', project.id);
      projectkaartenSource.VIEWPARAMS.setItem('subproject', project.id);
      klicSource.VIEWPARAMS.setItem('tag', project.subprojectnr);
    } else {
      notitiesSource.VIEWPARAMS.unsetItem('subproject');
      plantekeningenSource.VIEWPARAMS.unsetItem('subproject');
      projectkaartenSource.VIEWPARAMS.unsetItem('subproject');
      klicUntag();
    }
    excludePlan(model);
    excludeProject(model);
    excludeKlic(model);
    excludeNotitie(model);
    projecten.setStyle(projectenStyle(model));
    filterProjectAndBronnen(model);
    filterGrid(model);
    filterKaart(model);
  });

  function filterProjectAndBronnen({ project, bronnen, modus }) {
    bronnen = project ? project.bronnen : bronnen;
    project = project || {};
    const hasValue = project.id || bronnen.getSize();
    [meetpuntenSource, labelsSource].forEach((source) =>
      setCqlFilter('PROJECT_BRONNEN', source, hasValue, () => {
        let filter = '';
        if (project.id) {
          filter = 'subproject=' + project.id;
        }
        if (bronnen.getSize()) {
          if (filter) {
            filter += ' or ';
          }
          filter += 'categorie_bron in (' + Array.from(bronnen.values()) + ')';
        }
        return filter;
      }),
    );

    // P(F)-mode (not KP(F)-mode):
    if (modus().startsWith('P')) {
      meetpuntenSource.VIEWPARAMS.setItem('subproject', project.id);
    } else {
      meetpuntenSource.VIEWPARAMS.unsetItem('subproject');
    }
  }

  function onModelBronnen(_, model) {
    filterProjectAndBronnen(model);
    refresh([meetpunten, labels]);
  }
  $rootScope.$on('model-bronnen', onModelBronnen);

  function onModelLabels(_, model) {
    labelsSource.VIEWPARAMS.setItem('projectnr', model.labels.has('PROJECTNR'));
    labelsSource.VIEWPARAMS.setItem('tagklant', model.labels.has('TAGKLANT'));
    refresh([labels]);
  }
  $rootScope.$on('model-labels', onModelLabels);

  let highlight,
    projecten,
    meetpunten,
    meetpuntenSource,
    labels,
    labelsSource,
    klic,
    klicSource,
    plantekeningen,
    plantekeningenSource,
    projectkaarten,
    projectkaartenSource,
    notities,
    notitiesSource;

  $rootScope.$on('map-layers-loaded', () => {
    setHighlight('Meetpunten');
    projecten = getLayer('Projecten');
    meetpunten = getLayer('Meetpunten');
    meetpuntenSource = meetpunten.getSource();
    labels = getLayer('Meetpunt labels');
    labelsSource = labels.getSource();
    klic = getLayer('KLIC');
    klicSource = klic.getSource();
    const refreshNow = true;
    klicUntag(refreshNow);
    plantekeningen = getLayer('Plantekeningen');
    plantekeningenSource = plantekeningen.getSource();
    projectkaarten = getLayer('Projectkaarten');
    projectkaartenSource = projectkaarten.getSource();
    notities = getLayer('Notities');
    notitiesSource = notities.getSource();
    let cleared = false;
    meetpuntenSource.on('clear', () => {
      if (meetpunten.getVisible() && meetpunten.getOpacity()) {
        cleared = true;
      }
    });
    meetpunten.on('postrender', () => {
      if (cleared) {
        cleared = false;
        modelGetFeatures();
      }
    });
    meetpunten.on('change:visible', () => modelGetFeatures());
    meetpunten.on('change:opacity', () => modelGetFeatures());

    const model = modelService.getModel();
    onModelLabels(null, model);
    onModelBronnen(null, model);
    setMaxResolutions(model);
  });

  $rootScope.$on('map-moveend', () => {
    // Cannot rely on postrender; it fires more than we'd want.
    if (meetpunten.getVisible() && meetpunten.getOpacity()) {
      modelGetFeatures();
    }
  });

  function modelGetFeatures() {
    modelService.update('model-getFeatures', (filtered) => {
      const view = map.getView();
      if (
        meetpunten.getVisible() &&
        meetpunten.getOpacity() > 0 &&
        view.getResolution() <= meetpunten.getMaxResolution() &&
        view.getResolution() >= meetpunten.getMinResolution()
      ) {
        const size = map.getSize();
        const extent = view.calculateExtent(size);
        const filter = filtered ? modelService.getModel().filter.GRID : [];
        const filterFn = filter.length
          ? (f) => filter.includes(f.get('id'))
          : () => true;
        return meetpuntenSource.getFeaturesInExtent(extent).filter(filterFn);
      } else {
        return [];
      }
    });
  }

  function setHighlight(targetLayer, opt_getFeatures) {
    highlight = new Highlight(targetLayer, opt_getFeatures);
  }

  $rootScope.$on('model-grid', (_, model) => {
    if (model.grid.ONDERZOEKEN || model.grid.METADATA) {
      setHighlight('Meetpunten');
    }
  });

  $rootScope.$on('model-selectie', (_, model) => {
    highlight.update(model.selectie);
  });

  function Highlight(targetLayer, opt_getFeatures) {
    targetLayer = getLayer(targetLayer);

    const title = 'highlight_',
      layer = getLayer(title) || createHighlightLayer(),
      source = layer.getSource(),
      getFeatures =
        opt_getFeatures ||
        ((ids) =>
          targetLayer
            .getSource()
            .getFeatures()
            .filter((f) => ids.includes(f.get('id'))));

    if (targetLayer) {
      if (!(opt_getFeatures || targetLayer instanceof VectorLayer)) {
        throw console.err("Don't know how to load hightlighted features.");
      }
      layer.setMinResolution(targetLayer.getMinResolution());
      layer.setMaxResolution(targetLayer.getMaxResolution());
      layer.setMinZoom(targetLayer.getMinZoom());
      layer.setMaxZoom(targetLayer.getMaxZoom());
      layer.setVisible(targetLayer.getVisible());
      layer.setZIndex(targetLayer.getZIndex() - 1);
    }

    this.setVisible = (changedLayer) => {
      if (changedLayer === targetLayer) {
        layer.setVisible(changedLayer.getVisible());
      }
    };

    this.update = (ids) => {
      const fast = true;
      source.clear(fast);
      if (ids.length) {
        source.addFeatures(getFeatures(ids));
      }
    };

    function createHighlightLayer() {
      const meetpuntenStyle = meetpuntenStyleCache.get(
        100,
        svgs.default.path,
        svgColors['highlight1'],
      );
      const styleOptions = {
        image: new Icon({
          src: meetpuntenStyle.getImage().getSrc(),
          imgSize: [100, 100],
          scale: 1,
          offset: [0.5, 0.5],
        }),
        stroke: new Stroke({
          color: [255, 255, 0, 0.7],
          width: 6,
        }),
        fill: new Fill({
          color: [255, 255, 0, 0.2],
        }),
      };
      const layer = new VectorLayer({
        title: title,
        style: new Style(styleOptions),
        source: new VectorSource(),
      });
      map.addLayer(layer);
      return layer;
    }
  }

  function initmap() {
    const zoomControl = new Zoom({ className: 'ol-zoom ol-zoom-custom' });
    const zoomSliderControl = new ZoomSlider();
    const rotateControl = new Rotate({
      className: 'ol-rotate ol-rotate-custom',
      label: northarrow,
      autoHide: true,
    });
    const scaleLineControl = new ScaleLine();
    const mousePositionControlWGS84 = new MousePosition({
      // format coords as "HDMS (x,y)"
      coordinateFormat: olCoordinate.createStringXY(5),
      className: 'custom-mouse-position2 ol-unselectable',
      //target: document.getElementById('mousepos'),
      projection: 'EPSG:4326',
    });
    function createCopyPositionControl() {
      const element = document.createElement('div');
      element.classList.add('custom-copy-position', 'ol-unselectable');
      element.title = 'Kopieer huidige XY';
      element.addEventListener('click', () => {
        const [x, y] = map.getView().getCenter(),
          format = (ordinate) => ordinate.toFixed(2),
          text = `${format(x)}, ${format(y)}`;
        navigator.clipboard.writeText(text).then(
          () => toastr.success(`${text} gekopieerd naar klembord`),
          () => toastr.error('Kopiëren naar klembord mislukt'),
        );
      });
      const icon = document.createElement('i');
      icon.classList.add('material-icons');
      icon.textContent = 'content_copy';
      element.append(icon);
      const copyPositionControl = new Control({ element });
      return copyPositionControl;
    }
    function createGeolocationControl() {
      const geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
      });
      const accuracyFeature = new Feature();
      const positionFeature = new Feature();
      const layer = new VectorLayer({
        title: 'geolocation',
        source: new VectorSource({
          features: [accuracyFeature, positionFeature],
        }),
        visible: true,
      });
      geolocation.on('change:position', () => {
        const coordinate = geolocation.getPosition();
        positionFeature.setGeometry(coordinate ? new Point(coordinate) : null);
      });
      geolocation.on('change:accuracyGeometry', () =>
        accuracyFeature.setGeometry(geolocation.getAccuracyGeometry()),
      );
      geolocation.on('error', (error) => toastr.warning(error.message));
      positionFeature.setStyle(
        new Style({
          image: new Icon({
            src: icLocationSvg,
          }),
        }),
      );
      $rootScope.$on('map-layers-loaded', () => {
        layer.setMap(map);
        // When switching the projection, the position is updated automatically
        // to the new projection, while the accuracy remains as it was - so we
        // transform the accuracy geometry by hand.
        const geom = accuracyFeature.getGeometry();
        if (geom) {
          const source = geolocation.getProjection();
          const destination = srid;
          geom.transform(source, destination);
        }
        geolocation.setProjection(srid);
        geolocation.setTracking(true);
      });
      const element = document.createElement('div'),
        icon = document.createElement('button');
      icon.classList.add('material-icons');
      icon.textContent = 'gps_not_fixed';
      icon.style.fontSize = 'initial';
      element.append(icon);
      element.classList.add(
        'ol-control',
        'custom-geolocation',
        'ol-unselectable',
      );
      element.style.cursor = 'pointer';
      element.title = 'Zoom naar huidige positie';
      element.addEventListener('click', () => {
        const geometry =
          accuracyFeature.getGeometry() || positionFeature.getGeometry();
        if (geometry) {
          fitToExtent(geometry, { maxZoom: 13 });
        } else {
          toastr.warning('Positie niet bekend.');
        }
      });
      const geolocationControl = new Control({ element });
      return geolocationControl;
    }
    mousePositionControl = new MousePosition({
      // format coords as "HDMS (x,y)"
      coordinateFormat: olCoordinate.createStringXY(0),
      className: 'custom-mouse-position ol-unselectable',
      //target: document.getElementById('mousepos'),
      projection: srid,
    });
    function createSridControl() {
      const element = document.createElement('div');
      element.classList.add('custom-srid', 'ol-unselectable');
      element.title = 'Huidige kaartprojectie';
      sridControl = new Control({ element });
      sridControl.setText = (srname = '') => {
        let textContent = srid || 'EPSG:28992';
        if (srname) {
          textContent += ` ${srname}`;
        }
        element.textContent = textContent;
      };
      sridControl.setText('RD-coördinaten');
      return sridControl;
    }

    map = new Map({
      target: 'map',
      view: largeView,
      loadTilesWhileAnimating: false,
      loadTilesWhileInteracting: false,
      controls: olControl
        .defaults({
          attribution: false,
          rotate: false,
          zoom: false,
        })
        .extend([
          zoomControl,
          zoomSliderControl,
          scaleLineControl,
          rotateControl,
          createCopyPositionControl(),
          mousePositionControl,
          mousePositionControlWGS84,
          createGeolocationControl(),
          createSridControl(),
        ]),
      interactions: olInteraction.defaults({
        keyboard: false,
        pinchRotate: false,
      }),
      logo: merkatorLogoKleinPng,
    });

    window.map = map;

    map.on(
      'moveend',
      debounce(() => {
        // after pan, zoom in, or zoom out
        $rootScope.$emit('map-moveend', map);
      }, 250),
    );

    map.on('singleclick', function (e) {
      if (drawing()) {
        return;
      }
      $rootScope.$emit('mapClicked', e, map);
      const anyTools = !!modelService.getModel().tools.getSize();
      const pointeditor = modelService.getModel().tools.has('POINTEDITOR');
      if (anyTools && !pointeditor) {
        e.stopPropagation();
        return;
      }
      setTimeout(function () {
        if (mapSelectionService.SelectSomething(e)) {
          e.stopPropagation(); // do not zoomin or anything else
        }
      });
    });

    map.on('dblclick', function (e) {
      if (drawing()) {
        return;
      }
      $rootScope.$emit('mapDoubleClicked', e);
      const anyTools = !!modelService.getModel().tools.getSize();
      if (anyTools) {
        e.stopPropagation();
      }
      if (mapSelectionService.hasIdentifyLayers() && !anyTools) {
        if (mapSelectionService.SelectSomething(e)) {
          e.stopPropagation();
        }
      }
    });

    // Make pinch-zoom-out on the map scale down the VisualViewport
    // (https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport) when a
    // very far pinch-zoom-in had accidentally scaled up the VisualViewport.
    map.once('loadend', () => {
      // Structure taken from
      // https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures.

      // Install event handlers for the pointer target
      const el = document.getElementsByClassName('ol-viewport').item(0);
      el.addEventListener('pointerdown', pointerdownHandler);
      el.addEventListener('pointermove', pointermoveHandler);

      // Use same handler for pointer{up,cancel,out,leave} events since
      // the semantics for these events - in this app - are the same.
      el.addEventListener('pointerup', pointerupHandler);
      el.addEventListener('pointercancel', pointerupHandler);
      el.addEventListener('pointerout', pointerupHandler);
      el.addEventListener('pointerleave', pointerupHandler);

      // Global vars to cache event state
      const evCache = [];
      let prevDiff = -1;

      function removeEvent(ev) {
        // Remove this event from the target's cache
        const index = evCache.findIndex(
          (cachedEv) => cachedEv.pointerId === ev.pointerId,
        );
        evCache.splice(index, 1);
      }

      function pointerdownHandler(ev) {
        // The pointerdown event signals the start of a touch interaction.
        // This event is cached to support 2-finger gestures
        evCache.push(ev);
        log('pointerDown', ev);
      }

      function pointerupHandler(ev) {
        log(ev.type, ev);
        // Remove this pointer from the cache.
        removeEvent(ev);
        // If the number of pointers down is less than two then reset diff tracker
        if (evCache.length < 2) {
          prevDiff = -1;
        }
      }

      const view = map.getView();
      const minResolution = view.getMinResolution();
      // This is the discriminator for whether pinch-zoom-out will zoom out the
      // map (normal case), or scale down the VisualViewport (exceptional case).
      let scaleAtWhichItOccurred;
      // Reset the discriminator when the VisualViewport was scaled down again
      // in any way.
      window.visualViewport.addEventListener('resize', () => {
        if (window.visualViewport.scale <= scaleAtWhichItOccurred) {
          scaleAtWhichItOccurred = undefined;
        }
      });

      function pointermoveHandler(ev) {
        // This function implements a 2-pointer horizontal pinch/zoom gesture.

        // log("pointerMove", ev);

        // Find this event in the cache and update its record with this event
        const index = evCache.findIndex(
          (cachedEv) => cachedEv.pointerId === ev.pointerId,
        );
        evCache[index] = ev;

        // If two pointers are down, check for pinch gestures
        if (evCache.length === 2) {
          // Calculate the distance between the two pointers
          const curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);

          if (prevDiff > 0) {
            const resolution = view.getResolution();
            if (curDiff > prevDiff) {
              // The distance between the two pointers has increased
              log(`Pinch moving OUT -> Zoom in: ${resolution}`, ev);
              if (!scaleAtWhichItOccurred && resolution < minResolution) {
                scaleAtWhichItOccurred = window.visualViewport.scale;
                log(
                  `It occurrred at scale ${scaleAtWhichItOccurred}, resolution ${resolution}`,
                  ev,
                );
              }
            }
            if (curDiff < prevDiff) {
              // The distance between the two pointers has decreased
              log(`Pinch moving IN -> Zoom out: ${resolution}`, ev);
              // Discriminate VisualViewport scale against
              // scaleAtWhichItOccurred; test is false when
              // scaleAtWhichItOccurred is undefined.
              if (window.visualViewport.scale > scaleAtWhichItOccurred) {
                log('Stop propagation', ev);
                ev.stopPropagation();
              }
            }
          }

          // Cache the distance for the next move event
          prevDiff = curDiff;
        }
      }

      // Log events flag
      const LOG_EVENTS = false;

      function log(prefix, ev) {
        if (!LOG_EVENTS) return;
        console.log({
          prefix,
          pointerID: ev.pointerId,
          pointerType: ev.pointerType,
          isPrimary: ev.isPrimary,
        });
      }
    });

    const drawing = () => {
      var result = false;
      map.getInteractions().forEach(function (interaction) {
        if (interaction instanceof Draw) {
          result = true;
        }
      });
      return result;
    };
  }

  function getHeaders(href) {
    // e.g. /geowep/mapproxy/...
    let destinationIsOurProxy = href.startsWith('/');
    if (!destinationIsOurProxy) {
      // e.g. https://localhost:7443/geowep/mapproxy/... from http://localhost:3000
      const url = new URL(href);
      destinationIsOurProxy = url.hostname === window.location.hostname;
    }
    if (destinationIsOurProxy) {
      // Return something truish, so that the caller will send headers,
      // including (and that's what we're after in this case) any cookies.
      return {};
    }
  }

  function headersLoadFunction(opt_modifier) {
    return (tileOrImage, src) => {
      if (opt_modifier) {
        src = opt_modifier(src, tileOrImage);
      }
      const headers = getHeaders(src);
      if (headers) {
        // Explicitly issue a request through fetch(), instead of just assigning
        // the URL to the HTML src attribute (which is the default
        // implementation), to ensure any headers and cookies(!) are passed
        // along. This also makes the request subject to all fetch's CORS
        // rulings, so this will only work in specific cases.
        fetch(src, { headers })
          .then((response) => response.blob())
          .then((blob) => {
            tileOrImage.getImage().src = URL.createObjectURL(blob);
          });
      } else {
        // The default implementation.
        tileOrImage.getImage().src = src;
      }
    };
  }

  function setTileLoadFunction(source, opt_modifier) {
    source.setTileLoadFunction(headersLoadFunction(opt_modifier));
  }

  function setImageLoadFunction(source, opt_modifier) {
    source.setImageLoadFunction(headersLoadFunction(opt_modifier));
  }

  async function wmtsSourceFromCapabilities(url, main_layers, main_format) {
    let wmtsurl;
    const urlParts = url.split('?');
    if (urlParts[0] != Proxy_URL) {
      wmtsurl = urlParts[0] + '?service=WMTS&request=GetCapabilities';
    } else {
      wmtsurl =
        urlParts[0] +
        '?' +
        urlParts[1] +
        '?service=WMTS&request=GetCapabilities';
    }
    const headers = getHeaders(url);
    const response = await fetch(wmtsurl, { headers });
    const capabilities = new WMTSCapabilities().read(await response.text());
    const layer = capabilities.Contents.Layer.find(
      (l) => l.Identifier === main_layers,
    );
    let matrixSet, format;
    if (layer) {
      const matrixSets = layer.TileMatrixSetLink.map(({ TileMatrixSet }) => {
        const set = capabilities.Contents.TileMatrixSet.find(
          ({ Identifier }) => Identifier === TileMatrixSet,
        );
        return set;
      });
      // srid global variable, e.g. EPSG:4326
      matrixSet =
        matrixSets.find(({ Identifier }) => Identifier === srid + ':16') ||
        matrixSets.find(({ Identifier }) => Identifier === srid) ||
        matrixSets.find(({ Identifier }) => Identifier.startsWith(srid)) ||
        matrixSets.find(({ SupportedCRS }) => SupportedCRS === srid) ||
        matrixSets.find(({ SupportedCRS }) => SupportedCRS.endsWith(srid)) ||
        // urn:ogc:def:crs:EPSG::28992
        matrixSets.find(({ SupportedCRS }) =>
          SupportedCRS.endsWith(srid.split(':').join('::')),
        ) ||
        matrixSets[0];
      format = layer.Format.find((f) => f === main_format);
    }

    const options = optionsFromCapabilities(capabilities, {
      layer: main_layers,
      matrixSet: matrixSet.Identifier,
      format,
      crossOrigin: 'anonymous',
    });
    if (!options) {
      toastr.error('options uitlezen voor ' + main_layers + ' mislukt ');
      console.error(
        'cannot find options for ' + main_layers + ' in ' + wmtsurl,
      );
      return;
    }
    options.cacheSize = 100 * 2048;

    const source = new WMTS(options);
    setTileLoadFunction(source);

    // Save here what we need to provide to MapFish later.
    source.TILE_MATRIX_ARRAY = matrixSet.TileMatrix;

    function replace(a, b) {
      source.setUrl(source.getUrls()[0].replace(a, b));
    }

    // Route the request through the reverse proxy to let it add the
    // Authorization header.
    replace('https://atlas.cyclomedia.com/', '/geowep/cyclomedia/');

    // Some tweak.
    replace(
      config.prefix + '/geowep/topoplus/',
      '/geowep/topoplus/topoplus/map/',
    );

    return source;
  }

  function createPolygonStyle(strokecolor, strokewidth, fillcolor) {
    return new Style({
      stroke: new Stroke({
        color: ValidateColor(strokecolor),
        width: strokewidth,
      }),
      fill: new Fill({ color: ValidateColor(fillcolor) }), //'rgba(255,255,255,0.5)'
    });
  }

  function createPolylineStyle(strokecolor, strokewidth) {
    return new Style({
      stroke: new Stroke({
        color: ValidateColor(strokecolor),
        width: strokewidth,
      }),
    });
  }

  function createPointStyleObj({
    lineColor,
    radius,
    fillColor,
    width,
    lineDash,
  }) {
    return createPointStyle(lineColor, radius, fillColor, width, lineDash);
  }

  function createPointStyle(
    lineColor = '#3399CC',
    radius = 5,
    fillColor = 'rgba(255, 255, 255, .4)',
    width = 1.25,
    lineDash = null,
  ) {
    var fill = new Fill({
      color: ValidateColor(fillColor),
    });
    var stroke = new Stroke({
      color: ValidateColor(lineColor),
      width,
      lineDash,
    });
    return new Style({
      image: new CircleStyle({
        fill,
        stroke,
        radius,
      }),
      fill,
      stroke,
    });
  }

  function createPointStyleIcon(iconname, iconwidth, iconheight, iconscale) {
    var iconsource = new URL(
      `../../images/mapicons/${iconname}`,
      import.meta.url,
    ).href;
    if (iconname && IconExists(iconsource)) {
      return new Style({
        image: new Icon({
          src: iconsource,
          size: [iconwidth, iconheight],
          scale: iconscale,
        }),
      });
    } else {
      console.warn(iconname + ' does not exist falling back to simple circle');
      return createPointStyle(
        'rgba(200,200,200,0.2)',
        3,
        'rgba(200,200,200,0.5)',
      ); //tiny grey cirkel
    }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function getStyleForLayerViaStylefield(stylefield) {
    //find in array
    //styleFunction
    return function (feature) {
      //console.log(feature);
      var styleid = feature.get(stylefield);
      if (styleid === undefined) {
        styleid = 1;
      }
      var style = styleCache[styleid];
      if (!style) {
        //can't find it in the array, setting a default style
        // console.log(feature.getGeometry().getType());
        if (feature.getGeometry().getType() === 'Point') {
          // TODO + MultiPoint
          style = createPointStyle(
            'rgba(255,255,255,0.7)',
            4,
            'rgba(255, 255, 255, 0.5)',
          );
          style.name = stylefield;
          styleCache[styleid] = style;
        } else if (feature.getGeometry().getType() === 'LineString') {
          //TODO + MultiLineString
          style = createPolylineStyle('rgba(255,255,255,0.7)', 2);
          style.name = stylefield;
          styleCache[styleid] = style;
        } else if (feature.getGeometry().getType() === 'Polygon') {
          //TODO + MultiPolygon
          style = createPolygonStyle(
            'rgba(255,255,255,0.7)',
            4,
            'rgba(255,255,255,0.2)',
          );
          style.name = stylefield;
          styleCache[styleid] = style;
        }
      }
      return [style];
    };
  }

  // Voor later als Alain 2 kleuren gaat gebruiken in SVG
  // function getSvgNEW(svgSize, svgPath, svgColorOut, svgColorIn) {
  //   var svg = getSvg(svgSize, svgPath, svgColorOut);
  //   return svg.replace(/#FFFFFF/g, svgColorIn);
  // }

  function getSvg(svgSize, svgPath, svgColor) {
    const svgTag =
      '<svg version="1.1" id="new1" xmlns="http://www.w3.org/2000/svg" width="' +
      svgSize +
      'px" height="' +
      svgSize +
      'px" viewBox="0 0 ' +
      svgSize +
      ' ' +
      svgSize +
      '">';
    const uncolored = svgTag + svgPath + '</svg>';
    const svg = uncolored.replace(/#000000/g, svgColor);
    return svg;
  }

  function getBaseSvg(name, colorIndex) {
    var svg = svgs[name];
    svg = svg || svgs.default;
    svg.color = svgColors[colorIndex || 0];
    return svg;
  }

  function projectenStyle(model) {
    var strokeWidth = 6;
    var defaultStyle = createPolygonStyle(
      'rgba( 68, 201, 123, 0.7)',
      strokeWidth,
      'rgba( 68, 201, 123, 0.2)',
    );
    var highlightStyle = createPolygonStyle(
      'rgba(150, 191, 228, 0.7)',
      strokeWidth,
      'rgba(150, 191, 228, 0.2)',
    );
    var textStyle = new Text({
      scale: 2,
      fill: new Fill({
        color: 'darkgreen',
      }),
    });
    defaultStyle.setText(textStyle);
    highlightStyle.setText(textStyle);
    return (feature) => {
      const style =
        model.project && model.project.id === feature.get('id')
          ? highlightStyle
          : defaultStyle;
      style.getText().setText(feature.get('subprojectnr'));
      return style;
    };
  }

  const unitColours = {};

  function getStyleForLayer(layername) {
    switch (layername.toUpperCase()) {
      case 'PROJECTEN':
        return projectenStyle(modelService.getModel());
      case 'MEETPUNTEN':
        return meetpuntenStyle(modelService.getModel());
      case 'HECTOMETERPALEN':
        return createPointStyle('red', 5, 'red');
      case 'UNITS': {
        return (feature) => {
          const id = feature.get('id');
          const [r, g, b] = unitColours[id] || [
            Math.round(Math.random() * 255),
            Math.round(Math.random() * 255),
            Math.round(Math.random() * 255),
          ];
          unitColours[id] = [r, g, b];
          let lineColor = `rgba(${r},${g},${b}, .7)`;
          let width = 10;
          const edges = createPointStyleObj({ width, lineColor });
          lineColor = `rgba(${r},${g},${b}, 1)`;
          const transparent = 'rgba(0, 0, 0, 0)';
          width = 0.9;
          const traceEdges = createPointStyleObj({ width, lineColor });
          const verticesOptions = {
            width,
            lineColor,
            radius: 10,
            fillColor: transparent,
          };
          const vertices = createPointStyleObj(verticesOptions);
          const geometry = feature.getGeometry();
          const coordinates = geometry.getCoordinates();
          const points = new MultiPoint(coordinates);
          vertices.setGeometry(points);
          // Create highlight styles.
          lineColor = 'rgba(255, 255, 255, 1)';
          width *= 3;
          verticesOptions.lineColor = lineColor;
          verticesOptions.width = width;
          const verticesHighlight = createPointStyleObj(verticesOptions);
          verticesHighlight.setGeometry(points);
          const traceEdgesHighlight = createPointStyleObj({ width, lineColor });
          // ol will render points on top of linestrings, despite the order in
          // which these styles are given
          return [
            verticesHighlight,
            vertices,
            edges,
            traceEdgesHighlight,
            traceEdges,
          ];
        };
      }
      default:
        return createPolygonStyle('red', 3, '');
    }
  }

  function ValidateColor(rgbastring) {
    //(rgba(getal,getal,getal,getal);

    var result = rgbastring.replace(/\s+/g, ''); //remove spaces
    var cache = /^rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/;
    var array = cache.exec(result); //converted to array of strings
    if (array) {
      // only if rgba is found
      result = [
        parseInt(array[1]),
        parseInt(array[2]),
        parseInt(array[3]),
        parseFloat(array[4]),
      ]; //get the correct array
    }
    return result;
  }

  function IconExists(image_url) {
    var http = new XMLHttpRequest();

    http.open('HEAD', image_url);
    http.send();

    return http.status !== 404;
  }

  function getVectorStyle(stylefield) {
    switch (stylefield) {
      case 'default':
      default:
        return defaultVectorStyle;
    }
  }

  function defaultVectorStyle() {
    var fill = new Fill({
      color: 'rgba(255,255,255,0.4)',
    });
    var stroke = new Stroke({
      color: '#3399CC',
      width: 1.25,
    });
    return [
      new Style({
        image: new CircleStyle({
          fill: fill,
          stroke: stroke,
          radius: 5,
        }),
        fill: fill,
        stroke: stroke,
        // Tijdelijk even uitgezet, is nog te experimenteel
        //text: vectorTextStyle(feature, fill, stroke),
      }),
    ];
  }

  return mapService;
}
